    .dash-tile-container {
        width: 250px;
        height: 76px;
        padding: 10px;
        background-color: white;
        box-shadow: 0px 1px 20px 4px #0000001c;
        display: flex;
        margin: 30px;
        /* text-align: center; */
        border-radius: 5px;
        /* justify-content: center; */
        align-items: center;
        cursor: pointer;
    }

    .dash-tile-container.even {
        background-color: #1C2541;

    }

    .dash-tile-data {
        display: flex;
        padding-left: 10px;
        width: 180px;
        flex-direction: column;
    }

    .tile-icon {
        font-size: 25px;
        color: #25CDC7;
        display: flex;
    }

    .tile-icon.even {
        color: white
    }

    .dash-tile-count {
        font-size: 20px;
        font-weight: 700;
        color: black;
    }

    .dash-tile-count.even {
        color: white;
    }

    .dash-tile-label {
        font-size: 15px;
        font-weight: 600;
        color: black;
    }

    .dash-tile-label.even {
        color: white;
    }

    .dashdataitems {
        display: flex;
        justify-content: flex-end;
    }

    .dash-icon-container {
        height: 47px;
        width: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        border-radius: 8px;
        background: #0A223D;
    }

    .dash-icon-container.even {
        background: #14A5D3;
    }