.dash-banner {
    /* background-image: url("../../../resources/img/dashboard/userdashbanner.png"); */
    width: 100%;
    height: 85px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px !important;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
}

.adr2 {
    border-bottom: 1px solid #c4c4c4;
    border-top: 1px solid #c4c4c4;
    padding: 16px 0px 4px 0px;
}

.proj {
    background: rgba(243, 248, 255, 0.88);
    padding-bottom: 34px;
}

.projbox {
    background-color: white;
    /* width: calc(100% - 599px); */
    /* padding: 45px 142px; */
    margin-top: 20px;
    margin-bottom: 20px;
    /* margin: auto; */
    width: 91%;
    padding: 1px 11px;
}

.lftd {
    /* background-color: #d3d9ed; */
    color: #000;
    padding: 25px 16px;
    border-radius: 20px;
    background-color: #fff;
    padding: 11px 42px;
    border: 24px solid #f3f8ff;
    border-top: 0px;
    border-bottom: 0px;
}


/* .rytd {
      background-color: white;
      padding: 25px 20px;
  } */

.lftd p {
    margin-left: 0px;
    /* color: #000; */
}

.cont-address {
    color: #2cafe7;
    font-weight: 700;
    font-size: 20px;
}

.rytd h3 {
    color: #000;
}

.pd-0 {
    padding: 0px 15px;
}

.instax img {
    width: 35px;
}

.lftd hr {
    width: 40%;
    height: 2px;
}

.rytd hr {
    width: 40%;
    height: 2px;
}

.lftd .card-body {
    text-align: center;
}

.bgw {
    /* background-color: #fff; */
    padding: 21px 0px;
}

.instax {
    padding: 0px 4px;
}

.cont-ico .social-buttons {
    justify-content: center !important;
}

.lftd img {
    background: white;
    border: 1px solid #c4c4c4;
    padding: 7px;
    border-radius: 23px;
}

.lftd .card {
    border-radius: 0;
    /* border: none; */
}

.lftd h5 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    margin-top: 8px;
}

.cont-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 17px 0px 24px 0px;
}

.rytd .fieldset {
    margin-top: 0px;
}

.con-pg {
    margin-top: 0px;
}

.rytd button {
    background: #2cafe7;
    margin-left: 0px;
    float: right;
    margin-top: 2px;
}

.mxauto {
    margin: 0 auto;
}

@media (min-width: 992px) {
    .con-pg {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .lftd {
        margin: 0 auto;
        border: none!important;
    }
}

@media (min-width: 992px) {
    .pd-0 {
        padding: 0px;
    }
}

.bgw svg {
    height: 23rem!important;
}

#envelope {
    animation: float 2s ease-in-out infinite;
}

#star1,
#star2,
#star3,
#star4,
#star5,
#star6 {
    animation: blink 1s ease-in-out infinite;
}

#star2 {
    animation-delay: 100ms;
}

#star3 {
    animation-delay: 500ms;
}

#star4 {
    animation-delay: 700ms;
}

#star5 {
    animation-delay: 300ms;
}

#star6 {
    animation-delay: 200ms;
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}