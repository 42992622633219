.payment-modal  {
justify-content: center !important;
}
 .modal-header {
    background-color:#1c2541 !important;
    color: white !important;
}
 .payment-table th{
    border: 1px solid rgb(165, 164, 164) !important;
    font-weight: bolder !important;
 }
 .payment-table td {
    border: 1px solid rgb(165, 164, 164) !important;
 }
 .modal-footer {
    justify-content: center !important;
 }
 .swal-left-align .swal2-html-container, .swal-left-align .swal2-content {
   text-align: left;
}


.payment-new input {
   margin: 0px !important;
   margin-bottom: 10px !important;
}
.payment-new select {
   border: solid 1px #d5d5d5 !important;
   background-color: white;
   border-radius: 5px;
}
.payment-details-filed{
   display: flex !important;
   flex-direction: column !important;
   justify-content: start;

}
.payment-details-filed label{
   display: flex !important;
margin-bottom: 5px;
/* margin-top: 8px; */

}