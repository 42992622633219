.view-container-comp-1 {
    padding: 25px;
}

.view-container h5 b {
    color: #0a223d;
}

.mb-01 .fieldset {
    margin-bottom: 0px;
}

.font-z select {
    font-size: 14px;
}

.data .row {
    padding: 7px 5px;
    background: #E7E7E7;
    margin: 0 0 10px 0;
}

.back-g .nox .row {
    background-color: transparent!important;
}

.data .row .col-7 {
    color: black;
    font-size: 16px;
}

.data .row .col-3 {
    color: #666;
    font-size: 16px;
}

.mysubbtn:hover {
    color: white;
}

.data .nio {
    color: white !important;
    font-weight: 300;
    font-size: 15px;
    background: #0a223d!important;
}

.blink_me1 {
    animation: blinker 1s linear infinite;
    font-size: 17px !important;
    /* color: white !important; */
    font-weight: 600 !important;
    text-transform: uppercase;
    color: #0a223d!important;
    letter-spacing: 2px;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.dwnld span {
    margin-right: 3px;
    background: #1C2541;
    padding: 3px 5px;
    border-radius: 18px;
}

.dwnld span svg {
    margin-top: -5px;
}

.btn-warning1 {
    background-color: #F2994A;
}

.dwnld {
    color: black !important;
    text-decoration: none!important;
}

.down {
    color: #14A5D3 !important;
}

.text-justify {
    text-align: justify;
}

.dwnld {
    color: #F76C6C;
    font-weight: bold;
    text-decoration: underline;
}

.data .row .col-8 table {
    background-color: white !important;
    margin-top: 0px !important;
}

.venture {
    background-color: white !important;
    padding-top: 10px;
}

.data .current {
    background-color: #0a223d !important;
    color: white !important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white !important;
}

.status label {
    font-size: 16px;
}

.mysubbtn {
    margin-top: 19px;
    background: #0a223d !important;
    color: white;
    padding: 6px 66px;
}

.indicator {
    color: red;
}