.table-head {
  background-color: brown;
  color: #fff;
  border: 1px solid black;

}

tr {
  border: 1px solid black;
}

th {

  padding: 10px;
  text-align: center;
}

td {
  border: 1px solid black;
  padding: 8px;
  font-size: 13px;
  color: #000000;
}

.table-container {
  margin: 10px 10px 10px 10px;
}