.bg-cover {
    width: 100%;
    object-fit: cover;
}


/* ============================================================================================== 
SED Innovations
https://sed.am
https://mkrtchyan.ga
================================================================================================= */


/* .area {
    background: #4e54c8;
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
    width: 100%;
    height: 100vh;
} */

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

* {
    margin: 0;
    padding: 0;
}

header {
    background-color: rgba(33, 33, 33, 0.9);
    color: #ffffff;
    display: block;
    font: 14px/1.3 Arial, sans-serif;
    height: 50px;
    position: relative;
    z-index: 5;
}

h2 {
    margin-top: 30px;
    text-align: center;
}

header h2 {
    font-size: 22px;
    margin: 0 auto;
    padding: 10px 0;
    width: 80%;
    text-align: center;
}

header a,
a:visited {
    text-decoration: none;
    /* color: #fcfcfc; */
}

.login-section {
    /* position: absolute; */
    padding: 20px;
    margin: 0px 21px;
    /* transform: translate(50%, 50%); */
    background: white;
    height: 322px;
    width: 396px;
    border-radius: 5px;
}

.login-container {
    display: flex;
    background-size: cover;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 86px);
    /* background-image: url("../../../resources/img/auth/login.png"); */
    background: #0f0c29;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    min-height: 380px;
}

.login-header {
    font-family: Mulish;
    /* padding: 12px; */
    padding: 5px 12px 12px 12px;
    font-style: normal;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.08em;
}

.login-input {
    margin-top: 15px;
    margin-bottom: 15px;
}

.login-action {
    text-align: center;
}

.number-prefix {
    width: 47px;
    text-align: center;
    margin-right: 13px;
    border: 1px gray solid;
    height: 30px;
    border-radius: 3px;
}

.number {
    width: 190px;
    border: 1px gray solid;
    height: 30px;
    border-radius: 3px;
    padding-left: 6px;
    letter-spacing: 2px;
}

.number-label {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    padding: 20px;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */
    text-align: center;
}

.login-button {
    margin: 20px;
    width: 87px;
    height: 35px;
    /* line-height: 35px; */
    background: #1c2541;
    border: 1px solid #1efff7;
    text-transform: uppercase;
    text-align: center;
    color: #1efff7;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}


/* ---------------------------- */

@media (min-width:992px) {
    .login-section {
        margin: auto;
    }
}