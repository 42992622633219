.view-container {
    padding: 25px 20px 25px 40px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.btn-primary {
    background-color: #1C2541!important;
    border: none;
    padding: 6px 25px;
}

.domain-btn {
    float: right;
    background-color: #14A5D3!important;
}

.mr-2 {
    margin-right: 10px;
}

.edu {
    padding: 10px 0px;
    background: azure;
}

.img-eve {
    width: 300px!important;
    height: 210px!important;
}

.add-per label {
    font-size: 13px;
    font-weight: 500;
}

.pers {
    color: red;
}

.mt-00 .table-bordered {
    margin-top: 0px!important;
}

.mt-16 {
    margin-top: 16px;
}
.btn-approve-check {
    /* background-color: #0a223d; */
    /* color: white; */
    padding: 4px 15px;
    margin-left: 10px;
}
.btn-approve-check-cross{
    padding: 4px 11px;
    margin-left: 10px;
}
.pad-sub {
    padding: 0px 6px;
}