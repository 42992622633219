body {
    margin: 0;
    font-family: -apple-system, "Mulish", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.proposal-tiles-container {
    margin: 20px 0px;
}

.res_photo {
    width: 100%
}
.csvExp{
    height: 32px;
    width: 32px;
}

.header-banner {
    background: #2cafe7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
}
.banner-clamp{
    overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
}
.header-banner-from-home {
    background: #2cafe7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 20px 40px;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
    background: white !important;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 2000ms ease-in;
}

.bgc-white {
    background: white;
}

.check-box-cont {
    display: flex;
    flex-wrap: wrap;
}

.detailFile {
    cursor: pointer;
    color: #ee7e7e;
}


/* width */

/* ::-webkit-scrollbar {
    width: 10px;
} */


/* Track */

::-webkit-scrollbar-track {
    background: #b5b5b500;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #14a5d300;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #55555500;
}

.fieldset .input-group .form-control {
    /* background: #fafafa; */
    border: 1px solid #b8b9ba;
    box-sizing: border-box;
    border-radius: 3px !important;
    font-weight: normal;
    font-size: 13px;
    line-height: 32px;
    color: #222222;
    padding: 0 8px;
    transition: all 0.3s ease;
    background-image: none;
}
.clearSearch{
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    height : 38px !important;
    cursor : pointer;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    background-color: #0a223d;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);

}

.input-group.mandatory .form-control {
    box-shadow: inset 2.5px 0px 0px #ee7e7e !important;
    padding-left: 14px;
}

.upload-field {
    width: 100% !important;
}
.upload-field-head {
    width: 100% !important;
}

.upload-field label {
    background: #ffffff;
    border: 1px solid #b8b9ba;
    border-radius: 3px;
    font-weight: normal;
    font-size: 12px;
    color: #9a9393;
    line-height: 32px;
    height: 32px;
    padding: 0 15px;
    width: 100%;
    cursor: pointer;
    position: relative;
}
.upload-field-head label {
    background: #ffffff;
    border: 1px solid #b8b9ba;
    border-radius: 3px;
    font-weight: normal;
    font-size: 15px;
    color: #9a9393;
    line-height: 33px;
    height: 37px;
    padding: 0 15px;
    width: 100%;
    cursor: pointer;
    position: relative;
}

.proposal-gaps {
    margin-bottom: 20px;
    padding: 20px;
}

.errorfile {
    box-shadow: inset 2.5px 0px 0px #ee7e7e;
}

.allToCenter {
    align-items: center;
    justify-content: center;
    display: flex;
}

.upload-field input[type="file"] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.upload-field-head input[type="file"] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.fieldset .input-group .form-control::placeholder {
    color: #888888;
    font-size: 13px;
}

.fieldset .input-group .form-control {
    height: auto;
    /* cursor: pointer; */
}

.errorinput {
    border-color: 1px red !important;
}

.labelerror {
    color: red !important;
}

.fieldset .input-group select.form-control {
    height: 34px;
    appearance: auto;
}

fieldset.input-group .form-control {
    color: #222;
    font-size: 13px;
    font-weight: normal;
}

fieldset.input-group .form-control::placeholder {
    font-weight: normal;
    font-size: 13px;
    color: #888888;
}

@media (min-width: 992px) {
    .proposal-tiles-container {
        margin: 50px 0px;
    }
}