.view-container {
  padding: 25px 40px 25px 40px;
}
.view-container h5 b {
  color: #0a223d;
}
.font-z select {
  font-size: 14px;
}
.admin-body-items thead tr {
  background-color: #666666;
  color: #ffffff;
}
.admin-body-items tbody tr:hover {
  background-color: #f1eded;
}
.pers{
  color: red;
  font-size: 12px;
}
.mop{
  margin-left: 20px;
}
.mr-2{
  margin-right: 10px;
}