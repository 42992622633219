h1,
p {
  font-family: Lato;
}

table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}

.statuscoloum {
  width: 194px !important;
}

.action-column {
  width: 150px;
  justify-content: center;
  /* align-items: center;
  height: 100%; */
  /* border: none; */
}
.fileaction-column {
  width: 80px;
  justify-content: center;
  /* border: none; */
}

.noFieldTableWidth {
  width: 70px !important;
}

.messagecoloum {
  width: 85px;
}
.parserfont p {
  font-size: 13px;
  font-weight: inherit;
  margin: 0px;
}

.Submit.for.Acceptance {
  background: #14a5d3;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

thead tr th {
  font-weight: 500;
  text-align: left;
  background-color: #fafafa;
}

tbody tr:nth-child(even) {
  background-color: #fafafa;
}

th,
td {
  padding: 8px;
  overflow-wrap: break-word;
  text-transform: none;
  height: 100%;
}

.pagination-bar {
  width: 100%;
  display: flex;
  margin-top: 16px;
  justify-content: center;
}

.table-bordered {
  border: 1px solid #ced4da;
  margin-top: 10px !important;
  font-size: 14px !important;
}

.thed {
  font-weight: bold;
  background-color: #666;
  color: white;
}
.exphead {
  font-weight: bold;
  background-color: #666;
  color: white;
}

.viewContainer {
  width: 26px;
  display: flex;
  height: 26px;
  line-height: 26px;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: black;
}

.notificationContainer {
  /* width: 26px; */
  display: flex;
  height: 26px;
  line-height: 26px;
  border-radius: 50%;
  text-align: center !important;
  align-items: center;
  justify-content: center;
}

.notificationItem {
  color: grey;
  font-size: 20px;
  cursor: pointer;
}

.activenoti {
  color: rgb(49, 6, 149);
  font-size: 20px;
}

.iconCell {
  align-items: center;
  display: flex;
  justify-content: center;
}

.viewItem {
  font-size: 20px;
  color: #14a5d3;
}

.full-img-list {
  width: 500px;
  /* height: 39px; */
}

.img-list {
  width: 67px;
  height: 39px;
}

.img-list img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.full-img-list img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.action-button {
  margin: 5px;
  width: 25px;
  height: 25px;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
}

.actionColumns {
  width: max-content;
}

.action-button.delete {
  background-color: #ff5454;
  color: white;
}

.action-button.view {
  background-color: #0a223d;
  color: #14a5d3;
}

.approve {
  background: #329732;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.reject {
  background: #d10000;
  color: white;
}

.accepted,
.Approved,
.Accepted {
  background: #329732;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.rejected,
.Rejected {
  background: #d10000;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.pending,
.Pending {
  background: #f76c6c;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.inprogress {
  background: rgba(10, 34, 61, 0.69);
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.acceptance {
  background: rgba(214, 150, 54, 0.69);
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}
.acceptance2 {
  background: rgb(156, 71, 2);
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.resubmit {
  background: #14a5d3;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.agrementexecution {
  background: #0a66c2;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}
