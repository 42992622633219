.comp-layout-con {
    padding: 20px;
}

.comp-outer-cont {
    display: flex;
    flex-direction: column;
}

.comp-domain-container {
    display: flex;
    flex-direction: column;
}

.comp-domains {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    width: 100%;
}

.comp-common-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 17px;
    /* identical to box height, or 77% */
    letter-spacing: -0.03em;
    color: #2F2483;
}

.com-dir-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 0px;
}

.comp-search-box {
    width: 100%;
}

.com-select-filter-cont {
    display: flex;
    width: 100%;
}

.com-sel-filters {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.comp-list-con {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.comp-clear-filter {
    width: 80px;
    padding: 10px 0px;
    cursor: pointer;
}

.comp-filter-item-label {
    margin: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #14A5D3;
}

.comp-item-product-prof-cont {
    padding: 17px 0px;
    font-size: 16px;
    color: #5a4d4d;
}

.comp-item-view-more-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
    /* letter-spacing: -0.03em; */
    text-decoration-line: underline;
    cursor: pointer;
    color: #2F2483;
}

.comp-view-more-cont {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0px 25px 25px 0px;
    height: revert;
}

.comp-filter-item {
    margin: 5px;
    display: flex;
    padding: 5px;
    border: 1px solid;
    border-radius: 5px;
    border: 1px solid rgba(20, 165, 211, 0.5);
}

.filter-clearButton-cont {
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-clear-button {
    justify-content: center;
    align-items: center;
    display: flex;
    background: #2cafe7;
    padding: 2px;
    font-size: 20px;
    margin: 5px;
    border-radius: 50%;
    color: white;
    font-weight: 800;
}

.comp-list-item-cont {
    width: 100%;
    padding: 10px 0px;
}

.comp-list-item {
    /* width: 480px; */
    /* height: 600px; */
    height: 100%;
    position: relative;
    /* left: 394px;
top: 473px; */
    background: #FFFFFF;
    box-shadow: 2px 2px 5px rgba(20, 165, 211, 0.25);
    border-radius: 10px;
    padding: 20px;
}

.comp-item-header {
    display: flex;
}

.comp-item-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 20px; */
    font-family: sans-serif;
    font-weight: 700;
    background: #2F2483;
    border-radius: 10px;
    font-size: 66px;
    width: 120px;
    height: 120px;
    color: white;
}

.comp-item-header-data {
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.comp-item-person-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
}

.comp-item-data-icons {
    font-size: 11px;
    color: #2F2483;
    margin: 0px 10px 0px 0px;
}

.comp-item-comp-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 35px;
    padding: 20px 0px 0px 0px;
    /* or 140% */
    letter-spacing: 0.03em;
}

.comp-item-dom-cont {
    display: flex;
    flex-wrap: wrap;
}

.comp-item-dom {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #14A5D3;
    padding-right: 10px;
}

.comp-item-prod-prof-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* identical to box height */
    color: #2F2483;
}

.comp-item-prod-prof-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: 0.03em;
    color: #000000;
    margin-bottom: 5px;
}

.comp-item-prod-prof-desc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    /* or 167% */
    text-align: left;
    color: rgba(0, 0, 0, 0.75);
}
.comp-item-prod-prof-desc p{
font-size: 15px;
text-align: justify;
}
@media only screen and (min-width: 768px) {
    .comp-list-item {
        padding: 25px;
    }
    .comp-list-item-cont {
        width: 50%;
        padding: 10px;
    }
    .comp-list-con {
        flex-direction: row;
    }
    .comp-layout-con {
        padding: 50px;
    }
    .comp-outer-cont {
        flex-direction: row;
    }
    .comp-domain-container {
        padding: 20px;
        width: 25%;
    }
    .comp-domains {
        display: block;
    }
    .comp-common-title {
        font-size: 18px;
    }
    .com-dir-cont {
        width: 75%;
        padding: 20px;
    }
}