.view-container {
  padding: 25px 20px 25px 40px;
}
.view-container h5 b {
  color: #0a223d;
}
.font-z select {
  font-size: 14px;
}
.btn-primary {
  background-color: #1c2541 !important;
  border: none;
  padding: 6px 25px;
}
table td {
  min-width: 65px !important;
}
