.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.pers{
    color: red;
    font-size: 11px!important;
}
.per{
    color: red;
    font-size: 12px!important;
}
.edu{
    padding: 10px 0px;
    background: azure;
    margin: 0px;
}
.mr-2{margin-right: 10px;}
.mt-00 .table-bordered{
    margin-top: 0px!important;
}