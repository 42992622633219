.otp-bg-cover {
    width: 100%;
    object-fit: cover;
}

.otp-login-section {
    padding: 20px;
    margin: auto;
    /* transform: translate(50%, 50%); */
    background: white;
    height: 322px;
    width: 396px;
    /* border-radius: ; */
}

.otp-login-container {
    display: flex;
    background-size: cover;
    width: 100%;
    height: calc(100vh - 86px);
    align-items: center;
    justify-content: center;
    background-image: url("../../../resources/img/auth/otp.png");
}

.otp-otpbox {
    text-align: center;
    justify-content: center;
    display: flex;
}

.otp-login-header {
    font-family: Mulish;
    padding: 12px;
    font-style: normal;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.08em;
}

.otp-login-action {
    text-align: center;
}

.otp-number-prefix {
    width: 50px;
    text-align: center;
    border: 1px gray solid;
    margin: 0px 5px;
}

.otp-resend-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    /* identical to box height */
    text-align: center;
    color: #F76C6C;
}

.otp-not-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    /* identical to box height */
    text-align: center;
    color: #1c2541;
}

.otp-number {
    width: 230px;
    border: 1px gray solid;
}

.otp-number-label {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    padding: 20px;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */
    text-align: center;
}

.otp-login-button {
    margin: 20px;
    width: 87px;
    height: 35px;
    /* line-height: 35px; */
    text-transform: uppercase;
    background: #1c2541;
    text-align: center;
    color: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 3px !important;
}