.newspad{
    background: white;
    width:100%;
    padding: 18px 25px;
  height: auto;
}
/* .deta{} */
  
.page-bg1{
    background-color: #F3F8FF;
    padding-bottom:40px;
   
    /* background-color: blue; */
}
.morenews hr{    margin: 0px 0px 16px 0px;
}
.newslink{overflow: hidden;}
.deta p{font-family: 'Mulish';
text-align: justify;}
.vertical {
  
    padding: 0px 12px;
    width: 50px;
}
.brdr{border-left: 1px solid #777;}
.morenews{
    padding-left: auto;
}
.morenews h6{margin-top: 5px;
    font-weight: 600;
    color: #444;
}
.morenews .card{background-color: #E3ECEF;}
.morenews .card-body{background-color: #E3ECEF;
    padding: 4px 8px;
    font-weight: 600;
    font-style: italic;
}
.morenews .card-body a{
    text-decoration: none;
    float: right;
}

@media (min-width:992px){

    .newspad{
        width: calc(100% - 470px)!important;
        margin-top: 30px;
    }
    .vertical {
        border-left: 1px solid;
    }
    .deta{
        /* border-right: 1px solid; */

        padding-right: 27px;
    }
    .morenews {
        padding-left: 23px;
    }
    .tit-txt {
        font-size: 30px;
    }
    
    

}