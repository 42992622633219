.App {
    font-family: sans-serif;
    text-align: center;
}

.marquee-container {
    display: flex;
    /* justify-content: center; */
}

.marquee {
    /* border: solid 1px #ccc; */
    /* width: 500px; */
    width: 100%;
    /* padding: 50px 0; */
    /* text-align: center; */
    overflow: hidden;
}

.marquee-content {
    animation-duration: 50s;
    display: flex;
    animation-name: marquee;
    width: max-content;
    animation-iteration-count: infinite;
    justify-content: flex-end;
    animation-timing-function: linear;
}

.marquee-content.inactive {
    animation-play-state: paused;
}

@keyframes marquee {
    from {
        transform: translateX(50%)
    }
    to {
        transform: translateX(-100%)
    }
}