@import url("https://use.fontawesome.com/releases/v5.6.3/css/all.css");

/* ------------------------ */

.social-buttons a,
.social-buttons a:visited {
    /* color: unset!important; */
}

.footer-logo-image {
    width: calc(100% - 150px);
    padding: 20px;
}

.social-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -10px;
}
.twitter_icon {
   width: 21px;
   z-index: 999;
   background-color: #fff;
}

.social-buttons__button {
    margin: 10px 5px 0;
}

.social-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: 30px;
    height: 30px;
    text-decoration: none;
    border-radius: 100%;
    background: #fff;
    text-align: center;
    padding: 0px 25px;
}

.social-button::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-radius: 100%;
    transition: 0.3s;
}

.social-button:focus,
.social-button:hover {
    color: #fff;
}

.social-button:focus::after,
.social-button:hover::after {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin-left: calc(-50% - 1px);
}

.social-button i,
.social-button svg {
    position: relative;
    z-index: 1;
    transition: 0.3s;
}

.social-button i {
    font-size: 17px;
}

.social-button svg {
    height: 40%;
    width: 40%;
}

.social-button--mail {
    color: #0072c6;
}

.social-button--mail::after {
    background: #0072c6;
}

.social-button--facebook {
    color: #3b5999;
}

.social-button--facebook::after {
    background: #3b5999;
}

.social-button--linkedin {
    color: #0077b5;
}

.social-button--linkedin::after {
    background: #0077b5;
}

.social-button--github {
    color: #6e5494;
}

.social-button--github::after {
    background: #6e5494;
}

.social-button--youtube {
    color: #FF0000;
}

.social-button--youtube::after {
    background: #FF0000;
}


/* .social-button--insta {
color:  #8a3ab9;    
}
.social-button--insta::after {
    color: transparent;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
} */

.social-button--twitter {
    color: #7a7878;
    z-index: 1 !important;
}

.social-button--twitter::after {
    background: #7a7878;
    z-index: 0 !important;
}

.social-button--insta {
    color: #e4405f;
}

.social-button--insta::after {
    /* background: #e4405f; */
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

header a,
a:visited {
    color: none;
}


/* ----------------------------- */

.my-node-enter {
    opacity: 0;
}

.my-node-enter-active {
    opacity: 1;
    transition: opacity 500ms;
}

.my-node-exit {
    opacity: 1;
}

.my-node-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}

.heat-map-cont {
    width: 100%;
}

.layout {
    height: 100vh;
    /* background: red; */
    overflow: hidden;
}

.new-menuIcon-cont {
    background: white;
    color: #2cafe7;
    padding: 15px;
    display: flex;
    border-radius: 6px;
    margin-right: 40px;
    width: 50px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.header-container {
    height: 120px;
    /* background-color: red !important; */
    display: flex;
    align-items: center;
    font-family: "Poppins";
    /* justify-content: center; */
    /* box-shadow: 0px 0px 10px 0px #00000045; */
    background: white;
}

.userAvatar {
    background: #2f2483;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    color: white;
    /* width: 100%; */
    width: 30px;
    margin: 10px 0px;
    height: 30px;
    font-weight: 900;
}


/* 
.menu-li {
    position: relative;
}

.menu-li.active {
    background-color: #1c2541!important;
    color: #ffffff!important;
    border-radius: 15px;
    text-align: center;
} */


/* .submenu-ul {
    position: static;
    width: auto;
    left: 100%;
    top: -8px;
    margin: 15px;
    border-radius: 10px;
    box-shadow: 3px 3px 0px #0000004d;
    padding: 8px!important;
    background: white;
    color: #1c2541!important;
    list-style-type: none;
    padding: 7px 5px;
    margin-left: 15px;
  
    font-family: mulish!important;
    font-weight: 700;
    font-size: 15px;
} */

@media (min-width: 992px) {
    /* .submenu-ul {
        position: absolute!important;
        width: 325px!important;
        margin: 15px;
        border-radius: 0px!important;
    } */
    /* .menu-li.active {
        border-radius: 0!important;
        text-align: left!important;
    } */
}


/* .submenu-ul li:hover {
    background-color: #1c2541!important;
    color: #ffffff!important;
} */

.body-container {
    /* background: yellow; */
    height: calc(100vh - 120px);
    overflow: auto;
}

.addressLine {
    margin: 0px;
}

.body-items {
    /* background: violet; */
    font-family: mulish;
    /* min-height: calc(100vh - 844px); */
}

.header-menu-icon {
    font-size: 30px;
}

.hed-padi {
    /* padding-top: 10px;
    padding-bottom: 10px ; */
}

.header-title1 {
    /* align-self: end;
    margin-left: -15px;
    font-size: 25px;
    line-height: 38px;
    height: 35px; */
    align-self: end;
    margin-left: -30px;
    font-weight: 700;
    /* margin-top: -22px; */
    font-size: 30px;
    font-family: "PT Sans Narrow";
    line-height: 50px;
}

.header-title {
    /* align-self: auto; */
    margin-left: -30px;
    font-weight: 700;
    /* margin-top: -22px; */
    font-size: 30px;
    font-family: "PT Sans Narrow";
    line-height: 50px;
    margin-top: 24px;
}

.social-small-logo {
    height: 27px;
}

.lang-container {
    padding: 0px 5px;
    border: 1px solid black;
    border-top: 0px;
    border-bottom: 0px;
    margin: auto 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-family: "Montserrat";
    font-size: 13px;
}

.login-status-container {
    padding: 0px 0px 0px 15px;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 13px;
}

.address-container div {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
}

.address-container {
    margin-bottom: 13px;
}

.icon-f-c {
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
}

.contact-no-container {
    margin-bottom: 13px;
}

.email-container {
    margin-bottom: 13px;
}

.logo-link {
    cursor: pointer;
}

.footer {
    /* height: 758px; */
    padding: 50px;
    background: #f5fbfe;
}

.footer-logo {
    width: 98px;
    height: 70px;
}

.kspace-foot {
    /* padding: 20px 0px; */
    width: 100%;
}

.kspace-footer-links {
    width: 100%;
}

.kspace-footer-other-links {
    width: 100%;
    display: block;
}

.kspace-footer-qa {
    width: 100%;
}

.copy-cont {
    display: flex;
    text-align: center;
    margin: 15px 0px 0px;
    align-items: center;
    justify-content: center;
}

.copyrt {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 190%;
    /* identical to box height, or 42px */
    display: flex;
    align-items: center;
    /* Primary */
    color: #009fe3;
}

.kspace-footer-contact {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 0px 10px;
    line-height: 185%;
    /* or 37px */
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
}

.kspace-footer-item-values {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 130%; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 33px;
    /* display: flex; */
    /* align-items: center; */
    color: #000000;
}

.kspace-footer-item-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 190%;
    margin-bottom: 20px;
    margin-top: 20px;
    /* identical to box height, or 46px */
    /* display: flex; */
    align-items: center;
    color: #000000;
}

.insta {
    padding-right: 15px;
}

.kspace-foot-title {
    font-family: "PT Sans Narrow";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 39px;
    margin-top: 40px;
    margin-left: -25px;
    /* identical to box height */
    color: #000000;
}

.kspace-foot-desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 190%;
    padding: 20px 0px;
    /* or 42px */
    /* display: none; */
    align-items: center;
    color: #333333;
}


/* ///////////////////////////// */


/* menu list */

.menuLabel {
    margin: auto 10px;
    font-size: 12px;
    display: flex;
}

.menu-cont {
    cursor: pointer;
}


/* .menulist {
    position: absolute;
    z-index: 99;
    background: #ffffff;
    box-shadow: 0px 10px 20px 6px #0000004d;
    padding: 10px 15px;
    cursor: pointer;
    top: 140px;
    width: 100%;
    left: 0px;
}

.menulist ul {
    padding: 0;
}

.menulist li {
    list-style-type: none;
    list-style-type: none;
    padding: 10px;


font-family: mulish!important;
font-weight: 700;
font-size: 15px;

}
*/

.divider {
    margin: 1px !important;
    height: 1px !important;
    width: 100%;
    opacity: 1;
    color: lightgray;
}

.menu-head-text-cont {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 4px;
    color: #2cafe7;
    font-weight: bold;
}

.footi .social-button {
    background: none !important;
}

.header-profile-title {
    font-family: poppins;
}

.new-nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.new-submenu {
    position: absolute;
    z-index: 999;
    top: 103px;
    padding: 10px;
    background: white;
    border-radius: 10px 0px 20px 0px;
    right: 0;
    box-shadow: -8px 5px 14px 0px #0000004f;
}

.new-dropdown-item {
    background: white;
    cursor: pointer;
    padding: 5px 10px;
}

.new-dropdown-item:hover {
    background: #009de2;
    color: white;
}

.menulist li:hover {
    background-color: #1c2541;
    color: #ffffff;
}

.no-border {
    border: 0px !important;
}

.login-head {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 33px;
    color: #009fe3;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.hideonMobile {
    display: none !important;
}

.no-decoration {
    text-decoration: none !important;
}

.header-logo-image-kg {
    height: 70px;
}
.kit-logo {
    height:50px;
    margin-left: 30px;
}


/* .submenu {
    position: absolute;
    right: 0px;
    top: 140px;
    z-index: 999;
    text-align: left;
    padding: 10px;
    box-shadow: 0px 9px 14px 3px #0000004a;
    margin-right: 5px;
    background: white;
} */

.footer-icons-cont {
    display: none;
}

.footer-icons-cont {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.footer-main-items-cont {
    display: block;
}

.link-style {
    margin: 0;
    /* list-style-type: none; */
    /* padding: 10px; */
    /* border-bottom: 1px solid lightgrey; */
    font-family: mulish !important;
    font-weight: 700;
    font-size: 15px;
    color: #212529 !important;
    /* background: #8c1f1f; */
    text-decoration: none;
}

@media (min-width: 320px) {
    .hideonMobile {
        display: none !important;
    }
    .hideonMobileSocial{
        display:none !important;
    }
    .header-logo-image {
        height: 80px !important;
        margin-left: 30px;
        /* height: 55px !important; */
        /* margin-top: -28px; */
    }
}

@media (min-width: 481px) {
    .hideonMobile {
        display: none !important;
    }
    .hideonMobileSocial{
        display:none !important;
    }
    .header-logo-image {
        height: 80px !important;
        margin-left: 30px;
        /* height: 55px !important; */
        /* margin-top: -28px; */
    }
}

@media (min-width: 769px) {
    .hideonMobile {
        display: none !important;
    }

    .hideonMobileSocial{
        display:none !important;
    }

    .kspace-foot {
        /* padding: 0px 20px; */
        width: 35%;
    }

    .footer {
        /* height: 758px; */
        padding: 50px;
    }

    .kspace-footer-qa {
        width: 30%;
        padding: 20px;
    }
    .header-logo-image {
        height: 80px !important;
        margin-left: 30px;
        /* height: 55px !important; */
        /* margin-top: -28px; */
    }
}


@media (min-width: 992px) {
    .kspace-footer-item-title {
        /* identical to box height, or 46px */
        display: flex;
        margin-top: 0px;
    }

    .footer-main-items-cont {
        display: flex;
    }

    .kspace-footer-other-links {
        width: 20%;
        padding: 20px;
        display: block;
    }

    .copyrt {
        font-size: 15px;
    }
    .new-menuIcon-cont {
        color: white;
        background: #2cafe7;
    }

    .kspace-foot-desc {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 190%;
        padding: 10px 0px;
        /* or 42px */
        /* display: block; */
        /* align-items: center; */
        color: #333333;
    }

    .kspace-footer-links {
        width: 20%;
        padding: 20px;
    }

    .menulist {
        left: 23px !important;
        top: 140px !important;
        width: auto !important;
    }

    .header-logo-image {
        height: 80px !important;
        margin-left: 30px;
        /* height: 55px !important; */
        /* margin-top: -28px; */
    }

    .hideonMobile {
        display: block !important;
    }

    .footer-right-part {
        justify-content: flex-end !important;
        display: flex !important;
        width: 33% !important;
    }

    .usefull-underline {
        height: 2px !important;
        margin: 5px 0px 15px !important;
        width: 120px !important;
        color: #009de2 !important;
        opacity: 1 !important;
    }

    .useFull-links-item {
        padding-right: 10px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */
        color: #f6f8fa;
    }

    .useFull-links-item:hover {
        color: #009de2;
        cursor: pointer;
    }

    .links-section {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 160px;
        text-align: left;
        margin-right: 100px;
    }

    .usefullLinksTitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        color: #14a5d3;
    }

    .links-container {
        display: flex;
    }

    .footer-center-part {
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        display: flex !important;
        width: 52% !important;
        padding: 10px;
        color: #fff;
    }

    .footer-left-part {
        justify-content: flex-start;
        align-items: center;
        width: 25%;
        display: flex;
    }

    .footer-items {
        flex-direction: row !important;
    }

    .footer-icons-cont {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hideonMobileSocial{
        display:none !important;
    }
    @media (min-width: 1200px) {
    .hideonMobileSocial {
        display: block !important;
    } 
    .header-logo-image {
        height: 100px !important;
        width: 175px !important;
        margin-left: 30px;
        /* height: 55px !important; */
        margin-top: -8px;
    }}
}