      .overlay {
          background: #000000b0;
          position: fixed;
          top: 0%;
          left: 0%;
          width: 100%;
          height: 100%;
          z-index: 999;
          transform: translate(0%, 0%);
      }
      
      .action-dialog-cont {
          background: #ffffff;
          position: fixed;
          border-radius: 10px;
          box-shadow: 1px 1px 20px 5px #000000a1;
          top: 50%;
          left: 50%;
          padding: 20px;
          /* width: 400px;
          height: 200px; */
          transform: translate(-50%, -50%);
      }
      
      .action-title-cont {
          font-weight: 700;
          font-size: 17px;
          padding-bottom: 20px;
      }
      
      .action-msg {
          font-size: 14px;
          font-family: 'Mulish';
          height: 60px;
          font-weight: 600;
      }
      
      .actn-btn-cntr-cont {
          display: flex;
          justify-content: flex-end;
      }
      
      .act-btn {
          margin-left: 20px;
      }