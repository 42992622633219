.cardx {
    padding: 10px;
    margin: 0px 35px;
}
.sam{
    padding: 15px!important;
}
.xbody{
    padding: 0px!important;
}
.rytbody span {
    color: #14A5D3;
    font-size: 16px;
}

.rytbody p {
    margin-bottom: 0px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
}
.pic{
    /* width: 120px; */
    border-radius: 50%;
    height: 200px;
    width :200px;
}
.rytbody h6{
    font-size: 18px;
    font-weight: 600;
    margin-top: 3px;
    margin-bottom: 0px;
    color: #1c2541;
}
.detailb{
    font-size: 13px;
    padding: 4px 47px;
    margin-top: 3px;
    color:white!important ;
    border: 1px solid #1c2541;
    /* background: white!important; */
}
.xbody p{
    margin-bottom: 2px!important;
}
/* .cardx:hover .detailb{
    border: 1px solid #1c2541;
    background: #1c2541!important;
    color: white!important;
} */
.smal {
    text-align: justify !important;
    line-height: 16px;
    color: #555;
}
.cors{
    font-weight: 700;
    color:#777;
}
.smal small {
    font-size: 13px !important;
    font-weight: 500;
    color: black;
}
.cardx:hover{
    background-color: #D2EBF7;
}
.member h6 {
    color: black;
    font-size: 14px;
    text-align: center;
}

.member hr {
    border: black;
}

.ceo p {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 700;
    color: #0A223D;
}

.ceo h6 {
    font-size: 11px;
    color: #666;
    margin-bottom: 0px;
}

.ceo address {
    font-size: 11px;
    font-weight: 500;
}
.proj{
    background-color: #F3F8FF;
}
.bodybox .cardx {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.deta1{
    background: white;
    margin: auto;
    padding-bottom: 40px;
    padding-top: 30px;

}
.cd{
    padding: 0px 55px;
}
.pic2{
    width: 150px;
    border-radius:50%;
}
.gray{
    background-color: #dfe3e899;
    text-align: left;
    padding: 5px 0px 5px 18px;
}
.gray .col-8{
    font-size: 14px;
    font-weight: 600;
}
.edux{
    padding-right: 26px;
    padding-bottom: 13px;
}
tbody{
    background-color: white!important;
}
/* =================================================================================== */
@media (min-width:992px) {
    .dash-banner {
        height: 162px;
    }
    .cardx{
        margin: auto;
    }
    .tit-txt {
        font-size: 30px;
        line-height: 38px;
    }
    .rytbody{
        /* padding-left: 18px; */
    }
    .cd{
        padding:initial;
    }
}