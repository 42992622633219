        .error {
            background: #dc3545!important;
            border-radius: 10px 10px 0px 0px;
        }
        
        .snackIconContainer {
            height: 220px;
        }
        
        .snackTitle {
            font-size: 25px;
            font-weight: 700;
            text-align: center;
            color: #184688;
            margin: 10px;
        }
        
        .snackIcon {
            font-size: 150px;
            transform: translate(75px, 35px);
            color: white;
        }
        
        .success {
            background: #35b651!important;
            border-radius: 10px 10px 0px 0px;
        }
        .info {
            background: #f8d301!important;
            border-radius: 10px 10px 0px 0px;
        }
        
        .iconContaier {
            color: white
        }
        
        .snackbar-msg-p {
            font-family: 'Mulish';
            margin: 0px!important;
        }
        
        .snackbar {
            background: #ffffff;
            position: fixed;
            border-radius: 10px;
            box-shadow: 1px 1px 20px 5px #000000a1;
            top: 50%;
            left: 50%;
            width: 300px;
            /* height: 424px; */
            transform: translate(-150px, -212px);
        }
        
        .overlay-snack {
            background: #000000b0;
            position: fixed;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            z-index: 9999;
            transform: translate(0%, 0%);
        }
        
        .snackmessageContainer {
            margin: 0;
            padding: 0px 20px;
            height: auto;
            overflow: auto;
            text-align: center;
        }
        
        .snackbuttonContainer {
            justify-content: center;
            /* padding: 14px; */
            display: flex;
            padding: 10px;
        }
        
        .snackButtonClass {
            width: 120px;
            color: white;
            height: 43px;
            /* box-shadow: 0 0 20px 0px #0000005e; */
            font-weight: 600;
            border: none;
            border-radius: 50px;
            margin-bottom: 7px;
        }