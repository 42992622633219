.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.ml-3{margin-left: 10px;}
.res{
    background: #dde7ee;
    padding:  10px 15px;
    text-align: justify;
}
.mandatory-select{
    border: 1px solid #b8b9ba70 !important;
    border-radius: 5px !important;
    border-left: 2px red !important;
    /* line-height: 31px !important; */
    font-size: 15px !important;
}