.dash-banner {
  background-image: url("../../../resources/img/dashboard/userdashbanner.png");
  width: 100%;
  height: 85px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.no-margin {
  margin: 0px !important;
}

.tit-txt {
  font-family: Mulish;
  font-style: normal;
  color: white;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  padding: 0px 11px;
}
.key {
  margin: 13px 0px;
}
.mx-ht{
    max-height: 230px!important;
    overflow: hidden;
}
.member hr {
  margin: 0px 0px 7px 0px !important;
}
.card {
  position: relative;
  transition: all 0.5s ease-in;
}
.smil {
  color: unset !important;
  font-size: 12px !important;
}

.card__image {
  display: block;
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: none;
  transition: 0.5s ease;
  /* background-color: #393839; */
  background-color: #d2ebf7;
}

.card:hover .card__overlay {
  opacity: 1;
}

.overlay__text {
  /* color: white; */
  /* font-size: 13px; */
  position: absolute;
  text-align: justify;
  position: relative;
  top: 50%;
  height: inherit;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0px 10px;
}
.body-container {
  background: #f3f8ff;
}
.button {
  background-color: #f05328;
  padding: 1.5rem;
  margin-top: 2rem;
  display: inline-block;
  color: white;
  text-decoration: none;
}

.bodybox .card {
  padding: 15px 10px;
  font-weight: 600;
}

.sox {
  padding: 10px 17px;
}
.overlay__text p {
  font-size: 13px;
}
.nilo small {
  font-size: 12px !important;
}
.rytbody span {
  color: #14a5d3;
  font-size: 14px;
}

.rytbody p {
  margin-bottom: 8px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.rytbody h5 b {
  font-size: 19px;
}
.rytbody h5 {
  margin-top: -5px;
}
.mer {
  padding-top: 17px;
}
.smal {
  text-align: justify !important;
  line-height: 16px;
  color: #555;
  margin-top: 5px;
}

.smal small {
  /* font-size: 12px !important; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: justify;
  /* letter-spacing: -0.2px; */
  font-size: 12px !important;
  letter-spacing: 0px;
  /* word-break: break-all; */
  word-spacing: -2px;
}

.member h6 {
  color: black;
  font-size: 16px;
  text-align: center;
  min-height: 16px;
}
.member hr {
  border: black;
}
.ceo p {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 700;
  color: #0a223d;
}
.ceo h6 {
  font-size: 10px;
  color: #666;
  margin-bottom: 0px;
}
.ceo address {
  font-size: 9px;
  font-weight: 600;
  /* padding: 0px 4px; */
}
.ceo {
  padding: 0px 3px;
}
.bodybox .card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.clogo {
  height: 84px;
  width: 90px;
}
.webs span {
  font-size: 11px !important;
  margin-left: 2px;
}
.img {
  padding: 12px;
}
/* =================================================================================== */
@media (min-width: 992px) {
  .dash-banner {
    height: 162px;
  }

  .sox {
    padding: 17px;
  }
  .tit-txt {
    font-size: 30px;
    line-height: 38px;
  }
  
}

@media screen and (min-width: 480px) {
  .smal small {
    word-spacing: 0px;
  }
}

@media (min-width: 756px) {
  .rytbody {
    padding-left: 0px;
  }
}
@media (min-width: 640px) {
  .rytbody {
    padding-left: 0px;
  }
}
@media (min-width: 900px) and (min-width: 1000px) and (min-width: 1215px) and (min-width: 1100px) and (max-width: 1228px) and (min-width: 1212px) and (min-width: 1217px) {
  .rytbody {
    padding-left: 30px;
  }
}
@media (min-width: 1086px) {
  .rytbody {
    padding-left: 38px;
  }
}
@media (min-width: 1110px) {
  .rytbody {
    padding-left: 30px;
  }
}
@media (min-width: 1240px) {
  .rytbody {
    padding-left: 30px;
  }
}
@media (min-width: 1241px) {
  .rytbody {
    padding-left: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .rytbody {
    padding-left: 10px;
  }
}
