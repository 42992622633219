.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.ml-3{margin-left: 10px;}
.res{
    background: #dde7ee;
    padding:  10px;
}
.mor1{
    display: none;
}
#toggle-content1 {
    display: none;
  }
  .mycheckbox1:checked~#toggle-content1 {
    display: block;
    height: 100px;
  }
    .mop{
        margin-left: 10px;
    }
    .pers{
        color: red;
    }
    .edu{
        background-color: azure;
        margin: 0px;
        padding: 6px;
    }