.mydash{
    /* background: hsla(18, 76%, 85%, 1);

background: linear-gradient(90deg, hsla(18, 76%, 85%, 1) 0%, hsla(203, 69%, 84%, 1) 100%);  */
/* background: #373B44; 
background: -webkit-linear-gradient(to right, #4286f4, #373B44); 
background: linear-gradient(to right, #4286f4, #373B44);  */
background-color: #E3EEFA;
padding-top:15px;
padding-bottom: 15px;
}
.inline{
    display: inline-flex;
    padding: 0px 33px;
}
.dash-header-button{
    align-items: center!important;
}
.edit-app-button {
    width: 170px;
    height: 32px;
    border: 1px solid #0a223d;
    display: flex;
    border-radius: 2px;
    align-items: center;
    padding: 0px 10px;
    color:white;
}

.demo{}
.edit-label{
    color: #0a223d;
}
.menu1{
    background-color: #f3f6f3;
    margin-top: 10px;
}

.loc span{
    margin-top: -1px;
    font-size: 14px;
}