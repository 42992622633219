.admin-login-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* justify-content: center; */
    background-image: linear-gradient(#e4eaeb00, #5d8ecd8a)
}

.admin-login-container {
    padding: 50px;
    width: 527px;
    height: 358px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
    background: white;
}

.admin-login-title {
    font-size: 16px;
    font-weight: 700;
    padding: 20px;
}

.login-input {
    margin-top: 15px;
    margin-bottom: 15px;
}

.admin-login-logo {
    height: 131px;
    margin: 40px;
    width: 179px;
}

.butnCnt {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
}