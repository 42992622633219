.dash-banner {
    /* background-image: url("../../../resources/img/dashboard/userdashbanner.png"); */
    width: 100%;
    height: 85px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px !important;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
}

.proj {
    background: rgba(243, 248, 255, .88);
    padding-bottom: 34px;
}

.projbox {
    background-color: white;
    /* width: calc(100% - 599px); */
    /* padding: 45px 142px; */
    margin-top: 20px;
    margin-bottom: 20px;
    /* margin: auto; */
    width: 91%;
    padding: 1px 11px;
}

.pdf {
    background-color: #D2EBF7;
}


/* .proposalbox {
    padding: a;
} */
.flexy{
    width: 83%!important;
}
.proposalbox .card{
    border: none!important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.pro-data {
    padding: 8px 14px 0px 13px;
}

.pro-data h5 {
    font-size: 18px;
    font-weight: 600;
}

.pro-data small {
    font-size: 12px;
    color: #A1A8AF;
}

.pro-data .d-flex p {
    margin-bottom: 3px;
    margin-left: 4px;
    font-size: 13px;
    font-weight: 600;
}

.pro-data .d-flex span {
    background: #DFE3E8;
    color: #0A223D;
    padding: 0px 5px;
    border-radius: 22px;
}

.pro-data h6 {
    font-size: 12px;
    text-align: justify;
    color: rgba(68, 68, 68, 0.94);
}


.vwbt {
    padding: 2px 15px!important;
    margin: 0px 8px;
}
.pdfimg{
    width: 65px;
}
.pro-data h6{
    word-spacing: -1px;
}
/* =================================================================================== */

@media (min-width:992px) {
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
    }
    .flexy{
        width: 60%!important;
    }
    .proposalbox {
        padding: 0px 67px;
    }
    .projbox {
        background-color: white;
        width: calc(100% - 599px);
        padding: 45px 142px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .pro-data h6 {
        font-size: 12px;
    }
    .vwbt{
        padding: 2px 26px!important;
    }
    .pdfimg{
        width: 90px;
    }
    .pro-data h6{
        word-spacing: 0px;
    }

}