.dash-banner {
    background-image: url("../../../../resources/img/dashboard/userdashbanner.png");
    width: 100%;
    height: 162px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px!important;
}
.data .back-g {background-color: transparent!important;}
.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
}

.basic-submit {
    margin: 0px!important;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-bg {
    background: #EBE9E9;
    padding: 4px;
}

.page-bg {
    background: #EBE9E9;
}

.section-Cont {
    background-color: #F3F8FF;
    padding: 20px;
    margin-bottom: 15px;
    /* margin: 20px 0px; */
    border: 1px solid rgba(85, 85, 85, 0.5);
}

.items-cont {
    padding: 20px 10px;
}

.to-left-side {
    display: flex!important;
    align-items: center!important;
    justify-content: flex-start!important;
}

.main-head-txt {
    font-size: 15px;
}

.data {
    padding: 8px 0px 10px 0px;
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 0px;
}

.form-section {
    background: white;
    width: 100%;
}

.fileContainer {
    display: flex
}

.more-btn-cnt {
    margin: 15px 0px;
    display: flex;
    flex-direction: row-reverse;
}

.chk-items-cont {
    padding: 10px
}

.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile:focus+label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
    pointer-events: none;
}

.secondary-btn {
    background: #F76C6C;
    padding: 10px 25px;
    margin: 0px 10px;
    cursor: pointer;
    border-radius: 4px;
    color: white;
}

thead {
    background: rgb(255, 255, 255)!important;
    color: #0A223D!important;
}

.btn-primary {
    background: #1C2541!important;
    border: none;
    cursor: pointer;
}

.primary-btn {
    background: #1C2541;
    padding: 10px 27px;
    margin: 0px 10px;
    color: white;
    cursor: pointer;
    border-radius: 3px;
}

.optionLabelHeader-cont {
    color: black;
    font-weight: 500;
    height: 29px;
    /* padding: 0px 20px; */
    line-height: 29px;
}

.tableHeading {
    background: #0A223D;
    color: white;
    height: 25px;
    line-height: 25px;
    padding: 0px 15px;
}

.table-list-cont {
    margin: 20px 10px;
}

.header-body-spacing {
    margin-top: 20px;
}

.header-helper-text {
    text-align: right;
    color: red;
    font-weight: 700;
}

.headerbuttonodd {
    height: 40px;
    background: #E3ECEF;
    margin-right: 50px;
    color: #000;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.headerbuttonodd.active {
    height: 40px;
    background: #0A223D;
    margin-right: 50px;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.indexCont {
    background: #14A5D3;
    width: 30px!important;
    text-align: center;
    border-radius: 50%;
    margin: auto 10px;
    color: white;
    height: 30px!important;
    line-height: 30px;
}

.indexCont.even {
    margin-left: -15px;
    z-index: 1;
}

.headerbuttonodd.active:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #0A223D;
}

.headerbuttonodd:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #E3ECEF;
}

.headerbuttoneven {
    height: 40px;
    background: #E3ECEF;
    display: flex;
    color: #000;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #E3ECEF;
    border-left: 20px solid transparent;
}

.headerbuttoneven.active {
    height: 40px;
    background: #0A223D;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven.active:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #0A223D;
    border-left: 20px solid transparent
}

.form-inner-sections {
    /* padding: 12px 0px 20px 0px; */
    width: 100%;
}

.fieldset {
    margin: 15px 0px;
}

.file-up-helper {
    color: red;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 14px;
}

.p-10 {
    padding: 5px
}

.section-datas-cont {
    /* margin: 17px auto; */
}

.file-up-label-cont {
    margin-bottom: -30px;
    margin-top: 11px;
    color: #1C2541!important;
    font-weight: 600;
    line-height: 17.75px;
}

.header-label {
    margin-top: 15px;
    margin-bottom: 14px;
    font-weight: 600;
    color: #1C2541;
}

.view-container {
    padding: 25px 20px 25px 40px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.data .row {
    padding: 7px 5px;
    background: #E7E7E7;
    margin: 0 0 10px 0;
}

.data .row .col-8 {
    color: black;
    font-size: 16px;
}

.data .row .col-3 {
    color: #666;
    font-size: 16px;
}

.mysubbtn:hover {
    color: white;
}

.nio {
    color: white!important;
    font-weight: 300;
    font-size: 15px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px!important;
    font-weight: 400!important;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.text-justify {
    text-align: justify;
}

.dwnld {
    color: #F76C6C;
    font-weight: bold;
    text-decoration: underline;
}

.data .row .col-8 table {
    background-color: white!important;
    margin-top: 0px!important;
}

.venture {
    background-color: white!important;
    padding-top: 10px;
}

.data .current {
    background-color: #0a223d!important;
    color: white!important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white!important;
}

.status label {
    font-size: 16px;
}

.mysubbtn {
    margin-top: 19px;
    background: #0a223d!important;
    color: white;
    padding: 6px 66px;
}

.filex {
    margin-top: -13px;
}

.indicator {
    color: red;
}

.headerbuttoneven {
    font-size: 12px;
}

.headerbuttonodd {
    font-size: 12px;
}

.accordion-body {
    padding: 1rem 0rem;
}

.scrolltable {
    overflow: hidden;
    overflow-x: auto;
    padding: 0px;
}

.ownlad {
    padding: 0px 14px;
}

.jointv {
    padding: 0px 14px;
}

.bg-col {
    background-color: #d8e6eca1!important;
}


/* ================================================================================================================================== */

@media (min-width:992px) {
    .form-section {
        width: 921px!important;
    }
    .items-cont {
        padding: 60px 60px 81px!important;
    }
    .form-bg {
        padding: 50px!important;
    }
    .bg-col {
        background-color: #d8e6eca1!important;
    }
    .headerbuttoneven {
        font-size: 14px!important;
    }
    .headerbuttonodd {
        font-size: 14px!important;
    }
    .accordion-body {
        padding: 12px 10px!important;
    }
    .ownd textarea {
        height: 110px!important;
    }
    .table-list-cont {
        margin: 20px;
    }
}