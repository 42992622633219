.launchheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  color: red;
  padding: 0px 20px;

}

.launchfooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  color: red;
  padding: 0px 20px;


}

.launchmain-logo {
  height: 90px;
  width: 120px;
}

.launchhero-area {
  background: linear-gradient(0deg, rgba(12, 12, 12, 0.5), rgba(18, 17, 17, 0.5));
  height: 50vh;
  position: relative;

}

.launchmain-hero-wrapper {
  height: 100vh;
}

.launchcontent-area {
  position: absolute;
  top: 72%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: poppins;


}

.launchcm-img {
  height: 200px;
  width: 200px;
  background-color: #d5d5d5;
  border-radius: 50%;
  padding: 5px;
}

.launchcontent-area button {
  padding: 15px 32px;
  background-color: #2f2483;
  border-radius: 15px;
  transition: 500ms;
  border: solid 3px #2f2483;
  font-weight: 600;
  font-size: 21px;
  color: #fff;
}

.launchcontent-area button:hover {
  border: solid 3px #2f2483;
  color: #2f2483;
  background-color: #fff;
}

.launchfooter-logo {
  width: 300px;
  height: 90px;
}