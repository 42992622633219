.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 500px;
  /* height: 460px; */
  transform: translate(-150px, -212px);
  padding: 30px;
}
.big-dia8 {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 500px;
  /* height: 460px; */
  transform: translate(-150px, -212px);
  padding: 30px;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebar {
  display: flex;
  margin: 5px 0px 15px 0px;
  /* flex-direction: column; */
  align-items: center;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebar hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
  padding-right: 0;
}

.book-form button {
  margin-top: 15px;
}
.dialog .form-select {
  font-size: 13px;
  box-shadow: inset 2.5px 0px 0px #ee7e7e;
}
