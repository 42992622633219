.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
p{font-family: mulish;font-size: 16px;
    /* margin-top: 20px; */
    color: #444;}
.accominput .fieldset{margin-top: 15px!important;}