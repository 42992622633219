.dash-banner {
    /* background-image: url("../../../resources/img/dashboard/userdashbanner.png"); */
    width: 100%;
    height: 85px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px !important;
}
.rytbody h5  b{
    color: #1c2541;
    
}
.no-data{
    text-align: center;
}
.no-data img{
    width: 350px;
}
.mb-20{
    margin-bottom: 14px;
}
.max-ht{
    max-height: 209px!important;
    overflow: hidden;
}
.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
   
}
.cad {
    padding: 10px;
    min-height: 300px;
}

.rytbody span {
    color: #14A5D3;
    font-size: 16px;
}

.rytbody p {
    margin-bottom: 2px;
    font-weight: 700;

}

.smal {
    text-align: justify !important;
    line-height: 16px;
    color: #555;
}
.cors{
    font-weight: 700;
    color:#777;
}
.smal small {
    font-size: 13px !important;
    font-weight: 500;
}
.cad:hover{
    background-color: #D2EBF7;
}
.member h6 {
    color: black;
    font-size: 14px;
    text-align: center;
}

.member hr {
    border: black;
}

.ceo p {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 700;
    color: #0A223D;
}

.ceo h6 {
    font-size: 11px;
    color: #666;
    margin-bottom: 0px;
}
.lok span {
    margin-top: -2px!important;
}
.ceo address {
    font-size: 11px;
    font-weight: 500;
}
.pl-50{
    padding-left: 8px;
    margin-top: 2px;
}
.bodybox .cad {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.vwbt {
    padding: 2px 15px!important;
    margin: 0px 8px;
}
.pdfimg{
    width: 65px;
}
.proj{
    padding-bottom: 9px!important;
}
.pro-data h6{
    word-spacing: -1px;
}
/* =================================================================================== */
@media (min-width:992px) {
    .dash-banner {
        height: 162px;
    }

    .tit-txt {
        font-size: 30px;
        line-height: 38px;
    }
    .mb-20{
        margin-bottom: 23px;
    }
    .vwbt{
        padding: 2px 26px!important;
    }
    .pdfimg{
        width: 90px;
    }
    .pro-data h6{
        word-spacing: 0px;
    }

}