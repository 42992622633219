.hr {
    margin: 1px!important;
    height: 2px!important;
    width: 60px!important;
    border-radius: 10px!important;
    opacity: 1!important;
    color: #14A5D3!important;
}

.top {
    margin-left: 0px!important;
}

.bottom {
    margin-left: 20px!important;
}

.container-ul {
    display: flex;
    margin: 5px 0px 10px 0px;
    flex-direction: column;
    align-items: center;
}