h1,
p {
  font-family: "Mulish";
}

table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}

thead tr th {
  font-weight: 500;
  text-align: left;
  background-color: #fafafa;
}

tbody tr:nth-child(even) {
  background-color: #fafafa;
}

th,
td {
  padding: 8px;
  overflow-wrap: break-word;
}

.table-bordered {
  border: 1px solid #ced4da;
  margin-top: 7px!important;
  font-size: 14px !important;
  
}

.table-container {
  margin: 10px 0px;
}

.selectbox.joint-venture {
  width: 70% !important;
}

.pad20 {
  padding: 20px !important;
}
.ownd .fieldset {
  margin: 7px 0px;
  /* height: 100%; */
}
.bg-col {
  background-color: #d8e6eca1 !important;
}
.jointx .fieldset {
  margin: 7px 0px;
}
.jointx .joix {
  margin: 11px 0px;
}
