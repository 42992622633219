.tile img {
    background: #0A223D;
    border-radius: 50%;
    border: 2px solid #0A223D;
}

.tile h6 {
    margin-top: 4px;
    font-size: 17px;
    margin-bottom: 0px;
}

.tile p {
    color: #14A5D3;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
}

.adviser_image {
    width: 130px;
    height: 130px;
    border-radius: 50%!important;
    object-fit: cover;
}

.adv-bg {
    background: #f3f8ff;
}

.adviser_image.chief {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%!important;
}

;
.cd {
    background-color: unset!important;
}

.cd:hover {
    background-color: white!important;
}

.adviser-cards-container {
    width: 200px;
    margin: 15px auto;
}

.tile address {
    font-size: 12px;
    line-height: 13px;
    font-weight: 300;
}

.tile {
    border: none;
}

.hedp {
    background: #0A223D;
    text-align: center;
    padding: 7px 0px;
    color: white;
    font-size: 14px;
    border-radius: 3px;
}

.adbox {
    padding: 0px 16px;
}

.card:hover {
    background-color: #D2EBF7;
}


/* =================================================================================== */

@media (min-width:992px) {
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
        line-height: 38px;
    }
    .hedp {
        font-size: 17px;
    }
}