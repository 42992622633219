.error {
    background: #dc3545 !important;
    border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
    height: 220px;
}

.snackTitle {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    color: #184688;
    margin: 10px;
}

.snackIcon {
    font-size: 150px;
    transform: translate(75px, 35px);
    color: white;
}

.success {
    background: #35b651 !important;
    border-radius: 10px 10px 0px 0px;
}

.btn-closex {
    background-color: #0a223d;
    padding: 4px 32px;
    margin-top: 11px;
    color: white;
}

.iconContaier {
    color: white;
}

.w-100 {
    width: 100%!important;
}

.msgbox {
    display: flex;
    flex-direction: column;
    border: 1px solid #777;
    padding: 0px;
    min-width: 414px;
    min-height: 330px;
    max-height: 330px;
    overflow: hidden;
    overflow-y: auto;
}

.adminbox small,
.superbox small {
    font-size: 14px;
    font-weight: 600;
}

.adminbox p,
.superbox p {
    font-family: mulish;
    margin-bottom: 1px;
    font-size: 13px;
}

.superbox {
    background-color: #C5E6F0;
    width: calc(100% - 77px);
    border-radius: 4px 0px 0px 4px;
    float: right;
    padding: 0px 5px;
    margin-top: 10px;
}

.adminbox {
    background-color: #cfd3f2;
    width: calc(100% - 77px);
    border-radius: 0px 4px 4px 0px;
    padding: 0px 5px;
    margin-top: 10px;
}

.superbox p {
    text-align: right;
}

.superbox small {
    width: 100%;
    float: right;
    text-align: right;
}

.big-dia1 {
    background: #ffffff;
    position: fixed;
    box-shadow: 1px 1px 20px 5px #000000a1;
    top: 50%;
    left: 50%;
    width: 450px;
    height: 460px;
    transform: translate(-50%, -50%);
    padding: 30px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.overlay {
    background: #000000b0;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 999;
    transform: translate(0%, 0%);
}

.messageContainer {
    margin: 0;
    padding: 0px 20px;
    height: 70px;
    overflow: auto;
    text-align: center;
}

.buttonContainer {
    justify-content: center;
    /* padding: 14px; */
    display: flex;
}

.snackButtonClass {
    width: 125px;
    color: white;
    height: 50px;
    /* box-shadow: 0 0 20px 0px #0000005e; */
    font-weight: 600;
    border: none;
    border-radius: 50px;
}

.book-head {
    font-family: "Mulish";
    font-weight: 600;
    line-height: 21px;
}

.tilebar1 {
    display: flex;
    margin: 5px 0px 10px 0px;
    flex-direction: column;
    align-items: center;
}

.diaCloseCon {
    /* padding: 20px; */
    float: right;
    font-size: 20px;
    font-weight: 900;
    line-height: 1;
    cursor: pointer;
}

.flexDisplay {
    display: flex;
}

.diaTitleCon1 {
    /* padding: 20px 0px 0px 20px; */
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    line-height: 1;
    padding-right: 0px;
}

.tilebar hr {
    margin: 1px !important;
    height: 2px !important;
    width: 51px;
    opacity: 1;
    color: #14a5d3;
}

.dialog1 input {
    width: 100%;
}

.dialog1 label {
    font-size: 13px;
    font-weight: 500;
}

.book-form div {
    margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
    line-height: 30px;
    border-radius: 4px;
    padding-right: 0;
}

.book-form button {
    margin-top: 15px;
}

.dialog1 .form-select {
    font-size: 13px;
    box-shadow: inset 2.5px 0px 0px #ee7e7e;
}