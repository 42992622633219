.tender-head {
    padding: 15px 0px;
}

.tender {
    min-height: calc(100vh - 428px);
}

.bg-tender {
    background: #f3f8ff;
}

.tender-head h4 {
    text-align: center;
    margin-bottom: 0px;
}

.container-ul {
    display: flex;
    margin: 5px 0px 10px 0px;
    flex-direction: column;
    align-items: center;
}

.hr {
    margin: 1px !important;
    height: 3px !important;
    width: 35px;
    opacity: 1;
    color: #14a5d3;
}

.top {
    margin-left: 0px !important;
}

.bottom {
    margin-left: 20px !important;
}

.bg-white {
    padding-bottom: 45px;
}

.res-page-cont {
    padding: 0px 0px;
}

.new-res-tile-cont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.new-res-tile-cont-jb {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-evenly;
    padding-top: 40px !important;
    /* width: 100%; */
}

.res-page-cont-details {
    padding: 10px 0px;
}

.res-det-con {
    margin: 0px;
    background: white;
    padding: 20px;
    border: 0.4px solid #888888;
}

.bg-res {
    background: white;
}

.res-ban-img {
    object-fit: cover;
    height: 83px;
}

.res-bannerContainer {
    position: relative;
    flex-direction: column;
}

.res-profile-img {
    width: 130px;
    height: 130px;
    object-fit: cover !important;
}

.profile-box {
    background: #FFFFFF;
    border: 1px solid rgba(68, 68, 68, 0.6);
    box-sizing: border-box;
    padding: 10px;
}

.profile-res-con {
    width: 100%;
    padding: 10px;
    margin-top: 0px;
    background: white;
}
.profile-sres-con {
    width: 100%;
    padding: 10px;
    margin-top: 0px;
    background: white;
}

.res-person-name {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    /* identical to box height */
    text-transform: capitalize;
    color: #0A223D;
}

.res-abo-head {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    /* identical to box height */
    color: #0A223D;
}

.res-exp {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #444444;
}

.exp-values {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    /* identical to box height */
    color: #0A223D;
}

.res-about-cont {
    background: #E7EAED;
    padding: 15px;
}

.res-about-sec {
    padding-top: 10px;
}

.res-card-container {
    width: 100%;
    height: 100%;
    /* padding: 15px; */
}

.res-cards-container {
    /* padding: 15px; */
    width: 100%;
    border-top: 6px dotted #14a5d3;
    overflow: auto;
    padding: 10px;
    height: calc(100vh - 285px);
}

.resource-image-card {
    width: 52px;
    height: 57px;
}

.oth-inter-head {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    padding: 10px;
    font-size: 17px;
}

.resbandata {
    margin-top: -50px;
    margin-left: 0px;
}

.res-ban-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.res-name {
    border: 1px solid #0A223D;
    border-radius: 50%;
    width: 110px;
    height: 100px;
    background-color: #14a5d3;
    color: #E7EAED;
    text-align: center;
    justify-content: center;
    font-size: 60px;
    font-weight: 700;
    text-transform: capitalize;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
}

@media (min-width:992px) {
    .res-tile-cont {
        justify-content: space-between!important;
    }
    .resbandata {
        margin-top: -50px;
        margin-left: 0px!important;
    }
    .tit-txt {
        font-size: 30px!important;
    }
    .res-ban-data {
        flex-direction: row!important;
        align-items: left!important;
    }
    .profile-res-con {
        margin-top: 50px!important;
    }
    .oth-inter-head {
        padding: 0px!important;
    }
    .res-det-con {
        margin: 30px!important;
    }
}