.resourse-tile {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    margin: 80px 10px 20px;
    width: 300px;
}

.resource-image-cont {
    width: 134px;
    height: 134px;
    position: absolute;
    transform: translateY(-50%);
    box-shadow: 0px 4px 4px rgb(20 165 211 / 45%);
    border-radius: 50%;
}
.resource-word-cont {
    width: 134px;
    height: 134px;
    position: absolute;
    transform: translateY(-50%);
    box-shadow: 0px 4px 4px rgb(20 165 211 / 45%);
    border-radius: 50%;
    /* background-color: #14A5D3; */
    /* z-index: 1; */

}
.bolded_text {
    font-weight: bold !important;
}
.candidate-word {
    width: 100%;
    text-decoration: solid;
    text-transform: capitalize;
    /* border-radius: 50%;  */
    /* height: 100%; */
    text-align: center;
    font-size: 100px;
    object-fit: cover;
    color: #14A5D3;
    z-index: 999;
}

.resource-card {
    background: #E3F6FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}

.resource-image {
    width: 100%;
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
}

.resource-desc-container {
    width: 300px;
    height: 206px;
    padding-top: 77px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}
.candidate-desc-container {
    width: 300px;
    height: 206px;
    padding-top: 30px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}

.res-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 21px;
    text-align: center;
    color: #0A223D;
}

.res-email {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #444343;
    /* line-break: anywhere; */
    width: 100%;
    padding: 0px 14px;
}

.res-title-card {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    /* identical to box height */
    color: #0A223D;
}

.res-exp-heading-card {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    color: #444444;
}

.resource-desc-container-card {
    padding: 0px 10px;
}

.expertize-decs-card {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: #0A223D;
}

.res-email-card {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: #444444;
}

.res-exp-heading {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    color: #14A5D3;
    margin-top: 5px;
}

.resourse-card-style {
    display: flex;
    background: #E3F6FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 15px;
    margin: 10px;
}

.expertize-decs {
    font-family: Mulish;
    font-style: normal;
    /* font-weight: 600; */
    font-size: 12px;
    line-height: 16px;
    height: 32px;
    /* display: flex; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    color: #0A223D;
    width: 100%;
    padding: 0px 10px;
    margin-bottom: 7px;
}

.read-more-button {
    width: 99px;
    height: 23px;
    border: none;
    background: #14A5D3;
    border-radius: 2px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
}
.status-ribbon {
    position: absolute;
    top: 270px;
    right:0px;
    /* width :180px; */
    text-align: end;
    /* background-color: #0A223D; */
    color: white;
    background-color: #ffffff;
    padding: 5px 6px 5px 12px;
    border-top-left-radius: 10px;
    /* border-bottom-left-radius: -30px; */
    font-size: 14px;
    font-weight: bold;
    /* transform: rotate(45deg); */
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
    z-index: 1;
  }
  .jobSeek-image{
    width :auto;
    height : 300px
  }
  .cover-image {
    width :100%;
    height : 100%;
    object-fit: cover;
    object-position: top;
  }
  /* .cover-image-fill {
    width :full;
    height : 100%;
    object-fit: fill;
    object-position: top;
  } */