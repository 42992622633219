.pubbox {
  background-color: #f3f8ff;
  /* background-color: red; */
}

.swal-publication {
  /* width: 100% !important; */
  display: flex !important;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: left !important;
}
.swal-publication-image {
  /* margin: 0px !important; */
  margin: 1em 0em 0em 1em !important;
}
.swal-publication-text {
  text-align: left !important;
}

.tit-txt {
  font-family: Mulish;
  font-style: normal;
  color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
}

.one-line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pubbox .container {
  background: white;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px 20px 0px 20px;
}

/* .publi .card{border: none;} */

.publi {
  margin-bottom: 18px;
}

.publi .card {
  border: none;
}

.publi .card-body {
  padding: 10px;
  background-color: #f3f8ff;
  border: none;
  border-radius: 4px;
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;  */
}

.publi .card-body:hover {
  background-color: white;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 4px 5px 1px rgb(0 0 0 / 25%);
}

.publi .imgx {
  padding-right: 0px;
}

.imgx img {
  width: 75px;
  height: 113px;
  margin-top: 3px;
}

.abt h5 {
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 16px;
  color: #0a223d;
  min-height: 37px;
}

.abt small {
  font-size: 11px;
  font-weight: 500;
}

.abt h6 {
  line-height: 10px;
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 13px;
  color: #0a223d;
}

.abt p {
  text-align: justify;
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 15px;
  word-break: break-all;
}

/* ------------------------------------------------------------------------------------------------------ */

@media (min-width: 992px) {
  .tit-txt {
    font-size: 30px;
  }
  .imgx img {
    width: 93px;
    height: 93px;
  }
  /* .abt h5{
        min-height: 41px;
    } */
  .abt h6 {
    line-height: 10px;
    margin-bottom: 4px;
  }
  .publi {
    margin-bottom: 25px;
  }
  .abt h5 {
    min-height: auto;
  }
}
