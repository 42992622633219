.event-bg {
    /* height: 178px; */
    background-image: url("./kspaceevent.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.event-bg h3 {
    color: #fff;
    text-align: center;
    font-size: 24px;
}

.events {
    background: rgba(243, 248, 255, 0.88);
    align-items: center;
    justify-content: center;
}

.m-55 {
    padding: 60px 0px;
}

.event {
    height: 44px;
    margin: 23px 0px;
    line-height: 44px;
    text-transform: capitalize;
    font-family: "Mulish";
    font-family: Open Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

.event-bkng {
    background: #14a5d3;
    color: black;
}

.mtb-5 {
    margin: 5px 0;
}

.mtb-25 {
    margin-top: 0px;
    margin-bottom: 25px;
}


/* event detail page */

.eventdetail-bg {
    height: 40vh;
    background-image: url("./img/eventdetail.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    position: relative;
}

.eventdetail-bg h3 {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 22px;
}

.head-detail {
    flex-direction: column-reverse;
}

.eventdetail-bg .bar {
    position: absolute;
    left: 55px;
    border-left: 8px solid #14a5d3;
    /* height: 65px; */
    width: 50%;
    bottom: 20px;
}

.detail-desc {
    text-align: justify;
    padding: 20px 0px;
}

.fee {
    color: #14a5d3;
    font-weight: bold;
}

.more-details {
    padding: 20px 0px;
}

.booknow-btn {
    background: #0a223d;
    color: #ffffff;
    padding: 2px 8px;
    height: 28px;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
}

.booknow-btn:hover {
    background: #14a5d3;
    color: #0a223d;
    font-weight: 700;
}
.cover_image{
    object-fit: cover !important;
    width: 100% !important;
    height : 400px !important;
    padding : 10px !important
}
.full_img {
   /* max-width: fit-content !important; */
   /* height: max-content; */
   overflow: auto;
   justify-content: center !important;
}
.my-3 {
    background: #f4f4f4;
    padding: 7px;
    min-height: 85px;
    border-left: 8px solid #14a5d3;
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.gal-i {
    padding: 30px 10px!important;
}

.my-3 p {
    margin-bottom: 2px !important;
    font-weight: bold;
}

.font-weight-bold {
    font-weight: bold;
}

.my-3 span {
    padding-left: 10px;
    display: inline-flex;
}

.tile-pad {
    display: flex;
    padding: 50px 50px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .tile-pad {
        padding: 50px 30px;
    }
    .bar {
        /* width: 65%; */
        /* height: 60px; */
    }
    .bar h3 {
        font-size: 12px;
        margin-bottom: 0;
    }
    .m-55 {
        padding: 15px 0px;
    }
    .event-bg h3 {
        font-size: 19px;
    }
}