.breadcrumb-wrapper {
    padding: 0 16px;
    width: 100%;
    background: #f3f8ff;
    position: fixed;
    z-index: 1;
}

.breadcrumb-wrapper .breadcrumb {
    background: transparent;
    padding: 6px 0;
    margin: 0;
}

.breadcrumb-wrapper .breadcrumb li.breadcrumb-item {
    line-height: 15px;
}

.breadcrumb-wrapper .breadcrumb li.breadcrumb-item a {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #909090;
    text-decoration: none;
}

.breadcrumb-wrapper .breadcrumb li.breadcrumb-item.selactive a {
    color: #2f2483;
    background: none!important;
    font-weight: 600;
}

.breadcrumb-wrapper .breadcrumb li.breadcrumb-item:before {
    content: "";
    width: 7px;
    height: 7px;
    position: absolute;
    right: -1px;
    top: 6px;
    border-color: #909090;
    border-style: solid;
    border-width: 0px 1px 1px 0px;
    display: inline-block;
    transform: rotate(-45deg);
    padding: 0;
}

.breadcrumb-wrapper .breadcrumb li.breadcrumb-item {
    padding: 0 12px;
    position: relative;
}

.breadcrumb-wrapper .breadcrumb li.breadcrumb-item:first-child {
    padding-left: 0;
}

.breadcrumb-wrapper .breadcrumb li.breadcrumb-item:last-child:before {
    border-color: transparent;
}