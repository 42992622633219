.event-bg {
    /* height: 178px; */
    background-image: url("./kspaceevent.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.event-bg h3 {
    color: #fff;
    text-align: center;
    font-size: 24px;
}

.events {
    background: rgba(243, 248, 255, 0.88);
    align-items: center;
    justify-content: center;
}

.m-55 {
    padding: 60px 0px;
}

.event {
    height: 44px;
    margin: 23px 0px;
    line-height: 44px;
    text-transform: capitalize;
    font-family: "Mulish";
    font-family: Open Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

.left-tri {
    border: 0 solid #14a5d3;
    position: absolute;
    width: 264px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-bottom-width: 35px;
    border-right-width: 264px;
    border-right-color: transparent;
}

.right-tri {
    border: 0 solid #07789c;
    width: 264px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 35px;
    border-left-width: 264px;
    border-left-color: transparent;
}

.tri-container {
    position: relative;
    display: flex;
}

.event-date-container {
    height: 30px;
    background: #14a5d3;
    width: 264px;
}

.event-card-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-shape-cont {
    margin-top: -65px;
    width: 318px;
    align-items: center;
    flex-direction: column;
    display: flex;
}

.event-tile {
    padding: 40px 0px;
}

.event-datas {
    display: flex;
}

.event-info-details {
    height: 24px;
    line-height: 24px;
    padding: 0px 10px;
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.event-data-container {
    height: 220px;
    background: #fff;
    padding: 15px;
    width: 264px;
    box-shadow: 4px 10px 9px rgba(0, 0, 0, 0.25);
}

.event-tile-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    height: 25px;
    text-align: center;
    color: #1c2541;
}

.event-tile-by-text {
    font-size: 11px;
    line-height: 14px;
    height: 14px;
    color: #a1a8af;
    padding-right: 5px;
}

.event-org-by-text {
    color: #0a223d;
    font-size: 12px;
    font-weight: 700;
    height: 16px;
    line-height: 16px;
}

.event-tile-data-text {
    font-size: 14px;
    padding-left: 2px;
}

.event-tile-container {
    display: flex;
    padding: 0px;
    width: 100%;
    background: linear-gradient(180deg, #2cafe7, #2cafe76e, #88d0f275, #f5fbfe);
    flex-wrap: wrap;
    justify-content: center;
}

.event-card-tile {
    margin: 20px 20px;
}

.event-fee-title-text {
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
}

.event-tile-fee-symbol {
    height: 17px;
    line-height: 20px;
    padding-left: 5px;
    font-size: 10px;
}

.event-tile-fee {
    line-height: 18px;
    height: 18px;
    font-size: 14px;
    color: #14a5d3;
    font-weight: 700;
}

.event-tile-btn {
    height: 25px;
    width: 84px;
    font-size: 13px;
    border: none;
}

.event-read-more {
    color: white;
    background: #1c2541;
}

.event-bkng {
    background: #14a5d3;
    color: black;
}

.mtb-5 {
    margin: 5px 0;
}

.mtb-25 {
    margin-top: 0px;
    margin-bottom: 25px;
}


/* event detail page */

.eventdetail-bg {
    height: 40vh;
    background-image: url("./img/eventdetail.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    position: relative;
}

.eventdetail-bg h3 {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 22px;
}

.head-detail {
    flex-direction: column-reverse;
}

.eventdetail-bg .bar {
    position: absolute;
    left: 55px;
    border-left: 8px solid #14a5d3;
    /* height: 65px; */
    width: 50%;
    bottom: 20px;
}

.detail-desc {
    text-align: justify;
    padding: 20px 0px;
}

.fee {
    color: #14a5d3;
    font-weight: bold;
}

.more-details {
    padding: 20px 0px;
}

.event-booknow-btn {
    background: #0a223d;
    color: #ffffff;
    padding: 2px 8px;
    height: 28px;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
}

.booknow-btn:hover {
    background: #14a5d3;
    color: #0a223d;
    font-weight: 700;
}

.my-3 {
    background: #f4f4f4;
    padding: 7px;
    min-height: 85px;
    border-left: 8px solid #14a5d3;
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.my-3 p {
    margin-bottom: 2px !important;
    font-weight: bold;
}

.font-weight-bold {
    font-weight: bold;
}

.my-3 span {
    padding-left: 10px;
    display: inline-flex;
}

.tile-pad {
    display: flex;
    padding: 50px 50px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .tile-pad {
        padding: 50px 30px;
    }
    .bar {
        /* width: 65%; */
        /* height: 60px; */
    }
    .bar h3 {
        font-size: 12px;
        margin-bottom: 0;
    }
    .m-55 {
        padding: 15px 0px;
    }
    .events {
        padding: 0px!important;
    }
    .event-bg h3 {
        font-size: 19px;
    }
}

@media only screen and (min-width: 992px) {
    .event-tile-container {
        padding: 0px 50px;
    }
}