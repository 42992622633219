.dash-banner {
    background-image: url("../../../resources/img/dashboard/userdashbanner.png");
    width: 100%;
    height: 100px;
}

.dashboard-box {
    height: fit-content;
    margin: 40px auto;
    position: relative;
    padding: 20px;
    width: 100%;
    background: #E3EEFA;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.muli {
    font-family: mulish!important;
}

.fill-color {
    background: transparent;
}

.reg-det-title {
    color: #1c2541;
    font-size: 18px;
    margin: 10px 8px;
    font-weight: 600;
    border-bottom: 2px solid #e3eefa;
    padding: 1px 0px 12px 0px;
}

.reg-data-container {
    background: white;
    height: 100%;
    padding: 3px 13px;
}

.sepera {
    font-weight: 800;
}

.user-name-logo-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #80808061;
}

.dash {
    /* min-height: calc(100vh - 86px); */
    background: #fff;
}

.dashBox-container {
    /* min-height: calc(100vh - 86px); */
    justify-content: center;
    display: flex;
}

.msg-container {
    top: 20px;
    position: absolute;
    width: calc(100% - 40px);
    border: 1px solid rgba(237, 18, 18, 0.46);
    /* background: rgba(237, 18, 18, 0.43); */
    background: white;
    box-sizing: border-box;
    border-radius: 3px;
}

.dash-items {
    width: 100%;
    height: 100%;
}

.edit-app-button {
    width: 170px;
    height: 32px;
    background: #0a223d;
    display: flex;
    border-radius: 2px;
    align-items: center;
    padding: 0px 10px;
    color: white;
    /* margin-top: 47px; */
    margin-top: 15px;
}

.edit-button-cont {
    padding: 5px;
    height: 19px;
    align-items: center;
    justify-content: center;
    width: 19px;
    display: flex;
    background: #14a5d3;
    margin-right: 5px;
    border-radius: 50%;
}

.edit-label {
    font-size: 15px;
    color: white;
}

.dash-app-edit-icon {
    font-size: 15px;
    display: flex;
    line-height: 15px;
    font-weight: 700;
    height: 15px;
}

.dash-header-button {
    padding: 10px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.dash-header {
    background: #f3f8ff;
    display: flex;
    flex-direction: column;
}

.dash-logo {
    background: #f3f8ff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auto-display {
    display: block !important;
}

.auto-display-rev {
    display: flex !important;
}

.profile-labels {
    width: 218px;
}

.profile-values {
    padding: 5px 10px;
    font-weight: 600;
}

.col-con {
    display: none;
}

.ovr {
    overflow: auto;
}
.userDashb{display: flex;}
@media (max-width: 760px) {
    .userDashb{display: block;}
    .u-logo{
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
    .user-name-details {
        text-align: center;
    }
    .dash-application-details-container {
        height: 95px!important;
    }
    .dash-content-detail-items {
        padding: 22px 0px!important;
    }
    .dashuserStatus {
        width: 100%;
        margin: 0 auto;
        padding: 7px 0px;
        margin-bottom: 26px;
    }
    .reg-det-title {
        text-align: center;
    }
}

@media (min-width: 992px) {
    .ovr {
        overflow: auto;
    }
    .dash-header {
        flex-direction: row;
    }
    .edit-app-button {
        margin-top: 0%;
    }
    .dash-logo {
        align-items: flex-start;
    }
    .dash-header-data {
        align-items: flex-start !important;
    }
    .dash-header-button {
        align-items: flex-end;
    }
    .auto-display {
        display: flex !important;
    }
    .auto-display-rev {
        /* display: block !important; */
    }
    .dash-menuContainer {
        width: 145px;
        height: 100% !important;
        margin-top: 0px !important;
    }
    .dash-body-msg-container {
        /* width: 491px !important; */
    }
    .dash-content-container {
        height: 256px;
    }
    .dash-content-details-container {
        /* height: 238px!important; */
        /* margin-left: 17px !important; */
    }
    .application-status-cont {
        margin-top: 50px !important;
    }
    .dash-messages {
        margin-left: 25px !important;
    }
    .dash-header-logo {
        padding: 20px !important;
    }
    .dash-header-data {
        padding: 20px !important;
    }
    .dash-header-button {
        /* padding: 20px !important; */
        padding: 11px 11px 0px 11px !important;
    }
    .dashMenuButton {
        display: flex !important;
    }
    .dash-datas {
        /* width: 734px !important; */
    }
    .col-con {
        display: block !important;
    }
    .profile-values {
        padding: 0px 10px !important;
        font-weight: normal !important;
    }
    .status-ind-btn {
        width: 250px !important;
    }
}

.dash-items-data .row {
    background: #e3eefa;
    padding: 7px 5px;
    /* margin: 0 0 10px 0; */
}

.dash-t\items-datas {
    background: #e3eefa;
    padding: 7px 5px;
}

.dash-header-logo {
    padding: 10px;
}

.dash-header-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 22px;
    line-height: 28px;
    /* identical to box height */
    color: #1c2541;
}

.dash-header-mail {
    display: flex;
}

.header-sec-values {
    font-family: Mulish;
    font-style: normal;
    padding: 1px 5px;
    height: 18px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    color: #000000;
}

.dash-header-phone {
    display: flex;
}

.dash-header-address {
    display: flex;
}

.dash-header-data {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dash-header-info {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #93969a;
}

.dash-msg-icon {
    font-size: 16px;
}

.dash-logo-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
}

.dash-msg-text {
    padding: 0px 5px;
    width: 100%;
}

.dash-msg {
    font-family: Mulish;
    padding: 0px 30px;
    font-style: normal;
    font-weight: 600;
    background: rgba(237, 18, 18, 0.43);
    font-size: 12px;
    line-height: 25px;
    height: 25px;
    z-index: 999;
    /* identical to box height */
    /* text-align: center; */
}

.dash-menuContainer {
    width: 100%;
    height: fit-content;
    padding-bottom: 15px;
    background: #f3f8ff;
    margin-top: 10px;
}

.dashMenuButton {
    background: #0a223d;
    color: white;
    width: 100%;
    line-height: 23px;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    display: none;
    font-weight: 600;
    font-family: "Mulish";
    height: 23px;
    border: none;
}

.dash-content-container {
    height: fit-content;
    width: 100%;
    background: white;
}

.dash-content-details-container {
    /* width: fit-content; */
    margin-left: 0px;
    margin-top: 0px;
    height: fit-content;
    background: #ffffff;
    border: 0.6px solid #e3eefa;
    box-sizing: border-box;
}

.dash-content-detail-items {
    padding: 35px;
    background: #e3eefa;
    overflow: hidden;
}

.dash-body-label {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin: 5px 0px;
    line-height: 18px;
    align-items: center;
    display: flex;
    color: #444444;
}

.dash-body-value {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
    padding: 0px 10px;
    height: 25px;
    /* identical to box height */
    color: #0a223d;
}

.status0 {
    background: #f76c6c;
    color: white;
}

.status1 {
    background: rgba(10, 34, 61, 0.69);
    color: white;
}

.status2 {
    background: #d10000;
    color: white;
}

.status3 {
    background: #14a5d3;
    color: white;
}

.status4 {
    background: #14a5d3;
    color: white;
}

.status5 {
    background: #329732;
    color: white;
}

.status6 {
    background: #14a5d3;
    color: white;
}

.status-ind-btn {
    display: flex;
    text-align: center;
    align-items: center;
    text-transform: capitalize;
    justify-content: center;
    height: 42px;
    /* width: 300px; */
    width: 264px;
    border-radius: 0px;
}

.dash-body-msg-container {
    height: 117px;
    background: #e7e7e7;
    border-radius: 3px;
    width: 100%;
    padding: 15px;
    overflow-y: auto;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #1c2541;
}

.dash-details-body {
    padding: 5px 0px;
    background: #e3eefa;
}

.dash-messages {
    margin-left: 0px;
}

.application-status-cont {
    margin-top: 0px;
}

.dash-datas {
    width: 100%;
}

.dash-profile-data-cont {
    display: flex;
    background: #f6f6f6;
    padding: 5px 10px;
    margin-bottom: 10px;
    font-size: 14px;
}

.dahContent-header-section {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    text-transform: capitalize;
    padding: 10px 0px;
    background: #e3eefa;
    border-bottom: 3.5px solid #e3eefa;
    font-size: 15px;
    line-height: 19px;
    color: #0a223d;
}

.dash-menu-item {
    display: flex;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    /* padding: 15px 15px 0px; */
    padding: 7px 15px;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */
    color: #000000;
}

.menu-icon {
    margin-right: 10px;
}

.dash-menu-active {
    color: #fff;
    background-color: #0a223d!important;
}

.data .row {
    padding: 7px 5px;
    background: #e7e7e7;
    margin: 0 0 10px 0;
}

.data .row .col-8 {
    color: black;
    font-size: 14px;
    width: 100%;
    overflow: auto;
    font-size: 14px;
}

.data .row .col-3 {
    color: #666;
    font-size: 14px;
}

.mysubbtn:hover {
    color: white;
}

.nio {
    color: white !important;
    font-weight: 300;
    font-size: 15px;
}

.data .row .col-8 table {
    background-color: white !important;
    margin-top: 0px !important;
}

.venture {
    background-color: white !important;
    padding-top: 10px;
}
.joint-view-border{
    border-bottom: 1px solid grey;
}

.data .current {
    background-color: #0a223d !important;
    color: white !important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white !important;
}

.status label {
    font-size: 16px;
}

.down-ico {
    background: #D2EBF7;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 3px;
    text-decoration: none;
}

.down-ico:hover {
    color: unset;
}

.down-ico:hover.down-ico span {
    background: white;
    color: #0a223d;
}

.down-ico span {
    background: #0a223d;
    color: white;
    width: 22px;
    text-align: center;
    padding: 2px 0px 3px 0px;
    border-radius: 25px;
    margin-left: 4px;
}

.user-name-details {
    padding: 0px 25px;
}

.user-name-logo {
    width: 70px;
    display: flex;
    height: 70px;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: #1C2541;
    color: white;
}

.dashCompanyName {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    /* identical to box height */
    letter-spacing: 0.02em;
    color: #000000;
}

.dashPhoneNumber {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    padding: 10px 0px;
}

.dashuserApplicationId {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 21px;
    padding: 0px 0px 15px 0px;
    /* identical to box height */
    letter-spacing: 0.02em;
    color: #000000;
}

.dashuserStatus {
    background: #1C2541;
    border-radius: 5px;
    /* width: 174px; */
    height: 38px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFFFFF;
}

.dash-application-details-container {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dash-app-dettail-no-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 185px;
    color: grey;
    background: #fff;
}

.dashuserprof {
    background: #F6F8FA;
    box-shadow: 2px 2px 10px rgba(29, 68, 102, 0.3);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    height: 40px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    align-items: center;
    color: #000000;
    padding: 15px 0px;
    margin-top: 10px;
}

.goto-my-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
}

.dashusermyprof {
    background: #F6F8FA;
    box-shadow: 2px 2px 10px rgba(29, 68, 102, 0.3);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    height: 40px;
    width: 150px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
    color: #000000;
    padding: 15px 0px;
}

.blink {
    animation: blinker 2s linear infinite;
    color: #000;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.message-from-kspace {
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    overflow: auto;
    min-height: 185px;
    margin: 20px 0px;
    max-height: 185px;
    background: white;
    padding: 5px 5px;
    border: 1px solid #c4c4c4;
}

.message-from-kspace-action-cont {
    display: flex;
    text-align: center;
    align-items: center;
    /* justify-content: center; */
}

.file-from-kspace {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 12px;
    cursor: pointer;
    background: #14a5d3;
    margin-right: 17px;
    border-radius: 3px;
}

.dash-attachment-icon {
    font-size: 26px;
    color: #1C2541;
}

.update-current-propsosal {
    width: 176px;
    height: 34px;
    cursor: pointer;
    background: #F6F8FA;
    box-shadow: 2px 2px 10px rgba(34, 74, 110, 0.3);
    border-radius: 5px;
    /* font-family: Roboto; */
    font-style: normal;
    justify-content: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.message-from-kspace-btn {
    width: 173px;
    height: 38px;
    background: #1C2541;
    border: 1px solid #000000;
    box-sizing: border-box;
    backdrop-filter: blur(4px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 5px;
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFFFFF;
}

@media (min-width: 992px) {
    .dash-banner {
        height: 162px !important;
    }
    .fill-color {
        background: #f3f8ff!important;
    }
   
}