.new-menu-title {
    font-weight: 500;
    background: #2cafe7;
    padding: 6px 10px;
    font-size: 14px;
}

.new-menu-container {
    /* display: flex; */
    /* position: absolute; */
    background: #fff;
    color: white;
    z-index: 999;
    /* top: 140px; */
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    /* flex-wrap: wrap; */
    width: 100%;
    max-height: 100%;
}

.new-menu-item-data {
    display: flex;
    cursor: pointer;
    padding:3px 0px;
    font-weight: 400;
    /* border: 1px solid #2cafe7; */
}
.new-menu-item-data:hover .new-submenus-label{
    color:#2cafe7;   
     padding: 0px 3px;
    }
.new-menu-item-data:hover {
    /* font-weight: 500; */
    border-left: 4px solid #2f2483;
    border-radius: 3px;
    /* padding: 0px; */
}

.new-submenus-label {
    font-size: 15px;
    color: #000;
}

.new-menu-bg {
    position: absolute;
    z-index: 999;
    top: 120px;
    height: calc(100vh - 120px);
    background: #0000007d;
    width: 100%;
   
}
.anima{
    transition-duration: 4s;
    transition-delay: 2s;
}
.new-menu-item {
    padding: 10px;
    width: 100%;
}

.new-menu-home-icon {
    font-size: 20px;
    color: #2f2483;
}

.new-home-icon-cont {
    padding: 10px;
    background: #2cafe7;
    cursor: pointer;
    /* background: #2f2483; */
    /* margin: 10px; */
    border-radius: 50%;
}

.new-menu-home {
    /* padding: 30px; */
    /* padding: 30px; */
    padding-bottom: 30px;
    /* width: 100%; */
    background: transparent;
    position: absolute;
    /* bottom: 0;
    right: 0; */
    display: flex;
    flex-direction: row-reverse;
}

.submenus-icon {
    padding: 0px 5px;
    font-size: 20px;
    color: #000;
}

.new-menu-subItem {
    padding: 10px;
}

.menu-main-section {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    margin: 0 auto;
}

@media only screen and (min-width: 768px) {
    .new-menu-item {
        padding: 10px 20px;
        width: 33%;
    }
    .new-menu-home {
        padding-bottom: 0px;
        /* padding: 0px 20px; */
    }
    .new-menu-home-icon {
        font-size: 30px;
    }
}

@media (max-width: 760px) {
    .new-menuIcon-cont {
        margin-right:0px!important;
    }
}