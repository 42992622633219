.icon {
    height: 22px;
    line-height: 22px;
    color: grey;
    padding: 2px;
    display: flex;
    padding-right: 10px;
    font-size: 24px;
}

.parentdiv {
    height: 8px;
    display: flex;
    width: 100%;
    background-color: whitesmoke;
    border-radius: 40px;
}

.progressInd {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 166%;
    /* or 100px */
    display: flex;
    align-items: center;
    /* Primary */
    color: #009FE3;
    opacity: 0.3;
}

.label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    line-height: 166%;
    /* identical to box height, or 40px */
    color: #333333;
}

@media (min-width: 769px) {
    .label {
        font-size: 16px;
    }
    .progressInd {
        font-size: 50px;
    }
    .parentdiv {
        width: 200px;
    }
}