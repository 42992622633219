.admin-header-container {
    height: 86px;
    display: flex;
    align-items: center;
    font-family: "Titillium Web";
    /* justify-content: center; */
    box-shadow: 0px 0px 10px 0px #00000045;
    background: white;
}

.admin-body-container {
    /* background: yellow; */
    display: flex;
    height: calc(100vh - 126px);
    overflow: auto;
}

.admin-body-container.full-hght {
    height: 100vh;
}

.admin-body-items {
    /* background: violet; */
    font-family: mulish;
    min-height: calc(100vh - 126px);
    width: calc(100% - 235px);
    overflow-y: auto;
    overflow-x: hidden;
}

.mt0 {
    margin-top: 0px !important;
}

.AdminLayout .header-container {
    background: #ebe9e9;
    height: 100vh;
    /* background: red; */
    overflow: hidden;
}

.header-logo-image {
    height: 69px;
}

.header-menu-icon {
    font-size: 30px;
}

.admin-header-title {
    position: absolute;
    top: 49px;
    left: 90px;
}

.gap {
    gap: 10px;
}


/* sidebar */

.navbar {
    display: block;
    /* position: absolute; */
    background: #0a223d;
    left: 0;
    height: calc(100vh - 126px);
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    color: #f1f1f1;
}

.navbar-container {
    width: 300px !important;
}

.logo-link {
    cursor: pointer;
}

.logout {
    color: #1c2541;
    text-decoration: none;
    cursor: pointer;
}

.sidebar {
    padding-top: 10px;
}

.sidebar-nav {
    list-style: none;
    padding: 5px;
}


/* .sidebar-nav li {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    min-height: 35px;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 14px;
    border-radius: 16px;
    width: 100%;
} */

.navlist {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    min-height: 32px;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 8px;
    font-size: 14px;
    border-radius: 16px;
    width: 100%;
}

.adminType {
    font-size: 20px;
    font-weight: 900;
    color: red;
}

.navlistactive {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    min-height: 35px;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 14px;
    border-radius: 16px;
    width: 100%;
}

.active {
    background: #14a5d3;
}


/* .active {
    background: #14a5d3;
} */

.sidebar-nav img {
    width: 16px;
}

.sidebar-nav li:hover {
    background: #14a5d3;
}

.dropArrow {
    width: 11px !important;
}

.admin-footer {
    height: 40px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 14px 0px #00000059;
}

.inovace {
    text-decoration: none;
    text-transform: lowercase;
    padding: 0px 10px;
    color: #0d6efd !important;
    font-weight: 500;
}

.copyright {
    color: #8d8181;
    font-weight: 500;
    width: 100%;
    display: flex;
    letter-spacing: 2px;
    align-items: center;
    justify-content: center;
}

.iconclass {
    transform: rotate(180deg);
}

.putright {
    margin-left: auto;
}

.admin-body-items tbody tr:hover {
    background-color: #f1eded;
}

.sub {
    font-size: 13px;
}
.header-logo-imageA {
    height: 80px !important;
    width: 145px !important;
    margin-left: 30px;
    /* height: 55px !important; */
    margin-top: -8px;
}