.button-to-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
}

.chkbx {
    margin: auto 10px !important;
}



.commonButtonbutton {
    background: #0a223d !important;
    color: #ffffff !important;
    border: navajowhite !important;
    width: 95px !important;
    padding: 10px !important;
    cursor: pointer !important;
    line-height: 12px !important;
    border-radius: 3px;
}

.button-label-style {
    cursor: pointer;
}

.mylabel {
    color: #1c2541 !important;
    font-weight: 600 !important;
    line-height: 17.75px !important;
    padding-bottom: 4px !important;
}
.form-label-error {
    color: red !important;
    font-weight: 600 !important;
    line-height: 17.75px !important;
    padding-bottom: 4px !important;
}

.defaultSearch {
    color: white;
    background: #2cafe7;
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 25px;
    width: 25px;
    font-size: 20px;
    justify-content: center;
    border-radius: 0px 5px 5px 0px;
}

.clearInput {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    cursor: pointer;
    margin: 0px 10px;
    font-size: 20px;
}

.searchTextContainer {
    display: flex;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(20, 165, 211, 0.3);
    border-radius: 5px;
}

.searchTextInput {
    width: 100%;
    padding: 0px 10px;
    border: none;
    border-radius: 5px 0px 0px 5px;
}

input.searchTextInput:focus-visible {
    outline: none;
}

.rdo {
    margin: 10px !important;
}

@media only screen and (min-width: 768px) {
    .defaultSearch {
        height: 40px;
        width: 40px;
        padding: 10px;
    }

    .clearInput {
        height: 40px;
    }
}