.view-container {
  padding: 25px 40px 25px 40px;
}
.view-container h5 b {
  color: #0a223d;
}
.font-z select {
  font-size: 14px;
}
.admin-body-items thead tr {
  background-color: #666666;
  color: #ffffff;
}
.admin-body-items tbody tr:hover {
  background-color: #f1eded;
}
.btn-primary {
  background-color: #1c2541 !important;
  border: none;
  padding: 6px 25px;
}
.data .row {
  padding: 7px 5px;
  background: #e7e7e766!important;
  margin: 0 0 10px 0;
}
.appr{margin-right: 15px!important;}
/* .big-dia{left: 32%;
  width: 890px;
  height: 460px;
height: 650px;
top: 35%;} */