.tender {
    min-height: calc(100vh - 428px);
}

.bg-tender {
    background: #f3f8ff;
}

.vx {
    font-size: 18px !important;
}

.ix {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
}

.tix {
    font-size: 30px;
    color: white;
    font-weight: 600;
}

.container-ul {
    display: flex;
    margin: 5px 0px 10px 0px;
    flex-direction: column;
    align-items: center;
}


/* .hr-tenders {
  margin: 1px !important;
  height: 3px !important;
  width: 35px;
  opacity: 1;
  color: #14a5d3;
} */

.top {
    margin-left: 0px !important;
}

.bottom {
    margin-left: 20px !important;
}

.tender-head {
    padding: 15px 0px;
}

.tender-head h4 {
    text-align: center;
    margin-bottom: 0px;
}

.m-30 {
    margin: auto 30px;
}

.p-30 {
    padding: 30px;
    margin-bottom: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -2px 1px 11px -5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.p-50 {
    padding: 50px 70px;
}

.tender-box {
    margin: auto 88px;
}

.tender-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
}

.tender-box .head {
    text-transform: uppercase;
    /* font-size: 30px; */
}

.tender-box .head u {
    /* text-transform: uppercase; */
    font-size: 20px;
}

.tender-box p {
    font-family: Mulish !important;
    margin-bottom: 5px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px !important;
    color: black !important;
    font-weight: 400 !important;
    margin-top: -4px;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.label {
    align-self: baseline;
}

.label p {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    font-weight: 600;
    color: #444;
}

.contentx p {
    font-size: 15px;
    font-weight: 600;
}

.tender-datas {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.bg-white {
    padding-bottom: 35px;
    padding-top: 65px;
}

.break_link {
    word-break: break-all;
    cursor: pointer;
}

@media only screen and (max-width: 992px) {
    .tender-box {
        margin: auto 30px;
    }

    .tender-datas {
        flex-direction: column;
        display: unset;
    }

    /* .dash-banner .tit-txt {
    font-size: 18px!important;
} */
    .tender-box p {
        text-align: center;
        letter-spacing: 0px;
        overflow-wrap: anywhere;
    }

    .bg-white {
        padding-top: 35px;
    }

    .p-50 {
        padding: 22px 16px;
    }

    .contentx p {
        font-size: 14px;
        font-weight: 700;
    }

    .p-30 {
        padding: 19px;
    }

    .overf {
        overflow: hidden;
    }

    .tix {
        font-size: 19px;
    }

    .note p {
        text-align: justify;
    }
}