.view-container {
  padding: 25px 20px 25px 40px;
}
.view-container h5 b {
  color: #0a223d;
}
.font-z select {
  font-size: 14px;
}
.btn-primary {
  background-color: #1c2541 !important;
  border: none;
  padding: 6px 25px;
}
.domain-btn {
  float: right;
  background-color: #14a5d3 !important;
}
.inputfile {
  border: 1px solid #ced4da;
}

.uploadsmall {
  color: red;
}
.action-dialog {
  max-height: 185px !important;
}
.action-msg {
  margin-bottom: 0 !important;
}
