.dash-banner {
    background-image: url("../../../resources/img/dashboard/userdashbanner.png");
    width: 100%;
    height: 85px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px!important;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
}

.proj {
    background: rgba(243, 248, 255, .88);
    padding-bottom: 6px;
}

.projbox {
    background-color: white;
    /* width: calc(100% - 599px); */
    /* padding: 45px 142px; */
    margin-top: 20px;
    margin-bottom: 20px;
    /* margin: auto; */
    width: 91%;
    padding: 1px 11px;
}

.mex {
    padding: 20px 7px!important;
}

.pager {
    background-color: #fff;
    width: 95%;
    margin: 7px 10px;
    /* padding: 36px 69px; */
    padding: 6px 11px;
}

.btn:hover {
    color: white!important;
}
.view-container-comp-1 .white_btn_sup:hover {
    background-color: rgb(255, 157, 0)!important;
    color: #000!important;
}

.mex .data .row .col-3 {
    color: #666;
    font-size: 14px;
}

.projx {
    padding: 20px;
}

.helpy {
    margin-top: -12px!important;
}


/* =================================================================================== */

@media (min-width:992px) {
    .dash-banner {
        height: 162px;
    }
    .mex .data .row .col-3 {
        color: #666;
        font-size: 15px;
    }
    .tit-txt {
        font-size: 30px;
    }
    .projbox {
        background-color: white;
        width: calc(100% - 599px);
        padding: 26px 69px 13px 69px;
        margin-top: 20px;
    }
    .projx {
        margin-bottom: 8px;
        padding: 20px 142px;
    }
    .pager {
        background-color: #fff;
        width: 940px;
        margin: auto;
        padding: 36px 69px;
    }
}