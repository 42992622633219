.view-container {
  padding: 25px 20px 25px 40px;
}
.view-container h5 b {
  color: #0a223d;
}
.font-z select {
  font-size: 14px;
}
.btn-primary {
  background-color: #1c2541 !important;
  border: none;
  padding: 6px 25px;
}
.domain-btn {
  float: right;
  background-color: #14a5d3 !important;
}
.btn-approve {
  background-color: #0a223d;
  color: white;
  padding: 4px 25px;
  margin-left: 10px;
}
.btn-reject {
  background-color: #f76c6c;
  color: white;
  padding: 4px 25px;
  margin-left: 10px;
}
.btn-approve:hover {
  color: white;
}
.blink_me {
  animation: blinker 1s linear infinite;
  font-size: 17px !important;
  color: white !important;
  font-weight: 400 !important;
}
