html {
  --lh: 1.7rem;
}

.truncate-overflow {
  --max-lines: 3;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem;
  /* space for ellipsis */
}

.truncate-overflow::before {
  position: absolute;
  content: "";
  inset-block-end: 0;
  /* "bottom" */
  inset-inline-end: 0;
  /* "right" */
}

.bgc-default {
  background: #c4c4c440;
  /* opacity: 0.4; */
}

.banner-skelton {
  height: 940px;
  width: 100%;
}

.tab-cont {
  overflow: auto;
  display: flex;
  /* width: 100%; */
}
.tab-cont-partners {
  overflow: auto;
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}

.tab-cont-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.tab-cont-cont-partners {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tab-switch {
  padding: 0px 42px 20px;
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  font-size: 15px;
  opacity: 0.3;
}
.tab-switch-partners {
  padding: 0px 0px 20px;
  color: #000000;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  font-size: 24px;
  /* opacity: 0.3; */
}

.org-border-active {
  margin: 0px !important;
  width: 120px !important;
  height: 3px !important;
  opacity: 1 !important;
  border-radius: 6px !important;
  color: #009fe3 !important;
}

.org-border-inactive {
  margin: 0px !important;
  width: 120px !important;
  height: 3px !important;
  opacity: 1 !important;
  border-radius: 6px !important;
  color: transparent !important;
}

.gal-border-active {
  margin: 5px !important;
  width: 50px !important;
  height: 3px !important;
  opacity: 1 !important;
  border-radius: 6px !important;
  display: none;
  color: #009fe3 !important;
}

.gal-border-inactive {
  margin: 5px !important;
  width: 50px !important;
  height: 3px !important;
  opacity: 1 !important;
  border-radius: 6px !important;
  color: transparent !important;
}

.tab-switch.activeTab {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  /* line-height: 185%; */
  /* identical to box height, or 56px */
  text-align: center;
  opacity: 1;
  /* Primary */
  color: #009fe3;
}
.tab-switch-partners.activeTab {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  /* line-height: 185%; */
  /* identical to box height, or 56px */
  text-align: center;
  /* opacity: 1; */
  /* Primary */
  color: #009fe3;
}

.scope-container,
.gallery-container,
.new-accomp-cont,
.event-new-container,
.org-structure {
  padding: 20px 0px 0px;
}

.relative {
  position: relative;
}

.truncate-overflow::after {
  content: "";
  position: absolute;
  inset-inline-end: 0;
  /* "right" */
  width: 1rem;
  height: 1rem;
  /* background: white; */
}

.banner {
  position: relative;
}

.banner-text {
  padding: 30px;
}

.bannerTitleContainer {
  font-size: 16px;
  font-family: "Mulish";
  /* padding: 80px; */
  color: white;
  font-weight: 500;
  position: absolute;
  bottom: 25px;

  margin-right: 30%;
}

.carlo-bg-quotes {
  background-color: #009fe3 !important;
}

.quotes-new-inner {
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
}

.quotes-icon {
  opacity: 0.6;
  height: 30px;
}

.bg-white {
  background-color: white !important;
}

.quote-carlose {
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
  align-items: center;
  justify-content: center;
}

.leftSliderCon {
  position: absolute;
  left: 0px;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sliderIcon {
  font-size: 70px;
  color: #0000001f;
}

.banner-flex-con {
  display: flex;
  position: relative;
}

.rightSliderCon {
  position: absolute;
  right: 0px;
  height: 100%;
  z-index: 999;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.about-right-logo {
  height: 40px;
}

.BannerTitle {
  /* padding-right: 10px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 160%;
}

.news-head-label {
  position: absolute;
  display: none;
  z-index: 3;
  background: #f0f0f0;
  padding: 7px 20px 7px 30px;
}

.pos-rel {
  position: relative;
}

.new-scroll {
  background: #009fe3;
  color: white;
  padding: 5px 10px;
  width: max-content;
}

.news-label-text {
  padding: 3px 30px 3px 20px;
  border-right: 1px solid;
  font-weight: 500;
  font-family: "Poppins";
}

.new-scroll-new {
  background: #ed1e24;
  color: white;
  padding: 5px 10px;
}

.new-scroll-skew {
  /* padding: 0px 10px; */
}

.BannerTitleHighlight {
  /* padding-right: 10px; */
  /* color: #14a5d3;
     */
  color: #009fe3;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 160%;
}

.BannerSubTitle {
  font-size: 13px;
}

.newsIcon {
  color: red;
}

.newsIconContainer {
  padding-right: 10px;
}

.news {
  /* height: 40px;
        line-height: 40px; */
  font-size: 15;
  font-weight: 600;
  font-family: "Mulish";
  width: 100%;
}

.news-items {
  padding: 5px 20px;
  align-items: center;
  transform: skewX(-20deg);
  cursor: pointer;
  align-self: center;
}

.scopes {
  background: rgba(243, 248, 255, 0.88);
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
}

.scope-image-conta {
  display: flex;
  padding: 40px 15px;
  justify-content: center;
  align-items: center;
}

.left-scope-item {
  text-align: right;
  padding-top: 25px;
  padding-bottom: 25px;
}

.right-scope-item {
  text-align: left;
  padding-top: 25px;
  padding-bottom: 25px;
}

.img-scope {
  height: 404px;
}

.scope-card {
  margin: auto 0px;
  align-items: center;
  /* justify-content: center; */
  width: 313px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
  line-break: anywhere;
}

.scope-box {
  min-height: 404px;
  align-items: center;
}

.scope-left-box {
  text-align: left;
  justify-content: end;
}

.scope-item {
  /* justify-content: center; */
  /* align-items: center; */
  margin: 10px 0px;
  height: auto;
}

.left {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.scope-right-box {
  text-align: left;
  justify-content: end;
}

.scope-box-items-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.scope-icon {
  padding: 5px 20px 20px 20px;
}

.scope-text-box-con {
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
}

.news-label {
  width: 300px;
}

.news-scrolls {
  width: calc(100% - 300px);
}

.scope-left-text-box-con {
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
}

.scope-text-box {
  padding: 15px 0px 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}

.scope-items {
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  /* justify-content: space-between; */
  /* height: 235px; */
}

.scope-items-cont {
  padding: 20px 0px 0px;
  display: block;
}

.scope-right-image {
  padding-left: 0px;
  width: 100%;
}

.scope-left-image {
  padding-right: 0px;
  width: 100%;
}

.scope-right-direction {
  flex-direction: row-reverse;
}

.about-left {
  /* padding-left: 80px; */
  /* display: flex;
    flex-direction: column; */
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  /* padding-right: 104px; */
  padding: 20px;
  background: #009fe3;
  color: white;
}

.about-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  background: #f5fbfe;
}

.about-descs {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 185%;
  /* or 44px */
  text-align: center;
  color: #333333;
}

.about-tile-container {
  padding: 20px;
  margin: 20px 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.about-new {
  display: block;
}

.about-tile-header {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 185%;
  /* identical to box height, or 56px */
  text-align: center;
  color: #333333;
}

.about-left-title {
  text-align: left !important;
  font-family: Poppins;
  font-style: normal;
  /* height: 120px; */
  /* line-height: 120px;
         */
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 30px;
  /* identical to box height, or 93px */
  text-align: center;
  color: #ffffff;
}

.about-left-desc {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  /* or 48px */
  letter-spacing: 0.02em;
  line-height: 170%;
  color: #ffffff;
}

.about-cont {
  display: flex;
  /* margin: 20px 0px; */
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.acom-data {
  padding: 20px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.scope-title,
.new-accom-title,
.org-strecture-title {
  font-family: Poppins;
  font-style: normal;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 30px;
  /* padding-bottom: 60px; */
}

.galleryTitle {
  font-family: Poppins;
  font-style: normal;
  display: flex;
  align-items: center;
  padding: 20px 0px;
  justify-content: center;
  font-weight: 600;
  font-size: 30px;
}

.accom-items-cont {
  display: block;
}

.about {
  /* height: 44px; */
  text-transform: capitalize;
  padding: 40px 0px 20px;
  /* margin: 23px 0px; */
  /* line-height: 44px; */
  font-family: "Mulish";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
}

.about-cards {
  height: auto;
  padding: 15px;
  overflow-y: auto;
  width: 290px;
  text-align: justify;
  line-break: anywhere;
  display: flex;
  background: #ffffff;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 4px 4px rgb(0 0 0 / 25%); */
  border-radius: 3px;
}

.about-card-title {
  font-weight: 600;
  /* height: 28px; */
  text-align: center;
  text-transform: capitalize;
  /* line-height: 28px; */
  color: #0a223d;
  padding: 0px 10px;
  font-size: 20px;
}

.about-card-text {
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  margin-top: 10px;
  padding: 0px 10px;
  /* padding-bottom: 30px; */
  height: auto !important;
  /* text-align: center; */
  align-items: center;
  display: flex;
  justify-content: center;
}

.about-cards-cont {
  margin: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-icon-con {
  align-items: flex-start;
  justify-content: center;
  display: flex;
}

.strec-cont {
  padding: 30px 0px;
}
.strec-cont-partners {
  padding: 30px 65px;
}

.org-avatar.chief {
  width: 150px !important;
  height: 150px !important;
}

.strec-item-cont {
  align-items: center;
  justify-content: center !important;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  padding: 15px ​30px;
  line-height: 28px;
  margin-bottom: 20px;
  /* identical to box height */
  text-align: center;
  color: #0a223d;
}
.par-avatar-partners {
  height: auto;
  width: 300px !important;

}
.par-avatar-partners-small {
  height: auto;
  width: 250px !important;
  height: max-content;
/* min-height: fit-content !important; */
}

.org-avatar {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

.org-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 185%;
  /* identical to box height, or 50px */
  text-align: center;
  color: #333333;
}

.core-item {
  padding: 20px;
}

.def-pad {
  padding-bottom: 100px;
}

.chief-item {
  padding: 50px;
}

.org-designation {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 185%;
  /* or 44px */
  text-align: center;
  color: #333333;
  opacity: 0.5;
}

.org-sub-containers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.org-sub-item {
  width: 50%;
  margin: 15px 0px;
}

.org-core-item {
  width: 50%;
  margin: 15px 0px;
}

.event-fee-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 185%;
  /* or 44px */
  display: flex;
  align-items: center;
  text-align: center;
}

.evnt-tile-btns-cont {
  display: flex;
  justify-content: space-around;
}

.evnt-tile-btn-cont {
  display: flex;
  justify-content: center;
}

.banner-img {
  object-fit: cover;
  height: calc(100vh - 160px);
  width: 100%;
}

.event-fee-value {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 185%;
  /* or 44px */
  display: flex;
  align-items: center;
  text-align: center;
}

.event-fee-value-RUPPE {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  padding: 0px 5px;
  line-height: 185%;
  display: flex;
  align-items: center;
  text-align: center;
}

.priceCont {
  height: 44px;
  padding: 10px 0px;
}

.book-now-btn-event {
  /* position: absolute; */
  width: calc(50% - 20px);
  height: 50px;
  margin: 10px;
  border: none;
  color: white;
  /* Primary */
  background: #009fe3;
  border-radius: 6px;
}

.read-more-btn-event {
  width: calc(50% - 20px);
  height: 50px;
  margin: 10px;
  background: #ffffff;
  /* Primary */
  border: 1px solid #009fe3;
  box-sizing: border-box;
  border-radius: 6px;
}

.read-more-btn-gallery {
  width: 200px;
  height: 50px;
  margin: 10px;
  background: #ffffff;
  /* Primary */
  border: 1px solid #009fe3;
  box-sizing: border-box;
  border-radius: 6px;
}

.value-end {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 185%;
  /* or 44px */
  display: flex;
  align-items: center;
  text-align: center;
}

.msg-data-con {
  /* display: flex;
        flex-direction: column-reverse; */
  padding: 0px 15px;
}

.event-tile-box-title {
  font-family: Poppins;
  font-style: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  font-size: 20px;
  line-height: 185%;
  /* identical to box height, or 63px */
  /* text-align: center; */
  color: #333333;
}
.news-updates-tile-box-title {
  font-family: Poppins;
  font-style: normal;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  overflow: hidden;
  font-weight: 600;
  font-size: 20px;
  line-height: 185%;
  /* identical to box height, or 63px */
  text-align: left;
  color: #333333;
}

.event-tile-box-desc {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 170%;
  height: 80px;
  overflow: hidden;
  /* or 37px */
  color: #333333;
}

.event-icons {
  height: 18px;
}

.event-venue {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 185%;
  /* identical to box height, or 37px */
  text-align: center;
  color: #333333;
  padding: 0px 10px;
}

.event-tile-data-container {
  padding: 20px;
}

.messages-main-title,
.event-main-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 35px;
  /* line-height: 185%; */
  /* identical to box height, or 93px */
  text-align: center;
  color: #ffffff;
}

.quote-icon-container {
  /* display: flex;
    align-items: center; */
}

.msg-person-photo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.msg-person-data-cont {
  padding: 0px 20px;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
}

.msg-person-photo-cont {
  width: 100px;
  height: 100px;
}

.pad-30 {
  padding: 0px 30px;
}

.msg-person-designation {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 190%;
  /* or 42px */
  display: flex;
  align-items: center;
  color: #ffffff;
  opacity: 0.7;
}

.msg-person-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 190%;
  /* or 49px */
  display: flex;
  align-items: center;
  color: #ffffff;
}

.new-message-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 190%;
  padding: 30px;
  /* or 46px */
  display: flex;
  align-items: center;
  color: #ffffff;
}

.new-events {
  background: #009fe3;
  align-items: center;
  justify-content: center;
  padding: 40px 0px 0px;
  /* margin: 20px; */
  height: auto;
}
.news-updates-tileContainer {
  /* width: full; */
}
.new-messages {
  background: #009fe3;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
  /* margin: 20px; */
  /* height: 1202px; */
}

.event-preview-img {
  width: 100%;
  padding: 10px;
}

.event-prv {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.event {
  /* height: 44px; */
  /* margin: 23px 0px; */
  /* line-height: 44px; */
  text-transform: capitalize;
  font-family: "Mulish";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
}

.left-tri {
  border: 0 solid #14a5d3;
  position: absolute;
  width: 264px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 35px;
  border-right-width: 264px;
  border-right-color: transparent;
}

.right-tri {
  border: 0 solid #07789c;
  width: 264px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 35px;
  border-left-width: 264px;
  border-left-color: transparent;
}

.tri-container {
  position: relative;
  display: flex;
}

.event-date-container {
  height: 30px;
  background: #14a5d3;
  width: 264px;
}

.event-card-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-shape-cont {
  margin-top: -65px;
  width: 318px;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.event-tile {
  padding: 40px 0px;
}

.event-datas {
  display: flex;
}

.new-event-info-details {
  height: 18px;
  line-height: 18px;
  padding: 20px 10px;
  font-size: 18px;
  font-weight: 600;
  color: white;
}

.event-data-container {
  height: 230px;
  background: #fff;
  padding: 15px;
  width: 264px;
  box-shadow: 4px 10px 9px rgba(0, 0, 0, 0.25);
}

.event-tile-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  height: 25px;
  text-align: center;
  color: #1c2541;
}

.event-tile-by-text {
  font-size: 11px;
  line-height: 14px;
  height: 14px;
  color: #a1a8af;
  padding-right: 5px;
}

.event-org-by-text {
  color: #0a223d;
  font-size: 12px;
  font-weight: 700;
  height: 16px;
  line-height: 16px;
}

.accom-cont-margin {
  margin-bottom: 20px;
}

.event-tile-data-text {
  font-size: 14px;
  padding-left: 2px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
}

.event-tiles-container {
  display: flex;
  /* margin-top: 20px; */
  width: 100%;
  flex-wrap: wrap;
  margin: 0px;
  justify-content: center;
}

.event-tile-box-container {
  width: calc(100% - 18px);
  height: auto;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 10px;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.news-updates-tile-box-container {
  width: calc(100%);
  height: auto;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 10px;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.event-card-tile {
  margin: 20px 20px;
}

.event-tile-desc {
  line-height: 17px;
  font-size: 13px;
  text-align: justify;
  color: #000;
  line-break: anywhere;
  margin: 8px 0px;
  overflow: hidden;
  height: 80px;
  text-overflow: ellipsis;
}

.event-fee-title-text {
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
}

.event-tile-fee-symbol {
  height: 17px;
  line-height: 20px;
  padding-left: 5px;
  font-size: 10px;
}

.event-tile-fee {
  line-height: 18px;
  height: 18px;
  font-size: 14px;
  color: #14a5d3;
  font-weight: 700;
}

.event-tile-btn {
  height: 25px;
  width: 84px;
  font-size: 13px;
  border: none;
}

.event-read-more {
  color: white;
  background: #1c2541;
}

.event-bkng {
  background: #14a5d3;
  color: black;
}

.gal-menus {
  font-size: 20px;
  display: flex;
}

.gal-menu.selected {
  color: #14a5d3;
}

.gal-menu {
  padding-right: 5px;
  cursor: pointer;
}

.gallery-container .gallery-data-container {
  width: calc(100% - 10px);
  margin: 5px;
  margin-top: 40px;
  /* padding: 0px 0px 50px 0px */
}

.gallery {
  display: grid;
  grid-template-columns: repeat(8);
  grid-template-rows: repeat(6);
  grid-gap: 2px;
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-arrow {
  color: white;
  background: #675959e0;
  height: 50px;
  align-items: center;
  width: 50px;
  font-size: 30px;
  cursor: pointer;
  outline: 0;
  position: absolute;
  justify-content: center;
  display: flex;
  top: calc(50% - 25px);
}

.gallery-arrow.left {
  left: 0;
}

.curser-zoomout {
  cursor: zoom-out;
}

.curser-zoomin {
  cursor: zoom-in;
}

.gallery-arrow.right {
  right: 0;
}

.gallery-preview {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #0d0000c2;
  z-index: 999;
  /* overflow: auto; */
}

.all-to-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pad-50 {
  padding: 50px;
}

/* .gallery__img:hover {} */

.gallery__item--1 {
  grid-column: 1 / span 2;
  grid-row: 1 / span 3;
}

.gallery__item--2 {
  grid-column: 3 / span 2;
  grid-row: 1 / span 2;
}

.gallery__item--3 {
  grid-column: 5 / span 4;
  grid-row: 1 / span 3;
}

.gallery__item--4 {
  grid-column: 1 / span 2;
  grid-row: 4 / span 2;
}

.gallery__item--5 {
  grid-column: 3 / span 2;
  grid-row: 3 / span 3;
}

.gallery__item--6 {
  grid-column: 5 / span 4;
  grid-row: 4 / span 2;
}

.quotes-container {
  background: #1c2541;
  /* min-height: 432px; */
  display: flex;
  /* margin-top: 20px;
    padding: 20px; */
  /* align-items: center; */
  justify-content: center;
  flex-direction: row-reverse;
}

.quotes-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 707px; */
}

.quotes-text {
  padding: 20px 40px;
  text-align: center;
  color: white;
  font-size: 14px;
}

.quote-symbel {
  height: 33px;
  width: 43px;
}

.event-fee {
  /* padding: 10px 0px; */
  height: 18px !important;
}

.quotes-by {
  color: #14a5d3;
  font-size: 15px;
  font-weight: 700;
}

.quotes-by-company {
  font-size: 13px;
  font-weight: 400;
  color: white;
}

.quotes-by-user-image {
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
}

.side-padd-50 {
  padding: 0px 50px;
}

.accom-rt {
  border-right: 0px solid #0005;
  padding: 20px;
  width: 100%;
}

.accom-left {
  border-left: 0px solid #0005;
  padding: 20px;
  width: 100%;
}

.progressCont {
  width: 100%;
  padding: 20px;
}

.quote-actions {
  background-color: white;
  margin: 10px 3px;
  border-radius: 50%;
  cursor: pointer;
  height: 16px;
  width: 16px;
}

.scopeCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quote-actions.default {
  background-color: #c2ffffbd;
}

.margin-car {
  margin: 20px 0px;
}

.accom-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 166%;
  /* identical to box height, or 50px */
  color: #333333;
}

.accom-name-span {
  text-overflow: ellipsis;
  white-space: unset;
  overflow: hidden;
  padding: 0px 20px;
}

.quotes-bg {
  display: none;
}

.icon-to-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-title {
  font-size: 20px !important;
  width: fit-content;
}

.scope-icons-new {
  width: 60px;
  height: 60px;
  padding: 10px;
  background: #c4c4c422;
}

.about-left-desc {
  text-align: justify;
}

@media only screen and (min-width: 768px) {
  .about-left-desc {
    text-align: unset;
  }

  .priceCont {
    height: 74px;
    padding: 10px 0px;
  }

  .news-head-label {
    display: block;
  }

  .org-avatar.chief {
    width: 240px !important;
    height: 240px !important;
  }

  .accom-name-span {
    white-space: nowrap;
  }

  .event-tiles-container {
    display: flex;
    /* margin-top: 20px; */
    width: calc(100% - 60px);
    flex-wrap: wrap;
    margin: 0px 30px;
    justify-content: center;
  }
  .news-updates-tiles-container {
    display: flex;
    /* margin-top: 20px; */
    width: 380px;
    flex-wrap: wrap;
    margin: 0px 30px;
    justify-content: center;
  }

  .org-designation {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 185%;
    /* or 44px */
    text-align: center;
    color: #333333;
    opacity: 0.5;
  }

  .org-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 185%;
    /* identical to box height, or 50px */
    text-align: center;
    color: #333333;
  }

  .book-now-btn-event {
    /* position: absolute; */
    width: calc(50% - 20px);
    height: 50px;
    margin: 0px;
    border: none;
    color: white;
    /* Primary */
    background: #009fe3;
    border-radius: 6px;
  }

  .read-more-btn-event {
    width: calc(50% - 20px);
    height: 50px;
    margin: 0px;
    background: #ffffff;
    /* Primary */
    border: 1px solid #009fe3;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .value-end {
    font-size: 18px;
  }

  .event-fee-value {
    font-size: 25px;
  }

  .event-fee-value-RUPPE {
    font-size: 20px;
  }

  .event-fee-title {
    font-size: 18px;
  }

  .BannerTitleHighlight {
    font-size: 40px;
  }

  .BannerSubTitle {
    font-size: 20px;
  }

  .BannerTitle {
    font-size: 40px;
  }

  .org-avatar {
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }
  .par-avatar {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    padding: 6px;
  }
  .par-avatar-cover {
    border-radius: 50%;
    border: 2px solid #009fe3;
  }

  .accom-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 166%;
    /* identical to box height, or 50px */
    color: #333333;
  }

  .scope-title,
  .new-accom-title,
  .org-strecture-title,
  .event-main-title,
  .messages-main-title {
    font-size: 40px;
    /* padding-bottom: 60px; */
    /* margin-top: 60px; */
  }

  .galleryTitle {
    font-size: 40px;
  }

  .new-message-desc {
    font-size: 18px;
  }

  .accom-rt {
    border-right: 1px solid #0005;
    width: 50%;
    padding: 50px;
  }

  .accom-left {
    border-left: 1px solid #0005;
    width: 50%;
    padding: 50px;
  }

  .quotes-icon {
    height: 114px;
  }

  .quotes-new-inner {
    padding: 0px 100px;
    flex-direction: row;
  }

  .event-prv {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .accom-items-cont {
    display: flex;
    flex-wrap: wrap;
  }

  .about-tile-container {
    padding: 20px;
  }

  .about-descs {
    font-size: 18px;
  }

  .about-tile-header {
    font-size: 30px;
  }

  .about-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: calc(50% - 50px);
    background: #f5fbfe;
  }

  .event-tile-box-title {
    font-size: 22px;
  }

  .about-left {
    /* padding-left: 80px; */
    /* display: flex;
        flex-direction: column; */
    width: calc(50% + 54px);
    /* padding-right: 104px; */
    padding: 50px;
    background: #009fe3;
    color: white;
  }

  .event-tile-box-desc {
    font-size: 18px;
  }

  .scope-text-box-con {
    padding: 50px;
    display: flex;
    width: 50%;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
  }

  .event-venue {
    font-size: 15px;
  }

  .event-icons {
    height: 25px;
  }

  .scope-left-text-box-con {
    padding: 0px 50px 50px;
    display: flex;
    width: 50%;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
  }

  .scope-text-box {
    padding: 15px 0px 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  }

  .about-left-desc {
    font-size: 19px;
    /* or 48px */
  }

  .scope-items {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 20px;
    /* justify-content: space-between; */
    /* height: 235px; */
  }

  .scope-items-cont {
    padding: 60px 0px 0px;
    display: flex;
  }

  .scope-right-image {
    padding-left: 50px;
    width: 50%;
  }

  .scope-left-image {
    padding-right: 25px;
    width: 50%;
  }

  .scope-container,
  .gallery-container,
  .new-accomp-cont,
  .event-new-container,
  .org-structure {
    padding: 40px 0px 0px;
  }

  .about-new {
    display: flex;
  }

  .progressCont {
    width: 50%;
  }

  .about-left-title {
    text-align: left !important;
    font-family: Poppins;
    font-style: normal;
    /* height: 120px;
            line-height: 120px; */
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 40px;
    /* identical to box height, or 93px */
    text-align: center;
    color: #ffffff;
  }

  .event-tile-box-container {
    width: calc(33% - 20px);
  }

  .new-events {
    background: linear-gradient(to bottom, #009fe3 50%, white 50%);
    height: 915px;
  }

  .acom-data {
    padding: 50px 0px;
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (min-width: 992px) {
  .scope-title,
  .new-accom-title,
  .org-strecture-title,
  .event-main-title,
  .messages-main-title {
    font-size: 40px;
    /* padding-bottom: 60px; */
    /* margin-top: 60px; */
  }

  .galleryTitle {
    font-size: 40px;
  }

  .org-border-active {
    margin: 0px !important;
    width: 120px !important;
    height: 8px !important;
  }

  .org-border-inactive {
    margin: 0px !important;
    width: 120px !important;
    height: 8px !important;
    opacity: 1 !important;
    border-radius: 6px !important;
    color: transparent !important;
  }

  .gal-border-active {
    margin: 5px !important;
    width: 50px !important;
    height: 8px !important;
    opacity: 1 !important;
    display: block;
    border-radius: 6px !important;
    color: #009fe3 !important;
  }

  .gal-border-inactive {
    margin: 5px !important;
    width: 50px !important;
    height: 3px !important;
    opacity: 1 !important;
    border-radius: 6px !important;
    color: transparent !important;
  }

  .tab-switch {
    font-size: 30px;
  }

  .accom-rt {
    border-right: 1px solid #0005;
    width: 50%;
  }

  .accom-left {
    border-left: 1px solid #0005;
    width: 50%;
  }

  .acom-data {
    padding: 50px 0px;
    display: flex;
    flex-direction: row;
  }

  .quotes-icon {
    height: 114px;
  }

  .quotes-new-inner {
    padding: 0px 100px;
    min-height: 440px;
    flex-direction: row;
  }

  .accom-items-cont {
    display: flex;
    flex-wrap: wrap;
  }

  .about-tile-container {
    padding: 20px 35px;
  }

  .about-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: calc(50% - 50px);
    background: #f5fbfe;
  }

  .event-tile-box-title {
    font-size: 25px;
  }

  .event-tile-box-desc {
    font-size: 16px;
  }

  .event-venue {
    font-size: 18px;
  }

  .scope-left-text-box-con {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    padding: 0px 50px 50px;
  }

  .scope-text-box {
    padding: 15px 0px 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  }

  .scope-items {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 20px;
    /* justify-content: space-between; */
    /* height: 235px; */
  }

  .about-descs {
    font-size: 18px;
  }

  .gal-con-new {
    margin-top: 30px;
  }

  .scope-items-cont {
    padding: 60px 0px 0px;
    display: flex;
  }

  .scope-text-box-con {
    padding: 50px;
    display: flex;
    flex-direction: column;
    width: 50%;
    align-content: space-between;
    justify-content: space-between;
  }

  .scope-right-image {
    padding-left: 50px;
    width: 50%;
  }

  .scope-left-image {
    padding-right: 25px;
    width: 50%;
  }

  .scope-container,
  .gallery-container,
  .new-accomp-cont,
  .event-new-container,
  .org-structure {
    padding: 40px 0px 0px;
  }

  .about-new {
    display: flex;
  }

  .about-tile-header {
    font-size: 30px;
  }

  .about-left-title {
    text-align: left !important;
    font-family: Poppins;
    font-style: normal;
    /* height: 120px;
            line-height: 120px; */
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 40px;
    /* identical to box height, or 93px */
    text-align: center;
    color: #ffffff;
  }

  .about-left-desc {
    font-size: 19px;
    /* or 48px */
  }

  .progressCont {
    width: 50%;
  }

  .about-left {
    /* padding-left: 80px; */
    /* display: flex;
        flex-direction: column; */
    width: calc(50% + 50px);
    /* padding-right: 104px; */
    padding: 50px;
    background: #009fe3;
    color: white;
  }

  .event-prv {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .event-tile-box-container {
    width: calc(33%- 20px);
  }

  .new-events {
    background: linear-gradient(to bottom, #009fe3 50%, white 50%);
    height: 915px;
  }
}

/* .{} */

/* media queries */
