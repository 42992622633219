.dash-banner {
    background-image: url("../../../../resources/img/dashboard/userdashbanner.png");
    width: 100%;
    height: 90px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.noFieldTableWidth {
    width: 70px!important
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
}

/* .max-bold {
    font-weight: 900;
} */

.sub-items-txt {
    font-size: 14px;
}

.basic-submit {
    margin: 0px !important;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-bg .formbg {
    padding: 7px 2px!important;
}

.page-bg .itemc {
    padding: 11px 1px!important;
}

.form-bg {
    background: #ebe9e9;
    padding: 20px 50px 20px 50px!important;
}

.page-bg {
    background: #ebe9e9;
}

.section-Cont {
    background-color: #f3f8ff;
    padding: 20px 20px;
    margin-bottom: 15px;
    /* margin: 20px 0px; */
    border: 1px solid rgba(85, 85, 85, 0.5);
}

.items-cont {
    padding: 8px 3px;
}

.mobw {
    width: 79%;
}

.to-left-side {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 10px 0px; */
    /* margin: 27px 0px 10px 0px; */
    margin: 16px 0px 10px 0px;
}

.form-section {
    background: white;
    width: 100%;
    padding: 0px;
}

.fileContainer {
    display: flex;
}

.more-btn-cnt {
    display: flex;
    flex-direction: row-reverse;
}

.chk-items-cont {
    padding: 10px;
}

.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile:focus+label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
    pointer-events: none;
}

.secondary-btn {
    background: #f76c6c;
    padding: 10px 18px;
    margin: 0px 10px;
    cursor: pointer;
    border-radius: 4px;
    color: white;
}

thead {
    background: rgb(255, 255, 255) !important;
    color: #0a223d !important;
}

.btn-primary {
    background: #1c2541 !important;
    border: none;
    cursor: pointer;
}

.primary-btn {
    background: #1c2541;
    padding: 10px 14px;
    margin: 0px 10px;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 3px;
}

.optionLabelHeader-cont {
    color: black;
    font-weight: 500;
    height: 29px;
    /* padding: 0px 20px; */
    line-height: 29px;
}

.tableHeading {
    background: #0a223d;
    color: white;
    height: 25px;
    line-height: 25px;
    padding: 0px 15px;
}

.table-list-cont {
    margin: 20px 10px;
}

.header-body-spacing {
    margin-top: 20px;
}

.header-helper-text {
    text-align: right;
    color: red;
    font-weight: 700;
}

.headerbuttonodd {
    height: 40px;
    background: #e3ecef;
    margin-right: 50px;
    color: #000;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.headerbuttonodd.active {
    height: 40px;
    background: #0a223d;
    margin-right: 50px;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.indexCont {
    background: #14a5d3;
    width: 30px !important;
    text-align: center;
    border-radius: 50%;
    margin: auto 6px!important;
    color: white;
    height: 30px !important;
    line-height: 30px;
}

.indexCont.even {
    margin-left: -15px;
    z-index: 1;
}

.headerbuttonodd.active:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #0a223d;
}

.headerbuttonodd:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #e3ecef;
}

.headerbuttoneven {
    height: 40px;
    background: #e3ecef;
    display: flex;
    color: #000;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    /* margin-right: 10px; */
}

.headerbuttoneven:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #e3ecef;
    border-left: 20px solid transparent;
}

.headerbuttoneven.active {
    height: 40px;
    background: #0a223d;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven.active:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #0a223d;
    border-left: 20px solid transparent;
}

.form-inner-section-new {
    /* padding: 12px 0px 20px 0px;
    width: 100%; */
    width: 100%;
    padding: 15px 15px 0px;
}

.fieldset {
    margin: 15px 0px;
}

.file-up-helper {
    color: red;
    font-size: 12px;
    font-weight: 600;
    margin-top: -12px;
    margin-bottom: 14px;
}



.file-up-label-cont {
    margin-bottom: -10px;
    margin-top: 11px;
    color: #1c2541 !important;
    font-weight: 600;
    line-height: 17.75px;
}

.header-label {
    margin-top: 0px;
    margin-bottom: 14px;
    font-weight: 600;
    color: #1c2541;
}

.view-container {
    padding: 6px 0px 0px 0px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.data .row {
    padding: 7px 5px;
    background: #e7e7e7;
    margin: 0 0 10px 0;
}

.data .row .col-8 {
    color: black;
    font-size: 16px;
}

.data .row .col-3 {
    color: #666;
    font-size: 16px;
}

.mysubbtn:hover {
    color: white;
}

.nio {
    color: white !important;
    font-weight: 300;
    font-size: 15px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px !important;
    font-weight: 400 !important;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.text-justify {
    text-align: justify;
}

.dwnld {
    color: #f76c6c;
    font-weight: bold;
    text-decoration: underline;
}

.data .row .col-8 table {
    background-color: white !important;
    margin-top: 0px !important;
}

.venture {
    background-color: white !important;
    padding-top: 10px;
}

.data .current {
    background-color: #0a223d !important;
    color: white !important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white !important;
}

.status label {
    font-size: 16px;
}

.mysubbtn {
    margin-top: 19px;
    background: #0a223d !important;
    color: white;
    padding: 6px 66px;
}

.filex {
    margin-top: -13px;
}

.indicator {
    color: red;
}

.headerbuttoneven {
    font-size: 12px;
}

.headerbuttonodd {
    font-size: 12px;
}

.scrolltable {
    overflow: hidden;
    overflow-x: auto;
    padding: 0px;
}

.ownlad {
    padding: 0px 14px;
}

.jointv {
    padding: 0px 14px;
}

.fomy {
    padding: 0px 10px!important;
}

.bg-col {
    background-color: #d8e6eca1 !important;
}

.ownd textarea {
    height: 100px !important;
}

.ownd .nop {
    margin-bottom: 0px !important;
}

.items-cont {
    padding: 19px 10px 10px 10px!important;
}

.pix {
    width: fit-content!important;
}

.demox .conti {
    padding-bottom: 0px!important;
}
.footer-buttons-newreg {
    display: flex!important;
    margin: 17px;
}

/* ================================================================================================================================== */

@media (min-width: 992px) {
    .form-section {
        width: 921px !important;
        padding: auto;
    }
    .footer-buttons {
        margin: 27px 0px 5px 0px;
    }
    .fomy {
        padding: 15px 0px 20px 0px !important;
    }
    .page-bg .itemc {
        padding: 20px 60px 0px 60px!important;
    }
    .page-bg .formbg {
        padding: 19px 2px!important;
    }
    .indexCont {
        margin: auto 10px;
    }
    .items-cont {
        padding: 35px 49px 27px 49px!important;
    }
    .mobw {
        width: auto;
    }
    .form-bg {
        padding: 37px 50px!important;
    }
    .bg-col {
        background-color: #d8e6eca1 !important;
    }
    .headerbuttoneven {
        font-size: 14px !important;
    }
    .headerbuttonodd {
        font-size: 14px !important;
    }
    .ownd textarea {
        height: 100px !important;
    }
    .table-list-cont {
        margin: 20px;
    }
    .dash-banner {
        height: 162px;
    }
    .nop label {
        margin-bottom: 0px !important;
    }
    .tit-txt {
        font-size: 18px;
    }
    .footer-buttons-newreg {
        display: flex!important;
        align-items: center;
        justify-content: center;
        /* margin: 15px 0px 0px!important; */
    }
    /* .form-inner-section-new {
        padding: 12px 0px 0px 0px;
        width: 100%;
    } */
}