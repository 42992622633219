@import url(https://use.fontawesome.com/releases/v5.6.3/css/all.css);
.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: rgb(100, 100, 100);
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

.lds-spinner-light {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-spinner-light div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner-light div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: rgb(255, 255, 255);
}

.lds-spinner-light div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner-light div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner-light div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner-light div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner-light div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner-light div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner-light div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner-light div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner-light div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner-light div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner-light div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner-light div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.loader-bg {
    /* background: #00000045; */
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 999;
    transform: translate(0%, 0%);
}

.loader-bg-light {
    background: #00000045;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 999;
    transform: translate(0%, 0%);
}

.loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
        .error {
            background: #dc3545!important;
            border-radius: 10px 10px 0px 0px;
        }
        
        .snackIconContainer {
            height: 220px;
        }
        
        .snackTitle {
            font-size: 25px;
            font-weight: 700;
            text-align: center;
            color: #184688;
            margin: 10px;
        }
        
        .snackIcon {
            font-size: 150px;
            transform: translate(75px, 35px);
            color: white;
        }
        
        .success {
            background: #35b651!important;
            border-radius: 10px 10px 0px 0px;
        }
        
        .iconContaier {
            color: white
        }
        
        .snackbar-msg-p {
            font-family: 'Mulish';
            margin: 0px!important;
        }
        
        .snackbar {
            background: #ffffff;
            position: fixed;
            border-radius: 10px;
            box-shadow: 1px 1px 20px 5px #000000a1;
            top: 50%;
            left: 50%;
            width: 300px;
            /* height: 424px; */
            transform: translate(-150px, -212px);
        }
        
        .overlay-snack {
            background: #000000b0;
            position: fixed;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            z-index: 9999;
            transform: translate(0%, 0%);
        }
        
        .snackmessageContainer {
            margin: 0;
            padding: 0px 20px;
            height: auto;
            overflow: auto;
            text-align: center;
        }
        
        .snackbuttonContainer {
            justify-content: center;
            /* padding: 14px; */
            display: flex;
            padding: 10px;
        }
        
        .snackButtonClass {
            width: 120px;
            color: white;
            height: 43px;
            /* box-shadow: 0 0 20px 0px #0000005e; */
            font-weight: 600;
            border: none;
            border-radius: 50px;
            margin-bottom: 7px;
        }
.button-to-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
}

.chkbx {
    margin: auto 10px !important;
}



.commonButtonbutton {
    background: #0a223d !important;
    color: #ffffff !important;
    border: navajowhite !important;
    width: 95px !important;
    padding: 10px !important;
    cursor: pointer !important;
    line-height: 12px !important;
    border-radius: 3px;
}

.button-label-style {
    cursor: pointer;
}

.mylabel {
    color: #1c2541 !important;
    font-weight: 600 !important;
    line-height: 17.75px !important;
    padding-bottom: 4px !important;
}
.form-label-error {
    color: red !important;
    font-weight: 600 !important;
    line-height: 17.75px !important;
    padding-bottom: 4px !important;
}

.defaultSearch {
    color: white;
    background: #2cafe7;
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 25px;
    width: 25px;
    font-size: 20px;
    justify-content: center;
    border-radius: 0px 5px 5px 0px;
}

.clearInput {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    cursor: pointer;
    margin: 0px 10px;
    font-size: 20px;
}

.searchTextContainer {
    display: flex;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(20, 165, 211, 0.3);
    border-radius: 5px;
}

.searchTextInput {
    width: 100%;
    padding: 0px 10px;
    border: none;
    border-radius: 5px 0px 0px 5px;
}

input.searchTextInput:focus-visible {
    outline: none;
}

.rdo {
    margin: 10px !important;
}

@media only screen and (min-width: 768px) {
    .defaultSearch {
        height: 40px;
        width: 40px;
        padding: 10px;
    }

    .clearInput {
        height: 40px;
    }
}
.pagination-container {
    display: flex;
    list-style-type: none;
}

.pagination-container .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
}

.pagination-container .pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
}

.pagination-container .pagination-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
}

.pagination-container .pagination-item.selected {
    background-color: rgb(20 165 211);
    color: white;
    font-weight: 900;
}

.pagination-container .pagination-item .arrow::before {
    position: relative;
    /* top: 3pt;
    Uncomment this to lower the icons as requested in comments*/
    content: '';
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.pagination-container .pagination-item .arrow.left {
    transform: rotate(-135deg) translate(-50%);
}

.pagination-container .pagination-item .arrow.right {
    transform: rotate(45deg);
}

.pagination-container .pagination-item.disabled {
    pointer-events: none;
}

.pagination-container .pagination-item.disabled .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-container .pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
}
h1,
p {
  font-family: Lato;
}

table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}

.statuscoloum {
  width: 194px !important;
}

.action-column {
  width: 150px;
  justify-content: center;
  /* align-items: center;
  height: 100%; */
  /* border: none; */
}
.fileaction-column {
  width: 80px;
  justify-content: center;
  /* border: none; */
}

.noFieldTableWidth {
  width: 70px !important;
}

.messagecoloum {
  width: 85px;
}
.parserfont p {
  font-size: 13px;
  font-weight: inherit;
  margin: 0px;
}

.Submit.for.Acceptance {
  background: #14a5d3;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

thead tr th {
  font-weight: 500;
  text-align: left;
  background-color: #fafafa;
}

tbody tr:nth-child(even) {
  background-color: #fafafa;
}

th,
td {
  padding: 8px;
  overflow-wrap: break-word;
  text-transform: none;
  height: 100%;
}

.pagination-bar {
  width: 100%;
  display: flex;
  margin-top: 16px;
  justify-content: center;
}

.table-bordered {
  border: 1px solid #ced4da;
  margin-top: 10px !important;
  font-size: 14px !important;
}

.thed {
  font-weight: bold;
  background-color: #666;
  color: white;
}
.exphead {
  font-weight: bold;
  background-color: #666;
  color: white;
}

.viewContainer {
  width: 26px;
  display: flex;
  height: 26px;
  line-height: 26px;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: black;
}

.notificationContainer {
  /* width: 26px; */
  display: flex;
  height: 26px;
  line-height: 26px;
  border-radius: 50%;
  text-align: center !important;
  align-items: center;
  justify-content: center;
}

.notificationItem {
  color: grey;
  font-size: 20px;
  cursor: pointer;
}

.activenoti {
  color: rgb(49, 6, 149);
  font-size: 20px;
}

.iconCell {
  align-items: center;
  display: flex;
  justify-content: center;
}

.viewItem {
  font-size: 20px;
  color: #14a5d3;
}

.full-img-list {
  width: 500px;
  /* height: 39px; */
}

.img-list {
  width: 67px;
  height: 39px;
}

.img-list img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.full-img-list img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.action-button {
  margin: 5px;
  width: 25px;
  height: 25px;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
}

.actionColumns {
  width: -webkit-max-content;
  width: max-content;
}

.action-button.delete {
  background-color: #ff5454;
  color: white;
}

.action-button.view {
  background-color: #0a223d;
  color: #14a5d3;
}

.approve {
  background: #329732;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.reject {
  background: #d10000;
  color: white;
}

.accepted,
.Approved,
.Accepted {
  background: #329732;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.rejected,
.Rejected {
  background: #d10000;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.pending,
.Pending {
  background: #f76c6c;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.inprogress {
  background: rgba(10, 34, 61, 0.69);
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.acceptance {
  background: rgba(214, 150, 54, 0.69);
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}
.acceptance2 {
  background: rgb(156, 71, 2);
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.resubmit {
  background: #14a5d3;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.agrementexecution {
  background: #0a66c2;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.jointinput{
    margin: 5px 0px!important;
}
.ind{
    width: 50px!important;
}
h1,
p {
  font-family: "Mulish";
}

table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}

thead tr th {
  font-weight: 500;
  text-align: left;
  background-color: #fafafa;
}

tbody tr:nth-child(even) {
  background-color: #fafafa;
}

th,
td {
  padding: 8px;
  overflow-wrap: break-word;
}

.table-bordered {
  border: 1px solid #ced4da;
  margin-top: 7px!important;
  font-size: 14px !important;
  
}

.table-container {
  margin: 10px 0px;
}

.selectbox.joint-venture {
  width: 70% !important;
}

.pad20 {
  padding: 20px !important;
}
.ownd .fieldset {
  margin: 7px 0px;
  /* height: 100%; */
}
.bg-col {
  background-color: #d8e6eca1 !important;
}
.jointx .fieldset {
  margin: 7px 0px;
}
.jointx .joix {
  margin: 11px 0px;
}

.tooltip-text-editor ql-tooltip {
    position:initial !important;
}
.dash-banner {
    background-image: url(/static/media/userdashbanner.989062ce.png);
    width: 100%;
    height: 90px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.noFieldTableWidth {
    width: 70px!important
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
}

/* .max-bold {
    font-weight: 900;
} */

.sub-items-txt {
    font-size: 14px;
}

.basic-submit {
    margin: 0px !important;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-bg .formbg {
    padding: 7px 2px!important;
}

.page-bg .itemc {
    padding: 11px 1px!important;
}

.form-bg {
    background: #ebe9e9;
    padding: 20px 50px 20px 50px!important;
}

.page-bg {
    background: #ebe9e9;
}

.section-Cont {
    background-color: #f3f8ff;
    padding: 20px 20px;
    margin-bottom: 15px;
    /* margin: 20px 0px; */
    border: 1px solid rgba(85, 85, 85, 0.5);
}

.items-cont {
    padding: 8px 3px;
}

.mobw {
    width: 79%;
}

.to-left-side {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 10px 0px; */
    /* margin: 27px 0px 10px 0px; */
    margin: 16px 0px 10px 0px;
}

.form-section {
    background: white;
    width: 100%;
    padding: 0px;
}

.fileContainer {
    display: flex;
}

.more-btn-cnt {
    display: flex;
    flex-direction: row-reverse;
}

.chk-items-cont {
    padding: 10px;
}

.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile:focus+label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
    pointer-events: none;
}

.secondary-btn {
    background: #f76c6c;
    padding: 10px 18px;
    margin: 0px 10px;
    cursor: pointer;
    border-radius: 4px;
    color: white;
}

thead {
    background: rgb(255, 255, 255) !important;
    color: #0a223d !important;
}

.btn-primary {
    background: #1c2541 !important;
    border: none;
    cursor: pointer;
}

.primary-btn {
    background: #1c2541;
    padding: 10px 14px;
    margin: 0px 10px;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 3px;
}

.optionLabelHeader-cont {
    color: black;
    font-weight: 500;
    height: 29px;
    /* padding: 0px 20px; */
    line-height: 29px;
}

.tableHeading {
    background: #0a223d;
    color: white;
    height: 25px;
    line-height: 25px;
    padding: 0px 15px;
}

.table-list-cont {
    margin: 20px 10px;
}

.header-body-spacing {
    margin-top: 20px;
}

.header-helper-text {
    text-align: right;
    color: red;
    font-weight: 700;
}

.headerbuttonodd {
    height: 40px;
    background: #e3ecef;
    margin-right: 50px;
    color: #000;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.headerbuttonodd.active {
    height: 40px;
    background: #0a223d;
    margin-right: 50px;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.indexCont {
    background: #14a5d3;
    width: 30px !important;
    text-align: center;
    border-radius: 50%;
    margin: auto 6px!important;
    color: white;
    height: 30px !important;
    line-height: 30px;
}

.indexCont.even {
    margin-left: -15px;
    z-index: 1;
}

.headerbuttonodd.active:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #0a223d;
}

.headerbuttonodd:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #e3ecef;
}

.headerbuttoneven {
    height: 40px;
    background: #e3ecef;
    display: flex;
    color: #000;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    /* margin-right: 10px; */
}

.headerbuttoneven:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #e3ecef;
    border-left: 20px solid transparent;
}

.headerbuttoneven.active {
    height: 40px;
    background: #0a223d;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven.active:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #0a223d;
    border-left: 20px solid transparent;
}

.form-inner-section-new {
    /* padding: 12px 0px 20px 0px;
    width: 100%; */
    width: 100%;
    padding: 15px 15px 0px;
}

.fieldset {
    margin: 15px 0px;
}

.file-up-helper {
    color: red;
    font-size: 12px;
    font-weight: 600;
    margin-top: -12px;
    margin-bottom: 14px;
}



.file-up-label-cont {
    margin-bottom: -10px;
    margin-top: 11px;
    color: #1c2541 !important;
    font-weight: 600;
    line-height: 17.75px;
}

.header-label {
    margin-top: 0px;
    margin-bottom: 14px;
    font-weight: 600;
    color: #1c2541;
}

.view-container {
    padding: 6px 0px 0px 0px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.data .row {
    padding: 7px 5px;
    background: #e7e7e7;
    margin: 0 0 10px 0;
}

.data .row .col-8 {
    color: black;
    font-size: 16px;
}

.data .row .col-3 {
    color: #666;
    font-size: 16px;
}

.mysubbtn:hover {
    color: white;
}

.nio {
    color: white !important;
    font-weight: 300;
    font-size: 15px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px !important;
    font-weight: 400 !important;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.text-justify {
    text-align: justify;
}

.dwnld {
    color: #f76c6c;
    font-weight: bold;
    text-decoration: underline;
}

.data .row .col-8 table {
    background-color: white !important;
    margin-top: 0px !important;
}

.venture {
    background-color: white !important;
    padding-top: 10px;
}

.data .current {
    background-color: #0a223d !important;
    color: white !important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white !important;
}

.status label {
    font-size: 16px;
}

.mysubbtn {
    margin-top: 19px;
    background: #0a223d !important;
    color: white;
    padding: 6px 66px;
}

.filex {
    margin-top: -13px;
}

.indicator {
    color: red;
}

.headerbuttoneven {
    font-size: 12px;
}

.headerbuttonodd {
    font-size: 12px;
}

.scrolltable {
    overflow: hidden;
    overflow-x: auto;
    padding: 0px;
}

.ownlad {
    padding: 0px 14px;
}

.jointv {
    padding: 0px 14px;
}

.fomy {
    padding: 0px 10px!important;
}

.bg-col {
    background-color: #d8e6eca1 !important;
}

.ownd textarea {
    height: 100px !important;
}

.ownd .nop {
    margin-bottom: 0px !important;
}

.items-cont {
    padding: 19px 10px 10px 10px!important;
}

.pix {
    width: -webkit-fit-content!important;
    width: -moz-fit-content!important;
    width: fit-content!important;
}

.demox .conti {
    padding-bottom: 0px!important;
}
.footer-buttons-newreg {
    display: flex!important;
    margin: 17px;
}

/* ================================================================================================================================== */

@media (min-width: 992px) {
    .form-section {
        width: 921px !important;
        padding: auto;
    }
    .footer-buttons {
        margin: 27px 0px 5px 0px;
    }
    .fomy {
        padding: 15px 0px 20px 0px !important;
    }
    .page-bg .itemc {
        padding: 20px 60px 0px 60px!important;
    }
    .page-bg .formbg {
        padding: 19px 2px!important;
    }
    .indexCont {
        margin: auto 10px;
    }
    .items-cont {
        padding: 35px 49px 27px 49px!important;
    }
    .mobw {
        width: auto;
    }
    .form-bg {
        padding: 37px 50px!important;
    }
    .bg-col {
        background-color: #d8e6eca1 !important;
    }
    .headerbuttoneven {
        font-size: 14px !important;
    }
    .headerbuttonodd {
        font-size: 14px !important;
    }
    .ownd textarea {
        height: 100px !important;
    }
    .table-list-cont {
        margin: 20px;
    }
    .dash-banner {
        height: 162px;
    }
    .nop label {
        margin-bottom: 0px !important;
    }
    .tit-txt {
        font-size: 18px;
    }
    .footer-buttons-newreg {
        display: flex!important;
        align-items: center;
        justify-content: center;
        /* margin: 15px 0px 0px!important; */
    }
    /* .form-inner-section-new {
        padding: 12px 0px 0px 0px;
        width: 100%;
    } */
}
.admin-header-container {
    height: 86px;
    display: flex;
    align-items: center;
    font-family: "Titillium Web";
    /* justify-content: center; */
    box-shadow: 0px 0px 10px 0px #00000045;
    background: white;
}

.admin-body-container {
    /* background: yellow; */
    display: flex;
    height: calc(100vh - 126px);
    overflow: auto;
}

.admin-body-container.full-hght {
    height: 100vh;
}

.admin-body-items {
    /* background: violet; */
    font-family: mulish;
    min-height: calc(100vh - 126px);
    width: calc(100% - 235px);
    overflow-y: auto;
    overflow-x: hidden;
}

.mt0 {
    margin-top: 0px !important;
}

.AdminLayout .header-container {
    background: #ebe9e9;
    height: 100vh;
    /* background: red; */
    overflow: hidden;
}

.header-logo-image {
    height: 69px;
}

.header-menu-icon {
    font-size: 30px;
}

.admin-header-title {
    position: absolute;
    top: 49px;
    left: 90px;
}

.gap {
    grid-gap: 10px;
    gap: 10px;
}


/* sidebar */

.navbar {
    display: block;
    /* position: absolute; */
    background: #0a223d;
    left: 0;
    height: calc(100vh - 126px);
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    color: #f1f1f1;
}

.navbar-container {
    width: 300px !important;
}

.logo-link {
    cursor: pointer;
}

.logout {
    color: #1c2541;
    text-decoration: none;
    cursor: pointer;
}

.sidebar {
    padding-top: 10px;
}

.sidebar-nav {
    list-style: none;
    padding: 5px;
}


/* .sidebar-nav li {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    min-height: 35px;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 14px;
    border-radius: 16px;
    width: 100%;
} */

.navlist {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    min-height: 32px;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 8px;
    font-size: 14px;
    border-radius: 16px;
    width: 100%;
}

.adminType {
    font-size: 20px;
    font-weight: 900;
    color: red;
}

.navlistactive {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    min-height: 35px;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 14px;
    border-radius: 16px;
    width: 100%;
}

.active {
    background: #14a5d3;
}


/* .active {
    background: #14a5d3;
} */

.sidebar-nav img {
    width: 16px;
}

.sidebar-nav li:hover {
    background: #14a5d3;
}

.dropArrow {
    width: 11px !important;
}

.admin-footer {
    height: 40px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 14px 0px #00000059;
}

.inovace {
    text-decoration: none;
    text-transform: lowercase;
    padding: 0px 10px;
    color: #0d6efd !important;
    font-weight: 500;
}

.copyright {
    color: #8d8181;
    font-weight: 500;
    width: 100%;
    display: flex;
    letter-spacing: 2px;
    align-items: center;
    justify-content: center;
}

.iconclass {
    transform: rotate(180deg);
}

.putright {
    margin-left: auto;
}

.admin-body-items tbody tr:hover {
    background-color: #f1eded;
}

.sub {
    font-size: 13px;
}
.header-logo-imageA {
    height: 80px !important;
    width: 145px !important;
    margin-left: 30px;
    /* height: 55px !important; */
    margin-top: -8px;
}
.error {
    background: #dc3545 !important;
    border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
    height: 220px;
}

.dia-menu-title {
    font-size: 20px;
    padding: 5px 0px;
    line-height: 25px;
}

.snackTitle {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    color: #184688;
    margin: 10px;
}

.snackIcon {
    font-size: 150px;
    transform: translate(75px, 35px);
    color: white;
}

.success {
    background: #35b651 !important;
    border-radius: 10px 10px 0px 0px;
}

.iconContaier {
    color: white;
}

.dia-body {
    height: 70%;
}

.dia-menu-close {
    padding-left: 10px;
    margin: -15px;
}

.big-menu-dias {
    background: #ffffff;
    position: fixed;
    box-shadow: 1px 1px 20px 5px #000000a1;
    top: 50%;
    left: 50%;
    /* height: 60%;*/
    min-width: 90%;
    max-width: 90%;
    transform: translate(-50%, -50%);
    padding: 30px;
    border-radius: 5px !important;
}


/* .overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
} */

.messageContainer {
    margin: 0;
    padding: 0px 20px;
    height: 70px;
    overflow: auto;
    text-align: center;
}

.buttonContainer {
    justify-content: center;
    /* padding: 14px; */
    display: flex;
}

.button-verify .to-center {
    display: flex;
    /* align-items: center; */
    justify-content: center !important;
}

.snackButtonClass {
    width: 125px;
    color: white;
    height: 50px;
    /* box-shadow: 0 0 20px 0px #0000005e; */
    font-weight: 600;
    border: none;
    border-radius: 50px;
}

.book-head {
    font-family: "Mulish";
    font-weight: 600;
    line-height: 21px;
}

.tilebar {
    display: flex;
    margin: 5px 0px 15px 0px;
    justify-content: center;
}

.diaCloseCon {
    /* padding: 20px; */
    float: right;
    font-size: 20px;
    font-weight: 900;
    line-height: 1;
    cursor: pointer;
}

.flexDisplay {
    display: flex;
}

.diaTitleCon {
    /* padding: 20px 0px 0px 20px; */
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    line-height: 1;
    padding-right: 0px;
}

.tilebar hr {
    margin: 1px !important;
    height: 2px !important;
    width: 175px;
    opacity: 1;
    color: #14a5d3;
}

.dialog input {
    width: 100%;
}

.dialog label {
    font-size: 13px;
    font-weight: 500;
}

.book-form div {
    margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
    line-height: 30px;
    border-radius: 4px;
    /* box-shadow: inset 2.5px 0px 0px #ee7e7e; */
    padding-left: 15px;
}

.book-form button {
    margin-top: 15px;
}

.content-scroll {
    max-height: 320px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.checkboxStatus input {
    width: auto !important;
    margin: 15px 3px !important;
}

.dialog-para {
    margin-top: 10px;
    padding: 0px 25px;
    font-size: 16px;
    overflow: auto;
    max-height: 300px;
    text-align: center;
    margin-bottom: 20px;
}

@media (min-width:768px) {
    .big-menu-dias {
        min-width: 40%;
        max-width: 70%;
    }
}
.new-menu-title {
    font-weight: 500;
    background: #2cafe7;
    padding: 6px 10px;
    font-size: 14px;
}

.new-menu-container {
    /* display: flex; */
    /* position: absolute; */
    background: #fff;
    color: white;
    z-index: 999;
    /* top: 140px; */
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    /* flex-wrap: wrap; */
    width: 100%;
    max-height: 100%;
}

.new-menu-item-data {
    display: flex;
    cursor: pointer;
    padding:3px 0px;
    font-weight: 400;
    /* border: 1px solid #2cafe7; */
}
.new-menu-item-data:hover .new-submenus-label{
    color:#2cafe7;   
     padding: 0px 3px;
    }
.new-menu-item-data:hover {
    /* font-weight: 500; */
    border-left: 4px solid #2f2483;
    border-radius: 3px;
    /* padding: 0px; */
}

.new-submenus-label {
    font-size: 15px;
    color: #000;
}

.new-menu-bg {
    position: absolute;
    z-index: 999;
    top: 120px;
    height: calc(100vh - 120px);
    background: #0000007d;
    width: 100%;
   
}
.anima{
    transition-duration: 4s;
    transition-delay: 2s;
}
.new-menu-item {
    padding: 10px;
    width: 100%;
}

.new-menu-home-icon {
    font-size: 20px;
    color: #2f2483;
}

.new-home-icon-cont {
    padding: 10px;
    background: #2cafe7;
    cursor: pointer;
    /* background: #2f2483; */
    /* margin: 10px; */
    border-radius: 50%;
}

.new-menu-home {
    /* padding: 30px; */
    /* padding: 30px; */
    padding-bottom: 30px;
    /* width: 100%; */
    background: transparent;
    position: absolute;
    /* bottom: 0;
    right: 0; */
    display: flex;
    flex-direction: row-reverse;
}

.submenus-icon {
    padding: 0px 5px;
    font-size: 20px;
    color: #000;
}

.new-menu-subItem {
    padding: 10px;
}

.menu-main-section {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    margin: 0 auto;
}

@media only screen and (min-width: 768px) {
    .new-menu-item {
        padding: 10px 20px;
        width: 33%;
    }
    .new-menu-home {
        padding-bottom: 0px;
        /* padding: 0px 20px; */
    }
    .new-menu-home-icon {
        font-size: 30px;
    }
}

@media (max-width: 760px) {
    .new-menuIcon-cont {
        margin-right:0px!important;
    }
}
/* ------------------------ */

.social-buttons a,
.social-buttons a:visited {
    /* color: unset!important; */
}

.footer-logo-image {
    width: calc(100% - 150px);
    padding: 20px;
}

.social-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -10px;
}
.twitter_icon {
   width: 21px;
   z-index: 999;
   background-color: #fff;
}

.social-buttons__button {
    margin: 10px 5px 0;
}

.social-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: 30px;
    height: 30px;
    text-decoration: none;
    border-radius: 100%;
    background: #fff;
    text-align: center;
    padding: 0px 25px;
}

.social-button::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-radius: 100%;
    transition: 0.3s;
}

.social-button:focus,
.social-button:hover {
    color: #fff;
}

.social-button:focus::after,
.social-button:hover::after {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin-left: calc(-50% - 1px);
}

.social-button i,
.social-button svg {
    position: relative;
    z-index: 1;
    transition: 0.3s;
}

.social-button i {
    font-size: 17px;
}

.social-button svg {
    height: 40%;
    width: 40%;
}

.social-button--mail {
    color: #0072c6;
}

.social-button--mail::after {
    background: #0072c6;
}

.social-button--facebook {
    color: #3b5999;
}

.social-button--facebook::after {
    background: #3b5999;
}

.social-button--linkedin {
    color: #0077b5;
}

.social-button--linkedin::after {
    background: #0077b5;
}

.social-button--github {
    color: #6e5494;
}

.social-button--github::after {
    background: #6e5494;
}

.social-button--youtube {
    color: #FF0000;
}

.social-button--youtube::after {
    background: #FF0000;
}


/* .social-button--insta {
color:  #8a3ab9;    
}
.social-button--insta::after {
    color: transparent;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
} */

.social-button--twitter {
    color: #7a7878;
    z-index: 1 !important;
}

.social-button--twitter::after {
    background: #7a7878;
    z-index: 0 !important;
}

.social-button--insta {
    color: #e4405f;
}

.social-button--insta::after {
    /* background: #e4405f; */
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

header a,
a:visited {
    color: none;
}


/* ----------------------------- */

.my-node-enter {
    opacity: 0;
}

.my-node-enter-active {
    opacity: 1;
    transition: opacity 500ms;
}

.my-node-exit {
    opacity: 1;
}

.my-node-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}

.heat-map-cont {
    width: 100%;
}

.layout {
    height: 100vh;
    /* background: red; */
    overflow: hidden;
}

.new-menuIcon-cont {
    background: white;
    color: #2cafe7;
    padding: 15px;
    display: flex;
    border-radius: 6px;
    margin-right: 40px;
    width: 50px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.header-container {
    height: 120px;
    /* background-color: red !important; */
    display: flex;
    align-items: center;
    font-family: "Poppins";
    /* justify-content: center; */
    /* box-shadow: 0px 0px 10px 0px #00000045; */
    background: white;
}

.userAvatar {
    background: #2f2483;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    color: white;
    /* width: 100%; */
    width: 30px;
    margin: 10px 0px;
    height: 30px;
    font-weight: 900;
}


/* 
.menu-li {
    position: relative;
}

.menu-li.active {
    background-color: #1c2541!important;
    color: #ffffff!important;
    border-radius: 15px;
    text-align: center;
} */


/* .submenu-ul {
    position: static;
    width: auto;
    left: 100%;
    top: -8px;
    margin: 15px;
    border-radius: 10px;
    box-shadow: 3px 3px 0px #0000004d;
    padding: 8px!important;
    background: white;
    color: #1c2541!important;
    list-style-type: none;
    padding: 7px 5px;
    margin-left: 15px;
  
    font-family: mulish!important;
    font-weight: 700;
    font-size: 15px;
} */

@media (min-width: 992px) {
    /* .submenu-ul {
        position: absolute!important;
        width: 325px!important;
        margin: 15px;
        border-radius: 0px!important;
    } */
    /* .menu-li.active {
        border-radius: 0!important;
        text-align: left!important;
    } */
}


/* .submenu-ul li:hover {
    background-color: #1c2541!important;
    color: #ffffff!important;
} */

.body-container {
    /* background: yellow; */
    height: calc(100vh - 120px);
    overflow: auto;
}

.addressLine {
    margin: 0px;
}

.body-items {
    /* background: violet; */
    font-family: mulish;
    /* min-height: calc(100vh - 844px); */
}

.header-menu-icon {
    font-size: 30px;
}

.hed-padi {
    /* padding-top: 10px;
    padding-bottom: 10px ; */
}

.header-title1 {
    /* align-self: end;
    margin-left: -15px;
    font-size: 25px;
    line-height: 38px;
    height: 35px; */
    align-self: end;
    margin-left: -30px;
    font-weight: 700;
    /* margin-top: -22px; */
    font-size: 30px;
    font-family: "PT Sans Narrow";
    line-height: 50px;
}

.header-title {
    /* align-self: auto; */
    margin-left: -30px;
    font-weight: 700;
    /* margin-top: -22px; */
    font-size: 30px;
    font-family: "PT Sans Narrow";
    line-height: 50px;
    margin-top: 24px;
}

.social-small-logo {
    height: 27px;
}

.lang-container {
    padding: 0px 5px;
    border: 1px solid black;
    border-top: 0px;
    border-bottom: 0px;
    margin: auto 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-family: "Montserrat";
    font-size: 13px;
}

.login-status-container {
    padding: 0px 0px 0px 15px;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 13px;
}

.address-container div {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
}

.address-container {
    margin-bottom: 13px;
}

.icon-f-c {
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
}

.contact-no-container {
    margin-bottom: 13px;
}

.email-container {
    margin-bottom: 13px;
}

.logo-link {
    cursor: pointer;
}

.footer {
    /* height: 758px; */
    padding: 50px;
    background: #f5fbfe;
}

.footer-logo {
    width: 98px;
    height: 70px;
}

.kspace-foot {
    /* padding: 20px 0px; */
    width: 100%;
}

.kspace-footer-links {
    width: 100%;
}

.kspace-footer-other-links {
    width: 100%;
    display: block;
}

.kspace-footer-qa {
    width: 100%;
}

.copy-cont {
    display: flex;
    text-align: center;
    margin: 15px 0px 0px;
    align-items: center;
    justify-content: center;
}

.copyrt {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 190%;
    /* identical to box height, or 42px */
    display: flex;
    align-items: center;
    /* Primary */
    color: #009fe3;
}

.kspace-footer-contact {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 0px 10px;
    line-height: 185%;
    /* or 37px */
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
}

.kspace-footer-item-values {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 130%; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 33px;
    /* display: flex; */
    /* align-items: center; */
    color: #000000;
}

.kspace-footer-item-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 190%;
    margin-bottom: 20px;
    margin-top: 20px;
    /* identical to box height, or 46px */
    /* display: flex; */
    align-items: center;
    color: #000000;
}

.insta {
    padding-right: 15px;
}

.kspace-foot-title {
    font-family: "PT Sans Narrow";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 39px;
    margin-top: 40px;
    margin-left: -25px;
    /* identical to box height */
    color: #000000;
}

.kspace-foot-desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 190%;
    padding: 20px 0px;
    /* or 42px */
    /* display: none; */
    align-items: center;
    color: #333333;
}


/* ///////////////////////////// */


/* menu list */

.menuLabel {
    margin: auto 10px;
    font-size: 12px;
    display: flex;
}

.menu-cont {
    cursor: pointer;
}


/* .menulist {
    position: absolute;
    z-index: 99;
    background: #ffffff;
    box-shadow: 0px 10px 20px 6px #0000004d;
    padding: 10px 15px;
    cursor: pointer;
    top: 140px;
    width: 100%;
    left: 0px;
}

.menulist ul {
    padding: 0;
}

.menulist li {
    list-style-type: none;
    list-style-type: none;
    padding: 10px;


font-family: mulish!important;
font-weight: 700;
font-size: 15px;

}
*/

.divider {
    margin: 1px !important;
    height: 1px !important;
    width: 100%;
    opacity: 1;
    color: lightgray;
}

.menu-head-text-cont {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 4px;
    color: #2cafe7;
    font-weight: bold;
}

.footi .social-button {
    background: none !important;
}

.header-profile-title {
    font-family: poppins;
}

.new-nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.new-submenu {
    position: absolute;
    z-index: 999;
    top: 103px;
    padding: 10px;
    background: white;
    border-radius: 10px 0px 20px 0px;
    right: 0;
    box-shadow: -8px 5px 14px 0px #0000004f;
}

.new-dropdown-item {
    background: white;
    cursor: pointer;
    padding: 5px 10px;
}

.new-dropdown-item:hover {
    background: #009de2;
    color: white;
}

.menulist li:hover {
    background-color: #1c2541;
    color: #ffffff;
}

.no-border {
    border: 0px !important;
}

.login-head {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 33px;
    color: #009fe3;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.hideonMobile {
    display: none !important;
}

.no-decoration {
    text-decoration: none !important;
}

.header-logo-image-kg {
    height: 70px;
}
.kit-logo {
    height:50px;
    margin-left: 30px;
}


/* .submenu {
    position: absolute;
    right: 0px;
    top: 140px;
    z-index: 999;
    text-align: left;
    padding: 10px;
    box-shadow: 0px 9px 14px 3px #0000004a;
    margin-right: 5px;
    background: white;
} */

.footer-icons-cont {
    display: none;
}

.footer-icons-cont {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.footer-main-items-cont {
    display: block;
}

.link-style {
    margin: 0;
    /* list-style-type: none; */
    /* padding: 10px; */
    /* border-bottom: 1px solid lightgrey; */
    font-family: mulish !important;
    font-weight: 700;
    font-size: 15px;
    color: #212529 !important;
    /* background: #8c1f1f; */
    text-decoration: none;
}

@media (min-width: 320px) {
    .hideonMobile {
        display: none !important;
    }
    .hideonMobileSocial{
        display:none !important;
    }
    .header-logo-image {
        height: 80px !important;
        margin-left: 30px;
        /* height: 55px !important; */
        /* margin-top: -28px; */
    }
}

@media (min-width: 481px) {
    .hideonMobile {
        display: none !important;
    }
    .hideonMobileSocial{
        display:none !important;
    }
    .header-logo-image {
        height: 80px !important;
        margin-left: 30px;
        /* height: 55px !important; */
        /* margin-top: -28px; */
    }
}

@media (min-width: 769px) {
    .hideonMobile {
        display: none !important;
    }

    .hideonMobileSocial{
        display:none !important;
    }

    .kspace-foot {
        /* padding: 0px 20px; */
        width: 35%;
    }

    .footer {
        /* height: 758px; */
        padding: 50px;
    }

    .kspace-footer-qa {
        width: 30%;
        padding: 20px;
    }
    .header-logo-image {
        height: 80px !important;
        margin-left: 30px;
        /* height: 55px !important; */
        /* margin-top: -28px; */
    }
}


@media (min-width: 992px) {
    .kspace-footer-item-title {
        /* identical to box height, or 46px */
        display: flex;
        margin-top: 0px;
    }

    .footer-main-items-cont {
        display: flex;
    }

    .kspace-footer-other-links {
        width: 20%;
        padding: 20px;
        display: block;
    }

    .copyrt {
        font-size: 15px;
    }
    .new-menuIcon-cont {
        color: white;
        background: #2cafe7;
    }

    .kspace-foot-desc {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 190%;
        padding: 10px 0px;
        /* or 42px */
        /* display: block; */
        /* align-items: center; */
        color: #333333;
    }

    .kspace-footer-links {
        width: 20%;
        padding: 20px;
    }

    .menulist {
        left: 23px !important;
        top: 140px !important;
        width: auto !important;
    }

    .header-logo-image {
        height: 80px !important;
        margin-left: 30px;
        /* height: 55px !important; */
        /* margin-top: -28px; */
    }

    .hideonMobile {
        display: block !important;
    }

    .footer-right-part {
        justify-content: flex-end !important;
        display: flex !important;
        width: 33% !important;
    }

    .usefull-underline {
        height: 2px !important;
        margin: 5px 0px 15px !important;
        width: 120px !important;
        color: #009de2 !important;
        opacity: 1 !important;
    }

    .useFull-links-item {
        padding-right: 10px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */
        color: #f6f8fa;
    }

    .useFull-links-item:hover {
        color: #009de2;
        cursor: pointer;
    }

    .links-section {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 160px;
        text-align: left;
        margin-right: 100px;
    }

    .usefullLinksTitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        color: #14a5d3;
    }

    .links-container {
        display: flex;
    }

    .footer-center-part {
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        display: flex !important;
        width: 52% !important;
        padding: 10px;
        color: #fff;
    }

    .footer-left-part {
        justify-content: flex-start;
        align-items: center;
        width: 25%;
        display: flex;
    }

    .footer-items {
        flex-direction: row !important;
    }

    .footer-icons-cont {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hideonMobileSocial{
        display:none !important;
    }
    @media (min-width: 1200px) {
    .hideonMobileSocial {
        display: block !important;
    } 
    .header-logo-image {
        height: 100px !important;
        width: 175px !important;
        margin-left: 30px;
        /* height: 55px !important; */
        margin-top: -8px;
    }}
}
.admin-login-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* justify-content: center; */
    background-image: linear-gradient(#e4eaeb00, #5d8ecd8a)
}

.admin-login-container {
    padding: 50px;
    width: 527px;
    height: 358px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
    background: white;
}

.admin-login-title {
    font-size: 16px;
    font-weight: 700;
    padding: 20px;
}

.login-input {
    margin-top: 15px;
    margin-bottom: 15px;
}

.admin-login-logo {
    height: 131px;
    margin: 40px;
    width: 179px;
}

.butnCnt {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
}
.bg-cover {
    width: 100%;
    object-fit: cover;
}


/* ============================================================================================== 
SED Innovations
https://sed.am
https://mkrtchyan.ga
================================================================================================= */


/* .area {
    background: #4e54c8;
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
    width: 100%;
    height: 100vh;
} */

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

* {
    margin: 0;
    padding: 0;
}

header {
    background-color: rgba(33, 33, 33, 0.9);
    color: #ffffff;
    display: block;
    font: 14px/1.3 Arial, sans-serif;
    height: 50px;
    position: relative;
    z-index: 5;
}

h2 {
    margin-top: 30px;
    text-align: center;
}

header h2 {
    font-size: 22px;
    margin: 0 auto;
    padding: 10px 0;
    width: 80%;
    text-align: center;
}

header a,
a:visited {
    text-decoration: none;
    /* color: #fcfcfc; */
}

.login-section {
    /* position: absolute; */
    padding: 20px;
    margin: 0px 21px;
    /* transform: translate(50%, 50%); */
    background: white;
    height: 322px;
    width: 396px;
    border-radius: 5px;
}

.login-container {
    display: flex;
    background-size: cover;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 86px);
    /* background-image: url("../../../resources/img/auth/login.png"); */
    background: #0f0c29;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    min-height: 380px;
}

.login-header {
    font-family: Mulish;
    /* padding: 12px; */
    padding: 5px 12px 12px 12px;
    font-style: normal;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.08em;
}

.login-input {
    margin-top: 15px;
    margin-bottom: 15px;
}

.login-action {
    text-align: center;
}

.number-prefix {
    width: 47px;
    text-align: center;
    margin-right: 13px;
    border: 1px gray solid;
    height: 30px;
    border-radius: 3px;
}

.number {
    width: 190px;
    border: 1px gray solid;
    height: 30px;
    border-radius: 3px;
    padding-left: 6px;
    letter-spacing: 2px;
}

.number-label {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    padding: 20px;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */
    text-align: center;
}

.login-button {
    margin: 20px;
    width: 87px;
    height: 35px;
    /* line-height: 35px; */
    background: #1c2541;
    border: 1px solid #1efff7;
    text-transform: uppercase;
    text-align: center;
    color: #1efff7;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}


/* ---------------------------- */

@media (min-width:992px) {
    .login-section {
        margin: auto;
    }
}
.otp-bg-cover {
    width: 100%;
    object-fit: cover;
}

.otp-login-section {
    padding: 20px;
    margin: auto;
    /* transform: translate(50%, 50%); */
    background: white;
    height: 322px;
    width: 396px;
    /* border-radius: ; */
}

.otp-login-container {
    display: flex;
    background-size: cover;
    width: 100%;
    height: calc(100vh - 86px);
    align-items: center;
    justify-content: center;
    background-image: url(/static/media/otp.020f4ecc.png);
}

.otp-otpbox {
    text-align: center;
    justify-content: center;
    display: flex;
}

.otp-login-header {
    font-family: Mulish;
    padding: 12px;
    font-style: normal;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.08em;
}

.otp-login-action {
    text-align: center;
}

.otp-number-prefix {
    width: 50px;
    text-align: center;
    border: 1px gray solid;
    margin: 0px 5px;
}

.otp-resend-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    /* identical to box height */
    text-align: center;
    color: #F76C6C;
}

.otp-not-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    /* identical to box height */
    text-align: center;
    color: #1c2541;
}

.otp-number {
    width: 230px;
    border: 1px gray solid;
}

.otp-number-label {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    padding: 20px;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */
    text-align: center;
}

.otp-login-button {
    margin: 20px;
    width: 87px;
    height: 35px;
    /* line-height: 35px; */
    text-transform: uppercase;
    background: #1c2541;
    text-align: center;
    color: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 3px !important;
}
.otp-bg-cover {
    width: 100%;
    object-fit: cover;
}

.otp-login-section {
    padding: 20px;
   
    margin: 0px 21px;
    /* transform: translate(50%, 50%); */
    background: white;
    height: 322px;
    width: 396px;
}

.otp-login-container {
    display: flex;
    background-size: cover;
    width: 100%;
    height: calc(100vh - 86px);
    align-items: center;
    justify-content: center;
    background-image: url(/static/media/otp.020f4ecc.png);
}
.six {
    padding-top: 5px!important;
}
.otp-otpbox {
    text-align: center;
    justify-content: center;
    display: flex;
}

.otp-login-header {
    font-family: Mulish;
    padding: 12px;
    font-style: normal;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.08em;
}

.otp-login-action {
    text-align: center;
}

.otp-number-prefix {
    width: 50px;
    text-align: center;
    border: 1px gray solid;
    margin: 0px 5px;
}

.otp-resend-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    /* identical to box height */
    text-align: center;
    color: #F76C6C;
}

.otp-number {
    width: 230px;
    border: 1px gray solid;
}

.otp-number-label {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    padding: 20px;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */
    text-align: center;
}

.otp-login-button {
    margin: 20px;
    width: 87px;
    height: 35px;
    /* line-height: 35px; */
    text-transform: uppercase;
    background: #1c2541;
    text-align: center;
    color: #fff;
    align-items: center;
    justify-content: center;
    /* border-radius: 3px; */
}

.otp-not-text {
    font-family: Mulish;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    /* identical to box height */
    text-align: center;
    color: #1c2541;
}

/* ---------------------------- */
@media (min-width:992px) {
    .otp-login-section  {
        margin: auto;
    }
    }
.mydash{
    /* background: hsla(18, 76%, 85%, 1);

background: linear-gradient(90deg, hsla(18, 76%, 85%, 1) 0%, hsla(203, 69%, 84%, 1) 100%);  */
/* background: #373B44; 
background: -webkit-linear-gradient(to right, #4286f4, #373B44); 
background: linear-gradient(to right, #4286f4, #373B44);  */
background-color: #E3EEFA;
padding-top:15px;
padding-bottom: 15px;
}
.inline{
    display: inline-flex;
    padding: 0px 33px;
}
.dash-header-button{
    align-items: center!important;
}
.edit-app-button {
    width: 170px;
    height: 32px;
    border: 1px solid #0a223d;
    display: flex;
    border-radius: 2px;
    align-items: center;
    padding: 0px 10px;
    color:white;
}

.demo{}
.edit-label{
    color: #0a223d;
}
.menu1{
    background-color: #f3f6f3;
    margin-top: 10px;
}

.loc span{
    margin-top: -1px;
    font-size: 14px;
}
.dash-banner {
    background-image: url(/static/media/userdashbanner.989062ce.png);
    width: 100%;
    height: 100px;
}

.dashboard-box {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 40px auto;
    position: relative;
    padding: 20px;
    width: 100%;
    background: #E3EEFA;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.muli {
    font-family: mulish!important;
}

.fill-color {
    background: transparent;
}

.reg-det-title {
    color: #1c2541;
    font-size: 18px;
    margin: 10px 8px;
    font-weight: 600;
    border-bottom: 2px solid #e3eefa;
    padding: 1px 0px 12px 0px;
}

.reg-data-container {
    background: white;
    height: 100%;
    padding: 3px 13px;
}

.sepera {
    font-weight: 800;
}

.user-name-logo-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #80808061;
}

.dash {
    /* min-height: calc(100vh - 86px); */
    background: #fff;
}

.dashBox-container {
    /* min-height: calc(100vh - 86px); */
    justify-content: center;
    display: flex;
}

.msg-container {
    top: 20px;
    position: absolute;
    width: calc(100% - 40px);
    border: 1px solid rgba(237, 18, 18, 0.46);
    /* background: rgba(237, 18, 18, 0.43); */
    background: white;
    box-sizing: border-box;
    border-radius: 3px;
}

.dash-items {
    width: 100%;
    height: 100%;
}

.edit-app-button {
    width: 170px;
    height: 32px;
    background: #0a223d;
    display: flex;
    border-radius: 2px;
    align-items: center;
    padding: 0px 10px;
    color: white;
    /* margin-top: 47px; */
    margin-top: 15px;
}

.edit-button-cont {
    padding: 5px;
    height: 19px;
    align-items: center;
    justify-content: center;
    width: 19px;
    display: flex;
    background: #14a5d3;
    margin-right: 5px;
    border-radius: 50%;
}

.edit-label {
    font-size: 15px;
    color: white;
}

.dash-app-edit-icon {
    font-size: 15px;
    display: flex;
    line-height: 15px;
    font-weight: 700;
    height: 15px;
}

.dash-header-button {
    padding: 10px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.dash-header {
    background: #f3f8ff;
    display: flex;
    flex-direction: column;
}

.dash-logo {
    background: #f3f8ff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auto-display {
    display: block !important;
}

.auto-display-rev {
    display: flex !important;
}

.profile-labels {
    width: 218px;
}

.profile-values {
    padding: 5px 10px;
    font-weight: 600;
}

.col-con {
    display: none;
}

.ovr {
    overflow: auto;
}
.userDashb{display: flex;}
@media (max-width: 760px) {
    .userDashb{display: block;}
    .u-logo{
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
    .user-name-details {
        text-align: center;
    }
    .dash-application-details-container {
        height: 95px!important;
    }
    .dash-content-detail-items {
        padding: 22px 0px!important;
    }
    .dashuserStatus {
        width: 100%;
        margin: 0 auto;
        padding: 7px 0px;
        margin-bottom: 26px;
    }
    .reg-det-title {
        text-align: center;
    }
}

@media (min-width: 992px) {
    .ovr {
        overflow: auto;
    }
    .dash-header {
        flex-direction: row;
    }
    .edit-app-button {
        margin-top: 0%;
    }
    .dash-logo {
        align-items: flex-start;
    }
    .dash-header-data {
        align-items: flex-start !important;
    }
    .dash-header-button {
        align-items: flex-end;
    }
    .auto-display {
        display: flex !important;
    }
    .auto-display-rev {
        /* display: block !important; */
    }
    .dash-menuContainer {
        width: 145px;
        height: 100% !important;
        margin-top: 0px !important;
    }
    .dash-body-msg-container {
        /* width: 491px !important; */
    }
    .dash-content-container {
        height: 256px;
    }
    .dash-content-details-container {
        /* height: 238px!important; */
        /* margin-left: 17px !important; */
    }
    .application-status-cont {
        margin-top: 50px !important;
    }
    .dash-messages {
        margin-left: 25px !important;
    }
    .dash-header-logo {
        padding: 20px !important;
    }
    .dash-header-data {
        padding: 20px !important;
    }
    .dash-header-button {
        /* padding: 20px !important; */
        padding: 11px 11px 0px 11px !important;
    }
    .dashMenuButton {
        display: flex !important;
    }
    .dash-datas {
        /* width: 734px !important; */
    }
    .col-con {
        display: block !important;
    }
    .profile-values {
        padding: 0px 10px !important;
        font-weight: normal !important;
    }
    .status-ind-btn {
        width: 250px !important;
    }
}

.dash-items-data .row {
    background: #e3eefa;
    padding: 7px 5px;
    /* margin: 0 0 10px 0; */
}

.dash-t\items-datas {
    background: #e3eefa;
    padding: 7px 5px;
}

.dash-header-logo {
    padding: 10px;
}

.dash-header-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 22px;
    line-height: 28px;
    /* identical to box height */
    color: #1c2541;
}

.dash-header-mail {
    display: flex;
}

.header-sec-values {
    font-family: Mulish;
    font-style: normal;
    padding: 1px 5px;
    height: 18px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    color: #000000;
}

.dash-header-phone {
    display: flex;
}

.dash-header-address {
    display: flex;
}

.dash-header-data {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dash-header-info {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #93969a;
}

.dash-msg-icon {
    font-size: 16px;
}

.dash-logo-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
}

.dash-msg-text {
    padding: 0px 5px;
    width: 100%;
}

.dash-msg {
    font-family: Mulish;
    padding: 0px 30px;
    font-style: normal;
    font-weight: 600;
    background: rgba(237, 18, 18, 0.43);
    font-size: 12px;
    line-height: 25px;
    height: 25px;
    z-index: 999;
    /* identical to box height */
    /* text-align: center; */
}

.dash-menuContainer {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 15px;
    background: #f3f8ff;
    margin-top: 10px;
}

.dashMenuButton {
    background: #0a223d;
    color: white;
    width: 100%;
    line-height: 23px;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    display: none;
    font-weight: 600;
    font-family: "Mulish";
    height: 23px;
    border: none;
}

.dash-content-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    background: white;
}

.dash-content-details-container {
    /* width: fit-content; */
    margin-left: 0px;
    margin-top: 0px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: #ffffff;
    border: 0.6px solid #e3eefa;
    box-sizing: border-box;
}

.dash-content-detail-items {
    padding: 35px;
    background: #e3eefa;
    overflow: hidden;
}

.dash-body-label {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin: 5px 0px;
    line-height: 18px;
    align-items: center;
    display: flex;
    color: #444444;
}

.dash-body-value {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
    padding: 0px 10px;
    height: 25px;
    /* identical to box height */
    color: #0a223d;
}

.status0 {
    background: #f76c6c;
    color: white;
}

.status1 {
    background: rgba(10, 34, 61, 0.69);
    color: white;
}

.status2 {
    background: #d10000;
    color: white;
}

.status3 {
    background: #14a5d3;
    color: white;
}

.status4 {
    background: #14a5d3;
    color: white;
}

.status5 {
    background: #329732;
    color: white;
}

.status6 {
    background: #14a5d3;
    color: white;
}

.status-ind-btn {
    display: flex;
    text-align: center;
    align-items: center;
    text-transform: capitalize;
    justify-content: center;
    height: 42px;
    /* width: 300px; */
    width: 264px;
    border-radius: 0px;
}

.dash-body-msg-container {
    height: 117px;
    background: #e7e7e7;
    border-radius: 3px;
    width: 100%;
    padding: 15px;
    overflow-y: auto;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #1c2541;
}

.dash-details-body {
    padding: 5px 0px;
    background: #e3eefa;
}

.dash-messages {
    margin-left: 0px;
}

.application-status-cont {
    margin-top: 0px;
}

.dash-datas {
    width: 100%;
}

.dash-profile-data-cont {
    display: flex;
    background: #f6f6f6;
    padding: 5px 10px;
    margin-bottom: 10px;
    font-size: 14px;
}

.dahContent-header-section {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    text-transform: capitalize;
    padding: 10px 0px;
    background: #e3eefa;
    border-bottom: 3.5px solid #e3eefa;
    font-size: 15px;
    line-height: 19px;
    color: #0a223d;
}

.dash-menu-item {
    display: flex;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    /* padding: 15px 15px 0px; */
    padding: 7px 15px;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */
    color: #000000;
}

.menu-icon {
    margin-right: 10px;
}

.dash-menu-active {
    color: #fff;
    background-color: #0a223d!important;
}

.data .row {
    padding: 7px 5px;
    background: #e7e7e7;
    margin: 0 0 10px 0;
}

.data .row .col-8 {
    color: black;
    font-size: 14px;
    width: 100%;
    overflow: auto;
    font-size: 14px;
}

.data .row .col-3 {
    color: #666;
    font-size: 14px;
}

.mysubbtn:hover {
    color: white;
}

.nio {
    color: white !important;
    font-weight: 300;
    font-size: 15px;
}

.data .row .col-8 table {
    background-color: white !important;
    margin-top: 0px !important;
}

.venture {
    background-color: white !important;
    padding-top: 10px;
}
.joint-view-border{
    border-bottom: 1px solid grey;
}

.data .current {
    background-color: #0a223d !important;
    color: white !important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white !important;
}

.status label {
    font-size: 16px;
}

.down-ico {
    background: #D2EBF7;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 3px;
    text-decoration: none;
}

.down-ico:hover {
    color: unset;
}

.down-ico:hover.down-ico span {
    background: white;
    color: #0a223d;
}

.down-ico span {
    background: #0a223d;
    color: white;
    width: 22px;
    text-align: center;
    padding: 2px 0px 3px 0px;
    border-radius: 25px;
    margin-left: 4px;
}

.user-name-details {
    padding: 0px 25px;
}

.user-name-logo {
    width: 70px;
    display: flex;
    height: 70px;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: #1C2541;
    color: white;
}

.dashCompanyName {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    /* identical to box height */
    letter-spacing: 0.02em;
    color: #000000;
}

.dashPhoneNumber {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    padding: 10px 0px;
}

.dashuserApplicationId {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 21px;
    padding: 0px 0px 15px 0px;
    /* identical to box height */
    letter-spacing: 0.02em;
    color: #000000;
}

.dashuserStatus {
    background: #1C2541;
    border-radius: 5px;
    /* width: 174px; */
    height: 38px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFFFFF;
}

.dash-application-details-container {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dash-app-dettail-no-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 185px;
    color: grey;
    background: #fff;
}

.dashuserprof {
    background: #F6F8FA;
    box-shadow: 2px 2px 10px rgba(29, 68, 102, 0.3);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    height: 40px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    align-items: center;
    color: #000000;
    padding: 15px 0px;
    margin-top: 10px;
}

.goto-my-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
}

.dashusermyprof {
    background: #F6F8FA;
    box-shadow: 2px 2px 10px rgba(29, 68, 102, 0.3);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    height: 40px;
    width: 150px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
    color: #000000;
    padding: 15px 0px;
}

.blink {
    animation: blinker 2s linear infinite;
    color: #000;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.message-from-kspace {
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    overflow: auto;
    min-height: 185px;
    margin: 20px 0px;
    max-height: 185px;
    background: white;
    padding: 5px 5px;
    border: 1px solid #c4c4c4;
}

.message-from-kspace-action-cont {
    display: flex;
    text-align: center;
    align-items: center;
    /* justify-content: center; */
}

.file-from-kspace {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 12px;
    cursor: pointer;
    background: #14a5d3;
    margin-right: 17px;
    border-radius: 3px;
}

.dash-attachment-icon {
    font-size: 26px;
    color: #1C2541;
}

.update-current-propsosal {
    width: 176px;
    height: 34px;
    cursor: pointer;
    background: #F6F8FA;
    box-shadow: 2px 2px 10px rgba(34, 74, 110, 0.3);
    border-radius: 5px;
    /* font-family: Roboto; */
    font-style: normal;
    justify-content: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.message-from-kspace-btn {
    width: 173px;
    height: 38px;
    background: #1C2541;
    border: 1px solid #000000;
    box-sizing: border-box;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 5px;
    /* font-family: Roboto; */
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFFFFF;
}

@media (min-width: 992px) {
    .dash-banner {
        height: 162px !important;
    }
    .fill-color {
        background: #f3f8ff!important;
    }
   
}
.tender {
    min-height: calc(100vh - 428px);
}

.bg-tender {
    background: #f3f8ff;
}

.vx {
    font-size: 18px !important;
}

.ix {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
}

.tix {
    font-size: 30px;
    color: white;
    font-weight: 600;
}

.container-ul {
    display: flex;
    margin: 5px 0px 10px 0px;
    flex-direction: column;
    align-items: center;
}


/* .hr-tenders {
  margin: 1px !important;
  height: 3px !important;
  width: 35px;
  opacity: 1;
  color: #14a5d3;
} */

.top {
    margin-left: 0px !important;
}

.bottom {
    margin-left: 20px !important;
}

.tender-head {
    padding: 15px 0px;
}

.tender-head h4 {
    text-align: center;
    margin-bottom: 0px;
}

.m-30 {
    margin: auto 30px;
}

.p-30 {
    padding: 30px;
    margin-bottom: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -2px 1px 11px -5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.p-50 {
    padding: 50px 70px;
}

.tender-box {
    margin: auto 88px;
}

.tender-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
}

.tender-box .head {
    text-transform: uppercase;
    /* font-size: 30px; */
}

.tender-box .head u {
    /* text-transform: uppercase; */
    font-size: 20px;
}

.tender-box p {
    font-family: Mulish !important;
    margin-bottom: 5px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px !important;
    color: black !important;
    font-weight: 400 !important;
    margin-top: -4px;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.label {
    align-self: baseline;
}

.label p {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    font-weight: 600;
    color: #444;
}

.contentx p {
    font-size: 15px;
    font-weight: 600;
}

.tender-datas {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.bg-white {
    padding-bottom: 35px;
    padding-top: 65px;
}

.break_link {
    word-break: break-all;
    cursor: pointer;
}

@media only screen and (max-width: 992px) {
    .tender-box {
        margin: auto 30px;
    }

    .tender-datas {
        flex-direction: column;
        display: unset;
    }

    /* .dash-banner .tit-txt {
    font-size: 18px!important;
} */
    .tender-box p {
        text-align: center;
        letter-spacing: 0px;
        overflow-wrap: anywhere;
    }

    .bg-white {
        padding-top: 35px;
    }

    .p-50 {
        padding: 22px 16px;
    }

    .contentx p {
        font-size: 14px;
        font-weight: 700;
    }

    .p-30 {
        padding: 19px;
    }

    .overf {
        overflow: hidden;
    }

    .tix {
        font-size: 19px;
    }

    .note p {
        text-align: justify;
    }
}
.pubbox {
  background-color: #f3f8ff;
  /* background-color: red; */
}

.swal-publication {
  /* width: 100% !important; */
  display: flex !important;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: left !important;
}
.swal-publication-image {
  /* margin: 0px !important; */
  margin: 1em 0em 0em 1em !important;
}
.swal-publication-text {
  text-align: left !important;
}

.tit-txt {
  font-family: Mulish;
  font-style: normal;
  color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
}

.one-line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pubbox .container {
  background: white;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px 20px 0px 20px;
}

/* .publi .card{border: none;} */

.publi {
  margin-bottom: 18px;
}

.publi .card {
  border: none;
}

.publi .card-body {
  padding: 10px;
  background-color: #f3f8ff;
  border: none;
  border-radius: 4px;
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;  */
}

.publi .card-body:hover {
  background-color: white;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 4px 5px 1px rgb(0 0 0 / 25%);
}

.publi .imgx {
  padding-right: 0px;
}

.imgx img {
  width: 75px;
  height: 113px;
  margin-top: 3px;
}

.abt h5 {
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 16px;
  color: #0a223d;
  min-height: 37px;
}

.abt small {
  font-size: 11px;
  font-weight: 500;
}

.abt h6 {
  line-height: 10px;
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 13px;
  color: #0a223d;
}

.abt p {
  text-align: justify;
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 15px;
  word-break: break-all;
}

/* ------------------------------------------------------------------------------------------------------ */

@media (min-width: 992px) {
  .tit-txt {
    font-size: 30px;
  }
  .imgx img {
    width: 93px;
    height: 93px;
  }
  /* .abt h5{
        min-height: 41px;
    } */
  .abt h6 {
    line-height: 10px;
    margin-bottom: 4px;
  }
  .publi {
    margin-bottom: 25px;
  }
  .abt h5 {
    min-height: auto;
  }
}

.dash-banner {
    background-image: url(/static/media/userdashbanner.989062ce.png);
    width: 100%;
    height: 85px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px!important;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
}

.proj {
    background: rgba(243, 248, 255, .88);
    padding-bottom: 6px;
}

.projbox {
    background-color: white;
    /* width: calc(100% - 599px); */
    /* padding: 45px 142px; */
    margin-top: 20px;
    margin-bottom: 20px;
    /* margin: auto; */
    width: 91%;
    padding: 1px 11px;
}

.mex {
    padding: 20px 7px!important;
}

.pager {
    background-color: #fff;
    width: 95%;
    margin: 7px 10px;
    /* padding: 36px 69px; */
    padding: 6px 11px;
}

.btn:hover {
    color: white!important;
}
.view-container-comp-1 .white_btn_sup:hover {
    background-color: rgb(255, 157, 0)!important;
    color: #000!important;
}

.mex .data .row .col-3 {
    color: #666;
    font-size: 14px;
}

.projx {
    padding: 20px;
}

.helpy {
    margin-top: -12px!important;
}


/* =================================================================================== */

@media (min-width:992px) {
    .dash-banner {
        height: 162px;
    }
    .mex .data .row .col-3 {
        color: #666;
        font-size: 15px;
    }
    .tit-txt {
        font-size: 30px;
    }
    .projbox {
        background-color: white;
        width: calc(100% - 599px);
        padding: 26px 69px 13px 69px;
        margin-top: 20px;
    }
    .projx {
        margin-bottom: 8px;
        padding: 20px 142px;
    }
    .pager {
        background-color: #fff;
        width: 940px;
        margin: auto;
        padding: 36px 69px;
    }
}
.dash-banner {
    background-image: url(/static/media/userdashbanner.989062ce.png);
    width: 100%;
    height: 85px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px !important;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
}

.proj {
    background: rgba(243, 248, 255, .88);
    padding-bottom: 24px;
}

.projbox {
    background-color: white;
    /* width: calc(100% - 599px); */
    /* padding: 45px 142px; */
    margin-top: 20px;
    margin-bottom: 20px;
    /* margin: auto; */
    width: 91%;
    padding: 1px 11px;
}

.pdf {
    background-color: #D2EBF7;
    padding: 0px 0px;
}


/* .proposalbox {
    padding: a;
} */

.proposalbox .card {
    border: none!important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.pro-data {
    padding: 10px 17px 6px 16px;
}

.pro-data h5 {
    font-size: 18px;
    font-weight: 600;
}

.pro-data small {
    font-size: 12px;
    color: #A1A8AF;
}

.pro-data .d-flex p {
    margin-bottom: 3px;
    margin-left: 4px;
    font-size: 13px;
    font-weight: 600;
}

.pro-data .d-flex span {
    background: #DFE3E8;
    color: #0A223D;
    padding: 0px 5px;
    border-radius: 22px;
}

.pro-data h6 {
    font-size: 12px;
    text-align: justify;
    color: rgba(68, 68, 68, 0.94);
}

.row .mb-3 {
    padding: 15px 19px 5px 19px;
}

.mb-3 {
    margin-bottom: 0px!important;
}


/* =================================================================================== */

@media (min-width:992px) {
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
    }
    .mb-3 {
        margin-bottom: 1rem!important;
    }
    .proposalbox {
        padding: 0px 54px;
    }
    .projbox {
        background-color: white;
        width: calc(100% - 599px);
        padding: 45px 142px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .pro-data h6 {
        font-size: 12px;
    }
    .pdf {
        background-color: #D2EBF7;
        padding: 0px 14px;
    }
}
.comp-pagination-container {
    display: flex;
    list-style-type: none;
}

.comp-pagination-container .comp-pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    /* border-radius: 16px; */
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
}

.comp-pagination-container .comp-pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
}

.comp-pagination-container .comp-pagination-item:hover {
    background-color: rgb(44 175 231);
    color: white;
    font-weight: 900;
    cursor: pointer;
}

.comp-pagination-container .comp-pagination-item.selected {
    background-color: #2F2483;
    color: white;
    font-weight: 900;
}

.comp-pagination-container .comp-pagination-item .comp-arrow::before {
    position: relative;
    /* top: 3pt;
    Uncomment this to lower the icons as requested in comments*/
    content: '';
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.comp-pagination-container .comp-pagination-item .comp-arrow.left {
    transform: rotate(-135deg) translate(-50%);
}

.comp-pagination-container .comp-pagination-item .comp-arrow.right {
    transform: rotate(45deg);
}

.comp-pagination-container .comp-pagination-item.disabled {
    pointer-events: none;
}

.comp-pagination-container .comp-pagination-item.disabled .comp-arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.comp-pagination-container .comp-pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
}
.dash-banner {
  background-image: url(/static/media/userdashbanner.989062ce.png);
  width: 100%;
  height: 85px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.no-margin {
  margin: 0px !important;
}

.tit-txt {
  font-family: Mulish;
  font-style: normal;
  color: white;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  padding: 0px 11px;
}
.key {
  margin: 13px 0px;
}
.mx-ht{
    max-height: 230px!important;
    overflow: hidden;
}
.member hr {
  margin: 0px 0px 7px 0px !important;
}
.card {
  position: relative;
  transition: all 0.5s ease-in;
}
.smil {
  color: unset !important;
  font-size: 12px !important;
}

.card__image {
  display: block;
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: none;
  transition: 0.5s ease;
  /* background-color: #393839; */
  background-color: #d2ebf7;
}

.card:hover .card__overlay {
  opacity: 1;
}

.overlay__text {
  /* color: white; */
  /* font-size: 13px; */
  position: absolute;
  text-align: justify;
  position: relative;
  top: 50%;
  height: inherit;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0px 10px;
}
.body-container {
  background: #f3f8ff;
}
.button {
  background-color: #f05328;
  padding: 1.5rem;
  margin-top: 2rem;
  display: inline-block;
  color: white;
  text-decoration: none;
}

.bodybox .card {
  padding: 15px 10px;
  font-weight: 600;
}

.sox {
  padding: 10px 17px;
}
.overlay__text p {
  font-size: 13px;
}
.nilo small {
  font-size: 12px !important;
}
.rytbody span {
  color: #14a5d3;
  font-size: 14px;
}

.rytbody p {
  margin-bottom: 8px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.rytbody h5 b {
  font-size: 19px;
}
.rytbody h5 {
  margin-top: -5px;
}
.mer {
  padding-top: 17px;
}
.smal {
  text-align: justify !important;
  line-height: 16px;
  color: #555;
  margin-top: 5px;
}

.smal small {
  /* font-size: 12px !important; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: justify;
  /* letter-spacing: -0.2px; */
  font-size: 12px !important;
  letter-spacing: 0px;
  /* word-break: break-all; */
  word-spacing: -2px;
}

.member h6 {
  color: black;
  font-size: 16px;
  text-align: center;
  min-height: 16px;
}
.member hr {
  border: black;
}
.ceo p {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 700;
  color: #0a223d;
}
.ceo h6 {
  font-size: 10px;
  color: #666;
  margin-bottom: 0px;
}
.ceo address {
  font-size: 9px;
  font-weight: 600;
  /* padding: 0px 4px; */
}
.ceo {
  padding: 0px 3px;
}
.bodybox .card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.clogo {
  height: 84px;
  width: 90px;
}
.webs span {
  font-size: 11px !important;
  margin-left: 2px;
}
.img {
  padding: 12px;
}
/* =================================================================================== */
@media (min-width: 992px) {
  .dash-banner {
    height: 162px;
  }

  .sox {
    padding: 17px;
  }
  .tit-txt {
    font-size: 30px;
    line-height: 38px;
  }
  
}

@media screen and (min-width: 480px) {
  .smal small {
    word-spacing: 0px;
  }
}

@media (min-width: 756px) {
  .rytbody {
    padding-left: 0px;
  }
}
@media (min-width: 640px) {
  .rytbody {
    padding-left: 0px;
  }
}
@media (min-width: 900px) and (min-width: 1000px) and (min-width: 1215px) and (min-width: 1100px) and (max-width: 1228px) and (min-width: 1212px) and (min-width: 1217px) {
  .rytbody {
    padding-left: 30px;
  }
}
@media (min-width: 1086px) {
  .rytbody {
    padding-left: 38px;
  }
}
@media (min-width: 1110px) {
  .rytbody {
    padding-left: 30px;
  }
}
@media (min-width: 1240px) {
  .rytbody {
    padding-left: 30px;
  }
}
@media (min-width: 1241px) {
  .rytbody {
    padding-left: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .rytbody {
    padding-left: 10px;
  }
}

.resourse-tile {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    margin: 80px 10px 20px;
    width: 300px;
}

.resource-image-cont {
    width: 134px;
    height: 134px;
    position: absolute;
    transform: translateY(-50%);
    box-shadow: 0px 4px 4px rgb(20 165 211 / 45%);
    border-radius: 50%;
}
.resource-word-cont {
    width: 134px;
    height: 134px;
    position: absolute;
    transform: translateY(-50%);
    box-shadow: 0px 4px 4px rgb(20 165 211 / 45%);
    border-radius: 50%;
    /* background-color: #14A5D3; */
    /* z-index: 1; */

}
.bolded_text {
    font-weight: bold !important;
}
.candidate-word {
    width: 100%;
    -webkit-text-decoration: solid;
            text-decoration: solid;
    text-transform: capitalize;
    /* border-radius: 50%;  */
    /* height: 100%; */
    text-align: center;
    font-size: 100px;
    object-fit: cover;
    color: #14A5D3;
    z-index: 999;
}

.resource-card {
    background: #E3F6FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}

.resource-image {
    width: 100%;
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
}

.resource-desc-container {
    width: 300px;
    height: 206px;
    padding-top: 77px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}
.candidate-desc-container {
    width: 300px;
    height: 206px;
    padding-top: 30px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}

.res-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 21px;
    text-align: center;
    color: #0A223D;
}

.res-email {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #444343;
    /* line-break: anywhere; */
    width: 100%;
    padding: 0px 14px;
}

.res-title-card {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    /* identical to box height */
    color: #0A223D;
}

.res-exp-heading-card {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    color: #444444;
}

.resource-desc-container-card {
    padding: 0px 10px;
}

.expertize-decs-card {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: #0A223D;
}

.res-email-card {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: #444444;
}

.res-exp-heading {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    color: #14A5D3;
    margin-top: 5px;
}

.resourse-card-style {
    display: flex;
    background: #E3F6FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 15px;
    margin: 10px;
}

.expertize-decs {
    font-family: Mulish;
    font-style: normal;
    /* font-weight: 600; */
    font-size: 12px;
    line-height: 16px;
    height: 32px;
    /* display: flex; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    color: #0A223D;
    width: 100%;
    padding: 0px 10px;
    margin-bottom: 7px;
}

.read-more-button {
    width: 99px;
    height: 23px;
    border: none;
    background: #14A5D3;
    border-radius: 2px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
}
.status-ribbon {
    position: absolute;
    top: 270px;
    right:0px;
    /* width :180px; */
    text-align: end;
    /* background-color: #0A223D; */
    color: white;
    background-color: #ffffff;
    padding: 5px 6px 5px 12px;
    border-top-left-radius: 10px;
    /* border-bottom-left-radius: -30px; */
    font-size: 14px;
    font-weight: bold;
    /* transform: rotate(45deg); */
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
    z-index: 1;
  }
  .jobSeek-image{
    width :auto;
    height : 300px
  }
  .cover-image {
    width :100%;
    height : 100%;
    object-fit: cover;
    object-position: top;
  }
  /* .cover-image-fill {
    width :full;
    height : 100%;
    object-fit: fill;
    object-position: top;
  } */
.tender-head {
    padding: 15px 0px;
}

.tender {
    min-height: calc(100vh - 428px);
}

.bg-tender {
    background: #f3f8ff;
}

.new-res-tile-cont-res {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.others.hr {
    width: 220px
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
}

.tender-head h4 {
    text-align: center;
    margin-bottom: 0px;
}

.container-ul {
    display: flex;
    margin: 5px 0px 10px 0px;
    flex-direction: column;
    align-items: center;
}

.hr {
    margin: 1px !important;
    height: 3px !important;
    width: 35px;
    opacity: 1;
    color: #14a5d3;
}

.top {
    margin-left: 0px !important;
}

.bottom {
    margin-left: 20px !important;
}

.bg-white {
    padding-bottom: 45px;
}

.res-page-cont {
    padding: 50px 0px;
}

.res-tile-cont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.res-page-cont-details {
    padding: 10px 0px;
}

.res-det-con {
    margin: 0px;
    background: white;
    padding: 20px;
    border: 0.4px solid #888888;
}

.bg-res {
    background: white;
}

.res-ban-img {
    object-fit: cover;
    height: 83px;
}

.res-bannerContainer {
    position: relative;
    flex-direction: column;
}

.res-profile-img {
    width: 130px;
    height: 130px;
}

.profile-box {
    background: #FFFFFF;
    border: 1px solid rgba(68, 68, 68, 0.6);
    box-sizing: border-box;
    padding: 10px;
}

.profile-res-con {
    width: 100%;
    padding: 10px 0px;
    margin-top: 0px;
    background: white;
}

.res-person-name {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    /* identical to box height */
    text-transform: capitalize;
    color: #0A223D;
}

.res-abo-head {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    font-weight: 900;
    color: #0A223D;
}

.res-exp {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #444444;
}

.exp-values {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    /* identical to box height */
    color: #0A223D;
}

.res-about-cont {
    background: #E7EAED;
    padding: 15px;
}

.res-about-sec {
    padding-top: 10px;
}

.res-card-container {
    width: 100%;
    height: 100%;
    /* padding: 15px; */
}

.res-cards-container {
    /* padding: 15px; */
    width: 100%;
    /* border-top: 6px dotted #14a5d3; */
    overflow: auto;
    margin: 10px 0px;
    height: calc(100vh - 230px);
}

.resource-image-card {
    width: 52px;
    height: 57px;
}

.auto-display {
    display: none!important;
}


/* Styles for wrapping the search box */

.main {
    width: 50%;
    margin: 50px auto;
}


/* Bootstrap 4 text input with search icon */

.has-search .form-control {
    padding-left: 2.375rem;
}

.header-hr-about {
    margin: 5px 0px 10px;
}

.search-icon {
    font-size: 16px;
}

.form-group.has-search {
    position: relative;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.oth-inter-head {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    padding: 10px 0px;
    font-size: 17px;
}

.row-padding {
    padding: 10px;
}

.resbandata {
    margin-top: -50px;
    margin-left: 0px;
}

/* .max-bold {
    font-weight: 900;
} */

.res-ban-data {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width:992px) {
    .auto-display {
        display: block!important;
    }
    .res-tile-cont {
        justify-content: space-between!important;
    }
    .resbandata {
        margin-top: -50px;
        margin-left: 40px!important;
    }
    .tit-txt {
        font-size: 30px!important;
    }
    .res-ban-data {
        flex-direction: row!important;
        align-items: left!important;
    }
    .profile-res-con {
        margin-top: 50px!important;
        padding: 10px 10px!important;
    }
    .oth-inter-head {
        padding: 0px!important;
    }
    .res-det-con {
        margin: 30px!important;
    }
}
.breadcrumb-wrapper {
    padding: 0 16px;
    width: 100%;
    background: #f3f8ff;
    position: fixed;
    z-index: 1;
}

.breadcrumb-wrapper .breadcrumb {
    background: transparent;
    padding: 6px 0;
    margin: 0;
}

.breadcrumb-wrapper .breadcrumb li.breadcrumb-item {
    line-height: 15px;
}

.breadcrumb-wrapper .breadcrumb li.breadcrumb-item a {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #909090;
    text-decoration: none;
}

.breadcrumb-wrapper .breadcrumb li.breadcrumb-item.selactive a {
    color: #2f2483;
    background: none!important;
    font-weight: 600;
}

.breadcrumb-wrapper .breadcrumb li.breadcrumb-item:before {
    content: "";
    width: 7px;
    height: 7px;
    position: absolute;
    right: -1px;
    top: 6px;
    border-color: #909090;
    border-style: solid;
    border-width: 0px 1px 1px 0px;
    display: inline-block;
    transform: rotate(-45deg);
    padding: 0;
}

.breadcrumb-wrapper .breadcrumb li.breadcrumb-item {
    padding: 0 12px;
    position: relative;
}

.breadcrumb-wrapper .breadcrumb li.breadcrumb-item:first-child {
    padding-left: 0;
}

.breadcrumb-wrapper .breadcrumb li.breadcrumb-item:last-child:before {
    border-color: transparent;
}
.dash-banner {
    background-image: url(/static/media/userdashbanner.989062ce.png);
    width: 100%;
    height: 162px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px!important;
}
.data .back-g {background-color: transparent!important;}
.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
}

.basic-submit {
    margin: 0px!important;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-bg {
    background: #EBE9E9;
    padding: 4px;
}

.page-bg {
    background: #EBE9E9;
}

.section-Cont {
    background-color: #F3F8FF;
    padding: 20px;
    margin-bottom: 15px;
    /* margin: 20px 0px; */
    border: 1px solid rgba(85, 85, 85, 0.5);
}

.items-cont {
    padding: 20px 10px;
}

.to-left-side {
    display: flex!important;
    align-items: center!important;
    justify-content: flex-start!important;
}

.main-head-txt {
    font-size: 15px;
}

.data {
    padding: 8px 0px 10px 0px;
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 0px;
}

.form-section {
    background: white;
    width: 100%;
}

.fileContainer {
    display: flex
}

.more-btn-cnt {
    margin: 15px 0px;
    display: flex;
    flex-direction: row-reverse;
}

.chk-items-cont {
    padding: 10px
}

.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile:focus+label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
    pointer-events: none;
}

.secondary-btn {
    background: #F76C6C;
    padding: 10px 25px;
    margin: 0px 10px;
    cursor: pointer;
    border-radius: 4px;
    color: white;
}

thead {
    background: rgb(255, 255, 255)!important;
    color: #0A223D!important;
}

.btn-primary {
    background: #1C2541!important;
    border: none;
    cursor: pointer;
}

.primary-btn {
    background: #1C2541;
    padding: 10px 27px;
    margin: 0px 10px;
    color: white;
    cursor: pointer;
    border-radius: 3px;
}

.optionLabelHeader-cont {
    color: black;
    font-weight: 500;
    height: 29px;
    /* padding: 0px 20px; */
    line-height: 29px;
}

.tableHeading {
    background: #0A223D;
    color: white;
    height: 25px;
    line-height: 25px;
    padding: 0px 15px;
}

.table-list-cont {
    margin: 20px 10px;
}

.header-body-spacing {
    margin-top: 20px;
}

.header-helper-text {
    text-align: right;
    color: red;
    font-weight: 700;
}

.headerbuttonodd {
    height: 40px;
    background: #E3ECEF;
    margin-right: 50px;
    color: #000;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.headerbuttonodd.active {
    height: 40px;
    background: #0A223D;
    margin-right: 50px;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.indexCont {
    background: #14A5D3;
    width: 30px!important;
    text-align: center;
    border-radius: 50%;
    margin: auto 10px;
    color: white;
    height: 30px!important;
    line-height: 30px;
}

.indexCont.even {
    margin-left: -15px;
    z-index: 1;
}

.headerbuttonodd.active:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #0A223D;
}

.headerbuttonodd:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #E3ECEF;
}

.headerbuttoneven {
    height: 40px;
    background: #E3ECEF;
    display: flex;
    color: #000;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #E3ECEF;
    border-left: 20px solid transparent;
}

.headerbuttoneven.active {
    height: 40px;
    background: #0A223D;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven.active:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #0A223D;
    border-left: 20px solid transparent
}

.form-inner-sections {
    /* padding: 12px 0px 20px 0px; */
    width: 100%;
}

.fieldset {
    margin: 15px 0px;
}

.file-up-helper {
    color: red;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 14px;
}

.p-10 {
    padding: 5px
}

.section-datas-cont {
    /* margin: 17px auto; */
}

.file-up-label-cont {
    margin-bottom: -30px;
    margin-top: 11px;
    color: #1C2541!important;
    font-weight: 600;
    line-height: 17.75px;
}

.header-label {
    margin-top: 15px;
    margin-bottom: 14px;
    font-weight: 600;
    color: #1C2541;
}

.view-container {
    padding: 25px 20px 25px 40px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.data .row {
    padding: 7px 5px;
    background: #E7E7E7;
    margin: 0 0 10px 0;
}

.data .row .col-8 {
    color: black;
    font-size: 16px;
}

.data .row .col-3 {
    color: #666;
    font-size: 16px;
}

.mysubbtn:hover {
    color: white;
}

.nio {
    color: white!important;
    font-weight: 300;
    font-size: 15px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px!important;
    font-weight: 400!important;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.text-justify {
    text-align: justify;
}

.dwnld {
    color: #F76C6C;
    font-weight: bold;
    text-decoration: underline;
}

.data .row .col-8 table {
    background-color: white!important;
    margin-top: 0px!important;
}

.venture {
    background-color: white!important;
    padding-top: 10px;
}

.data .current {
    background-color: #0a223d!important;
    color: white!important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white!important;
}

.status label {
    font-size: 16px;
}

.mysubbtn {
    margin-top: 19px;
    background: #0a223d!important;
    color: white;
    padding: 6px 66px;
}

.filex {
    margin-top: -13px;
}

.indicator {
    color: red;
}

.headerbuttoneven {
    font-size: 12px;
}

.headerbuttonodd {
    font-size: 12px;
}

.accordion-body {
    padding: 1rem 0rem;
}

.scrolltable {
    overflow: hidden;
    overflow-x: auto;
    padding: 0px;
}

.ownlad {
    padding: 0px 14px;
}

.jointv {
    padding: 0px 14px;
}

.bg-col {
    background-color: #d8e6eca1!important;
}


/* ================================================================================================================================== */

@media (min-width:992px) {
    .form-section {
        width: 921px!important;
    }
    .items-cont {
        padding: 60px 60px 81px!important;
    }
    .form-bg {
        padding: 50px!important;
    }
    .bg-col {
        background-color: #d8e6eca1!important;
    }
    .headerbuttoneven {
        font-size: 14px!important;
    }
    .headerbuttonodd {
        font-size: 14px!important;
    }
    .accordion-body {
        padding: 12px 10px!important;
    }
    .ownd textarea {
        height: 110px!important;
    }
    .table-list-cont {
        margin: 20px;
    }
}
.tender-head {
    padding: 15px 0px;
}

.tender {
    min-height: calc(100vh - 428px);
}

.bg-tender {
    background: #f3f8ff;
}

.tender-head h4 {
    text-align: center;
    margin-bottom: 0px;
}

.container-ul {
    display: flex;
    margin: 5px 0px 10px 0px;
    flex-direction: column;
    align-items: center;
}

.hr {
    margin: 1px !important;
    height: 3px !important;
    width: 35px;
    opacity: 1;
    color: #14a5d3;
}

.top {
    margin-left: 0px !important;
}

.bottom {
    margin-left: 20px !important;
}

.bg-white {
    padding-bottom: 45px;
}

.res-page-cont {
    padding: 0px 0px;
}

.new-res-tile-cont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.res-page-cont-details {
    padding: 10px 0px;
}

.res-det-con {
    margin: 0px;
    background: white;
    padding: 20px;
    border: 0.4px solid #888888;
}

.bg-res {
    background: white;
}

.res-ban-img {
    object-fit: cover;
    height: 83px;
}

.res-bannerContainer {
    position: relative;
    flex-direction: column;
}

.res-profile-img {
    width: 130px;
    height: 130px;
}

.profile-box {
    background: #FFFFFF;
    border: 1px solid rgba(68, 68, 68, 0.6);
    box-sizing: border-box;
    padding: 10px;
}

.profile-res-con {
    width: 100%;
    padding: 10px;
    margin-top: 0px;
    background: white;
}

.res-person-name {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    /* identical to box height */
    text-transform: capitalize;
    color: #0A223D;
}

.res-abo-head {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    /* identical to box height */
    color: #0A223D;
}

.res-exp {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #444444;
}

.exp-values {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    /* identical to box height */
    color: #0A223D;
}

.res-about-cont {
    background: #E7EAED;
    padding: 15px;
}

.res-about-sec {
    padding-top: 10px;
}

.res-card-container {
    width: 100%;
    height: 100%;
    /* padding: 15px; */
}

.res-cards-container {
    /* padding: 15px; */
    width: 100%;
    border-top: 6px dotted #14a5d3;
    overflow: auto;
    padding: 10px;
    height: calc(100vh - 285px);
}

.resource-image-card {
    width: 52px;
    height: 57px;
}

.oth-inter-head {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    padding: 10px;
    font-size: 17px;
}

.resbandata {
    margin-top: -50px;
    margin-left: 0px;
}

.res-ban-data {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
}

@media (min-width:992px) {
    .res-tile-cont {
        justify-content: space-between!important;
    }
    .resbandata {
        margin-top: -50px;
        margin-left: 40px!important;
    }
    .tit-txt {
        font-size: 30px!important;
    }
    .res-ban-data {
        flex-direction: row!important;
        align-items: left!important;
    }
    .profile-res-con {
        margin-top: 50px!important;
    }
    .oth-inter-head {
        padding: 0px!important;
    }
    .res-det-con {
        margin: 30px!important;
    }
}
.tender-head {
    padding: 15px 0px;
}

.tender {
    min-height: calc(100vh - 428px);
}

.bg-tender {
    background: #f3f8ff;
}

.tender-head h4 {
    text-align: center;
    margin-bottom: 0px;
}

.container-ul {
    display: flex;
    margin: 5px 0px 10px 0px;
    flex-direction: column;
    align-items: center;
}

.hr {
    margin: 1px !important;
    height: 3px !important;
    width: 35px;
    opacity: 1;
    color: #14a5d3;
}

.top {
    margin-left: 0px !important;
}

.bottom {
    margin-left: 20px !important;
}

.bg-white {
    padding-bottom: 45px;
}

.res-page-cont {
    padding: 0px 0px;
}

.new-res-tile-cont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.new-res-tile-cont-jb {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-evenly;
    padding-top: 40px !important;
    /* width: 100%; */
}

.res-page-cont-details {
    padding: 10px 0px;
}

.res-det-con {
    margin: 0px;
    background: white;
    padding: 20px;
    border: 0.4px solid #888888;
}

.bg-res {
    background: white;
}

.res-ban-img {
    object-fit: cover;
    height: 83px;
}

.res-bannerContainer {
    position: relative;
    flex-direction: column;
}

.res-profile-img {
    width: 130px;
    height: 130px;
    object-fit: cover !important;
}

.profile-box {
    background: #FFFFFF;
    border: 1px solid rgba(68, 68, 68, 0.6);
    box-sizing: border-box;
    padding: 10px;
}

.profile-res-con {
    width: 100%;
    padding: 10px;
    margin-top: 0px;
    background: white;
}
.profile-sres-con {
    width: 100%;
    padding: 10px;
    margin-top: 0px;
    background: white;
}

.res-person-name {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    /* identical to box height */
    text-transform: capitalize;
    color: #0A223D;
}

.res-abo-head {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    /* identical to box height */
    color: #0A223D;
}

.res-exp {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #444444;
}

.exp-values {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    /* identical to box height */
    color: #0A223D;
}

.res-about-cont {
    background: #E7EAED;
    padding: 15px;
}

.res-about-sec {
    padding-top: 10px;
}

.res-card-container {
    width: 100%;
    height: 100%;
    /* padding: 15px; */
}

.res-cards-container {
    /* padding: 15px; */
    width: 100%;
    border-top: 6px dotted #14a5d3;
    overflow: auto;
    padding: 10px;
    height: calc(100vh - 285px);
}

.resource-image-card {
    width: 52px;
    height: 57px;
}

.oth-inter-head {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    padding: 10px;
    font-size: 17px;
}

.resbandata {
    margin-top: -50px;
    margin-left: 0px;
}

.res-ban-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.res-name {
    border: 1px solid #0A223D;
    border-radius: 50%;
    width: 110px;
    height: 100px;
    background-color: #14a5d3;
    color: #E7EAED;
    text-align: center;
    justify-content: center;
    font-size: 60px;
    font-weight: 700;
    text-transform: capitalize;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
}

@media (min-width:992px) {
    .res-tile-cont {
        justify-content: space-between!important;
    }
    .resbandata {
        margin-top: -50px;
        margin-left: 0px!important;
    }
    .tit-txt {
        font-size: 30px!important;
    }
    .res-ban-data {
        flex-direction: row!important;
        align-items: left!important;
    }
    .profile-res-con {
        margin-top: 50px!important;
    }
    .oth-inter-head {
        padding: 0px!important;
    }
    .res-det-con {
        margin: 30px!important;
    }
}
.search-style {
    color: white;
    background-color: #0a223d;
}

.search {
    cursor: pointer;
}
.tender-head {
    padding: 15px 0px;
}

.tender {
    min-height: calc(100vh - 428px);
}

.bg-tender {
    background: #f3f8ff;
}

.tender-head h4 {
    text-align: center;
    margin-bottom: 0px;
}

.container-ul {
    display: flex;
    margin: 5px 0px 10px 0px;
    flex-direction: column;
    align-items: center;
}

.hr {
    margin: 1px !important;
    height: 3px !important;
    width: 35px;
    opacity: 1;
    color: #14a5d3;
}

.top {
    margin-left: 0px !important;
}

.bottom {
    margin-left: 20px !important;
}

.bg-white {
    padding-bottom: 45px;
}

.res-page-cont {
    padding: 0px 0px;
}

.new-res-tile-cont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.res-page-cont-details {
    padding: 10px 0px;
}

.res-det-con {
    margin: 0px;
    background: white;
    padding: 20px;
    border: 0.4px solid #888888;
}

.bg-res {
    background: white;
}

.res-ban-img {
    object-fit: cover;
    height: 83px;
}

.res-bannerContainer {
    position: relative;
    flex-direction: column;
}

.res-profile-img {
    width: 130px;
    height: 130px;
}

.profile-box {
    background: #FFFFFF;
    border: 1px solid rgba(68, 68, 68, 0.6);
    box-sizing: border-box;
    padding: 10px;
}

.profile-res-con {
    width: 100%;
    padding: 10px;
    margin-top: 0px;
    background: white;
}
.profile-sres-con {
    width: 100%;
    padding: 10px;
    margin-top: 0px;
    background: white;
}

.res-person-name {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    /* identical to box height */
    text-transform: capitalize;
    color: #0A223D;
}

.res-abo-head {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    /* identical to box height */
    color: #0A223D;
}

.res-exp {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #444444;
}

.exp-values {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    /* identical to box height */
    color: #0A223D;
}

.res-about-cont {
    background: #E7EAED;
    padding: 15px;
}

.res-about-sec {
    padding-top: 10px;
}

.res-card-container {
    width: 100%;
    height: 100%;
    /* padding: 15px; */
}

.res-cards-container {
    /* padding: 15px; */
    width: 100%;
    border-top: 6px dotted #14a5d3;
    overflow: auto;
    padding: 10px;
    height: calc(100vh - 285px);
}

.resource-image-card {
    width: 52px;
    height: 57px;
}

.oth-inter-head {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    padding: 10px;
    font-size: 17px;
}

.resbandata {
    margin-top: -50px;
    margin-left: 0px;
}

.res-ban-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.res-name {
    border: 1px solid #0A223D;
    border-radius: 50%;
    width: 110px;
    height: 100px;
    background-color: #14a5d3;
    color: #E7EAED;
    text-align: center;
    justify-content: center;
    font-size: 60px;
    font-weight: 700;
    text-transform: capitalize;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
}

@media (min-width:992px) {
    .res-tile-cont {
        justify-content: space-between!important;
    }
    .resbandata {
        margin-top: -50px;
        margin-left: 0px!important;
    }
    .tit-txt {
        font-size: 30px!important;
    }
    .res-ban-data {
        flex-direction: row!important;
        align-items: left!important;
    }
    .profile-res-con {
        margin-top: 50px!important;
    }
    .oth-inter-head {
        padding: 0px!important;
    }
    .res-det-con {
        margin: 30px!important;
    }
}
.payment-modal  {
justify-content: center !important;
}
 .modal-header {
    background-color:#1c2541 !important;
    color: white !important;
}
 .payment-table th{
    border: 1px solid rgb(165, 164, 164) !important;
    font-weight: bolder !important;
 }
 .payment-table td {
    border: 1px solid rgb(165, 164, 164) !important;
 }
 .modal-footer {
    justify-content: center !important;
 }
.payment-modal  {
justify-content: center !important;
}
 .modal-header {
    background-color:#1c2541 !important;
    color: white !important;
}
 .payment-table th{
    border: 1px solid rgb(165, 164, 164) !important;
    font-weight: bolder !important;
 }
 .payment-table td {
    border: 1px solid rgb(165, 164, 164) !important;
 }
 .modal-footer {
    justify-content: center !important;
 }
 .swal-left-align .swal2-html-container, .swal-left-align .swal2-content {
   text-align: left;
}


.payment-new input {
   margin: 0px !important;
   margin-bottom: 10px !important;
}
.payment-new select {
   border: solid 1px #d5d5d5 !important;
   background-color: white;
   border-radius: 5px;
}
.payment-details-filed{
   display: flex !important;
   flex-direction: column !important;
   justify-content: start;

}
.payment-details-filed label{
   display: flex !important;
margin-bottom: 5px;
/* margin-top: 8px; */

}
.dash-banner {
    background-image: url(/static/media/userdashbanner.989062ce.png);
    width: 100%;
    height: 100px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px!important;
}

.mandatory-select {
    box-shadow: inset 2.5px 0px 0px #ee7e7e
}

.data-cards {
    background-color: #fff;
    padding: 20px;
    /* margin-bottom: 10px; */
}
.mobix .row{
    display: block!important;
}
.field-titles {
    font-weight: 900;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
}

.basic-submit {
    margin: 0px!important;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-bg {
    background: #EBE9E9;
    padding: 4px;
}

.page-bg {
    background: #EBE9E9;
}

.section-Cont {
    background-color: #F3F8FF;
    padding: 20px;
    margin-bottom: 5px;
    /* margin: 20px 0px; */
    border: 1px solid rgba(85, 85, 85, 0.5);
}

.items-cont {
    padding: 10px;
}

.to-left-side {
    display: flex!important;
    align-items: center!important;
    justify-content: flex-start!important;
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}

.form-section {
    background: white;
    width: 100%;
}

.fileContainer {
    display: flex
}

.more-btn-cnt {
    display: flex;
    flex-direction: row-reverse;
}

.chk-items-cont {
    padding: 10px
}

.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile:focus+label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
    pointer-events: none;
}

.secondary-btn {
    background: #F76C6C;
    padding: 10px 25px;
    margin: 0px 10px;
    cursor: pointer;
    border-radius: 4px;
    color: white;
}

thead {
    background: rgb(255, 255, 255)!important;
    color: #0A223D!important;
}

.btn-primary {
    background: #1C2541!important;
    border: none;
    cursor: pointer;
}

.primary-btn {
    background: #1C2541;
    padding: 10px 27px;
    margin: 0px 10px;
    color: white;
    cursor: pointer;
    border-radius: 3px;
}

.optionLabelHeader-cont {
    color: black;
    font-weight: 500;
    height: 29px;
    /* padding: 0px 20px; */
    line-height: 29px;
}

.tableHeading {
    background: #0A223D;
    color: white;
    height: 25px;
    line-height: 25px;
    padding: 0px 15px;
}

.table-list-cont {
    margin: 20px 10px;
}

.header-body-spacing {
    margin-top: 20px;
}

.header-helper-text {
    text-align: right;
    color: red;
    font-weight: 700;
}

.headerbuttonodd {
    height: 40px;
    background: #E3ECEF;
    margin-right: 50px;
    color: #000;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.headerbuttonodd.active {
    height: 40px;
    background: #0A223D;
    margin-right: 50px;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.indexCont {
    background: #14A5D3;
    width: 30px!important;
    text-align: center;
    border-radius: 50%;
    margin: auto 10px;
    color: white;
    height: 30px!important;
    line-height: 30px;
}

.indexCont.even {
    margin-left: -15px;
    z-index: 1;
}

.headerbuttonodd.active:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #0A223D;
}

.headerbuttonodd:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #E3ECEF;
}

.headerbuttoneven {
    height: 40px;
    background: #E3ECEF;
    display: flex;
    color: #000;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #E3ECEF;
    border-left: 20px solid transparent;
}

.headerbuttoneven.active {
    height: 40px;
    background: #0A223D;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven.active:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #0A223D;
    border-left: 20px solid transparent
}

.form-inner-section {
    padding: 12px 0px 20px 0px;
    width: 100%;
}

.fieldset {
    margin: 15px 0px !important;
}

.file-up-helper {
    color: red;
    font-size: 12px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 14px;
}

.section-datas-cont {
    /* margin: 17px auto; */
}

.file-up-label-cont {
    margin-bottom: -30px;
    margin-top: 11px;
    color: #1C2541!important;
    font-weight: 600;
    line-height: 17.75px;
}

.header-label {
    margin-top: 0px;
    margin-bottom: 14px;
    font-weight: 600;
    color: #1C2541;
}

.view-container {
    padding: 5px 0px 5px 0px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.data .row {
    padding: 7px 5px;
    background: #E7E7E7;
    margin: 0 0 10px 0;
}

.data .row .col-8 {
    color: black;
    font-size: 14px;
}

.data .row .col-3 {
    color: #666;
    font-size: 14px;
}

.mysubbtn:hover {
    color: white;
}

.nio {
    color: white!important;
    font-weight: 300;
    font-size: 15px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px!important;
    font-weight: 400!important;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.text-justify {
    text-align: justify;
}

.dwnld {
    color: #F76C6C;
    font-weight: bold;
    text-decoration: underline;
}

.data .row .col-8 table {
    background-color: white!important;
    margin-top: 0px!important;
}

.venture {
    background-color: white!important;
    padding-top: 10px;
}

.data .current {
    background-color: #0a223d!important;
    color: white!important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white!important;
}

.status label {
    font-size: 16px;
}

.mysubbtn {
    margin-top: 19px;
    background: #0a223d!important;
    color: white;
    padding: 6px 66px;
}

.filex {
    margin-top: -13px;
}

.indicator {
    color: red;
}

.headerbuttoneven {
    font-size: 12px;
}

.headerbuttonodd {
    font-size: 12px;
}

.accordion-body {
    padding: 1rem 0rem;
}

.scrolltable {
    overflow: hidden;
    overflow-x: auto;
    padding: 0px;
}

.ownlad {
    padding: 0px 14px;
}

.jointv {
    padding: 0px 14px;
}

.bg-col {
    background-color: #d8e6eca1!important;
}

.expert .fieldset {
    margin: 5px 0px;
}

.expert select {
    border: 1px solid #b8b9ba;
    box-sizing: border-box;
    border-radius: 3px !important;
    font-weight: normal;
    font-size: 13px;
    line-height: 32px;
    color: #222222;
    padding: 0 8px;
    transition: all 0.3s ease;
    background-image: none;
}

.expert .p-10 {
    margin: 2px 0px;
}

.oath label {
    text-align: justify;
    margin-top: 10px;
    font-size: 13px;
}


/* ================================================================================================================================== */

@media (min-width:992px) {
    .mobix .row{
        display: flex!important;
    }
    .form-section {
        width: 921px!important;
    }
    .items-cont {
        padding: 10px 42px!important;
    }
    .form-bg {
        padding: 50px!important;
    }
    .bg-col {
        background-color: #d8e6eca1!important;
    }
    .headerbuttoneven {
        font-size: 14px!important;
    }
    .headerbuttonodd {
        font-size: 14px!important;
    }
    .accordion-body {
        padding: 12px 10px!important;
    }
    .ownd textarea {
        height: 110px!important;
    }
    .table-list-cont {
        margin: 20px;
    }
    .expert .fieldset {
        margin: 11px 0px;
    }
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
    }
    .file-up-helper {
        margin-top: -9px;
    }
}
.dash-banner {
    background-image: url(/static/media/userdashbanner.989062ce.png);
    width: 100%;
    height: 100px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px!important;
}

.mandatory-select {
    box-shadow: inset 2.5px 0px 0px #ee7e7e
}

.data-cards {
    background-color: #fff;
    padding: 20px;
    /* margin-bottom: 10px; */
}
.mobix .row{
    display: block!important;
}
.field-titles {
    font-weight: 900;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
}

.basic-submit {
    margin: 0px!important;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-bg {
    background: #EBE9E9;
    padding: 4px;
}

.page-bg {
    background: #EBE9E9;
}

.section-Cont {
    background-color: #F3F8FF;
    padding: 20px;
    margin-bottom: 5px;
    /* margin: 20px 0px; */
    border: 1px solid rgba(85, 85, 85, 0.5);
}

.items-cont {
    padding: 10px;
}

.to-left-side {
    display: flex!important;
    align-items: center!important;
    justify-content: flex-start!important;
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}

.form-section {
    background: white;
    width: 100%;
}

.fileContainer {
    display: flex
}

.more-btn-cnt {
    display: flex;
    flex-direction: row-reverse;
}

.chk-items-cont {
    padding: 10px
}

.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile:focus+label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
    pointer-events: none;
}

.secondary-btn {
    background: #F76C6C;
    padding: 10px 25px;
    margin: 0px 10px;
    cursor: pointer;
    border-radius: 4px;
    color: white;
}

thead {
    background: rgb(255, 255, 255)!important;
    color: #0A223D!important;
}

.btn-primary {
    background: #1C2541!important;
    border: none;
    cursor: pointer;
}

.primary-btn {
    background: #1C2541;
    padding: 10px 27px;
    margin: 0px 10px;
    color: white;
    cursor: pointer;
    border-radius: 3px;
}

.optionLabelHeader-cont {
    color: black;
    font-weight: 500;
    height: 29px;
    /* padding: 0px 20px; */
    line-height: 29px;
}

.tableHeading {
    background: #0A223D;
    color: white;
    height: 25px;
    line-height: 25px;
    padding: 0px 15px;
}

.table-list-cont {
    margin: 20px 10px;
}

.header-body-spacing {
    margin-top: 20px;
}

.header-helper-text {
    text-align: right;
    color: red;
    font-weight: 700;
}

.headerbuttonodd {
    height: 40px;
    background: #E3ECEF;
    margin-right: 50px;
    color: #000;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.headerbuttonodd.active {
    height: 40px;
    background: #0A223D;
    margin-right: 50px;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.indexCont {
    background: #14A5D3;
    width: 30px!important;
    text-align: center;
    border-radius: 50%;
    margin: auto 10px;
    color: white;
    height: 30px!important;
    line-height: 30px;
}

.indexCont.even {
    margin-left: -15px;
    z-index: 1;
}

.headerbuttonodd.active:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #0A223D;
}

.headerbuttonodd:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #E3ECEF;
}

.headerbuttoneven {
    height: 40px;
    background: #E3ECEF;
    display: flex;
    color: #000;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #E3ECEF;
    border-left: 20px solid transparent;
}

.headerbuttoneven.active {
    height: 40px;
    background: #0A223D;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven.active:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #0A223D;
    border-left: 20px solid transparent
}

.form-inner-section {
    padding: 12px 0px 20px 0px;
    width: 100%;
}

.fieldset {
    margin: 15px 0px !important;
}

.file-up-helper {
    color: red;
    font-size: 12px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 14px;
}

.section-datas-cont {
    /* margin: 17px auto; */
}

.file-up-label-cont {
    margin-bottom: -30px;
    margin-top: 11px;
    color: #1C2541!important;
    font-weight: 600;
    line-height: 17.75px;
}

.header-label {
    margin-top: 0px;
    margin-bottom: 14px;
    font-weight: 600;
    color: #1C2541;
}

.view-container {
    padding: 5px 0px 5px 0px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.data .row {
    padding: 7px 5px;
    background: #E7E7E7;
    margin: 0 0 10px 0;
}

.data .row .col-8 {
    color: black;
    font-size: 14px;
}

.data .row .col-3 {
    color: #666;
    font-size: 14px;
}

.mysubbtn:hover {
    color: white;
}

.nio {
    color: white!important;
    font-weight: 300;
    font-size: 15px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px!important;
    font-weight: 400!important;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.text-justify {
    text-align: justify;
}

.dwnld {
    color: #F76C6C;
    font-weight: bold;
    text-decoration: underline;
}

.data .row .col-8 table {
    background-color: white!important;
    margin-top: 0px!important;
}

.venture {
    background-color: white!important;
    padding-top: 10px;
}

.data .current {
    background-color: #0a223d!important;
    color: white!important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white!important;
}

.status label {
    font-size: 16px;
}

.mysubbtn {
    margin-top: 19px;
    background: #0a223d!important;
    color: white;
    padding: 6px 66px;
}

.filex {
    margin-top: -13px;
}

.indicator {
    color: red;
}

.headerbuttoneven {
    font-size: 12px;
}

.headerbuttonodd {
    font-size: 12px;
}

.accordion-body {
    padding: 1rem 0rem;
}

.scrolltable {
    overflow: hidden;
    overflow-x: auto;
    padding: 0px;
}

.ownlad {
    padding: 0px 14px;
}

.jointv {
    padding: 0px 14px;
}

.bg-col {
    background-color: #d8e6eca1!important;
}

.expert .fieldset {
    margin: 5px 0px;
}

.expert select {
    border: 1px solid #b8b9ba;
    box-sizing: border-box;
    border-radius: 3px !important;
    font-weight: normal;
    font-size: 13px;
    line-height: 32px;
    color: #222222;
    padding: 0 8px;
    transition: all 0.3s ease;
    background-image: none;
}

.expert .p-10 {
    margin: 2px 0px;
}

.oath label {
    text-align: justify;
    margin-top: 10px;
    font-size: 13px;
}
.main-select {
    font-size: 20px;
}
.phone-side{
    padding-right: 8px !important;
}


/* ================================================================================================================================== */

@media (min-width:992px) {
    .mobix .row{
        display: flex!important;
    }
    .form-section {
        width: 921px!important;
    }
    .items-cont {
        padding: 10px 42px!important;
    }
    .form-bg {
        padding: 50px!important;
    }
    .bg-col {
        background-color: #d8e6eca1!important;
    }
    .headerbuttoneven {
        font-size: 14px!important;
    }
    .headerbuttonodd {
        font-size: 14px!important;
    }
    .accordion-body {
        padding: 12px 10px!important;
    }
    .ownd textarea {
        height: 110px!important;
    }
    .table-list-cont {
        margin: 20px;
    }
    .expert .fieldset {
        margin: 11px 0px;
    }
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
    }
    .file-up-helper {
        margin-top: -9px;
    }
}
.dash-banner {
    background-image: url(/static/media/userdashbanner.989062ce.png);
    width: 100%;
    height: 100px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px!important;
}

.mand {
    float: right;
    font-weight: 700;
    color: red;
}
.row .detail-pad{
    padding: 35px -1px;
    margin-top: 13px;
}
.intern-err{
    color: red;
    font-size: 10px;
}
.basic-submit {
    margin: 0px!important;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-bg {
    background: #EBE9E9;
    padding: 4px;
}

.page-bg {
    background: #EBE9E9;
}

.section-Cont {
    background-color: #F3F8FF;
    padding: 20px;
    margin-bottom: 5px;
    /* margin: 20px 0px; */
    border: 1px solid rgba(85, 85, 85, 0.5);
}

.items-cont {
    padding: 10px;
}

.to-left-side {
    display: flex!important;
    align-items: center!important;
    justify-content: flex-start!important;
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}

.form-section {
    background: white;
    width: 100%;
}

.fileContainer {
    display: flex
}

.more-btn-cnt {
    display: flex;
    flex-direction: row-reverse;
}

.chk-items-cont {
    padding: 10px
}

.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile:focus+label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
    pointer-events: none;
}

.secondary-btn {
    background: #F76C6C;
    padding: 10px 25px;
    margin: 0px 10px;
    cursor: pointer;
    border-radius: 4px;
    color: white;
}

thead {
    background: rgb(255, 255, 255)!important;
    color: #0A223D!important;
}

.btn-primary {
    background: #1C2541!important;
    border: none;
    cursor: pointer;
}

.primary-btn {
    background: #1C2541;
    padding: 10px 27px;
    margin: 0px 10px;
    color: white;
    cursor: pointer;
    border-radius: 3px;
}

.optionLabelHeader-cont {
    color: black;
    font-weight: 500;
    height: 29px;
    /* padding: 0px 20px; */
    line-height: 29px;
}

.tableHeading {
    background: #0A223D;
    color: white;
    height: 25px;
    line-height: 25px;
    padding: 0px 15px;
}

.table-list-cont {
    margin: 20px 10px;
}

.header-body-spacing {
    margin-top: 20px;
}

.header-helper-text {
    text-align: right;
    color: red;
    font-weight: 700;
}

.headerbuttonodd {
    height: 40px;
    background: #E3ECEF;
    margin-right: 50px;
    color: #000;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.headerbuttonodd.active {
    height: 40px;
    background: #0A223D;
    margin-right: 50px;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.indexCont {
    background: #14A5D3;
    width: 30px!important;
    text-align: center;
    border-radius: 50%;
    margin: auto 10px;
    color: white;
    height: 30px!important;
    line-height: 30px;
}

.indexCont.even {
    margin-left: -15px;
    z-index: 1;
}

.headerbuttonodd.active:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #0A223D;
}

.headerbuttonodd:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #E3ECEF;
}
/* ------------------- */
.headerbuttoneven {
    height: 40px;
    background: #E3ECEF;
    display: flex;
    color: #000;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #E3ECEF;
    border-left: 20px solid transparent;
}

.headerbuttoneven.active {
    height: 40px;
    background: #0A223D;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven.active:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #0A223D;
    border-left: 20px solid transparent
}

.form-inner-section {
    padding: 12px 0px 20px 0px;
    width: 100%;
}

.fieldset {
    margin: 15px 0px;
}

.file-up-helper {
    color: red;
    font-size: 12px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 14px;
}

.section-datas-cont {
    /* margin: 17px auto; */
}

.file-up-label-cont {
    margin-bottom: -30px;
    margin-top: 11px;
    color: #1C2541!important;
    font-weight: 600;
    line-height: 17.75px;
}

.header-label {
    margin-top: 0px;
    margin-bottom: 14px;
    font-weight: 600;
    color: #1C2541;
}

.view-container {
    padding: 5px 0px 5px 0px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.data .row {
    padding: 7px 5px;
    background: #E7E7E7;
    margin: 0 0 10px 0;
}

.data .row .col-8 {
    color: black;
    font-size: 14px;
}

.data .row .col-3 {
    color: #666;
    font-size: 14px;
}

.mysubbtn:hover {
    color: white;
}

.nio {
    color: white!important;
    font-weight: 300;
    font-size: 15px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px!important;
    font-weight: 400!important;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.text-justify {
    text-align: justify;
}

.dwnld {
    color: #F76C6C;
    font-weight: bold;
    text-decoration: underline;
}

.data .row .col-8 table {
    background-color: white!important;
    margin-top: 0px!important;
}

.venture {
    background-color: white!important;
    padding-top: 10px;
}

.data .current {
    background-color: #0a223d!important;
    color: white!important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white!important;
}

.status label {
    font-size: 16px;
}

.mysubbtn {
    margin-top: 19px;
    background: #0a223d!important;
    color: white;
    padding: 6px 66px;
}

.filex {
    margin-top: -13px;
}

.indicator {
    color: red;
}

.headerbuttoneven {
    font-size: 12px;
}

.headerbuttonodd {
    font-size: 12px;
}

.accordion-body {
    padding: 1rem 0rem;
}

.scrolltable {
    overflow: hidden;
    overflow-x: auto;
    padding: 0px;
}

.ownlad {
    padding: 0px 14px;
}

.jointv {
    padding: 0px 14px;
}

.bg-col {
    background-color: #d8e6eca1!important;
}

.expert .fieldset {
    margin: 5px 0px;
}

.expert select {
    border: 1px solid #b8b9ba;
    box-sizing: border-box;
    border-radius: 3px !important;
    font-weight: normal;
    font-size: 13px;
    line-height: 32px;
    color: #222222;
    padding: 0 8px;
    transition: all 0.3s ease;
    background-image: none;
}

.expert .p-10 {
    margin: 6px 0px;
}

.oath label {
    text-align: justify;
    margin-top: 10px;
    font-size: 13px;
}


/* ================================================================================================================================== */

@media (min-width:992px) {
    .row .detail-pad{
        padding: 35px 0px!important;
        margin-top: 13px;
    }
    .form-section {
        width: 921px!important;
    }
    .items-cont {
        padding: 10px 42px!important;
    }
    .form-bg {
        padding: 50px!important;
    }
    .bg-col {
        background-color: #d8e6eca1!important;
    }
    .headerbuttoneven {
        font-size: 14px!important;
    }
    .headerbuttonodd {
        font-size: 14px!important;
    }
    .accordion-body {
        padding: 12px 10px!important;
    }
    .ownd textarea {
        height: 110px!important;
    }
    .table-list-cont {
        margin: 20px;
    }
    .expert .fieldset {
        margin: 11px 0px;
    }
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
    }
    .file-up-helper {
        margin-top: -9px;
    }
}
.cardx {
    padding: 10px;
    margin: 0px 35px;
}
.sam{
    padding: 15px!important;
}
.xbody{
    padding: 0px!important;
}
.rytbody span {
    color: #14A5D3;
    font-size: 16px;
}

.rytbody p {
    margin-bottom: 0px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
}
.pic{
    /* width: 120px; */
    border-radius: 50%;
    height: 200px;
    width :200px;
}
.rytbody h6{
    font-size: 18px;
    font-weight: 600;
    margin-top: 3px;
    margin-bottom: 0px;
    color: #1c2541;
}
.detailb{
    font-size: 13px;
    padding: 4px 47px;
    margin-top: 3px;
    color:white!important ;
    border: 1px solid #1c2541;
    /* background: white!important; */
}
.xbody p{
    margin-bottom: 2px!important;
}
/* .cardx:hover .detailb{
    border: 1px solid #1c2541;
    background: #1c2541!important;
    color: white!important;
} */
.smal {
    text-align: justify !important;
    line-height: 16px;
    color: #555;
}
.cors{
    font-weight: 700;
    color:#777;
}
.smal small {
    font-size: 13px !important;
    font-weight: 500;
    color: black;
}
.cardx:hover{
    background-color: #D2EBF7;
}
.member h6 {
    color: black;
    font-size: 14px;
    text-align: center;
}

.member hr {
    border: black;
}

.ceo p {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 700;
    color: #0A223D;
}

.ceo h6 {
    font-size: 11px;
    color: #666;
    margin-bottom: 0px;
}

.ceo address {
    font-size: 11px;
    font-weight: 500;
}
.proj{
    background-color: #F3F8FF;
}
.bodybox .cardx {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.deta1{
    background: white;
    margin: auto;
    padding-bottom: 40px;
    padding-top: 30px;

}
.cd{
    padding: 0px 55px;
}
.pic2{
    width: 150px;
    border-radius:50%;
}
.gray{
    background-color: #dfe3e899;
    text-align: left;
    padding: 5px 0px 5px 18px;
}
.gray .col-8{
    font-size: 14px;
    font-weight: 600;
}
.edux{
    padding-right: 26px;
    padding-bottom: 13px;
}
tbody{
    background-color: white!important;
}
/* =================================================================================== */
@media (min-width:992px) {
    .dash-banner {
        height: 162px;
    }
    .cardx{
        margin: auto;
    }
    .tit-txt {
        font-size: 30px;
        line-height: 38px;
    }
    .rytbody{
        /* padding-left: 18px; */
    }
    .cd{
        padding:initial;
    }
}
.dash-banner {
    /* background-image: url("../../../resources/img/dashboard/userdashbanner.png"); */
    width: 100%;
    height: 85px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px !important;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
}

.adr2 {
    border-bottom: 1px solid #c4c4c4;
    border-top: 1px solid #c4c4c4;
    padding: 16px 0px 4px 0px;
}

.proj {
    background: rgba(243, 248, 255, 0.88);
    padding-bottom: 34px;
}

.projbox {
    background-color: white;
    /* width: calc(100% - 599px); */
    /* padding: 45px 142px; */
    margin-top: 20px;
    margin-bottom: 20px;
    /* margin: auto; */
    width: 91%;
    padding: 1px 11px;
}

.lftd {
    /* background-color: #d3d9ed; */
    color: #000;
    padding: 25px 16px;
    border-radius: 20px;
    background-color: #fff;
    padding: 11px 42px;
    border: 24px solid #f3f8ff;
    border-top: 0px;
    border-bottom: 0px;
}


/* .rytd {
      background-color: white;
      padding: 25px 20px;
  } */

.lftd p {
    margin-left: 0px;
    /* color: #000; */
}

.cont-address {
    color: #2cafe7;
    font-weight: 700;
    font-size: 20px;
}

.rytd h3 {
    color: #000;
}

.pd-0 {
    padding: 0px 15px;
}

.instax img {
    width: 35px;
}

.lftd hr {
    width: 40%;
    height: 2px;
}

.rytd hr {
    width: 40%;
    height: 2px;
}

.lftd .card-body {
    text-align: center;
}

.bgw {
    /* background-color: #fff; */
    padding: 21px 0px;
}

.instax {
    padding: 0px 4px;
}

.cont-ico .social-buttons {
    justify-content: center !important;
}

.lftd img {
    background: white;
    border: 1px solid #c4c4c4;
    padding: 7px;
    border-radius: 23px;
}

.lftd .card {
    border-radius: 0;
    /* border: none; */
}

.lftd h5 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    margin-top: 8px;
}

.cont-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 17px 0px 24px 0px;
}

.rytd .fieldset {
    margin-top: 0px;
}

.con-pg {
    margin-top: 0px;
}

.rytd button {
    background: #2cafe7;
    margin-left: 0px;
    float: right;
    margin-top: 2px;
}

.mxauto {
    margin: 0 auto;
}

@media (min-width: 992px) {
    .con-pg {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .lftd {
        margin: 0 auto;
        border: none!important;
    }
}

@media (min-width: 992px) {
    .pd-0 {
        padding: 0px;
    }
}

.bgw svg {
    height: 23rem!important;
}

#envelope {
    animation: float 2s ease-in-out infinite;
}

#star1,
#star2,
#star3,
#star4,
#star5,
#star6 {
    animation: blink 1s ease-in-out infinite;
}

#star2 {
    animation-delay: 100ms;
}

#star3 {
    animation-delay: 500ms;
}

#star4 {
    animation-delay: 700ms;
}

#star5 {
    animation-delay: 300ms;
}

#star6 {
    animation-delay: 200ms;
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
.dash-banner {
    /* background-image: url("../../../resources/img/dashboard/userdashbanner.png"); */
    width: 100%;
    height: 85px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px !important;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
}

.proj {
    background: rgba(243, 248, 255, .88);
    padding-bottom: 34px;
}

.projbox {
    background-color: white;
    /* width: calc(100% - 599px); */
    /* padding: 45px 142px; */
    margin-top: 20px;
    margin-bottom: 20px;
    /* margin: auto; */
    width: 91%;
    padding: 1px 11px;
}

.pdf {
    background-color: #D2EBF7;
}


/* .proposalbox {
    padding: a;
} */
.flexy{
    width: 83%!important;
}
.proposalbox .card{
    border: none!important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.pro-data {
    padding: 8px 14px 0px 13px;
}

.pro-data h5 {
    font-size: 18px;
    font-weight: 600;
}

.pro-data small {
    font-size: 12px;
    color: #A1A8AF;
}

.pro-data .d-flex p {
    margin-bottom: 3px;
    margin-left: 4px;
    font-size: 13px;
    font-weight: 600;
}

.pro-data .d-flex span {
    background: #DFE3E8;
    color: #0A223D;
    padding: 0px 5px;
    border-radius: 22px;
}

.pro-data h6 {
    font-size: 12px;
    text-align: justify;
    color: rgba(68, 68, 68, 0.94);
}


.vwbt {
    padding: 2px 15px!important;
    margin: 0px 8px;
}
.pdfimg{
    width: 65px;
}
.pro-data h6{
    word-spacing: -1px;
}
/* =================================================================================== */

@media (min-width:992px) {
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
    }
    .flexy{
        width: 60%!important;
    }
    .proposalbox {
        padding: 0px 67px;
    }
    .projbox {
        background-color: white;
        width: calc(100% - 599px);
        padding: 45px 142px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .pro-data h6 {
        font-size: 12px;
    }
    .vwbt{
        padding: 2px 26px!important;
    }
    .pdfimg{
        width: 90px;
    }
    .pro-data h6{
        word-spacing: 0px;
    }

}
.dash-banner {
    /* background-image: url("../../../resources/img/dashboard/userdashbanner.png"); */
    width: 100%;
    height: 85px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px !important;
}
.rytbody h5  b{
    color: #1c2541;
    
}
.no-data{
    text-align: center;
}
.no-data img{
    width: 350px;
}
.mb-20{
    margin-bottom: 14px;
}
.max-ht{
    max-height: 209px!important;
    overflow: hidden;
}
.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
   
}
.cad {
    padding: 10px;
    min-height: 300px;
}

.rytbody span {
    color: #14A5D3;
    font-size: 16px;
}

.rytbody p {
    margin-bottom: 2px;
    font-weight: 700;

}

.smal {
    text-align: justify !important;
    line-height: 16px;
    color: #555;
}
.cors{
    font-weight: 700;
    color:#777;
}
.smal small {
    font-size: 13px !important;
    font-weight: 500;
}
.cad:hover{
    background-color: #D2EBF7;
}
.member h6 {
    color: black;
    font-size: 14px;
    text-align: center;
}

.member hr {
    border: black;
}

.ceo p {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 700;
    color: #0A223D;
}

.ceo h6 {
    font-size: 11px;
    color: #666;
    margin-bottom: 0px;
}
.lok span {
    margin-top: -2px!important;
}
.ceo address {
    font-size: 11px;
    font-weight: 500;
}
.pl-50{
    padding-left: 8px;
    margin-top: 2px;
}
.bodybox .cad {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.vwbt {
    padding: 2px 15px!important;
    margin: 0px 8px;
}
.pdfimg{
    width: 65px;
}
.proj{
    padding-bottom: 9px!important;
}
.pro-data h6{
    word-spacing: -1px;
}
/* =================================================================================== */
@media (min-width:992px) {
    .dash-banner {
        height: 162px;
    }

    .tit-txt {
        font-size: 30px;
        line-height: 38px;
    }
    .mb-20{
        margin-bottom: 23px;
    }
    .vwbt{
        padding: 2px 26px!important;
    }
    .pdfimg{
        width: 90px;
    }
    .pro-data h6{
        word-spacing: 0px;
    }

}
.dash-banner {
    background-image: url(/static/media/userdashbanner.989062ce.png);
    width: 100%;
    height: 85px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.user-tech-prop-tile-data-container {
    background-color: transparent;
}

.user-tech-prop-tile-title {
    font-weight: 600;
    font-size: large;
    color: #2F2483;
    font-family: poppins;
    padding-bottom: 20px;
}
.user-tech-prop-tile-title-sub {
    font-weight: 400;
    font-size: medium;
    /* color: #2F2483; */
    font-family: poppins;
}

.user-tech-prop-tile-desc {
    font-family: poppins;
}

.user-tech-prop-tile-download {
    background-color: #2f2483;
    width: 100%;
    height: 50px;
    display: flex;
    color: white;
    font-size: 18px;
    margin-top: 10px;
    font-weight: 600;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.user-tech-prop-tile-download-cont {
    display: flex;
    align-content: end;
    justify-content: end;
}

.no-margin {
    margin: 0px!important;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
}

.proj {
    background: rgba(243, 248, 255, .88);
    padding-bottom: 6px;
}

.projbox {
    background-color: white;
    /* width: calc(100% - 599px); */
    /* padding: 45px 142px; */
    margin-top: 20px;
    margin-bottom: 20px;
    /* margin: auto; */
    width: 91%;
    padding: 1px 11px;
}

.mex {
    padding: 20px 7px!important;
}

.pager {
    background-color: #fff;
    width: 95%;
    margin: 7px 10px;
    /* padding: 36px 69px; */
    padding: 6px 11px;
}

.btn:hover {
    color: white!important;
}
.view-container-comp-1 .white_btn_sup:hover {
    background-color: rgb(255, 157, 0)!important;
    color: #000!important;
}

.mex .data .row .col-3 {
    color: #666;
    font-size: 14px;
}

.projx {
    padding: 20px;
}

.helpy {
    margin-top: -12px!important;
}

.downloadText-prop {
    font-size: 18px;
    padding: 0px 10px;
}


/* =================================================================================== */

@media (min-width:768px) {
    .dash-banner {
        height: 162px;
    }
    .mex .data .row .col-3 {
        color: #666;
        font-size: 15px;
    }
    .tit-txt {
        font-size: 30px;
    }
    .projbox {
        background-color: white;
        width: calc(100% - 599px);
        padding: 26px 69px 13px 69px;
        margin-top: 20px;
    }
    .projx {
        margin-bottom: 8px;
        padding: 20px 142px;
    }
    .pager {
        background-color: #fff;
        width: 940px;
        margin: auto;
        padding: 36px 69px;
    }
    .user-tech-prop-tile-download {
        width: 100px;
        background: #2cafe7;
        height: 100px;
        font-size: 50px;
        margin-top: 0px;
    }
}
.dash-banner {
    background-image: url(/static/media/userdashbanner.989062ce.png);
    width: 100%;
    height: 100px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px!important;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
}

.basic-submit {
    margin: 0px!important;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-bg {
    background: #EBE9E9;
    padding: 4px;
}

.page-bg {
    background: #EBE9E9;
}

.section-Cont {
    background-color: #F3F8FF;
    padding: 20px;
    margin-bottom: 5px;
    /* margin: 20px 0px; */
    border: 1px solid rgba(85, 85, 85, 0.5);
}

.items-cont {
    padding: 10px;
}

.to-left-side {
    display: flex!important;
    align-items: center!important;
    justify-content: flex-start!important;
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px ;
}

.form-section {
    background: white;
    width: 100%;
}

.fileContainer {
    display: flex
}

.more-btn-cnt {
    display: flex;
    flex-direction: row-reverse;
}

.chk-items-cont {
    padding: 10px
}

.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile:focus+label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
    pointer-events: none;
}

.secondary-btn {
    background: #F76C6C;
    padding: 10px 25px;
    margin: 0px 10px;
    cursor: pointer;
    border-radius: 4px;
    color: white;
}

thead {
    background: rgb(255, 255, 255)!important;
    color: #0A223D!important;
}

.btn-primary {
    background: #1C2541!important;
    border: none;
    cursor: pointer;
}

.primary-btn {
    background: #1C2541;
    padding: 10px 27px;
    margin: 0px 10px;
    color: white;
    cursor: pointer;
    border-radius: 3px;
}

.optionLabelHeader-cont {
    color: black;
    font-weight: 500;
    height: 29px;
    /* padding: 0px 20px; */
    line-height: 29px;
}

.tableHeading {
    background: #0A223D;
    color: white;
    height: 25px;
    line-height: 25px;
    padding: 0px 15px;
}

.table-list-cont {
    margin: 20px 10px;
}

.header-body-spacing {
    margin-top: 20px;
}

.header-helper-text {
    text-align: right;
    color: red;
    font-weight: 700;
}

.headerbuttonodd {
    height: 40px;
    background: #E3ECEF;
    margin-right: 50px;
    color: #000;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.headerbuttonodd.active {
    height: 40px;
    background: #0A223D;
    margin-right: 50px;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.indexCont {
    background: #14A5D3;
    width: 30px!important;
    text-align: center;
    border-radius: 50%;
    margin: auto 10px;
    color: white;
    height: 30px!important;
    line-height: 30px;
}

.indexCont.even {
    margin-left: -15px;
    z-index: 1;
}

.headerbuttonodd.active:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #0A223D;
}

.headerbuttonodd:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #E3ECEF;
}

.headerbuttoneven {
    height: 40px;
    background: #E3ECEF;
    display: flex;
    color: #000;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #E3ECEF;
    border-left: 20px solid transparent;
}

.headerbuttoneven.active {
    height: 40px;
    background: #0A223D;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven.active:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #0A223D;
    border-left: 20px solid transparent
}

.form-inner-section {
    padding: 12px 0px 20px 0px;
    width: 100%;
}

.fieldset {
    margin: 15px 0px;
}

.file-up-helper {
    color: red;
    font-size: 12px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 14px;
}

.section-datas-cont {
    /* margin: 17px auto; */
}

.file-up-label-cont {
    margin-bottom: -30px;
    margin-top: 11px;
    color: #1C2541!important;
    font-weight: 600;
    line-height: 17.75px;
}

.header-label {
    margin-top: 0px;
    margin-bottom: 14px;
    font-weight: 600;
    color: #1C2541;
}

.view-container {
    padding: 5px 0px 5px 0px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.data .row {
    padding: 7px 5px;
    background: #E7E7E7;
    margin: 0 0 10px 0;
}

.data .row .col-8 {
    color: black;
    font-size: 14px;
}

.data .row .col-3 {
    color: #666;
    font-size: 14px;
}

.mysubbtn:hover {
    color: white;
}

.nio {
    color: white!important;
    font-weight: 300;
    font-size: 15px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px!important;
    font-weight: 400!important;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.text-justify {
    text-align: justify;
}

.dwnld {
    color: #F76C6C;
    font-weight: bold;
    text-decoration: underline;
}

.data .row .col-8 table {
    background-color: white!important;
    margin-top: 0px!important;
}

.venture {
    background-color: white!important;
    padding-top: 10px;
}

.data .current {
    background-color: #0a223d!important;
    color: white!important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white!important;
}

.status label {
    font-size: 16px;
}

.mysubbtn {
    margin-top: 19px;
    background: #0a223d!important;
    color: white;
    padding: 6px 66px;
}

.filex {
    margin-top: -13px;
}

.indicator {
    color: red;
}

.headerbuttoneven {
    font-size: 12px;
}

.headerbuttonodd {
    font-size: 12px;
}

.accordion-body {
    padding: 1rem 0rem;
}

.scrolltable {
    overflow: hidden;
    overflow-x: auto;
    padding: 0px;
}

.ownlad {
    padding: 0px 14px;
}

.jointv {
    padding: 0px 14px;
}

.bg-col {
    background-color: #d8e6eca1!important;
}

.expert .fieldset {
    margin: 5px 0px;
}

/* .expert select {
    border: 1px solid #b8b9ba;
    box-sizing: border-box;
    border-radius: 3px !important;
    font-weight: normal;
    font-size: 13px;
    line-height: 32px;
    color: #222222;
    padding: 0 8px;
    transition: all 0.3s ease;
    background-image: none;
} */

.expert .p-10 {
    margin: 2px 0px;
}

.oath label {
    text-align: justify;
    margin-top: 10px;
    font-size: 13px;
}
.mandatory-select{
    border: 1px solid #b8b9ba70 !important;
    border-radius: 5px !important;
    border-left: 2px red !important;
    /* line-height: 31px !important; */
    font-size: 15px !important;
}
.mandatory-select:focus{
    border-right: 1px solid #388adb70 !important;
    border-top: 1px solid #388adb70 !important;
    border-bottom: 1px solid #388adb70 !important;
    border-radius: 5px !important;
    border-left: 2px solid red !important;
    /* line-height: 31px !important; */
    font-size: 15px !important;
    box-shadow: none !important;
    
}


/* ================================================================================================================================== */

@media (min-width:992px) {
    .form-section {
        width: 921px!important;
    }
    .items-cont {
        padding: 10px 42px!important;
    }
    .form-bg {
        padding: 50px!important;
    }
    .bg-col {
        background-color: #d8e6eca1!important;
    }
    .headerbuttoneven {
        font-size: 14px!important;
    }
    .headerbuttonodd {
        font-size: 14px!important;
    }
    .accordion-body {
        padding: 12px 10px!important;
    }
    .ownd textarea {
        height: 110px!important;
    }
    .table-list-cont {
        margin: 20px;
    }
    .expert .fieldset {
        margin: 11px 0px;
    }
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
    }
    .file-up-helper {
        margin-top: -9px;
        /* margin-bottom: 14px; */
    }
}
.dash-banner {
    background-image: url(/static/media/userdashbanner.989062ce.png);
    width: 100%;
    height: 100px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.no-margin {
    margin: 0px!important;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
}

.basic-submit {
    margin: 0px!important;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-bg {
    background: #EBE9E9;
    padding: 4px;
}

.page-bg {
    background: #EBE9E9;
}

.section-Cont {
    background-color: #F3F8FF;
    padding: 20px;
    margin-bottom: 5px;
    /* margin: 20px 0px; */
    border: 1px solid rgba(85, 85, 85, 0.5);
}

.items-cont {
    padding: 10px;
}

.to-left-side {
    display: flex!important;
    align-items: center!important;
    justify-content: flex-start!important;
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
}

.form-section {
    background: white;
    width: 100%;
}

.fileContainer {
    display: flex
}

.more-btn-cnt {
    display: flex;
    flex-direction: row-reverse;
}

.chk-items-cont {
    padding: 10px
}

.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile:focus+label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
    pointer-events: none;
}

.secondary-btn {
    background: #F76C6C;
    padding: 10px 25px;
    margin: 0px 10px;
    cursor: pointer;
    border-radius: 4px;
    color: white;
}

thead {
    background: rgb(255, 255, 255)!important;
    color: #0A223D!important;
}

.btn-primary {
    background: #1C2541!important;
    border: none;
    cursor: pointer;
}

.primary-btn {
    background: #1C2541;
    padding: 10px 27px;
    margin: 0px 10px;
    color: white;
    cursor: pointer;
    border-radius: 3px;
}

.optionLabelHeader-cont {
    color: black;
    font-weight: 500;
    height: 29px;
    /* padding: 0px 20px; */
    line-height: 29px;
}

.tableHeading {
    background: #0A223D;
    color: white;
    height: 25px;
    line-height: 25px;
    padding: 0px 15px;
}

.table-list-cont {
    margin: 20px 10px;
}

.header-body-spacing {
    margin-top: 20px;
}

.header-helper-text {
    text-align: right;
    color: red;
    font-weight: 700;
}

.headerbuttonodd {
    height: 40px;
    background: #E3ECEF;
    margin-right: 50px;
    color: #000;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.headerbuttonodd.active {
    height: 40px;
    background: #0A223D;
    margin-right: 50px;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.indexCont {
    background: #14A5D3;
    width: 30px!important;
    text-align: center;
    border-radius: 50%;
    margin: auto 10px;
    color: white;
    height: 30px!important;
    line-height: 30px;
}

.indexCont.even {
    margin-left: -15px;
    z-index: 1;
}

.headerbuttonodd.active:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #0A223D;
}

.headerbuttonodd:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #E3ECEF;
}

.headerbuttoneven {
    height: 40px;
    background: #E3ECEF;
    display: flex;
    color: #000;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #E3ECEF;
    border-left: 20px solid transparent;
}

.headerbuttoneven.active {
    height: 40px;
    background: #0A223D;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven.active:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #0A223D;
    border-left: 20px solid transparent
}

.form-inner-section {
    padding: 12px 0px 20px 0px;
    width: 100%;
}

.fieldset {
    margin: 15px 0px;
}
.col-set{
    padding-top: 3px !important;
}

.file-up-helper {
    color: red;
    font-size: 12px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 14px;
}

.section-datas-cont {
    /* margin: 17px auto; */
}

.file-up-label-cont {
    margin-bottom: -30px;
    margin-top: 11px;
    color: #1C2541!important;
    font-weight: 600;
    line-height: 17.75px;
}

.header-label {
    margin-top: 0px;
    margin-bottom: 14px;
    font-weight: 600;
    color: #1C2541;
}

.view-container {
    padding: 5px 0px 5px 0px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.data .row {
    padding: 7px 5px;
    background: #E7E7E7;
    margin: 0 0 10px 0;
}

.data .row .col-8 {
    color: black;
    font-size: 14px;
}

.data .row .col-3 {
    color: #666;
    font-size: 14px;
}

.mysubbtn:hover {
    color: white;
}

.nio {
    color: white!important;
    font-weight: 300;
    font-size: 15px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px!important;
    font-weight: 400!important;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.text-justify {
    text-align: justify;
}

.dwnld {
    color: #F76C6C;
    font-weight: bold;
    text-decoration: underline;
}

.data .row .col-8 table {
    background-color: white!important;
    margin-top: 0px!important;
}

.venture {
    background-color: white!important;
    padding-top: 10px;
}

.data .current {
    background-color: #0a223d!important;
    color: white!important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white!important;
}

.status label {
    font-size: 16px;
}

.mysubbtn {
    margin-top: 19px;
    background: #0a223d!important;
    color: white;
    padding: 6px 66px;
}

.filex {
    margin-top: -13px;
}

.indicator {
    color: red;
}

.headerbuttoneven {
    font-size: 12px;
}

.headerbuttonodd {
    font-size: 12px;
}

.accordion-body {
    padding: 1rem 0rem;
}

.scrolltable {
    overflow: hidden;
    overflow-x: auto;
    padding: 0px;
}

.ownlad {
    padding: 0px 14px;
}

.jointv {
    padding: 0px 14px;
}

.bg-col {
    background-color: #d8e6eca1!important;
}

.expert .fieldset {
    margin: 5px 0px;
}

.expert select {
    border: 1px solid #b8b9ba;
    box-sizing: border-box;
    border-radius: 5px !important;
    font-weight: normal;
    font-size: 13px;
    line-height: 32px;
    color: #222222;
    padding: 0 8px;
    transition: all 0.3s ease;
    background-image: none;
}

.expert .p-10 {
    margin: 6px 0px;
}

.oath label {
    text-align: justify;
    margin-top: 10px;
    font-size: 13px;
}


/* ================================================================================================================================== */

@media (min-width:992px) {
    .form-section {
        width: 921px!important;
    }
    .items-cont {
        padding: 10px 42px!important;
    }
    .form-bg {
        padding: 50px!important;
    }
    .bg-col {
        background-color: #d8e6eca1!important;
    }
    .headerbuttoneven {
        font-size: 14px!important;
    }
    .headerbuttonodd {
        font-size: 14px!important;
    }
    .accordion-body {
        padding: 12px 10px!important;
    }
    .ownd textarea {
        height: 110px!important;
    }
    .table-list-cont {
        margin: 20px;
    }
    .expert .fieldset {
        margin: 8px 0px;
    }
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
    }
    .file-up-helper {
        margin-top: -9px;
    }
}
.newspad{
    background: white;
    width:100%;
    padding: 18px 25px;
  height: auto;
}
/* .deta{} */
  
.page-bg1{
    background-color: #F3F8FF;
    padding-bottom:40px;
   
    /* background-color: blue; */
}
.morenews hr{    margin: 0px 0px 16px 0px;
}
.newslink{overflow: hidden;}
.deta p{font-family: 'Mulish';
text-align: justify;}
.vertical {
  
    padding: 0px 12px;
    width: 50px;
}
.brdr{border-left: 1px solid #777;}
.morenews{
    padding-left: auto;
}
.morenews h6{margin-top: 5px;
    font-weight: 600;
    color: #444;
}
.morenews .card{background-color: #E3ECEF;}
.morenews .card-body{background-color: #E3ECEF;
    padding: 4px 8px;
    font-weight: 600;
    font-style: italic;
}
.morenews .card-body a{
    text-decoration: none;
    float: right;
}

@media (min-width:992px){

    .newspad{
        width: calc(100% - 470px)!important;
        margin-top: 30px;
    }
    .vertical {
        border-left: 1px solid;
    }
    .deta{
        /* border-right: 1px solid; */

        padding-right: 27px;
    }
    .morenews {
        padding-left: 23px;
    }
    .tit-txt {
        font-size: 30px;
    }
    
    

}
.comp-layout-con {
    padding: 20px;
}

.comp-outer-cont {
    display: flex;
    flex-direction: column;
}

.comp-domain-container {
    display: flex;
    flex-direction: column;
}

.comp-domains {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    width: 100%;
}

.comp-common-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 17px;
    /* identical to box height, or 77% */
    letter-spacing: -0.03em;
    color: #2F2483;
}

.com-dir-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 0px;
}

.comp-search-box {
    width: 100%;
}

.com-select-filter-cont {
    display: flex;
    width: 100%;
}

.com-sel-filters {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.comp-list-con {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.comp-clear-filter {
    width: 80px;
    padding: 10px 0px;
    cursor: pointer;
}

.comp-filter-item-label {
    margin: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #14A5D3;
}

.comp-item-product-prof-cont {
    padding: 17px 0px;
    font-size: 16px;
    color: #5a4d4d;
}

.comp-item-view-more-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
    /* letter-spacing: -0.03em; */
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    cursor: pointer;
    color: #2F2483;
}

.comp-view-more-cont {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0px 25px 25px 0px;
    height: revert;
}

.comp-filter-item {
    margin: 5px;
    display: flex;
    padding: 5px;
    border: 1px solid;
    border-radius: 5px;
    border: 1px solid rgba(20, 165, 211, 0.5);
}

.filter-clearButton-cont {
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-clear-button {
    justify-content: center;
    align-items: center;
    display: flex;
    background: #2cafe7;
    padding: 2px;
    font-size: 20px;
    margin: 5px;
    border-radius: 50%;
    color: white;
    font-weight: 800;
}

.comp-list-item-cont {
    width: 100%;
    padding: 10px 0px;
}

.comp-list-item {
    /* width: 480px; */
    /* height: 600px; */
    height: 100%;
    position: relative;
    /* left: 394px;
top: 473px; */
    background: #FFFFFF;
    box-shadow: 2px 2px 5px rgba(20, 165, 211, 0.25);
    border-radius: 10px;
    padding: 20px;
}

.comp-item-header {
    display: flex;
}

.comp-item-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 20px; */
    font-family: sans-serif;
    font-weight: 700;
    background: #2F2483;
    border-radius: 10px;
    font-size: 66px;
    width: 120px;
    height: 120px;
    color: white;
}

.comp-item-header-data {
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.comp-item-person-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
}

.comp-item-data-icons {
    font-size: 11px;
    color: #2F2483;
    margin: 0px 10px 0px 0px;
}

.comp-item-comp-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 35px;
    padding: 20px 0px 0px 0px;
    /* or 140% */
    letter-spacing: 0.03em;
}

.comp-item-dom-cont {
    display: flex;
    flex-wrap: wrap;
}

.comp-item-dom {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #14A5D3;
    padding-right: 10px;
}

.comp-item-prod-prof-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* identical to box height */
    color: #2F2483;
}

.comp-item-prod-prof-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: 0.03em;
    color: #000000;
    margin-bottom: 5px;
}

.comp-item-prod-prof-desc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    /* or 167% */
    text-align: left;
    color: rgba(0, 0, 0, 0.75);
}
.comp-item-prod-prof-desc p{
font-size: 15px;
text-align: justify;
}
@media only screen and (min-width: 768px) {
    .comp-list-item {
        padding: 25px;
    }
    .comp-list-item-cont {
        width: 50%;
        padding: 10px;
    }
    .comp-list-con {
        flex-direction: row;
    }
    .comp-layout-con {
        padding: 50px;
    }
    .comp-outer-cont {
        flex-direction: row;
    }
    .comp-domain-container {
        padding: 20px;
        width: 25%;
    }
    .comp-domains {
        display: block;
    }
    .comp-common-title {
        font-size: 18px;
    }
    .com-dir-cont {
        width: 75%;
        padding: 20px;
    }
}
.dash-banner {
    background-image: url(/static/media/userdashbanner.989062ce.png);
    width: 100%;
    height: 100px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
}

.wid {
    width: auto!important;
}

.basic-submit {
    margin: 0px!important;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mycom .form-bg {
    background: #EBE9E9;
    padding: 15px;
}

.page-bg {
    background: #EBE9E9;
}

.section-Cont {
    background-color: #F3F8FF;
    padding: 20px 20px;
    margin-bottom: 15px;
    /* margin: 20px 0px; */
    border: 1px solid rgba(85, 85, 85, 0.5);
}

.items-cont {
    padding: 10px;
}

.to-left-side {
    display: flex!important;
    align-items: center!important;
    justify-content: flex-start!important;
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}

.form-section {
    background: white;
    width: 100%;
}

.fileContainer {
    display: flex
}

.more-btn-cnt {
    display: flex;
    flex-direction: row-reverse;
}

.chk-items-cont {
    padding: 10px
}

.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile:focus+label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
    pointer-events: none;
}

.secondary-btn {
    background: #F76C6C;
    padding: 10px 25px;
    margin: 0px 10px;
    cursor: pointer;
    border-radius: 4px;
    color: white;
}

thead {
    background: rgb(255, 255, 255)!important;
    color: #0A223D!important;
}

.btn-primary {
    background: #1C2541!important;
    border: none;
    cursor: pointer;
}

.primary-btn {
    background: #1C2541;
    padding: 10px 27px;
    margin: 0px 10px;
    color: white;
    cursor: pointer;
    border-radius: 3px;
}

.optionLabelHeader-cont {
    color: black;
    font-weight: 500;
    height: 29px;
    /* padding: 0px 20px; */
    line-height: 29px;
}

.tableHeading {
    background: #0A223D;
    color: white;
    height: 25px;
    line-height: 25px;
    padding: 0px 15px;
}

.table-list-cont {
    margin: 20px 10px;
}

.header-body-spacing {
    margin-top: 20px;
}

.header-helper-text {
    text-align: right;
    color: red;
    font-weight: 700;
}

.headerbuttonodd {
    height: 40px;
    background: #E3ECEF;
    margin-right: 50px;
    color: #000;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.headerbuttonodd.active {
    height: 40px;
    background: #0A223D;
    margin-right: 50px;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.indexCont {
    background: #14A5D3;
    width: 30px!important;
    text-align: center;
    border-radius: 50%;
    margin: auto 10px;
    color: white;
    height: 30px!important;
    line-height: 30px;
}

.indexCont.even {
    margin-left: -15px;
    z-index: 1;
}

.headerbuttonodd.active:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #0A223D;
}

.headerbuttonodd:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #E3ECEF;
}

.headerbuttoneven {
    height: 40px;
    background: #E3ECEF;
    display: flex;
    color: #000;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #E3ECEF;
    border-left: 20px solid transparent;
}

.headerbuttoneven.active {
    height: 40px;
    background: #0A223D;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven.active:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #0A223D;
    border-left: 20px solid transparent
}

.form-inner-section {
    padding: 12px 0px 20px 0px;
    width: 100%;
}

.fieldset {
    margin: 15px 0px;
}

.file-up-helper {
    color: red;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 14px;
}

.section-datas-cont {
    /* margin: 17px auto; */
}

.file-up-label-cont {
    margin-bottom: -30px;
    margin-top: 11px;
    color: #1C2541!important;
    font-weight: 600;
    line-height: 17.75px;
}

.header-label {
    margin-top: 0px;
    margin-bottom: 14px;
    font-weight: 600;
    color: #1C2541;
}

.view-container {
    padding: 25px 20px 25px 40px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.data .row {
    padding: 7px 5px;
    background: #E7E7E7;
    margin: 0 0 10px 0;
}

.data .row .col-8 {
    color: black;
    font-size: 16px;
}

.data .row .col-3 {
    color: #666;
    font-size: 16px;
}

.mysubbtn:hover {
    color: white;
}

.nio {
    color: white!important;
    font-weight: 300;
    font-size: 15px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px!important;
    font-weight: 400!important;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.text-justify {
    text-align: justify;
}

.dwnld {
    color: #F76C6C;
    font-weight: bold;
    text-decoration: underline;
}

.data .row .col-8 table {
    background-color: white!important;
    margin-top: 0px!important;
}

.venture {
    background-color: white!important;
    padding-top: 10px;
}

.data .current {
    background-color: #0a223d!important;
    color: white!important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white!important;
}

.status label {
    font-size: 16px;
}

.mysubbtn {
    margin-top: 19px;
    background: #0a223d!important;
    color: white;
    padding: 6px 66px;
}

.filex {
    margin-top: -13px;
}

.indicator {
    color: red;
}

.headerbuttoneven {
    font-size: 12px;
}

.headerbuttonodd {
    font-size: 12px;
}

.accordion-body {
    padding: 1rem 0rem;
}

.scrolltable {
    overflow: hidden;
    overflow-x: auto;
    padding: 0px;
}

.ownlad {
    padding: 0px 14px;
}

.jointv {
    padding: 0px 14px;
}

.bg-col {
    background-color: #d8e6eca1!important;
}


/* ================================================================================================================================== */

@media (min-width:992px) {
    .form-section {
        width: 921px!important;
    }
    .items-cont {
        padding: 50px!important;
    }
    .form-bg {
        padding: 50px!important;
    }
    .bg-col {
        background-color: #d8e6eca1!important;
    }
    .headerbuttoneven {
        font-size: 14px!important;
    }
    .headerbuttonodd {
        font-size: 14px!important;
    }
    .accordion-body {
        padding: 12px 10px!important;
    }
    .ownd textarea {
        height: 110px!important;
    }
    .table-list-cont {
        margin: 20px;
    }
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
    }
}
.dash-banner {
    background-image: url(/static/media/userdashbanner.989062ce.png);
    width: 100%;
    height: 100px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
}

.basic-submit {
    margin: 0px !important;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mycom .form-bg {
    background: #EBE9E9;
    padding: 15px;
    max-width: 6px 0px;
}

.page-bg {
    background: #EBE9E9;
}

.section-Cont {
    background-color: #F3F8FF;
    padding: 20px 20px;
    margin-bottom: 15px;
    /* margin: 20px 0px; */
    border: 1px solid rgba(85, 85, 85, 0.5);
}

.items-cont {
    padding: 50px 50px 10px 50px !important;
}

.to-left-side {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}

.form-section {
    background: white;
    width: 100%;
}

.fileContainer {
    display: flex
}

.more-btn-cnt {
    display: flex;
    flex-direction: row-reverse;
}

.chk-items-cont {
    padding: 10px
}

.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile:focus+label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
    pointer-events: none;
}

.secondary-btn {
    background: #F76C6C;
    padding: 10px 25px;
    margin: 0px 10px;
    cursor: pointer;
    border-radius: 4px;
    color: white;
}

thead {
    background: rgb(255, 255, 255) !important;
    color: #0A223D !important;
}

.btn-primary {
    background: #1C2541 !important;
    border: none;
    cursor: pointer;
}

.primary-btn {
    background: #1C2541;
    padding: 10px 27px;
    margin: 0px 10px;
    color: white;
    cursor: pointer;
    border-radius: 3px;
}

.optionLabelHeader-cont {
    color: black;
    font-weight: 500;
    height: 29px;
    /* padding: 0px 20px; */
    line-height: 29px;
}

.tableHeading {
    background: #0A223D;
    color: white;
    height: 25px;
    line-height: 25px;
    padding: 0px 15px;
}

.table-list-cont {
    margin: 20px 10px;
}

.header-body-spacing {
    margin-top: 20px;
}

.header-helper-text {
    text-align: right;
    color: red;
    font-weight: 700;
}

.headerbuttonodd {
    height: 40px;
    background: #E3ECEF;
    margin-right: 50px;
    color: #000;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.headerbuttonodd.active {
    height: 40px;
    background: #0A223D;
    margin-right: 50px;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.indexCont {
    background: #14A5D3;
    width: 30px !important;
    text-align: center;
    border-radius: 50%;
    margin: auto 6px;
    color: white;
    height: 30px !important;
    line-height: 30px;
}

.indexCont.even {
    margin-left: -15px;
    z-index: 1;
}

.headerbuttonodd.active:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #0A223D;
}

.headerbuttonodd:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #E3ECEF;
}

.headerbuttoneven {
    height: 40px;
    background: #E3ECEF;
    display: flex;
    color: #000;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 0px !important;
}

.headerbuttoneven:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #E3ECEF;
    border-left: 20px solid transparent;
}

.headerbuttoneven.active {
    height: 40px;
    background: #0A223D;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven.active:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #0A223D;
    border-left: 20px solid transparent
}

.form-inner-section {
    padding: 12px 0px 0px 0px;
    width: 100%;
}

.fieldset {
    margin: 15px 0px;
}

.file-up-helper {
    padding-top: 7px;
    color: red;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 14px;
}

.section-datas-cont {
    /* margin: 17px auto; */
}

.file-up-label-cont {
    margin-bottom: -30px;
    margin-top: 11px;
    color: #1C2541 !important;
    font-weight: 600;
    line-height: 17.75px;
}

.header-label {
    margin-top: 0px;
    margin-bottom: 14px;
    font-weight: 600;
    color: #1C2541;
}

.view-container {
    padding: 25px 20px 25px 40px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.data .row {
    padding: 7px 5px;
    background: #E7E7E7;
    margin: 0 0 10px 0;
}

.data .row .col-8 {
    color: black;
    font-size: 16px;
}

.data .row .col-3 {
    color: #666;
    font-size: 16px;
}

.mysubbtn:hover {
    color: white;
}

.nio {
    color: white !important;
    font-weight: 300;
    font-size: 15px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px !important;
    font-weight: 400 !important;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.text-justify {
    text-align: justify;
}

.dwnld {
    color: #F76C6C;
    font-weight: bold;
    text-decoration: underline;
}

.data .row .col-8 table {
    background-color: white !important;
    margin-top: 0px !important;
}

.venture {
    background-color: white !important;
    padding-top: 10px;
}

.data .current {
    background-color: #0a223d !important;
    color: white !important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white !important;
}

.status label {
    font-size: 16px;
}

.mysubbtn {
    margin-top: 19px;
    background: #0a223d !important;
    color: white;
    padding: 6px 66px;
}

.filex {
    margin-top: -13px;
}

.indicator {
    color: red;
}

.headerbuttoneven {
    font-size: 12px;
}

.headerbuttonodd {
    font-size: 12px;
}

.accordion-body {
    padding: 1rem 0rem;
}

.scrolltable {
    overflow: hidden;
    overflow-x: auto;
    padding: 0px;
}

.ownlad {
    padding: 0px 14px;
}

.jointv {
    padding: 0px 14px;
}

.bg-col {
    background-color: #d8e6eca1 !important;
}


/* ================================================================================================================================== */

@media (min-width:992px) {
    .form-section {
        width: 921px !important;
    }

    .items-cont {
        padding: 50px 50px 0px 50px !important;
    }

    .form-bg {
        padding: 50px !important;
    }

    .bg-col {
        background-color: #d8e6eca1 !important;
    }

    .headerbuttoneven {
        font-size: 14px !important;
    }

    .headerbuttonodd {
        font-size: 14px !important;
    }

    .accordion-body {
        padding: 12px 10px !important;
    }

    .ownd textarea {
        height: 110px !important;
    }

    .table-list-cont {
        margin: 20px;
    }

    .dash-banner {
        height: 162px;
    }

    .tit-txt {
        font-size: 30px;
    }
}
.dash-banner {
    background-image: url(/static/media/userdashbanner.989062ce.png);
    width: 100%;
    height: 100px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.tit-txt {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
}

.basic-submit {
    margin: 0px!important;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mycom .form-bg {
    background: #EBE9E9;
    padding: 15px;
}

.page-bg {
    background: #EBE9E9;
}

.section-Cont {
    background-color: #F3F8FF;
    padding: 20px 20px;
    margin-bottom: 15px;
    /* margin: 20px 0px; */
    border: 1px solid rgba(85, 85, 85, 0.5);
}

.items-cont {
    padding: 10px;
}

.to-left-side {
    display: flex!important;
    align-items: center!important;
    justify-content: flex-start!important;
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}

.form-section {
    background: white;
    width: 100%;
}

.fileContainer {
    display: flex
}

.more-btn-cnt {
    display: flex;
    flex-direction: row-reverse;
}

.chk-items-cont {
    padding: 10px
}

.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile:focus+label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile+label * {
    pointer-events: none;
}

.secondary-btn {
    background: #F76C6C;
    padding: 10px 18px;
    margin: 0px 10px;
    cursor: pointer;
    border-radius: 4px;
    color: white;
}

thead {
    background: rgb(255, 255, 255)!important;
    color: #0A223D!important;
}

.btn-primary {
    background: #1C2541!important;
    border: none;
    cursor: pointer;
}

.primary-btn {
    background: #1C2541;
    padding: 10px 23px;
    margin: 0px 10px;
    color: white;
    cursor: pointer;
    border-radius: 3px;
}

.optionLabelHeader-cont {
    color: black;
    font-weight: 500;
    height: 29px;
    /* padding: 0px 20px; */
    line-height: 29px;
}

.tableHeading {
    background: #0A223D;
    color: white;
    height: 25px;
    line-height: 25px;
    padding: 0px 15px;
}

.table-list-cont {
    margin: 20px 10px;
}

.header-body-spacing {
    margin-top: 20px;
}

.header-helper-text {
    text-align: right;
    color: red;
    font-weight: 700;
}

.headerbuttonodd {
    height: 40px;
    background: #E3ECEF;
    margin-right: 50px;
    color: #000;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.headerbuttonodd.active {
    height: 40px;
    background: #0A223D;
    margin-right: 50px;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
}

.indexCont {
    background: #14A5D3;
    width: 30px!important;
    text-align: center;
    border-radius: 50%;
    margin: auto 10px;
    color: white;
    height: 30px!important;
    line-height: 30px;
}

.indexCont.even {
    margin-left: -15px;
    z-index: 1;
}

.headerbuttonodd.active:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #0A223D;
}

.headerbuttonodd:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 20px dotted transparent;
    border-left: 20px solid #E3ECEF;
}

.headerbuttoneven {
    height: 40px;
    background: #E3ECEF;
    display: flex;
    color: #000;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #E3ECEF;
    border-left: 20px solid transparent;
}

.headerbuttoneven.active {
    height: 40px;
    background: #0A223D;
    color: #fff;
    display: flex;
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}

.headerbuttoneven.active:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 100%;
    top: 0;
    border: 20px solid #0A223D;
    border-left: 20px solid transparent
}

.form-inner-section {
    padding: 12px 0px 20px 0px;
    width: 100%;
}

.fieldset {
    margin: 15px 0px;
}

.file-up-helper {
    color: red;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 14px;
}

.section-datas-cont {
    /* margin: 17px auto; */
}

.file-up-label-cont {
    margin-bottom: -30px;
    margin-top: 11px;
    color: #1C2541!important;
    font-weight: 600;
    line-height: 17.75px;
}

.header-label {
    margin-top: 0px;
    margin-bottom: 14px;
    font-weight: 600;
    color: #1C2541;
}

.view-container {
    padding: 25px 20px 25px 40px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.data .row {
    padding: 7px 5px;
    background: #E7E7E7;
    margin: 0 0 10px 0;
}

.data .row .col-8 {
    color: black;
    font-size: 16px;
}

.data .row .col-3 {
    color: #666;
    font-size: 16px;
}

.mysubbtn:hover {
    color: white;
}

.nio {
    color: white!important;
    font-weight: 300;
    font-size: 15px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px!important;
    font-weight: 400!important;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.text-justify {
    text-align: justify;
}

.dwnld {
    color: #F76C6C;
    font-weight: bold;
    text-decoration: underline;
}

.data .row .col-8 table {
    background-color: white!important;
    margin-top: 0px!important;
}

.venture {
    background-color: white!important;
    padding-top: 10px;
}

.data .current {
    background-color: #0a223d!important;
    color: white!important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white!important;
}

.status label {
    font-size: 16px;
}

.mysubbtn {
    margin-top: 19px;
    background: #0a223d!important;
    color: white;
    padding: 6px 66px;
}

.filex {
    margin-top: -13px;
}

.indicator {
    color: red;
}

.headerbuttoneven {
    font-size: 12px;
}

.headerbuttonodd {
    font-size: 12px;
}

.accordion-body {
    padding: 1rem 0rem;
}

.scrolltable {
    overflow: hidden;
    overflow-x: auto;
    padding: 0px;
}

.ownlad {
    padding: 0px 14px;
}

.jointv {
    padding: 0px 14px;
}

.bg-col {
    background-color: #d8e6eca1!important;
}

.wid {
    width: auto!important;
}


/* ================================================================================================================================== */

@media (min-width:992px) {
    .form-section {
        width: 921px!important;
    }
    .items-cont {
        padding: 18px 50px 11px 50px!important;
    }
    .form-bg {
        padding: 38px!important;
    }
    .bg-col {
        background-color: #d8e6eca1!important;
    }
    .headerbuttoneven {
        font-size: 14px!important;
    }
    .headerbuttonodd {
        font-size: 14px!important;
    }
    .accordion-body {
        padding: 12px 10px!important;
    }
    .ownd textarea {
        height: 120px!important;
    }
    .table-list-cont {
        margin: 20px;
    }
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
    }
}
.admin-dash-data-tiles {
    display: flex;
    flex-wrap: wrap;
}
    .dash-tile-container {
        width: 250px;
        height: 76px;
        padding: 10px;
        background-color: white;
        box-shadow: 0px 1px 20px 4px #0000001c;
        display: flex;
        margin: 30px;
        /* text-align: center; */
        border-radius: 5px;
        /* justify-content: center; */
        align-items: center;
        cursor: pointer;
    }

    .dash-tile-container.even {
        background-color: #1C2541;

    }

    .dash-tile-data {
        display: flex;
        padding-left: 10px;
        width: 180px;
        flex-direction: column;
    }

    .tile-icon {
        font-size: 25px;
        color: #25CDC7;
        display: flex;
    }

    .tile-icon.even {
        color: white
    }

    .dash-tile-count {
        font-size: 20px;
        font-weight: 700;
        color: black;
    }

    .dash-tile-count.even {
        color: white;
    }

    .dash-tile-label {
        font-size: 15px;
        font-weight: 600;
        color: black;
    }

    .dash-tile-label.even {
        color: white;
    }

    .dashdataitems {
        display: flex;
        justify-content: flex-end;
    }

    .dash-icon-container {
        height: 47px;
        width: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        border-radius: 8px;
        background: #0A223D;
    }

    .dash-icon-container.even {
        background: #14A5D3;
    }
.error {
    background: #dc3545 !important;
    border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
    height: 220px;
}

.snackTitle {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    color: #184688;
    margin: 10px;
}

.snackIcon {
    font-size: 150px;
    transform: translate(75px, 35px);
    color: white;
}

.success {
    background: #35b651 !important;
    border-radius: 10px 10px 0px 0px;
}

.btn-closex {
    background-color: #0a223d;
    padding: 4px 32px;
    margin-top: 11px;
    color: white;
}

.iconContaier {
    color: white;
}

.w-100 {
    width: 100%!important;
}

.msgbox {
    display: flex;
    flex-direction: column;
    border: 1px solid #777;
    padding: 0px;
    min-width: 414px;
    min-height: 330px;
    max-height: 330px;
    overflow: hidden;
    overflow-y: auto;
}

.adminbox small,
.superbox small {
    font-size: 14px;
    font-weight: 600;
}

.adminbox p,
.superbox p {
    font-family: mulish;
    margin-bottom: 1px;
    font-size: 13px;
}

.superbox {
    background-color: #C5E6F0;
    width: calc(100% - 77px);
    border-radius: 4px 0px 0px 4px;
    float: right;
    padding: 0px 5px;
    margin-top: 10px;
}

.adminbox {
    background-color: #cfd3f2;
    width: calc(100% - 77px);
    border-radius: 0px 4px 4px 0px;
    padding: 0px 5px;
    margin-top: 10px;
}

.superbox p {
    text-align: right;
}

.superbox small {
    width: 100%;
    float: right;
    text-align: right;
}

.big-dia1 {
    background: #ffffff;
    position: fixed;
    box-shadow: 1px 1px 20px 5px #000000a1;
    top: 50%;
    left: 50%;
    width: 450px;
    height: 460px;
    transform: translate(-50%, -50%);
    padding: 30px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.overlay {
    background: #000000b0;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 999;
    transform: translate(0%, 0%);
}

.messageContainer {
    margin: 0;
    padding: 0px 20px;
    height: 70px;
    overflow: auto;
    text-align: center;
}

.buttonContainer {
    justify-content: center;
    /* padding: 14px; */
    display: flex;
}

.snackButtonClass {
    width: 125px;
    color: white;
    height: 50px;
    /* box-shadow: 0 0 20px 0px #0000005e; */
    font-weight: 600;
    border: none;
    border-radius: 50px;
}

.book-head {
    font-family: "Mulish";
    font-weight: 600;
    line-height: 21px;
}

.tilebar1 {
    display: flex;
    margin: 5px 0px 10px 0px;
    flex-direction: column;
    align-items: center;
}

.diaCloseCon {
    /* padding: 20px; */
    float: right;
    font-size: 20px;
    font-weight: 900;
    line-height: 1;
    cursor: pointer;
}

.flexDisplay {
    display: flex;
}

.diaTitleCon1 {
    /* padding: 20px 0px 0px 20px; */
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    line-height: 1;
    padding-right: 0px;
}

.tilebar hr {
    margin: 1px !important;
    height: 2px !important;
    width: 51px;
    opacity: 1;
    color: #14a5d3;
}

.dialog1 input {
    width: 100%;
}

.dialog1 label {
    font-size: 13px;
    font-weight: 500;
}

.book-form div {
    margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
    line-height: 30px;
    border-radius: 4px;
    padding-right: 0;
}

.book-form button {
    margin-top: 15px;
}

.dialog1 .form-select {
    font-size: 13px;
    box-shadow: inset 2.5px 0px 0px #ee7e7e;
}
      .overlay {
          background: #000000b0;
          position: fixed;
          top: 0%;
          left: 0%;
          width: 100%;
          height: 100%;
          z-index: 999;
          transform: translate(0%, 0%);
      }
      
      .action-dialog-cont {
          background: #ffffff;
          position: fixed;
          border-radius: 10px;
          box-shadow: 1px 1px 20px 5px #000000a1;
          top: 50%;
          left: 50%;
          padding: 20px;
          /* width: 400px;
          height: 200px; */
          transform: translate(-50%, -50%);
      }
      
      .action-title-cont {
          font-weight: 700;
          font-size: 17px;
          padding-bottom: 20px;
      }
      
      .action-msg {
          font-size: 14px;
          font-family: 'Mulish';
          height: 60px;
          font-weight: 600;
      }
      
      .actn-btn-cntr-cont {
          display: flex;
          justify-content: flex-end;
      }
      
      .act-btn {
          margin-left: 20px;
      }
.view-container-comp-1 {
    padding: 25px;
}

.view-container h5 b {
    color: #0a223d;
}

.mb-01 .fieldset {
    margin-bottom: 0px;
}

.font-z select {
    font-size: 14px;
}

.data .row {
    padding: 7px 5px;
    background: #E7E7E7;
    margin: 0 0 10px 0;
}

.back-g .nox .row {
    background-color: transparent!important;
}

.data .row .col-7 {
    color: black;
    font-size: 16px;
}

.data .row .col-3 {
    color: #666;
    font-size: 16px;
}

.mysubbtn:hover {
    color: white;
}

.data .nio {
    color: white !important;
    font-weight: 300;
    font-size: 15px;
    background: #0a223d!important;
}

.blink_me1 {
    animation: blinker 1s linear infinite;
    font-size: 17px !important;
    /* color: white !important; */
    font-weight: 600 !important;
    text-transform: uppercase;
    color: #0a223d!important;
    letter-spacing: 2px;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.dwnld span {
    margin-right: 3px;
    background: #1C2541;
    padding: 3px 5px;
    border-radius: 18px;
}

.dwnld span svg {
    margin-top: -5px;
}

.btn-warning1 {
    background-color: #F2994A;
}

.dwnld {
    color: black !important;
    text-decoration: none!important;
}

.down {
    color: #14A5D3 !important;
}

.text-justify {
    text-align: justify;
}

.dwnld {
    color: #F76C6C;
    font-weight: bold;
    text-decoration: underline;
}

.data .row .col-8 table {
    background-color: white !important;
    margin-top: 0px !important;
}

.venture {
    background-color: white !important;
    padding-top: 10px;
}

.data .current {
    background-color: #0a223d !important;
    color: white !important;
    font-size: 15px;
}

.data .current .col-2 {
    color: white !important;
}

.status label {
    font-size: 16px;
}

.mysubbtn {
    margin-top: 19px;
    background: #0a223d !important;
    color: white;
    padding: 6px 66px;
}

.indicator {
    color: red;
}
.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  /* width: 500px; */
  /* height: 460px; */
  transform: translate(-50%, -50%);
  padding: 30px;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebar {
  display: flex;
  margin: 5px 0px 15px 0px;
  /* flex-direction: column; */
  align-items: center;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebar hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
}

.book-form button {
  margin-top: 15px;
}

.content-scroll {
  max-height: 320px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0px 1px;
}

.not-required .fieldset .input-group.mandatory .form-control {
  box-shadow: none;
}

.view-container {
  padding: 25px 20px 25px 40px;
}
.view-container h5 b {
  color: #0a223d;
}
.font-z select {
  font-size: 14px;
}
.btn-primary {
  background-color: #1c2541 !important;
  border: none;
  padding: 6px 25px;
}
.domain-btn {
  float: right;
  background-color: #14a5d3 !important;
}
.inputfile {
  border: 1px solid #ced4da;
}

.uploadsmall {
  color: red;
}
.action-dialog {
  max-height: 185px !important;
}
.action-msg {
  margin-bottom: 0 !important;
}

.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia-scroll {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 500px;
  /* height: 560px; */

  height: auto;
  transform: translate(-50%, -50%);
  padding: 30px;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebar {
  display: flex;
  margin: 5px 0px 10px 0px;
  /* flex-direction: column; */
  align-items: center;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebar hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
}

.book-form button {
  margin-top: 15px;
}

.not-required .fieldset .input-group.mandatory .form-control {
  box-shadow: none;
}
.rmdp-container {
  width: 100% !important;
}
.rmdp-calendar {
  height: 365px;
}

.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.tender {
  min-height: calc(100vh - 428px);
}

.bg-tender {
  background: #f3f8ff;
}

.vx {
  font-size: 18px !important;
}

.ix {
  font-family: Mulish;
  font-style: normal;
  color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
}

.tix {
  font-size: 30px;
  color: white;
  font-weight: 600;
}

.container-ul {
  display: flex;
  margin: 5px 0px 10px 0px;
  flex-direction: column;
  align-items: center;
}

/* .hr-tenders {
  margin: 1px !important;
  height: 3px !important;
  width: 35px;
  opacity: 1;
  color: #14a5d3;
} */

.top {
  margin-left: 0px !important;
}

.bottom {
  margin-left: 20px !important;
}

.tender-head {
  padding: 15px 0px;
}

.tender-head h4 {
  text-align: center;
  margin-bottom: 0px;
}

.m-30 {
  margin: auto 30px;
}

.p-30 {
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    -2px 1px 11px -5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.p-50 {
  padding: 50px 70px;
}

.tender-box {
  margin: auto 88px;
}

.tender-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.tender-box .head {
  text-transform: uppercase;
}

.tender-box p {
  font-family: Mulish !important;
  margin-bottom: 5px;
}

.blink_me {
  animation: blinker 1s linear infinite;
  font-size: 17px !important;
  color: black !important;
  font-weight: 400 !important;
  margin-top: -4px;
}

@keyframes blinker {
  30% {
    opacity: 0;
  }
}

.label {
  align-self: baseline;
}

.label p {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  font-weight: 600;
  color: #444;
}

.contentx p {
  font-size: 15px;
  font-weight: 600;
}

.tender-datas {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.bg-white {
  padding-bottom: 35px;
  padding-top: 65px;
}

.break_link {
  word-break: break-all;
  cursor: pointer;
}
.parserfont p {
  font-size: 13px;
  font-weight: inherit;
  margin: 0px;
}

@media only screen and (max-width: 992px) {
  .tender-box {
    margin: auto 30px;
  }
  .tender-datas {
    flex-direction: column;
    display: unset;
  }
  /* .dash-banner .tit-txt {
    font-size: 18px!important;
} */
  .tender-box p {
    text-align: center;
    letter-spacing: 0px;
    overflow-wrap: anywhere;
  }
  .bg-white {
    padding-top: 35px;
  }
  .p-50 {
    padding: 22px 16px;
  }
  .contentx p {
    font-size: 14px;
    font-weight: 700;
  }
  .p-30 {
    padding: 19px;
  }
  .overf {
    overflow: hidden;
  }
  .tix {
    font-size: 19px;
  }
  .note p {
    text-align: justify;
  }
}

.tender {
  min-height: calc(100vh - 428px);
}

.bg-tender {
  background: #f3f8ff;
}

.vx {
  font-size: 18px !important;
}

.ix {
  font-family: Mulish;
  font-style: normal;
  color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
}

.tix {
  font-size: 30px;
  color: white;
  font-weight: 600;
}

.container-ul {
  display: flex;
  margin: 5px 0px 10px 0px;
  flex-direction: column;
  align-items: center;
}

/* .hr-tenders {
  margin: 1px !important;
  height: 3px !important;
  width: 35px;
  opacity: 1;
  color: #14a5d3;
} */

.top {
  margin-left: 0px !important;
}

.bottom {
  margin-left: 20px !important;
}

.tender-head {
  padding: 15px 0px;
}

.tender-head h4 {
  text-align: center;
  margin-bottom: 0px;
}

.m-30 {
  margin: auto 30px;
}

.p-30 {
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    -2px 1px 11px -5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.p-50 {
  padding: 50px 70px;
}

.tender-box {
  margin: auto 88px;
}

.tender-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.tender-box .head {
  text-transform: uppercase;
}

.tender-box p {
  font-family: Mulish !important;
  margin-bottom: 5px;
}

.blink_me {
  animation: blinker 1s linear infinite;
  font-size: 17px !important;
  color: black !important;
  font-weight: 400 !important;
  margin-top: -4px;
}

@keyframes blinker {
  30% {
    opacity: 0;
  }
}

.label {
  align-self: baseline;
}

.label p {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  font-weight: 600;
  color: #444;
}

.contentx p {
  font-size: 15px;
  font-weight: 600;
}

.tender-datas {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.bg-white {
  padding-bottom: 35px;
  padding-top: 65px;
}

.break_link {
  word-break: break-all;
  cursor: pointer;
}
.parserfont p {
  font-size: 13px;
  font-weight: inherit;
  margin: 0px;
}

@media only screen and (max-width: 992px) {
  .tender-box {
    margin: auto 30px;
  }
  .tender-datas {
    flex-direction: column;
    display: unset;
  }
  /* .dash-banner .tit-txt {
    font-size: 18px!important;
} */
  .tender-box p {
    text-align: center;
    letter-spacing: 0px;
    overflow-wrap: anywhere;
  }
  .bg-white {
    padding-top: 35px;
  }
  .p-50 {
    padding: 22px 16px;
  }
  .contentx p {
    font-size: 14px;
    font-weight: 700;
  }
  .p-30 {
    padding: 19px;
  }
  .overf {
    overflow: hidden;
  }
  .tix {
    font-size: 19px;
  }
  .note p {
    text-align: justify;
  }
}

.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia-scroll {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 500px;
  /* height: 560px; */

  height: auto;
  transform: translate(-50%, -50%);
  padding: 30px;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebar {
  display: flex;
  margin: 5px 0px 10px 0px;
  /* flex-direction: column; */
  align-items: center;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebar hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
}

.book-form button {
  margin-top: 15px;
}

.not-required .fieldset .input-group.mandatory .form-control {
  box-shadow: none;
}
.rmdp-container {
  width: 100% !important;
}
.rmdp-calendar {
  height: 365px;
}

.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia-scroll {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 500px;
  /* height: 560px; */

  height: auto;
  transform: translate(-50%, -50%);
  padding: 30px;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebar {
  display: flex;
  margin: 5px 0px 10px 0px;
  /* flex-direction: column; */
  align-items: center;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebar hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
}

.book-form button {
  margin-top: 15px;
}

.not-required .fieldset .input-group.mandatory .form-control {
  box-shadow: none;
}
.rmdp-container {
  width: 100% !important;
}
.rmdp-calendar {
  height: 365px;
}

.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
p{font-family: mulish;font-size: 16px;
    /* margin-top: 20px; */
    color: #444;}
.accominput .fieldset{margin-top: 15px!important;}
.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia-scroll {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 500px;
  /* height: 560px; */

  height: auto;
  transform: translate(-50%, -50%);
  padding: 30px;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebar {
  display: flex;
  margin: 5px 0px 10px 0px;
  /* flex-direction: column; */
  align-items: center;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebar hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
}

.book-form button {
  margin-top: 15px;
}

.not-required .fieldset .input-group.mandatory .form-control {
  box-shadow: none;
}
.rmdp-container {
  width: 100% !important;
}
.rmdp-calendar {
  height: 365px;
}

.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.content-scroll {
  height: 400px;
  overflow: hidden;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia-scope {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 350px;
  transform: translate(-50%, -50%);
  padding: 30px;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebar {
  display: flex;
  margin: 5px 0px 10px 0px;
  /* flex-direction: column; */
  align-items: center;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebar hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
}

.book-form button {
  margin-top: 15px;
}
.content-scroll {
  max-height: 320px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.view-container {
  padding: 25px 20px 25px 40px;
}
.view-container h5 b {
  color: #0a223d;
}
.font-z select {
  font-size: 14px;
}
.btn-primary {
  background-color: #1c2541 !important;
  border: none;
  padding: 6px 25px;
}
table td {
  min-width: 65px !important;
}

.error {
    background: #dc3545 !important;
    border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
    height: 220px;
}

.snackTitle {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    color: #184688;
    margin: 10px;
}

.snackIcon {
    font-size: 150px;
    transform: translate(75px, 35px);
    color: white;
}

.success {
    background: #35b651 !important;
    border-radius: 10px 10px 0px 0px;
}

.iconContaier {
    color: white;
}

.big-dia-quotes {
    background: #ffffff;
    position: fixed;
    box-shadow: 1px 1px 20px 5px #000000a1;
    top: 50%;
    left: 50%;
    width: 500px;
    /* height: 240px; */
    transform: translate(-50%, -50%);
    padding: 30px;
}

.overlay {
    background: #000000b0;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 999;
    transform: translate(0%, 0%);
}

.messageContainer {
    margin: 0;
    padding: 0px 20px;
    height: 70px;
    overflow: auto;
    text-align: center;
}

.buttonContainer {
    justify-content: center;
    /* padding: 14px; */
    display: flex;
}

.snackButtonClass {
    width: 125px;
    color: white;
    height: 50px;
    /* box-shadow: 0 0 20px 0px #0000005e; */
    font-weight: 600;
    border: none;
    border-radius: 50px;
}

.book-head {
    font-family: "Mulish";
    font-weight: 600;
    line-height: 21px;
}

.tilebar {
    display: flex;
    margin: 5px 0px 15px 0px;
    /* flex-direction: column; */
    align-items: center;
}

.diaCloseCon {
    /* padding: 20px; */
    float: right;
    font-size: 20px;
    font-weight: 900;
    line-height: 1;
    cursor: pointer;
}

.flexDisplay {
    display: flex;
}

.diaTitleCon {
    /* padding: 20px 0px 0px 20px; */
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    line-height: 1;
    padding-right: 0px;
}

.tilebar hr {
    margin: 1px !important;
    height: 2px !important;
    width: 51px;
    opacity: 1;
    color: #14a5d3;
}

.dialog input {
    width: 100%;
}

.dialog label {
    font-size: 13px;
    font-weight: 500;
}

.book-form div {
    margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
    line-height: 30px;
    border-radius: 4px;
    box-shadow: inset 2.5px 0px 0px #ee7e7e;
    padding-left: 15px;
}

.book-form button {
    margin-top: 15px;
}

.content-scroll {
    max-height: 320px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 800px;
  /* width: 500px;
  height: 460px; */
  transform: translate(-50%, -50%);
  padding: 30px;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebar {
  display: flex;
  margin: 5px 0px 15px 0px;
  /* flex-direction: column; */
  align-items: center;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebar hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
  padding-right: 0;
}

.book-form button {
  margin-top: 15px;
}

.table-bordered {
  table-layout: fixed;
}
/* .content-scroll {
    max-height: 320px;
    overflow-y: scroll;
    overflow-x: hidden;
} */

.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 500px;
  /* height: 460px; */
  transform: translate(-150px, -212px);
  padding: 30px;
}
.big-dia8 {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 500px;
  /* height: 460px; */
  transform: translate(-150px, -212px);
  padding: 30px;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebar {
  display: flex;
  margin: 5px 0px 15px 0px;
  /* flex-direction: column; */
  align-items: center;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebar hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
  padding-right: 0;
}

.book-form button {
  margin-top: 15px;
}
.dialog .form-select {
  font-size: 13px;
  box-shadow: inset 2.5px 0px 0px #ee7e7e;
}

.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.error {
    background: #dc3545 !important;
    border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
    height: 220px;
}

.snackTitle {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    color: #184688;
    margin: 10px;
}

.snackIcon {
    font-size: 150px;
    transform: translate(75px, 35px);
    color: white;
}

.success {
    background: #35b651 !important;
    border-radius: 10px 10px 0px 0px;
}

.iconContaier {
    color: white;
}

.som-big-dia {
    background: #ffffff;
    position: fixed;
    box-shadow: 1px 1px 20px 5px #000000a1;
    top: 50%;
    left: 50%;
    width: 500px;
    /* height: 480px; */
    transform: translate(-50%, -50%);
    padding: 30px;
}

.overlay {
    background: #000000b0;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 999;
    transform: translate(0%, 0%);
}

.messageContainer {
    margin: 0;
    padding: 0px 20px;
    height: 70px;
    overflow: auto;
    text-align: center;
}

.buttonContainer {
    justify-content: center;
    /* padding: 14px; */
    display: flex;
}

.snackButtonClass {
    width: 125px;
    color: white;
    height: 50px;
    /* box-shadow: 0 0 20px 0px #0000005e; */
    font-weight: 600;
    border: none;
    border-radius: 50px;
}

.book-head {
    font-family: "Mulish";
    font-weight: 600;
    line-height: 21px;
}

.tilebar {
    display: flex;
    margin: 5px 0px 15px 0px;
    /* flex-direction: column; */
    align-items: left !important;
    justify-content: left !important;
}

.diaCloseCon {
    /* padding: 20px; */
    float: right;
    font-size: 20px;
    font-weight: 900;
    line-height: 1;
    cursor: pointer;
}

.flexDisplay {
    display: flex;
}

.diaTitleCon {
    /* padding: 20px 0px 0px 20px; */
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    line-height: 1;
    padding-right: 0px;
}

.tilebar hr {
    margin: 1px !important;
    height: 2px !important;
    width: 51px;
    opacity: 1;
    color: #14a5d3;
}

.dialog input {
    width: 100%;
}

.dialog label {
    font-size: 13px;
    font-weight: 500;
}

.book-form div {
    margin-bottom: 6px;
}

.book-form .fieldset .form-control {
    line-height: 30px;
    border-radius: 4px;
    box-shadow: inset 2.5px 0px 0px #ee7e7e;
    padding-left: 15px;
    border: 1px solid #b8b9ba;
    box-sizing: border-box;
    border-radius: 3px !important;
    font-weight: normal;
    font-size: 13px;
    padding: 0 8px;
}

.book-form button {
    margin-top: 15px;
}


/* .content-scroll {
  max-height: 320px;
  overflow-y: scroll;
  overflow-x: hidden;
} */

.checkboxStatus input {
    width: auto !important;
    margin: 15px 3px !important;
}
.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.error {
    background: #dc3545 !important;
    border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
    height: 220px;
}

.snackTitle {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    color: #184688;
    margin: 10px;
}

.snackIcon {
    font-size: 150px;
    transform: translate(75px, 35px);
    color: white;
}

.success {
    background: #35b651 !important;
    border-radius: 10px 10px 0px 0px;
}

.iconContaier {
    color: white;
}

.som-big-dia {
    background: #ffffff;
    position: fixed;
    box-shadow: 1px 1px 20px 5px #000000a1;
    top: 50%;
    left: 50%;
    width: 500px;
    /* height: 480px; */
    transform: translate(-50%, -50%);
    padding: 30px;
}

.overlay {
    background: #000000b0;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 999;
    transform: translate(0%, 0%);
}

.messageContainer {
    margin: 0;
    padding: 0px 20px;
    height: 70px;
    overflow: auto;
    text-align: center;
}

.buttonContainer {
    justify-content: center;
    /* padding: 14px; */
    display: flex;
}

.snackButtonClass {
    width: 125px;
    color: white;
    height: 50px;
    /* box-shadow: 0 0 20px 0px #0000005e; */
    font-weight: 600;
    border: none;
    border-radius: 50px;
}

.book-head {
    font-family: "Mulish";
    font-weight: 600;
    line-height: 21px;
}

.tilebar {
    display: flex;
    margin: 5px 0px 15px 0px;
    /* flex-direction: column; */
    align-items: left !important;
    justify-content: left !important;
}

.diaCloseCon {
    /* padding: 20px; */
    float: right;
    font-size: 20px;
    font-weight: 900;
    line-height: 1;
    cursor: pointer;
}

.flexDisplay {
    display: flex;
}

.diaTitleCon {
    /* padding: 20px 0px 0px 20px; */
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    line-height: 1;
    padding-right: 0px;
}

.tilebar hr {
    margin: 1px !important;
    height: 2px !important;
    width: 51px;
    opacity: 1;
    color: #14a5d3;
}

.dialog input {
    width: 100%;
}

.dialog label {
    font-size: 13px;
    font-weight: 500;
}

.book-form div {
    margin-bottom: 6px;
}

.book-form .fieldset .form-control {
    line-height: 30px;
    border-radius: 4px;
    box-shadow: inset 2.5px 0px 0px #ee7e7e;
    padding-left: 15px;
    border: 1px solid #b8b9ba;
    box-sizing: border-box;
    border-radius: 3px !important;
    font-weight: normal;
    font-size: 13px;
    padding: 0 8px;
}

.book-form button {
    margin-top: 15px;
}


/* .content-scroll {
  max-height: 320px;
  overflow-y: scroll;
  overflow-x: hidden;
} */

.checkboxStatus input {
    width: auto !important;
    margin: 15px 3px !important;
}
.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}

.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.pers{
    color: red;
    font-size: 11px!important;
}
.per{
    color: red;
    font-size: 12px!important;
}
.edu{
    padding: 10px 0px;
    background: azure;
    margin: 0px;
}
.mr-2{margin-right: 10px;}
.mt-00 .table-bordered{
    margin-top: 0px!important;
}
.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}




.view-container {
  padding: 25px 20px 25px 40px;
}
.view-container h5 b {
  color: #0a223d;
}
.font-z select {
  font-size: 14px;
}
.btn-primary {
  background-color: #1c2541 !important;
  border: none;
  padding: 6px 25px;
}
.domain-btn {
  float: right;
  background-color: #14a5d3 !important;
}
.btn-approve {
  background-color: #0a223d;
  color: white;
  padding: 4px 25px;
  margin-left: 10px;
}
.btn-reject {
  background-color: #f76c6c;
  color: white;
  padding: 4px 25px;
  margin-left: 10px;
}
.btn-approve:hover {
  color: white;
}
.blink_me {
  animation: blinker 1s linear infinite;
  font-size: 17px !important;
  color: white !important;
  font-weight: 400 !important;
}

.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.view-container {
    padding: 25px 20px 25px 40px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.btn-primary {
    background-color: #1C2541!important;
    border: none;
    padding: 6px 25px;
}

.domain-btn {
    float: right;
    background-color: #14A5D3!important;
}

.mr-2 {
    margin-right: 10px;
}

.edu {
    padding: 10px 0px;
    background: azure;
}

.img-eve {
    width: 300px!important;
    height: 210px!important;
}

.add-per label {
    font-size: 13px;
    font-weight: 500;
}

.pers {
    color: red;
}

.mt-00 .table-bordered {
    margin-top: 0px!important;
}

.mt-16 {
    margin-top: 16px;
}
.view-container {
    padding: 25px 20px 25px 40px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.btn-primary {
    background-color: #1C2541!important;
    border: none;
    padding: 6px 25px;
}

.domain-btn {
    float: right;
    background-color: #14A5D3!important;
}

.mr-2 {
    margin-right: 10px;
}

.edu {
    padding: 10px 0px;
    background: azure;
}

.img-eve {
    width: 300px!important;
    height: 210px!important;
}

.add-per label {
    font-size: 13px;
    font-weight: 500;
}

.pers {
    color: red;
}

.mt-00 .table-bordered {
    margin-top: 0px!important;
}

.mt-16 {
    margin-top: 16px;
}
.btn-approve-check {
    /* background-color: #0a223d; */
    /* color: white; */
    padding: 4px 15px;
    margin-left: 10px;
}
.btn-approve-check-cross{
    padding: 4px 11px;
    margin-left: 10px;
}
.pad-sub {
    padding: 0px 6px;
}
.view-container {
    padding: 25px 20px 25px 40px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.btn-primary {
    background-color: #1C2541!important;
    border: none;
    padding: 6px 25px;
}

.domain-btn {
    float: right;
    background-color: #14A5D3!important;
}

.mr-2 {
    margin-right: 10px;
}

.edu {
    padding: 10px 0px;
    background: azure;
}

.img-eve {
    width: 300px!important;
    height: 210px!important;
}

.add-per label {
    font-size: 13px;
    font-weight: 500;
}

.pers {
    color: red;
}

.mt-00 .table-bordered {
    margin-top: 0px!important;
}

.mt-16 {
    margin-top: 16px;
}
.view-container {
    padding: 25px 20px 25px 40px;
}

.view-container h5 b {
    color: #0a223d;
}

.font-z select {
    font-size: 14px;
}

.btn-primary {
    background-color: #1C2541!important;
    border: none;
    padding: 6px 25px;
}

.domain-btn {
    float: right;
    background-color: #14A5D3!important;
}

.mr-2 {
    margin-right: 10px;
}

.edu {
    padding: 10px 0px;
    background: azure;
}

.img-eve {
    width: 300px!important;
    height: 210px!important;
}

.add-per label {
    font-size: 13px;
    font-weight: 500;
}

.pers {
    color: red;
}

.mt-00 .table-bordered {
    margin-top: 0px!important;
}

.mt-16 {
    margin-top: 16px;
}
.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia-tender-admin {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 700px;
  height: 560px;
  transform: translate(-50%, -50%);
  padding: 30px;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebars {
  display: flex;
  margin: 5px 0px 15px 0px;
  /* flex-direction: column; */
  align-items: center;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebars hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
  box-shadow: inset 2.5px 0px 0px #ee7e7e;
  padding-left: 15px;
}

.book-form button {
  margin-top: 15px;
}
.content-scroll-xx {
  max-height: 420px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.checkboxStatus input {
  width: auto !important;
  margin: 15px 3px !important;
}

.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia-career-admin {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: auto;
  height: 400px;
  transform: translate(-50%, -50%);
  padding: 30px;
}
.big-dia-career-admin .ql-tooltip{
 position: initial !important;
}


.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebars {
  display: flex;
  margin: 5px 0px 15px 0px;
  /* flex-direction: column; */
  align-items: center;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebars hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
  box-shadow: inset 2.5px 0px 0px #ee7e7e;
  padding-left: 15px;
}

.book-form button {
  margin-top: 15px;
}

.content-scroll-xx {
  max-height: 420px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.checkboxStatus input {
  width: auto !important;
  margin: 15px 3px !important;
}
.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.publication-box {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 500px;

  transform: translate(-50%, -50%);
  padding: 30px;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebar {
  display: flex;
  margin: 5px 0px 15px 0px;
  /* flex-direction: column; */
  align-items: center;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebar hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
  box-shadow: inset 2.5px 0px 0px #ee7e7e;
  padding-left: 15px;
}

.book-form button {
  margin-top: 15px;
}

.checkboxStatus input {
  width: auto !important;
  margin: 15px 3px !important;
}

.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.ml-3{margin-left: 10px;}
.res{
    background: #dde7ee;
    padding:  10px 15px;
    text-align: justify;
}
.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.ml-3{margin-left: 10px;}
.res{
    background: #dde7ee;
    padding:  10px 15px;
    text-align: justify;
}
.mandatory-select{
    border: 1px solid #b8b9ba70 !important;
    border-radius: 5px !important;
    border-left: 2px red !important;
    /* line-height: 31px !important; */
    font-size: 15px !important;
}
.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.ml-3{margin-left: 10px;}
.res{
    background: #dde7ee;
    padding:  10px;
}
.pers{
    color: red;
}
.edu{
    background-color: azure;
    margin: 0px;
}
.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.ml-3{margin-left: 10px;}
.res{
    background: #dde7ee;
    padding:  10px;
}
.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.ml-3{margin-left: 10px;}
.res{
    background: #dde7ee;
    padding:  10px;
}
.mor1{
    display: none;
}
#toggle-content1 {
    display: none;
  }
  .mycheckbox1:checked~#toggle-content1 {
    display: block;
    height: 100px;
  }
    .mop{
        margin-left: 10px;
    }
    .pers{
        color: red;
    }
    .edu{
        background-color: azure;
        margin: 0px;
        padding: 6px;
    }
.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 500px;
  /* height: 480px; */
  transform: translate(-50%, -50%);
  padding: 30px;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebar {
  display: flex;
  margin: 5px 0px 15px 0px;
  /* flex-direction: column; */
  align-items: left !important;
  justify-content: left !important;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebar hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .form-control {
  line-height: 30px;
  border-radius: 4px;
  box-shadow: inset 2.5px 0px 0px #ee7e7e;
  padding-left: 15px;
  border: 1px solid #b8b9ba;
  box-sizing: border-box;
  border-radius: 3px !important;
  font-weight: normal;
  font-size: 13px;
  padding: 0 8px;
}

.book-form button {
  margin-top: 15px;
}
/* .content-scroll {
  max-height: 320px;
  overflow-y: scroll;
  overflow-x: hidden;
} */
.checkboxStatus input {
  width: auto !important;
  margin: 15px 3px !important;
}
.pagination-bar {
  margin-top: 25px;
}

.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}
.btn-primary {background-color: #1C2541!important;border: none;    padding: 6px 25px;}
.domain-btn{float: right;background-color: #14A5D3!important;
}
.error {
    background: #dc3545 !important;
    border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
    height: 220px;
}

.snackTitle {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    color: #184688;
    margin: 10px;
}

.rmdp-container {
    width: 100% !important;
}

.rmdp-calendar {
    height: 365px;
}

.snackIcon {
    font-size: 150px;
    transform: translate(75px, 35px);
    color: white;
}

.success {
    background: #35b651 !important;
    border-radius: 10px 10px 0px 0px;
}

.iconContaier {
    color: white;
}

.big-dia-event-create {
    background: #ffffff;
    position: fixed;
    box-shadow: 1px 1px 20px 5px #000000a1;
    top: 50%;
    left: 50%;
    width: calc(100% - 200px);
    height: calc(100% - 200px);
    transform: translate(-50%, -50%);
    padding: 30px;
    overflow: hidden;
}

.overlay {
    background: #000000b0;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 999;
    transform: translate(0%, 0%);
}

.messageContainer {
    margin: 0;
    padding: 0px 20px;
    height: 70px;
    overflow: auto;
    text-align: center;
}

.buttonContainer {
    justify-content: center;
    /* padding: 14px; */
    display: flex;
}

.snackButtonClass {
    width: 125px;
    color: white;
    height: 50px;
    /* box-shadow: 0 0 20px 0px #0000005e; */
    font-weight: 600;
    border: none;
    border-radius: 50px;
}

.book-head {
    font-family: "Mulish";
    font-weight: 600;
    line-height: 21px;
}

.tilebar {
    display: flex;
    margin: 5px 0px 10px 0px;
    flex-direction: column;
    align-items: center;
}

.diaCloseCon {
    /* padding: 20px; */
    float: right;
    font-size: 20px;
    font-weight: 900;
    line-height: 1;
    cursor: pointer;
}

.flexDisplay {
    display: flex;
}

.diaTitleCon {
    /* padding: 20px 0px 0px 20px; */
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    line-height: 1;
    padding-right: 0px;
}

.tilebar hr {
    margin: 1px !important;
    height: 2px !important;
    width: 51px;
    opacity: 1;
    color: #14a5d3;
}

.dialog input {
    width: 100%;
}

.dialog label {
    font-size: 13px;
    font-weight: 500;
}

.event-book-form div {
    margin-bottom: 6px;
}

.event-dia-action {
    margin-top: 20px;
}

.event-book-form {
    overflow: auto;
    height: calc(100% - 85px);
}

.event-book-form .fieldset .input-group .form-control {
    line-height: 30px;
    border-radius: 4px;
    padding-right: 0;
}

.event-book-form button {
    margin-top: 15px;
}

.dialog .form-select {
    font-size: 13px;
    box-shadow: inset 2.5px 0px 0px #ee7e7e;
}

.dialog-act {
    padding: 20px 0px;
}
.view-container {
  padding: 25px 40px 25px 40px;
}
.view-container h5 b {
  color: #0a223d;
}
.font-z select {
  font-size: 14px;
}
.admin-body-items thead tr {
  background-color: #666666;
  color: #ffffff;
}
.admin-body-items tbody tr:hover {
  background-color: #f1eded;
}
.btn-primary {
  background-color: #1c2541 !important;
  border: none;
  padding: 6px 25px;
}
.data .row {
  padding: 7px 5px;
  background: #e7e7e766!important;
  margin: 0 0 10px 0;
}
.appr{margin-right: 15px!important;}
/* .big-dia{left: 32%;
  width: 890px;
  height: 460px;
height: 650px;
top: 35%;} */
.error {
    background: #dc3545 !important;
    border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
    height: 220px;
}

.snackTitle {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    color: #184688;
    margin: 10px;
}

.event-booking-buttons {
    margin: auto 20px;
}

.snackIcon {
    font-size: 150px;
    transform: translate(75px, 35px);
    color: white;
}

.success {
    background: #35b651 !important;
    border-radius: 10px 10px 0px 0px;
}

.iconContaier {
    color: white;
}

.dia-big-event-actiity {
    background: #ffffff;
    position: fixed;
    box-shadow: 1px 1px 20px 5px #000000a1;
    top: 50%;
    left: 50%;
    width: 601px;
    height: 486px;
    transform: translate(-50%, -50%);
    padding: 30px;
    overflow-y: auto;
    overflow-x: hidden;
}

.overlay {
    background: #000000b0;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 999;
    transform: translate(0%, 0%);
}

.messageContainer {
    margin: 0;
    padding: 0px 20px;
    height: 70px;
    overflow: auto;
    text-align: center;
}

.buttonContainer {
    justify-content: center;
    /* padding: 14px; */
    display: flex;
}

.snackButtonClass {
    width: 125px;
    color: white;
    height: 50px;
    /* box-shadow: 0 0 20px 0px #0000005e; */
    font-weight: 600;
    border: none;
    border-radius: 50px;
}

.book-head {
    font-family: "Mulish";
    font-weight: 600;
    line-height: 21px;
}

.tilebar {
    display: flex;
    margin: 5px 0px 10px 0px;
    flex-direction: column;
    align-items: center;
}

.diaCloseCon {
    /* padding: 20px; */
    float: right;
    font-size: 20px;
    font-weight: 900;
    line-height: 1;
    cursor: pointer;
}

.flexDisplay {
    display: flex;
}

.diaTitleCon {
    /* padding: 20px 0px 0px 20px; */
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    line-height: 1;
    padding-right: 0px;
}

.tilebar hr {
    margin: 1px !important;
    height: 2px !important;
    width: 51px;
    opacity: 1;
    color: #14a5d3;
}

.dialog input {
    width: 100%;
}

.dialog label {
    font-size: 13px;
    font-weight: 500;
}

.event-booking-form div {
    margin-bottom: 6px;
}

.event-booking-form .fieldset .input-group .form-control {
    line-height: 30px;
    border-radius: 4px;
    padding-right: 0;
}

.event-booking-form button {
    margin-top: 15px;
}

.dialog .form-select {
    font-size: 13px;
    box-shadow: inset 2.5px 0px 0px #ee7e7e;
}
.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 460px;
  transform: translate(-150px, -212px);
  padding: 30px;
  overflow-y: auto;
  overflow-x: hidden;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebar {
  display: flex;
  margin: 5px 0px 10px 0px;
  flex-direction: column;
  align-items: center;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebar hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
  padding-right: 0;
}

.book-form button {
  margin-top: 15px;
}
.dialog .form-select {
  font-size: 13px;
  box-shadow: inset 2.5px 0px 0px #ee7e7e;
}

.view-container {
  padding: 25px 40px 25px 40px;
}
.view-container h5 b {
  color: #0a223d;
}
.font-z select {
  font-size: 14px;
}
.admin-body-items thead tr {
  background-color: #666666;
  color: #ffffff;
}
.admin-body-items tbody tr:hover {
  background-color: #f1eded;
}
.btn-primary {
  background-color: #1c2541 !important;
  border: none;
  padding: 6px 25px;
}

.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 430px;
  transform: translate(-150px, -212px);
  padding: 30px;
  overflow-y: auto;
  overflow-x: hidden;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
}

.tilebar {
  display: flex;
  margin: 5px 0px 10px 0px;
  flex-direction: column;
  align-items: center;
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebar hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
  padding-right: 0;
}

.book-form button {
  margin-top: 15px;
}
.dialog .form-select {
  font-size: 13px;
  box-shadow: inset 2.5px 0px 0px #ee7e7e;
}

.view-container {
  padding: 25px 40px 25px 40px;
}
.view-container h5 b {
  color: #0a223d;
}
.font-z select {
  font-size: 14px;
}
.admin-body-items thead tr {
  background-color: #666666;
  color: #ffffff;
}
.admin-body-items tbody tr:hover {
  background-color: #f1eded;
}
.btn-primary {
  background-color: #1c2541 !important;
  border: none;
  padding: 6px 25px;
}

.view-container {
  padding: 25px 40px 25px 40px;
}
.view-container h5 b {
  color: #0a223d;
}
.font-z select {
  font-size: 14px;
}
.admin-body-items thead tr {
  background-color: #666666;
  color: #ffffff;
}
.admin-body-items tbody tr:hover {
  background-color: #f1eded;
}
.pers{
  color: red;
  font-size: 12px;
}
.mop{
  margin-left: 20px;
}
.mr-2{
  margin-right: 10px;
}
.view-container{
padding:25px 20px 25px 40px;
}
.view-container h5 b{
    color: #0a223d;
}
 .font-z select{font-size: 14px;}

.hr {
    margin: 1px!important;
    height: 2px!important;
    width: 60px!important;
    border-radius: 10px!important;
    opacity: 1!important;
    color: #14A5D3!important;
}

.top {
    margin-left: 0px!important;
}

.bottom {
    margin-left: 20px!important;
}

.container-ul {
    display: flex;
    margin: 5px 0px 10px 0px;
    flex-direction: column;
    align-items: center;
}
.icon {
    height: 22px;
    line-height: 22px;
    color: grey;
    padding: 2px;
    display: flex;
    padding-right: 10px;
    font-size: 24px;
}

.parentdiv {
    height: 8px;
    display: flex;
    width: 100%;
    background-color: whitesmoke;
    border-radius: 40px;
}

.progressInd {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 166%;
    /* or 100px */
    display: flex;
    align-items: center;
    /* Primary */
    color: #009FE3;
    opacity: 0.3;
}

.label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    line-height: 166%;
    /* identical to box height, or 40px */
    color: #333333;
}

@media (min-width: 769px) {
    .label {
        font-size: 16px;
    }
    .progressInd {
        font-size: 50px;
    }
    .parentdiv {
        width: 200px;
    }
}
.App {
    font-family: sans-serif;
    text-align: center;
}

.marquee-container {
    display: flex;
    /* justify-content: center; */
}

.marquee {
    /* border: solid 1px #ccc; */
    /* width: 500px; */
    width: 100%;
    /* padding: 50px 0; */
    /* text-align: center; */
    overflow: hidden;
}

.marquee-content {
    animation-duration: 50s;
    display: flex;
    animation-name: marquee;
    width: -webkit-max-content;
    width: max-content;
    animation-iteration-count: infinite;
    justify-content: flex-end;
    animation-timing-function: linear;
}

.marquee-content.inactive {
    animation-play-state: paused;
}

@keyframes marquee {
    from {
        transform: translateX(50%)
    }
    to {
        transform: translateX(-100%)
    }
}
.tile img {
    background: #0A223D;
    border-radius: 50%;
    border: 2px solid #0A223D;
}

.tile h6 {
    margin-top: 4px;
    font-size: 17px;
    margin-bottom: 0px;
}

.tile p {
    color: #14A5D3;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
}

.adviser_image {
    width: 130px;
    height: 130px;
    border-radius: 50%!important;
    object-fit: cover;
}

.adv-bg {
    background: #f3f8ff;
}

.adviser_image.chief {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%!important;
}

;
.cd {
    background-color: unset!important;
}

.cd:hover {
    background-color: white!important;
}

.adviser-cards-container {
    width: 200px;
    margin: 15px auto;
}

.tile address {
    font-size: 12px;
    line-height: 13px;
    font-weight: 300;
}

.tile {
    border: none;
}

.hedp {
    background: #0A223D;
    text-align: center;
    padding: 7px 0px;
    color: white;
    font-size: 14px;
    border-radius: 3px;
}

.adbox {
    padding: 0px 16px;
}

.card:hover {
    background-color: #D2EBF7;
}


/* =================================================================================== */

@media (min-width:992px) {
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
        line-height: 38px;
    }
    .hedp {
        font-size: 17px;
    }
}
html {
  --lh: 1.7rem;
}

.truncate-overflow {
  --max-lines: 3;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem;
  /* space for ellipsis */
}

.truncate-overflow::before {
  position: absolute;
  content: "";
  inset-block-end: 0;
  /* "bottom" */
  inset-inline-end: 0;
  /* "right" */
}

.bgc-default {
  background: #c4c4c440;
  /* opacity: 0.4; */
}

.banner-skelton {
  height: 940px;
  width: 100%;
}

.tab-cont {
  overflow: auto;
  display: flex;
  /* width: 100%; */
}
.tab-cont-partners {
  overflow: auto;
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}

.tab-cont-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.tab-cont-cont-partners {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tab-switch {
  padding: 0px 42px 20px;
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  font-size: 15px;
  opacity: 0.3;
}
.tab-switch-partners {
  padding: 0px 0px 20px;
  color: #000000;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  font-size: 24px;
  /* opacity: 0.3; */
}

.org-border-active {
  margin: 0px !important;
  width: 120px !important;
  height: 3px !important;
  opacity: 1 !important;
  border-radius: 6px !important;
  color: #009fe3 !important;
}

.org-border-inactive {
  margin: 0px !important;
  width: 120px !important;
  height: 3px !important;
  opacity: 1 !important;
  border-radius: 6px !important;
  color: transparent !important;
}

.gal-border-active {
  margin: 5px !important;
  width: 50px !important;
  height: 3px !important;
  opacity: 1 !important;
  border-radius: 6px !important;
  display: none;
  color: #009fe3 !important;
}

.gal-border-inactive {
  margin: 5px !important;
  width: 50px !important;
  height: 3px !important;
  opacity: 1 !important;
  border-radius: 6px !important;
  color: transparent !important;
}

.tab-switch.activeTab {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  /* line-height: 185%; */
  /* identical to box height, or 56px */
  text-align: center;
  opacity: 1;
  /* Primary */
  color: #009fe3;
}
.tab-switch-partners.activeTab {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  /* line-height: 185%; */
  /* identical to box height, or 56px */
  text-align: center;
  /* opacity: 1; */
  /* Primary */
  color: #009fe3;
}

.scope-container,
.gallery-container,
.new-accomp-cont,
.event-new-container,
.org-structure {
  padding: 20px 0px 0px;
}

.relative {
  position: relative;
}

.truncate-overflow::after {
  content: "";
  position: absolute;
  inset-inline-end: 0;
  /* "right" */
  width: 1rem;
  height: 1rem;
  /* background: white; */
}

.banner {
  position: relative;
}

.banner-text {
  padding: 30px;
}

.bannerTitleContainer {
  font-size: 16px;
  font-family: "Mulish";
  /* padding: 80px; */
  color: white;
  font-weight: 500;
  position: absolute;
  bottom: 25px;

  margin-right: 30%;
}

.carlo-bg-quotes {
  background-color: #009fe3 !important;
}

.quotes-new-inner {
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
}

.quotes-icon {
  opacity: 0.6;
  height: 30px;
}

.bg-white {
  background-color: white !important;
}

.quote-carlose {
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
  align-items: center;
  justify-content: center;
}

.leftSliderCon {
  position: absolute;
  left: 0px;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sliderIcon {
  font-size: 70px;
  color: #0000001f;
}

.banner-flex-con {
  display: flex;
  position: relative;
}

.rightSliderCon {
  position: absolute;
  right: 0px;
  height: 100%;
  z-index: 999;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.about-right-logo {
  height: 40px;
}

.BannerTitle {
  /* padding-right: 10px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 160%;
}

.news-head-label {
  position: absolute;
  display: none;
  z-index: 3;
  background: #f0f0f0;
  padding: 7px 20px 7px 30px;
}

.pos-rel {
  position: relative;
}

.new-scroll {
  background: #009fe3;
  color: white;
  padding: 5px 10px;
  width: -webkit-max-content;
  width: max-content;
}

.news-label-text {
  padding: 3px 30px 3px 20px;
  border-right: 1px solid;
  font-weight: 500;
  font-family: "Poppins";
}

.new-scroll-new {
  background: #ed1e24;
  color: white;
  padding: 5px 10px;
}

.new-scroll-skew {
  /* padding: 0px 10px; */
}

.BannerTitleHighlight {
  /* padding-right: 10px; */
  /* color: #14a5d3;
     */
  color: #009fe3;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 160%;
}

.BannerSubTitle {
  font-size: 13px;
}

.newsIcon {
  color: red;
}

.newsIconContainer {
  padding-right: 10px;
}

.news {
  /* height: 40px;
        line-height: 40px; */
  font-size: 15;
  font-weight: 600;
  font-family: "Mulish";
  width: 100%;
}

.news-items {
  padding: 5px 20px;
  align-items: center;
  transform: skewX(-20deg);
  cursor: pointer;
  align-self: center;
}

.scopes {
  background: rgba(243, 248, 255, 0.88);
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
}

.scope-image-conta {
  display: flex;
  padding: 40px 15px;
  justify-content: center;
  align-items: center;
}

.left-scope-item {
  text-align: right;
  padding-top: 25px;
  padding-bottom: 25px;
}

.right-scope-item {
  text-align: left;
  padding-top: 25px;
  padding-bottom: 25px;
}

.img-scope {
  height: 404px;
}

.scope-card {
  margin: auto 0px;
  align-items: center;
  /* justify-content: center; */
  width: 313px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
  line-break: anywhere;
}

.scope-box {
  min-height: 404px;
  align-items: center;
}

.scope-left-box {
  text-align: left;
  justify-content: end;
}

.scope-item {
  /* justify-content: center; */
  /* align-items: center; */
  margin: 10px 0px;
  height: auto;
}

.left {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.scope-right-box {
  text-align: left;
  justify-content: end;
}

.scope-box-items-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.scope-icon {
  padding: 5px 20px 20px 20px;
}

.scope-text-box-con {
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
}

.news-label {
  width: 300px;
}

.news-scrolls {
  width: calc(100% - 300px);
}

.scope-left-text-box-con {
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
}

.scope-text-box {
  padding: 15px 0px 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}

.scope-items {
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  /* justify-content: space-between; */
  /* height: 235px; */
}

.scope-items-cont {
  padding: 20px 0px 0px;
  display: block;
}

.scope-right-image {
  padding-left: 0px;
  width: 100%;
}

.scope-left-image {
  padding-right: 0px;
  width: 100%;
}

.scope-right-direction {
  flex-direction: row-reverse;
}

.about-left {
  /* padding-left: 80px; */
  /* display: flex;
    flex-direction: column; */
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  /* padding-right: 104px; */
  padding: 20px;
  background: #009fe3;
  color: white;
}

.about-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  background: #f5fbfe;
}

.about-descs {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 185%;
  /* or 44px */
  text-align: center;
  color: #333333;
}

.about-tile-container {
  padding: 20px;
  margin: 20px 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.about-new {
  display: block;
}

.about-tile-header {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 185%;
  /* identical to box height, or 56px */
  text-align: center;
  color: #333333;
}

.about-left-title {
  text-align: left !important;
  font-family: Poppins;
  font-style: normal;
  /* height: 120px; */
  /* line-height: 120px;
         */
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 30px;
  /* identical to box height, or 93px */
  text-align: center;
  color: #ffffff;
}

.about-left-desc {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  /* or 48px */
  letter-spacing: 0.02em;
  line-height: 170%;
  color: #ffffff;
}

.about-cont {
  display: flex;
  /* margin: 20px 0px; */
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.acom-data {
  padding: 20px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.scope-title,
.new-accom-title,
.org-strecture-title {
  font-family: Poppins;
  font-style: normal;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 30px;
  /* padding-bottom: 60px; */
}

.galleryTitle {
  font-family: Poppins;
  font-style: normal;
  display: flex;
  align-items: center;
  padding: 20px 0px;
  justify-content: center;
  font-weight: 600;
  font-size: 30px;
}

.accom-items-cont {
  display: block;
}

.about {
  /* height: 44px; */
  text-transform: capitalize;
  padding: 40px 0px 20px;
  /* margin: 23px 0px; */
  /* line-height: 44px; */
  font-family: "Mulish";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
}

.about-cards {
  height: auto;
  padding: 15px;
  overflow-y: auto;
  width: 290px;
  text-align: justify;
  line-break: anywhere;
  display: flex;
  background: #ffffff;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 4px 4px rgb(0 0 0 / 25%); */
  border-radius: 3px;
}

.about-card-title {
  font-weight: 600;
  /* height: 28px; */
  text-align: center;
  text-transform: capitalize;
  /* line-height: 28px; */
  color: #0a223d;
  padding: 0px 10px;
  font-size: 20px;
}

.about-card-text {
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  margin-top: 10px;
  padding: 0px 10px;
  /* padding-bottom: 30px; */
  height: auto !important;
  /* text-align: center; */
  align-items: center;
  display: flex;
  justify-content: center;
}

.about-cards-cont {
  margin: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-icon-con {
  align-items: flex-start;
  justify-content: center;
  display: flex;
}

.strec-cont {
  padding: 30px 0px;
}
.strec-cont-partners {
  padding: 30px 65px;
}

.org-avatar.chief {
  width: 150px !important;
  height: 150px !important;
}

.strec-item-cont {
  align-items: center;
  justify-content: center !important;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  padding: 15px ​30px;
  line-height: 28px;
  margin-bottom: 20px;
  /* identical to box height */
  text-align: center;
  color: #0a223d;
}
.par-avatar-partners {
  height: auto;
  width: 300px !important;

}
.par-avatar-partners-small {
  height: auto;
  width: 250px !important;
  height: -webkit-max-content;
  height: max-content;
/* min-height: fit-content !important; */
}

.org-avatar {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

.org-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 185%;
  /* identical to box height, or 50px */
  text-align: center;
  color: #333333;
}

.core-item {
  padding: 20px;
}

.def-pad {
  padding-bottom: 100px;
}

.chief-item {
  padding: 50px;
}

.org-designation {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 185%;
  /* or 44px */
  text-align: center;
  color: #333333;
  opacity: 0.5;
}

.org-sub-containers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.org-sub-item {
  width: 50%;
  margin: 15px 0px;
}

.org-core-item {
  width: 50%;
  margin: 15px 0px;
}

.event-fee-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 185%;
  /* or 44px */
  display: flex;
  align-items: center;
  text-align: center;
}

.evnt-tile-btns-cont {
  display: flex;
  justify-content: space-around;
}

.evnt-tile-btn-cont {
  display: flex;
  justify-content: center;
}

.banner-img {
  object-fit: cover;
  height: calc(100vh - 160px);
  width: 100%;
}

.event-fee-value {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 185%;
  /* or 44px */
  display: flex;
  align-items: center;
  text-align: center;
}

.event-fee-value-RUPPE {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  padding: 0px 5px;
  line-height: 185%;
  display: flex;
  align-items: center;
  text-align: center;
}

.priceCont {
  height: 44px;
  padding: 10px 0px;
}

.book-now-btn-event {
  /* position: absolute; */
  width: calc(50% - 20px);
  height: 50px;
  margin: 10px;
  border: none;
  color: white;
  /* Primary */
  background: #009fe3;
  border-radius: 6px;
}

.read-more-btn-event {
  width: calc(50% - 20px);
  height: 50px;
  margin: 10px;
  background: #ffffff;
  /* Primary */
  border: 1px solid #009fe3;
  box-sizing: border-box;
  border-radius: 6px;
}

.read-more-btn-gallery {
  width: 200px;
  height: 50px;
  margin: 10px;
  background: #ffffff;
  /* Primary */
  border: 1px solid #009fe3;
  box-sizing: border-box;
  border-radius: 6px;
}

.value-end {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 185%;
  /* or 44px */
  display: flex;
  align-items: center;
  text-align: center;
}

.msg-data-con {
  /* display: flex;
        flex-direction: column-reverse; */
  padding: 0px 15px;
}

.event-tile-box-title {
  font-family: Poppins;
  font-style: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  font-size: 20px;
  line-height: 185%;
  /* identical to box height, or 63px */
  /* text-align: center; */
  color: #333333;
}
.news-updates-tile-box-title {
  font-family: Poppins;
  font-style: normal;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  overflow: hidden;
  font-weight: 600;
  font-size: 20px;
  line-height: 185%;
  /* identical to box height, or 63px */
  text-align: left;
  color: #333333;
}

.event-tile-box-desc {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 170%;
  height: 80px;
  overflow: hidden;
  /* or 37px */
  color: #333333;
}

.event-icons {
  height: 18px;
}

.event-venue {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 185%;
  /* identical to box height, or 37px */
  text-align: center;
  color: #333333;
  padding: 0px 10px;
}

.event-tile-data-container {
  padding: 20px;
}

.messages-main-title,
.event-main-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 35px;
  /* line-height: 185%; */
  /* identical to box height, or 93px */
  text-align: center;
  color: #ffffff;
}

.quote-icon-container {
  /* display: flex;
    align-items: center; */
}

.msg-person-photo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.msg-person-data-cont {
  padding: 0px 20px;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
}

.msg-person-photo-cont {
  width: 100px;
  height: 100px;
}

.pad-30 {
  padding: 0px 30px;
}

.msg-person-designation {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 190%;
  /* or 42px */
  display: flex;
  align-items: center;
  color: #ffffff;
  opacity: 0.7;
}

.msg-person-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 190%;
  /* or 49px */
  display: flex;
  align-items: center;
  color: #ffffff;
}

.new-message-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 190%;
  padding: 30px;
  /* or 46px */
  display: flex;
  align-items: center;
  color: #ffffff;
}

.new-events {
  background: #009fe3;
  align-items: center;
  justify-content: center;
  padding: 40px 0px 0px;
  /* margin: 20px; */
  height: auto;
}
.news-updates-tileContainer {
  /* width: full; */
}
.new-messages {
  background: #009fe3;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
  /* margin: 20px; */
  /* height: 1202px; */
}

.event-preview-img {
  width: 100%;
  padding: 10px;
}

.event-prv {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.event {
  /* height: 44px; */
  /* margin: 23px 0px; */
  /* line-height: 44px; */
  text-transform: capitalize;
  font-family: "Mulish";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
}

.left-tri {
  border: 0 solid #14a5d3;
  position: absolute;
  width: 264px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 35px;
  border-right-width: 264px;
  border-right-color: transparent;
}

.right-tri {
  border: 0 solid #07789c;
  width: 264px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 35px;
  border-left-width: 264px;
  border-left-color: transparent;
}

.tri-container {
  position: relative;
  display: flex;
}

.event-date-container {
  height: 30px;
  background: #14a5d3;
  width: 264px;
}

.event-card-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-shape-cont {
  margin-top: -65px;
  width: 318px;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.event-tile {
  padding: 40px 0px;
}

.event-datas {
  display: flex;
}

.new-event-info-details {
  height: 18px;
  line-height: 18px;
  padding: 20px 10px;
  font-size: 18px;
  font-weight: 600;
  color: white;
}

.event-data-container {
  height: 230px;
  background: #fff;
  padding: 15px;
  width: 264px;
  box-shadow: 4px 10px 9px rgba(0, 0, 0, 0.25);
}

.event-tile-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  height: 25px;
  text-align: center;
  color: #1c2541;
}

.event-tile-by-text {
  font-size: 11px;
  line-height: 14px;
  height: 14px;
  color: #a1a8af;
  padding-right: 5px;
}

.event-org-by-text {
  color: #0a223d;
  font-size: 12px;
  font-weight: 700;
  height: 16px;
  line-height: 16px;
}

.accom-cont-margin {
  margin-bottom: 20px;
}

.event-tile-data-text {
  font-size: 14px;
  padding-left: 2px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
}

.event-tiles-container {
  display: flex;
  /* margin-top: 20px; */
  width: 100%;
  flex-wrap: wrap;
  margin: 0px;
  justify-content: center;
}

.event-tile-box-container {
  width: calc(100% - 18px);
  height: auto;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 10px;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.news-updates-tile-box-container {
  width: calc(100%);
  height: auto;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 10px;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.event-card-tile {
  margin: 20px 20px;
}

.event-tile-desc {
  line-height: 17px;
  font-size: 13px;
  text-align: justify;
  color: #000;
  line-break: anywhere;
  margin: 8px 0px;
  overflow: hidden;
  height: 80px;
  text-overflow: ellipsis;
}

.event-fee-title-text {
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
}

.event-tile-fee-symbol {
  height: 17px;
  line-height: 20px;
  padding-left: 5px;
  font-size: 10px;
}

.event-tile-fee {
  line-height: 18px;
  height: 18px;
  font-size: 14px;
  color: #14a5d3;
  font-weight: 700;
}

.event-tile-btn {
  height: 25px;
  width: 84px;
  font-size: 13px;
  border: none;
}

.event-read-more {
  color: white;
  background: #1c2541;
}

.event-bkng {
  background: #14a5d3;
  color: black;
}

.gal-menus {
  font-size: 20px;
  display: flex;
}

.gal-menu.selected {
  color: #14a5d3;
}

.gal-menu {
  padding-right: 5px;
  cursor: pointer;
}

.gallery-container .gallery-data-container {
  width: calc(100% - 10px);
  margin: 5px;
  margin-top: 40px;
  /* padding: 0px 0px 50px 0px */
}

.gallery {
  display: grid;
  grid-template-columns: repeat(8);
  grid-template-rows: repeat(6);
  grid-gap: 2px;
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-arrow {
  color: white;
  background: #675959e0;
  height: 50px;
  align-items: center;
  width: 50px;
  font-size: 30px;
  cursor: pointer;
  outline: 0;
  position: absolute;
  justify-content: center;
  display: flex;
  top: calc(50% - 25px);
}

.gallery-arrow.left {
  left: 0;
}

.curser-zoomout {
  cursor: zoom-out;
}

.curser-zoomin {
  cursor: zoom-in;
}

.gallery-arrow.right {
  right: 0;
}

.gallery-preview {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #0d0000c2;
  z-index: 999;
  /* overflow: auto; */
}

.all-to-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pad-50 {
  padding: 50px;
}

/* .gallery__img:hover {} */

.gallery__item--1 {
  grid-column: 1 / span 2;
  grid-row: 1 / span 3;
}

.gallery__item--2 {
  grid-column: 3 / span 2;
  grid-row: 1 / span 2;
}

.gallery__item--3 {
  grid-column: 5 / span 4;
  grid-row: 1 / span 3;
}

.gallery__item--4 {
  grid-column: 1 / span 2;
  grid-row: 4 / span 2;
}

.gallery__item--5 {
  grid-column: 3 / span 2;
  grid-row: 3 / span 3;
}

.gallery__item--6 {
  grid-column: 5 / span 4;
  grid-row: 4 / span 2;
}

.quotes-container {
  background: #1c2541;
  /* min-height: 432px; */
  display: flex;
  /* margin-top: 20px;
    padding: 20px; */
  /* align-items: center; */
  justify-content: center;
  flex-direction: row-reverse;
}

.quotes-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 707px; */
}

.quotes-text {
  padding: 20px 40px;
  text-align: center;
  color: white;
  font-size: 14px;
}

.quote-symbel {
  height: 33px;
  width: 43px;
}

.event-fee {
  /* padding: 10px 0px; */
  height: 18px !important;
}

.quotes-by {
  color: #14a5d3;
  font-size: 15px;
  font-weight: 700;
}

.quotes-by-company {
  font-size: 13px;
  font-weight: 400;
  color: white;
}

.quotes-by-user-image {
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
}

.side-padd-50 {
  padding: 0px 50px;
}

.accom-rt {
  border-right: 0px solid #0005;
  padding: 20px;
  width: 100%;
}

.accom-left {
  border-left: 0px solid #0005;
  padding: 20px;
  width: 100%;
}

.progressCont {
  width: 100%;
  padding: 20px;
}

.quote-actions {
  background-color: white;
  margin: 10px 3px;
  border-radius: 50%;
  cursor: pointer;
  height: 16px;
  width: 16px;
}

.scopeCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quote-actions.default {
  background-color: #c2ffffbd;
}

.margin-car {
  margin: 20px 0px;
}

.accom-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 166%;
  /* identical to box height, or 50px */
  color: #333333;
}

.accom-name-span {
  text-overflow: ellipsis;
  white-space: unset;
  overflow: hidden;
  padding: 0px 20px;
}

.quotes-bg {
  display: none;
}

.icon-to-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-title {
  font-size: 20px !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.scope-icons-new {
  width: 60px;
  height: 60px;
  padding: 10px;
  background: #c4c4c422;
}

.about-left-desc {
  text-align: justify;
}

@media only screen and (min-width: 768px) {
  .about-left-desc {
    text-align: unset;
  }

  .priceCont {
    height: 74px;
    padding: 10px 0px;
  }

  .news-head-label {
    display: block;
  }

  .org-avatar.chief {
    width: 240px !important;
    height: 240px !important;
  }

  .accom-name-span {
    white-space: nowrap;
  }

  .event-tiles-container {
    display: flex;
    /* margin-top: 20px; */
    width: calc(100% - 60px);
    flex-wrap: wrap;
    margin: 0px 30px;
    justify-content: center;
  }
  .news-updates-tiles-container {
    display: flex;
    /* margin-top: 20px; */
    width: 380px;
    flex-wrap: wrap;
    margin: 0px 30px;
    justify-content: center;
  }

  .org-designation {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 185%;
    /* or 44px */
    text-align: center;
    color: #333333;
    opacity: 0.5;
  }

  .org-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 185%;
    /* identical to box height, or 50px */
    text-align: center;
    color: #333333;
  }

  .book-now-btn-event {
    /* position: absolute; */
    width: calc(50% - 20px);
    height: 50px;
    margin: 0px;
    border: none;
    color: white;
    /* Primary */
    background: #009fe3;
    border-radius: 6px;
  }

  .read-more-btn-event {
    width: calc(50% - 20px);
    height: 50px;
    margin: 0px;
    background: #ffffff;
    /* Primary */
    border: 1px solid #009fe3;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .value-end {
    font-size: 18px;
  }

  .event-fee-value {
    font-size: 25px;
  }

  .event-fee-value-RUPPE {
    font-size: 20px;
  }

  .event-fee-title {
    font-size: 18px;
  }

  .BannerTitleHighlight {
    font-size: 40px;
  }

  .BannerSubTitle {
    font-size: 20px;
  }

  .BannerTitle {
    font-size: 40px;
  }

  .org-avatar {
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }
  .par-avatar {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    padding: 6px;
  }
  .par-avatar-cover {
    border-radius: 50%;
    border: 2px solid #009fe3;
  }

  .accom-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 166%;
    /* identical to box height, or 50px */
    color: #333333;
  }

  .scope-title,
  .new-accom-title,
  .org-strecture-title,
  .event-main-title,
  .messages-main-title {
    font-size: 40px;
    /* padding-bottom: 60px; */
    /* margin-top: 60px; */
  }

  .galleryTitle {
    font-size: 40px;
  }

  .new-message-desc {
    font-size: 18px;
  }

  .accom-rt {
    border-right: 1px solid #0005;
    width: 50%;
    padding: 50px;
  }

  .accom-left {
    border-left: 1px solid #0005;
    width: 50%;
    padding: 50px;
  }

  .quotes-icon {
    height: 114px;
  }

  .quotes-new-inner {
    padding: 0px 100px;
    flex-direction: row;
  }

  .event-prv {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .accom-items-cont {
    display: flex;
    flex-wrap: wrap;
  }

  .about-tile-container {
    padding: 20px;
  }

  .about-descs {
    font-size: 18px;
  }

  .about-tile-header {
    font-size: 30px;
  }

  .about-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: calc(50% - 50px);
    background: #f5fbfe;
  }

  .event-tile-box-title {
    font-size: 22px;
  }

  .about-left {
    /* padding-left: 80px; */
    /* display: flex;
        flex-direction: column; */
    width: calc(50% + 54px);
    /* padding-right: 104px; */
    padding: 50px;
    background: #009fe3;
    color: white;
  }

  .event-tile-box-desc {
    font-size: 18px;
  }

  .scope-text-box-con {
    padding: 50px;
    display: flex;
    width: 50%;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
  }

  .event-venue {
    font-size: 15px;
  }

  .event-icons {
    height: 25px;
  }

  .scope-left-text-box-con {
    padding: 0px 50px 50px;
    display: flex;
    width: 50%;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
  }

  .scope-text-box {
    padding: 15px 0px 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  }

  .about-left-desc {
    font-size: 19px;
    /* or 48px */
  }

  .scope-items {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 20px;
    /* justify-content: space-between; */
    /* height: 235px; */
  }

  .scope-items-cont {
    padding: 60px 0px 0px;
    display: flex;
  }

  .scope-right-image {
    padding-left: 50px;
    width: 50%;
  }

  .scope-left-image {
    padding-right: 25px;
    width: 50%;
  }

  .scope-container,
  .gallery-container,
  .new-accomp-cont,
  .event-new-container,
  .org-structure {
    padding: 40px 0px 0px;
  }

  .about-new {
    display: flex;
  }

  .progressCont {
    width: 50%;
  }

  .about-left-title {
    text-align: left !important;
    font-family: Poppins;
    font-style: normal;
    /* height: 120px;
            line-height: 120px; */
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 40px;
    /* identical to box height, or 93px */
    text-align: center;
    color: #ffffff;
  }

  .event-tile-box-container {
    width: calc(33% - 20px);
  }

  .new-events {
    background: linear-gradient(to bottom, #009fe3 50%, white 50%);
    height: 915px;
  }

  .acom-data {
    padding: 50px 0px;
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (min-width: 992px) {
  .scope-title,
  .new-accom-title,
  .org-strecture-title,
  .event-main-title,
  .messages-main-title {
    font-size: 40px;
    /* padding-bottom: 60px; */
    /* margin-top: 60px; */
  }

  .galleryTitle {
    font-size: 40px;
  }

  .org-border-active {
    margin: 0px !important;
    width: 120px !important;
    height: 8px !important;
  }

  .org-border-inactive {
    margin: 0px !important;
    width: 120px !important;
    height: 8px !important;
    opacity: 1 !important;
    border-radius: 6px !important;
    color: transparent !important;
  }

  .gal-border-active {
    margin: 5px !important;
    width: 50px !important;
    height: 8px !important;
    opacity: 1 !important;
    display: block;
    border-radius: 6px !important;
    color: #009fe3 !important;
  }

  .gal-border-inactive {
    margin: 5px !important;
    width: 50px !important;
    height: 3px !important;
    opacity: 1 !important;
    border-radius: 6px !important;
    color: transparent !important;
  }

  .tab-switch {
    font-size: 30px;
  }

  .accom-rt {
    border-right: 1px solid #0005;
    width: 50%;
  }

  .accom-left {
    border-left: 1px solid #0005;
    width: 50%;
  }

  .acom-data {
    padding: 50px 0px;
    display: flex;
    flex-direction: row;
  }

  .quotes-icon {
    height: 114px;
  }

  .quotes-new-inner {
    padding: 0px 100px;
    min-height: 440px;
    flex-direction: row;
  }

  .accom-items-cont {
    display: flex;
    flex-wrap: wrap;
  }

  .about-tile-container {
    padding: 20px 35px;
  }

  .about-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: calc(50% - 50px);
    background: #f5fbfe;
  }

  .event-tile-box-title {
    font-size: 25px;
  }

  .event-tile-box-desc {
    font-size: 16px;
  }

  .event-venue {
    font-size: 18px;
  }

  .scope-left-text-box-con {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    padding: 0px 50px 50px;
  }

  .scope-text-box {
    padding: 15px 0px 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  }

  .scope-items {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 20px;
    /* justify-content: space-between; */
    /* height: 235px; */
  }

  .about-descs {
    font-size: 18px;
  }

  .gal-con-new {
    margin-top: 30px;
  }

  .scope-items-cont {
    padding: 60px 0px 0px;
    display: flex;
  }

  .scope-text-box-con {
    padding: 50px;
    display: flex;
    flex-direction: column;
    width: 50%;
    align-content: space-between;
    justify-content: space-between;
  }

  .scope-right-image {
    padding-left: 50px;
    width: 50%;
  }

  .scope-left-image {
    padding-right: 25px;
    width: 50%;
  }

  .scope-container,
  .gallery-container,
  .new-accomp-cont,
  .event-new-container,
  .org-structure {
    padding: 40px 0px 0px;
  }

  .about-new {
    display: flex;
  }

  .about-tile-header {
    font-size: 30px;
  }

  .about-left-title {
    text-align: left !important;
    font-family: Poppins;
    font-style: normal;
    /* height: 120px;
            line-height: 120px; */
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 40px;
    /* identical to box height, or 93px */
    text-align: center;
    color: #ffffff;
  }

  .about-left-desc {
    font-size: 19px;
    /* or 48px */
  }

  .progressCont {
    width: 50%;
  }

  .about-left {
    /* padding-left: 80px; */
    /* display: flex;
        flex-direction: column; */
    width: calc(50% + 50px);
    /* padding-right: 104px; */
    padding: 50px;
    background: #009fe3;
    color: white;
  }

  .event-prv {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .event-tile-box-container {
    width: calc(33%- 20px);
  }

  .new-events {
    background: linear-gradient(to bottom, #009fe3 50%, white 50%);
    height: 915px;
  }
}

/* .{} */

/* media queries */

.carousel-container.multi>button.react-multiple-carousel__arrow--left {
    left: 0;
    /* display : none; */
}

.carousel-container.multi>button.react-multiple-carousel__arrow--right {
    right: 0;
    /* display : none; */
}

.carousel-container.multi>button.react-multiple-carousel__arrow {
    border-radius: 0;
    background: none;
}

.carousel-container.multi.react-multi-carousel-list {
    position: unset;
}

.carousal-padding-multi {
    padding: 0px 50px;
    position: relative;
}

.carousel-container.multi>button.react-multiple-carousel__arrow.flaticon-back-5:before,
.carousel-container.multi>button.react-multiple-carousel__arrow.flaticon-next-7:before {
    color: #d0d0d0;
    font-size: 50px;
}

.carousel-container.multi>button.react-multiple-carousel__arrow.flaticon-back-5:hover::before,
.carousel-container.multi>button.react-multiple-carousel__arrow.flaticon-next-7:hover::before {
    color: #ffa93c;
    font-size: 50px;
}
 .react-multi-carousel-list .carousel-container button {
    display: none !important;
 }
 .org-sub-containers button {
    display: none !important;
 }
        .error {
            background: #dc3545!important;
            border-radius: 10px 10px 0px 0px;
        }
        
        .snackIconContainer {
            height: 220px;
        }
        
        .snackTitle {
            font-size: 25px;
            font-weight: 700;
            text-align: center;
            color: #184688;
            margin: 10px;
        }
        
        .snackIcon {
            font-size: 150px;
            transform: translate(75px, 35px);
            color: white;
        }
        
        .success {
            background: #35b651!important;
            border-radius: 10px 10px 0px 0px;
        }
        .info {
            background: #f8d301!important;
            border-radius: 10px 10px 0px 0px;
        }
        
        .iconContaier {
            color: white
        }
        
        .snackbar-msg-p {
            font-family: 'Mulish';
            margin: 0px!important;
        }
        
        .snackbar {
            background: #ffffff;
            position: fixed;
            border-radius: 10px;
            box-shadow: 1px 1px 20px 5px #000000a1;
            top: 50%;
            left: 50%;
            width: 300px;
            /* height: 424px; */
            transform: translate(-150px, -212px);
        }
        
        .overlay-snack {
            background: #000000b0;
            position: fixed;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            z-index: 9999;
            transform: translate(0%, 0%);
        }
        
        .snackmessageContainer {
            margin: 0;
            padding: 0px 20px;
            height: auto;
            overflow: auto;
            text-align: center;
        }
        
        .snackbuttonContainer {
            justify-content: center;
            /* padding: 14px; */
            display: flex;
            padding: 10px;
        }
        
        .snackButtonClass {
            width: 120px;
            color: white;
            height: 43px;
            /* box-shadow: 0 0 20px 0px #0000005e; */
            font-weight: 600;
            border: none;
            border-radius: 50px;
            margin-bottom: 7px;
        }
.event-bg {
    /* height: 178px; */
    background-image: url(/static/media/kspaceevent.e893dee4.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.event-bg h3 {
    color: #fff;
    text-align: center;
    font-size: 24px;
}

.events {
    background: rgba(243, 248, 255, 0.88);
    align-items: center;
    justify-content: center;
}

.m-55 {
    padding: 60px 0px;
}

.event {
    height: 44px;
    margin: 23px 0px;
    line-height: 44px;
    text-transform: capitalize;
    font-family: "Mulish";
    font-family: Open Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

.left-tri {
    border: 0 solid #14a5d3;
    position: absolute;
    width: 264px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-bottom-width: 35px;
    border-right-width: 264px;
    border-right-color: transparent;
}

.right-tri {
    border: 0 solid #07789c;
    width: 264px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 35px;
    border-left-width: 264px;
    border-left-color: transparent;
}

.tri-container {
    position: relative;
    display: flex;
}

.event-date-container {
    height: 30px;
    background: #14a5d3;
    width: 264px;
}

.event-card-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-shape-cont {
    margin-top: -65px;
    width: 318px;
    align-items: center;
    flex-direction: column;
    display: flex;
}

.event-tile {
    padding: 40px 0px;
}

.event-datas {
    display: flex;
}

.event-info-details {
    height: 24px;
    line-height: 24px;
    padding: 0px 10px;
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.event-data-container {
    height: 220px;
    background: #fff;
    padding: 15px;
    width: 264px;
    box-shadow: 4px 10px 9px rgba(0, 0, 0, 0.25);
}

.event-tile-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    height: 25px;
    text-align: center;
    color: #1c2541;
}

.event-tile-by-text {
    font-size: 11px;
    line-height: 14px;
    height: 14px;
    color: #a1a8af;
    padding-right: 5px;
}

.event-org-by-text {
    color: #0a223d;
    font-size: 12px;
    font-weight: 700;
    height: 16px;
    line-height: 16px;
}

.event-tile-data-text {
    font-size: 14px;
    padding-left: 2px;
}

.event-tile-container {
    display: flex;
    padding: 0px;
    width: 100%;
    background: linear-gradient(180deg, #2cafe7, #2cafe76e, #88d0f275, #f5fbfe);
    flex-wrap: wrap;
    justify-content: center;
}

.event-card-tile {
    margin: 20px 20px;
}

.event-fee-title-text {
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
}

.event-tile-fee-symbol {
    height: 17px;
    line-height: 20px;
    padding-left: 5px;
    font-size: 10px;
}

.event-tile-fee {
    line-height: 18px;
    height: 18px;
    font-size: 14px;
    color: #14a5d3;
    font-weight: 700;
}

.event-tile-btn {
    height: 25px;
    width: 84px;
    font-size: 13px;
    border: none;
}

.event-read-more {
    color: white;
    background: #1c2541;
}

.event-bkng {
    background: #14a5d3;
    color: black;
}

.mtb-5 {
    margin: 5px 0;
}

.mtb-25 {
    margin-top: 0px;
    margin-bottom: 25px;
}


/* event detail page */

.eventdetail-bg {
    height: 40vh;
    background-image: url(/static/media/eventdetail.83deddf9.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    position: relative;
}

.eventdetail-bg h3 {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 22px;
}

.head-detail {
    flex-direction: column-reverse;
}

.eventdetail-bg .bar {
    position: absolute;
    left: 55px;
    border-left: 8px solid #14a5d3;
    /* height: 65px; */
    width: 50%;
    bottom: 20px;
}

.detail-desc {
    text-align: justify;
    padding: 20px 0px;
}

.fee {
    color: #14a5d3;
    font-weight: bold;
}

.more-details {
    padding: 20px 0px;
}

.event-booknow-btn {
    background: #0a223d;
    color: #ffffff;
    padding: 2px 8px;
    height: 28px;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
}

.booknow-btn:hover {
    background: #14a5d3;
    color: #0a223d;
    font-weight: 700;
}

.my-3 {
    background: #f4f4f4;
    padding: 7px;
    min-height: 85px;
    border-left: 8px solid #14a5d3;
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.my-3 p {
    margin-bottom: 2px !important;
    font-weight: bold;
}

.font-weight-bold {
    font-weight: bold;
}

.my-3 span {
    padding-left: 10px;
    display: inline-flex;
}

.tile-pad {
    display: flex;
    padding: 50px 50px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .tile-pad {
        padding: 50px 30px;
    }
    .bar {
        /* width: 65%; */
        /* height: 60px; */
    }
    .bar h3 {
        font-size: 12px;
        margin-bottom: 0;
    }
    .m-55 {
        padding: 15px 0px;
    }
    .events {
        padding: 0px!important;
    }
    .event-bg h3 {
        font-size: 19px;
    }
}

@media only screen and (min-width: 992px) {
    .event-tile-container {
        padding: 0px 50px;
    }
}
.event-bg {
    /* height: 178px; */
    background-image: url(/static/media/kspaceevent.e893dee4.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.event-bg h3 {
    color: #fff;
    text-align: center;
    font-size: 24px;
}

.events {
    background: rgba(243, 248, 255, 0.88);
    align-items: center;
    justify-content: center;
}

.m-55 {
    padding: 60px 0px;
}

.event {
    height: 44px;
    margin: 23px 0px;
    line-height: 44px;
    text-transform: capitalize;
    font-family: "Mulish";
    font-family: Open Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

.event-bkng {
    background: #14a5d3;
    color: black;
}

.mtb-5 {
    margin: 5px 0;
}

.mtb-25 {
    margin-top: 0px;
    margin-bottom: 25px;
}


/* event detail page */

.eventdetail-bg {
    height: 40vh;
    background-image: url(/static/media/eventdetail.83deddf9.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    position: relative;
}

.eventdetail-bg h3 {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 22px;
}

.head-detail {
    flex-direction: column-reverse;
}

.eventdetail-bg .bar {
    position: absolute;
    left: 55px;
    border-left: 8px solid #14a5d3;
    /* height: 65px; */
    width: 50%;
    bottom: 20px;
}

.detail-desc {
    text-align: justify;
    padding: 20px 0px;
}

.fee {
    color: #14a5d3;
    font-weight: bold;
}

.more-details {
    padding: 20px 0px;
}

.booknow-btn {
    background: #0a223d;
    color: #ffffff;
    padding: 2px 8px;
    height: 28px;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
}

.booknow-btn:hover {
    background: #14a5d3;
    color: #0a223d;
    font-weight: 700;
}
.cover_image{
    object-fit: cover !important;
    width: 100% !important;
    height : 400px !important;
    padding : 10px !important
}
.full_img {
   /* max-width: fit-content !important; */
   /* height: max-content; */
   overflow: auto;
   justify-content: center !important;
}
.my-3 {
    background: #f4f4f4;
    padding: 7px;
    min-height: 85px;
    border-left: 8px solid #14a5d3;
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.gal-i {
    padding: 30px 10px!important;
}

.my-3 p {
    margin-bottom: 2px !important;
    font-weight: bold;
}

.font-weight-bold {
    font-weight: bold;
}

.my-3 span {
    padding-left: 10px;
    display: inline-flex;
}

.tile-pad {
    display: flex;
    padding: 50px 50px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .tile-pad {
        padding: 50px 30px;
    }
    .bar {
        /* width: 65%; */
        /* height: 60px; */
    }
    .bar h3 {
        font-size: 12px;
        margin-bottom: 0;
    }
    .m-55 {
        padding: 15px 0px;
    }
    .event-bg h3 {
        font-size: 19px;
    }
}
.tile img {
    background: #0A223D;
    border-radius: 50%;
    border: 2px solid #0A223D;
}

.tile h6 {
    margin-top: 4px;
    font-size: 17px;
    margin-bottom: 0px;
}

.tile p {
    color: #14A5D3;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
}

.adviser_image {
    width: 130px;
    height: 130px;
    border-radius: 50%!important;
    object-fit: cover;
}

.adv-bg {
    background: #f3f8ff;
}

.adviser_image.chief {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%!important;
}

;
.cd {
    background-color: unset!important;
}

.cd:hover {
    background-color: white!important;
}

.adviser-cards-container {
    width: 200px;
    margin: 15px auto;
}

.tile address {
    font-size: 12px;
    line-height: 13px;
    font-weight: 300;
}

.tile {
    border: none;
}

.hedp {
    background: #0A223D;
    text-align: center;
    padding: 7px 0px;
    color: white;
    font-size: 14px;
    border-radius: 3px;
}

.adbox {
    padding: 0px 16px;
}

.card:hover {
    background-color: #D2EBF7;
}


/* =================================================================================== */

@media (min-width:992px) {
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
        line-height: 38px;
    }
    .hedp {
        font-size: 17px;
    }
}
.tile img {
    background: #0A223D;
    border-radius: 50%;
    border: 2px solid #0A223D;
}

.tile h6 {
    margin-top: 4px;
    font-size: 17px;
    margin-bottom: 0px;
}

.tile p {
    color: #14A5D3;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
}

.adviser_image {
    width: 130px;
    height: 130px;
    border-radius: 50%!important;
    object-fit: cover;
}

.adv-bg {
    background: #f3f8ff;
}

.adviser_image.chief {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%!important;
}

;
.cd {
    background-color: unset!important;
}

.cd:hover {
    background-color: white!important;
}

.adviser-cards-container {
    width: 200px;
    margin: 15px auto;
}

.tile address {
    font-size: 12px;
    line-height: 13px;
    font-weight: 300;
}

.tile {
    border: none;
}

.hedp {
    background: #0A223D;
    text-align: center;
    padding: 7px 0px;
    color: white;
    font-size: 14px;
    border-radius: 3px;
}

.adbox {
    padding: 0px 16px;
}

.card:hover {
    background-color: #D2EBF7;
}


/* =================================================================================== */

@media (min-width:992px) {
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
        line-height: 38px;
    }
    .hedp {
        font-size: 17px;
    }
}
.tile img {
    background: #0A223D;
    border-radius: 50%;
    border: 2px solid #0A223D;
}

.tile h6 {
    margin-top: 4px;
    font-size: 17px;
    margin-bottom: 0px;
}

.tile p {
    color: #14A5D3;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
}

.adviser_image {
    width: 130px;
    height: 130px;
    border-radius: 50%!important;
    object-fit: cover;
}

.adv-bg {
    background: #f3f8ff;
}

.adviser_image.chief {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%!important;
}

;
.cd {
    background-color: unset!important;
}

.cd:hover {
    background-color: white!important;
}

.adviser-cards-container {
    width: 200px;
    margin: 15px auto;
}

.tile address {
    font-size: 12px;
    line-height: 13px;
    font-weight: 300;
}

.tile {
    border: none;
}

.hedp {
    background: #0A223D;
    text-align: center;
    padding: 7px 0px;
    color: white;
    font-size: 14px;
    border-radius: 3px;
}

.adbox {
    padding: 0px 16px;
}

.card:hover {
    background-color: #D2EBF7;
}


/* =================================================================================== */

@media (min-width:992px) {
    .dash-banner {
        height: 162px;
    }
    .tit-txt {
        font-size: 30px;
        line-height: 38px;
    }
    .hedp {
        font-size: 17px;
    }
}
.tender {
  min-height: calc(100vh - 428px);
}

.bg-tender {
  background: #f3f8ff;
}

.vx {
  font-size: 18px !important;
}

.ix {
  font-family: Mulish;
  font-style: normal;
  color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
}

.tix {
  font-size: 30px;
  color: white;
  font-weight: 600;
}

.container-ul {
  display: flex;
  margin: 5px 0px 10px 0px;
  flex-direction: column;
  align-items: center;
}

/* .hr-tenders {
  margin: 1px !important;
  height: 3px !important;
  width: 35px;
  opacity: 1;
  color: #14a5d3;
} */

.top {
  margin-left: 0px !important;
}

.bottom {
  margin-left: 20px !important;
}

.tender-head {
  padding: 15px 0px;
}

.tender-head h4 {
  text-align: center;
  margin-bottom: 0px;
}

.m-30 {
  margin: auto 30px;
}

.p-30 {
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    -2px 1px 11px -5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.p-50 {
  padding: 50px 70px;
}

.tender-box {
  margin: auto 88px;
}

.tender-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.tender-box .head {
  text-transform: uppercase;
}

.tender-box p {
  font-family: Mulish !important;
  margin-bottom: 5px;
}

.blink_me {
  animation: blinker 1s linear infinite;
  font-size: 17px !important;
  color: black !important;
  font-weight: 400 !important;
  margin-top: -4px;
}

@keyframes blinker {
  30% {
    opacity: 0;
  }
}

.label {
  align-self: baseline;
}

.label p {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  font-weight: 600;
  color: #444;
}

.contentx p {
  font-size: 15px;
  font-weight: 600;
}

.tender-datas {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.bg-white {
  padding-bottom: 35px;
  padding-top: 65px;
}

.break_link {
  word-break: break-all;
  cursor: pointer;
}
.parserfont p {
  font-size: 13px;
  font-weight: inherit;
  margin: 0px;
}

@media only screen and (max-width: 992px) {
  .tender-box {
    margin: auto 30px;
  }
  .tender-datas {
    flex-direction: column;
    display: unset;
  }
  /* .dash-banner .tit-txt {
    font-size: 18px!important;
} */
  .tender-box p {
    text-align: center;
    letter-spacing: 0px;
    overflow-wrap: anywhere;
  }
  .bg-white {
    padding-top: 35px;
  }
  .p-50 {
    padding: 22px 16px;
  }
  .contentx p {
    font-size: 14px;
    font-weight: 700;
  }
  .p-30 {
    padding: 19px;
  }
  .overf {
    overflow: hidden;
  }
  .tix {
    font-size: 19px;
  }
  .note p {
    text-align: justify;
  }
}

.tender {
  min-height: calc(100vh - 428px);
}

.bg-tender {
  background: #f3f8ff;
}

.vx {
  font-size: 18px !important;
}

.ix {
  font-family: Mulish;
  font-style: normal;
  color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
}

.tix {
  font-size: 30px;
  color: white;
  font-weight: 600;
}

.container-ul {
  display: flex;
  margin: 5px 0px 10px 0px;
  flex-direction: column;
  align-items: center;
}

/* .hr-tenders {
  margin: 1px !important;
  height: 3px !important;
  width: 35px;
  opacity: 1;
  color: #14a5d3;
} */

.top {
  margin-left: 0px !important;
}

.bottom {
  margin-left: 20px !important;
}

.tender-head {
  padding: 15px 0px;
}

.tender-head h4 {
  text-align: center;
  margin-bottom: 0px;
}

.m-30 {
  margin: auto 30px;
}

.p-30 {
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    -2px 1px 11px -5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.p-50 {
  padding: 50px 70px;
}

.tender-box {
  margin: auto 88px;
}

.tender-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.tender-box .head {
  text-transform: uppercase;
}

.tender-box p {
  font-family: Mulish !important;
  margin-bottom: 5px;
}

.blink_me {
  animation: blinker 1s linear infinite;
  font-size: 17px !important;
  color: black !important;
  font-weight: 400 !important;
  margin-top: -4px;
}

@keyframes blinker {
  30% {
    opacity: 0;
  }
}

.label {
  align-self: baseline;
}

.label p {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  font-weight: 600;
  color: #444;
}

.contentx p {
  font-size: 15px;
  font-weight: 600;
}

.tender-datas {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.bg-white {
  padding-bottom: 35px;
  padding-top: 65px;
}

.break_link {
  word-break: break-all;
  cursor: pointer;
}
.parserfont p {
  font-size: 13px;
  font-weight: inherit;
  margin: 0px;
}

@media only screen and (max-width: 992px) {
  .tender-box {
    margin: auto 30px;
  }
  .tender-datas {
    flex-direction: column;
    display: unset;
  }
  /* .dash-banner .tit-txt {
    font-size: 18px!important;
} */
  .tender-box p {
    text-align: center;
    letter-spacing: 0px;
    overflow-wrap: anywhere;
  }
  .bg-white {
    padding-top: 35px;
  }
  .p-50 {
    padding: 22px 16px;
  }
  .contentx p {
    font-size: 14px;
    font-weight: 700;
  }
  .p-30 {
    padding: 19px;
  }
  .overf {
    overflow: hidden;
  }
  .tix {
    font-size: 19px;
  }
  .note p {
    text-align: justify;
  }
}

.error {
  background: #dc3545 !important;
  border-radius: 10px 10px 0px 0px;
}

.snackIconContainer {
  height: 220px;
}

.snackTitle {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #184688;
  margin: 10px;
}

.snackIcon {
  font-size: 150px;
  transform: translate(75px, 35px);
  color: white;
}

.success {
  background: #35b651 !important;
  border-radius: 10px 10px 0px 0px;
}

.iconContaier {
  color: white;
}

.big-dia-book {
  background: #ffffff;
  position: fixed;
  box-shadow: 1px 1px 20px 5px #000000a1;
  top: 50%;
  left: 50%;
  /* max-height: 75%; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transform: translate(-50%, -50%);
  padding: 30px;
}

.overlay {
  background: #000000b0;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transform: translate(0%, 0%);
}

.messageContainer {
  margin: 0;
  padding: 0px 20px;
  height: 70px;
  overflow: auto;
  text-align: center;
}

.buttonContainer {
  justify-content: center;
  /* padding: 14px; */
  display: flex;
}

.snackButtonClass {
  width: 125px;
  color: white;
  height: 50px;
  /* box-shadow: 0 0 20px 0px #0000005e; */
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.book-head {
  font-family: "Mulish";
  font-weight: 600;
  line-height: 21px;
  flex: 0.5 1;
}
.dia-close {
  flex: 0.5 1;
}
.tilebar-book {
  display: flex;
  margin: 5px 0px 10px 0px;
  /* flex-direction: column; */
  /* align-items: center; */
}

.diaCloseCon {
  /* padding: 20px; */
  float: right;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  cursor: pointer;
}

.flexDisplay {
  display: flex;
}

.diaTitleCon {
  /* padding: 20px 0px 0px 20px; */
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
  padding-right: 0px;
}

.tilebar-book hr {
  margin: 1px !important;
  height: 2px !important;
  width: 51px;
  opacity: 1;
  color: #14a5d3;
}

.dialog input {
  width: 100%;
}

.dialog label {
  font-size: 13px;
  font-weight: 500;
}

.book-form div {
  margin-bottom: 6px;
}

.book-form .fieldset .input-group .form-control {
  line-height: 30px;
  border-radius: 4px;
}

.book-form button {
  margin-top: 15px;
}

.table-head {
  background-color: brown;
  color: #fff;
  border: 1px solid black;

}

tr {
  border: 1px solid black;
}

th {

  padding: 10px;
  text-align: center;
}

td {
  border: 1px solid black;
  padding: 8px;
  font-size: 13px;
  color: #000000;
}

.table-container {
  margin: 10px 10px 10px 10px;
}
.launchheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  color: red;
  padding: 0px 20px;

}

.launchfooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  color: red;
  padding: 0px 20px;


}

.launchmain-logo {
  height: 90px;
  width: 120px;
}

.launchhero-area {
  background: linear-gradient(0deg, rgba(12, 12, 12, 0.5), rgba(18, 17, 17, 0.5));
  height: 50vh;
  position: relative;

}

.launchmain-hero-wrapper {
  height: 100vh;
}

.launchcontent-area {
  position: absolute;
  top: 72%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: poppins;


}

.launchcm-img {
  height: 200px;
  width: 200px;
  background-color: #d5d5d5;
  border-radius: 50%;
  padding: 5px;
}

.launchcontent-area button {
  padding: 15px 32px;
  background-color: #2f2483;
  border-radius: 15px;
  transition: 500ms;
  border: solid 3px #2f2483;
  font-weight: 600;
  font-size: 21px;
  color: #fff;
}

.launchcontent-area button:hover {
  border: solid 3px #2f2483;
  color: #2f2483;
  background-color: #fff;
}

.launchfooter-logo {
  width: 300px;
  height: 90px;
}
.tender {
    min-height: calc(100vh - 428px);
}

.bg-tender {
    background: #f3f8ff;
}

.vx {
    font-size: 18px !important;
}

.ix {
    font-family: Mulish;
    font-style: normal;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
}

.tix {
    font-size: 30px;
    color: white;
    font-weight: 600;
}

.container-ul {
    display: flex;
    margin: 5px 0px 10px 0px;
    flex-direction: column;
    align-items: center;
}


/* .hr-tenders {
  margin: 1px !important;
  height: 3px !important;
  width: 35px;
  opacity: 1;
  color: #14a5d3;
} */

.top {
    margin-left: 0px !important;
}

.bottom {
    margin-left: 20px !important;
}

.tender-head {
    padding: 15px 0px;
}

.tender-head h4 {
    text-align: center;
    margin-bottom: 0px;
}

.m-30 {
    margin: auto 30px;
}

.p-30 {
    padding: 30px;
    margin-bottom: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -2px 1px 11px -5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.p-50 {
    padding: 50px 70px;
}

.tender-box {
    margin: auto 88px;
}

.tender-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
}

.tender-box .head {
    text-transform: uppercase;
    /* font-size: 30px; */
}

.tender-box .head u {
    /* text-transform: uppercase; */
    font-size: 20px;
}

.tender-box p {
    font-family: Mulish !important;
    margin-bottom: 5px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    font-size: 17px !important;
    color: black !important;
    font-weight: 400 !important;
    margin-top: -4px;
}

@keyframes blinker {
    30% {
        opacity: 0;
    }
}

.label {
    align-self: baseline;
}

.label p {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    font-weight: 600;
    color: #444;
}

.contentx p {
    font-size: 15px;
    font-weight: 600;
}

.tender-datas {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.bg-white {
    padding-bottom: 35px;
    padding-top: 65px;
}

.break_link {
    word-break: break-all;
    cursor: pointer;
}

@media only screen and (max-width: 992px) {
    .tender-box {
        margin: auto 30px;
    }

    .tender-datas {
        flex-direction: column;
        display: unset;
    }

    /* .dash-banner .tit-txt {
    font-size: 18px!important;
} */
    .tender-box p {
        text-align: center;
        letter-spacing: 0px;
        overflow-wrap: anywhere;
    }

    .bg-white {
        padding-top: 35px;
    }

    .p-50 {
        padding: 22px 16px;
    }

    .contentx p {
        font-size: 14px;
        font-weight: 700;
    }

    .p-30 {
        padding: 19px;
    }

    .overf {
        overflow: hidden;
    }

    .tix {
        font-size: 19px;
    }

    .note p {
        text-align: justify;
    }
}
body {
    margin: 0;
    font-family: -apple-system, "Mulish", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.proposal-tiles-container {
    margin: 20px 0px;
}

.res_photo {
    width: 100%
}
.csvExp{
    height: 32px;
    width: 32px;
}

.header-banner {
    background: #2cafe7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
}
.banner-clamp{
    overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
}
.header-banner-from-home {
    background: #2cafe7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 20px 40px;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
    background: white !important;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 2000ms ease-in;
}

.bgc-white {
    background: white;
}

.check-box-cont {
    display: flex;
    flex-wrap: wrap;
}

.detailFile {
    cursor: pointer;
    color: #ee7e7e;
}


/* width */

/* ::-webkit-scrollbar {
    width: 10px;
} */


/* Track */

::-webkit-scrollbar-track {
    background: #b5b5b500;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #14a5d300;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #55555500;
}

.fieldset .input-group .form-control {
    /* background: #fafafa; */
    border: 1px solid #b8b9ba;
    box-sizing: border-box;
    border-radius: 3px !important;
    font-weight: normal;
    font-size: 13px;
    line-height: 32px;
    color: #222222;
    padding: 0 8px;
    transition: all 0.3s ease;
    background-image: none;
}
.clearSearch{
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    height : 38px !important;
    cursor : pointer;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    background-color: #0a223d;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);

}

.input-group.mandatory .form-control {
    box-shadow: inset 2.5px 0px 0px #ee7e7e !important;
    padding-left: 14px;
}

.upload-field {
    width: 100% !important;
}
.upload-field-head {
    width: 100% !important;
}

.upload-field label {
    background: #ffffff;
    border: 1px solid #b8b9ba;
    border-radius: 3px;
    font-weight: normal;
    font-size: 12px;
    color: #9a9393;
    line-height: 32px;
    height: 32px;
    padding: 0 15px;
    width: 100%;
    cursor: pointer;
    position: relative;
}
.upload-field-head label {
    background: #ffffff;
    border: 1px solid #b8b9ba;
    border-radius: 3px;
    font-weight: normal;
    font-size: 15px;
    color: #9a9393;
    line-height: 33px;
    height: 37px;
    padding: 0 15px;
    width: 100%;
    cursor: pointer;
    position: relative;
}

.proposal-gaps {
    margin-bottom: 20px;
    padding: 20px;
}

.errorfile {
    box-shadow: inset 2.5px 0px 0px #ee7e7e;
}

.allToCenter {
    align-items: center;
    justify-content: center;
    display: flex;
}

.upload-field input[type="file"] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.upload-field-head input[type="file"] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.fieldset .input-group .form-control::placeholder {
    color: #888888;
    font-size: 13px;
}

.fieldset .input-group .form-control {
    height: auto;
    /* cursor: pointer; */
}

.errorinput {
    border-color: 1px red !important;
}

.labelerror {
    color: red !important;
}

.fieldset .input-group select.form-control {
    height: 34px;
    -webkit-appearance: auto;
            appearance: auto;
}

fieldset.input-group .form-control {
    color: #222;
    font-size: 13px;
    font-weight: normal;
}

fieldset.input-group .form-control::placeholder {
    font-weight: normal;
    font-size: 13px;
    color: #888888;
}

@media (min-width: 992px) {
    .proposal-tiles-container {
        margin: 50px 0px;
    }
}
